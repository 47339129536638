import { BufferTraversalPath } from "./BufferTraversalPath";
import Vue from "vue";

export type EditorMergeStatus = 'noChange'|'merged'|'conflict'|'requiresRefresh';

export class EditorConflictMap {
  [key: string]: string|number|boolean|EditorConflictMap|undefined;

  public static addConflict(map: EditorConflictMap, path: BufferTraversalPath, value: string|number|boolean): void {

    if (path.child == undefined) {
      const key = path.formattedKey;

      Vue.set(map, key, value);
    } else {
      if (map[path.formattedKey] == undefined) {
        Vue.set(map, path.formattedKey, new EditorConflictMap());
      }

      EditorConflictMap.addConflict(map[path.formattedKey] as EditorConflictMap, path.child, value);
    }
  }
}

export class EditorConflictValues {
  constructor(
    public mine: unknown,
    public theirs: unknown,
    public displayMine: string|false = false,
    public displayTheirs: string|false = false,
    public resolveWith: string[]|false = false,
  ) {}
}

export const ENTITY_DELETED_FLAG = "<<<ENTITY_DELETED>>>"