import * as gt from '../getterNames'
import { TicketType, TicketTypeListModel } from '@/models/tickets/TicketType'
import Client from '@/models/core/Client'
import { ENTITY_MANAGER_REF } from '../refNames'
import { GetterTree } from 'vuex'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.TICKET_TYPES_FOR_CURRENT_CLIENT](state, getters): TicketTypeListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.TICKET_TYPES_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): TicketTypeListModel[] => {
      return state.getListModelsForRef(client, refName);
  },
    
  [gt.TICKET_TYPES_OPEN_FOR_CURRENT_CLIENT] (state, getters): TicketType[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.TICKET_TYPES_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): TicketType[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.TICKET_TYPE_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },
}