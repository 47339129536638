
import MessageContact from '@/models/messages/MessageContact'
import MessageContactComponent from './MessageContact.vue'
import MessageHeaderRow from './MessageHeaderRow.vue'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  components: {
    MessageContactComponent,
    MessageHeaderRow,
  },

  props: {
    contacts: {
      type: Array,
      default: (): MessageContact[] => []
    } as PropValidator<MessageContact[]>,

    label: {
      type: String,
      default: "",
    },
  }
})
