import { MessageContactState, MessageContactType } from './MessageEnums';

export default class MessageContact {
  public state: MessageContactState = MessageContactState.New;
  public type: MessageContactType = MessageContactType.From;
  public name: string|null = null;
  public address = "";

  public static CopyNewContact(original: MessageContact, typeOverride: MessageContactType|false = false): MessageContact {
    const copy = new MessageContact();
    copy.name = original.name;
    copy.address = original.address;
    
    if(typeOverride !== false) {
      copy.type = typeOverride;
    } else {
      copy.type = original.type;
    }

    return copy;
  }
}