// Core
export const CORE_TOGGLE_SIDENAV_MINI = 'coreToggleSidenavMini'
export const CORE_INCREMENT_PERMISSIONS_CHANGE_COUNT = "coreUpdatePermissionsChangeCount"
export const CORE_INCREMENT_NAV_COUNT = "coreIncrementNavCount"
export const CORE_SET_LOADING_CLIENT = "coreSetLoadingClient"

export const CORE_SET_APP_DATA_LOAD_STATE = 'coreAppDataLoadState'
export const CORE_SET_APP_CONFIG = 'coreSetAppConfig'
export const CORE_SET_PAGE_TITLE = 'coreSetPageTitle'

export const CORE_USER_ID_SET = 'userIdSet'
export const CORE_REPLACE_USER_LIST = 'coreReplaceUserList'

export const CORE_ADD_NOTIFICATION = 'coreAddNotification'
export const CORE_REMOVE_NOTIFICATION = 'coreRemoveNotification'

export const CORE_CLIENT_REPLACE_ALL = 'clientReplaceAll'
export const CORE_CLIENT_SET_CURRENT = 'clientSetCurrent'

export const CORE_ADD_POPUP_REQUEST = 'coreAddPopupRequest'
export const CORE_REMOVE_POPUP_REQUEST = 'coreRemovePopupRequest'

export const CORE_ADD_DOC_LINK_REQUEST = 'coreAddDocLinkRequest'
export const CORE_REMOVE_DOC_LINK_REQUEST = 'coreRemoveDocLinkRequest'

export const CORE_ADD_DOC_CLOSE_REQUEST = 'coreAddDocCloseRequest'
export const CORE_REMOVE_DOC_CLOSE_REQUEST = 'coreRemoveDocCloseRequest'

export const CORE_CREATE_ENTITY_MANAGER_STATE = 'coreCreateEntityManagerState'
export const CORE_SET_ENTITY_MANAGER_STATE = 'coreSetEntityManagerState'
export const CORE_CLEAR_ENTITY_MANAGER_STATE = 'coreClearEntityManagerState'
export const CORE_ENTITY_MANAGER_PAGE_DATA_SET = 'coreEntityManagerPageDataSet'

export const CORE_SET_ENTITY_EDITOR_STATE = 'coreSetEntityEditorState'
export const CORE_ADD_ENTITY_EDITOR_DEPENDENT_BUFFER = 'coreAddEntityEditorDependentBuffer'
export const CORE_ADD_ENTITY_EDITOR_DEPENDENT_REFERRER = 'coreAddEntityEditorDependentReferrer'
export const CORE_CLEAR_ENTITY_EDITOR_STATE = 'coreClearEntityEditorState'
export const CORE_SET_ENTITY_EDITOR_ADDITIONAL_DATA = 'coreSetEntityEditorAdditionalData'
export const CORE_SET_ENTITY_EDITOR_COUNTER = 'coreSetEntityEditorCounter'

export const CORE_PROCESS_ENTITY_UPDATE = 'coreProcessEntityUpdate'
export const CORE_PROCESS_LINK_COUNT_UPDATE = 'coreProcessLinkCountUpdate'

export const CORE_SET_EDITOR_BUFFER = 'coreSetEditorBuffer'
export const CORE_SET_EDITOR_BUFFER_HAS_UNSAVED = 'coreSetEditorBufferHasUnsaved'
export const CORE_SET_EDITOR_BUFFER_REFRESHING = 'coreSetEditorBufferRefreshing'
export const CORE_SET_EDITOR_BUFFER_VALID = 'coreSetEditorBufferValid'
export const CORE_SET_EDITOR_BUFFER_SAVE_ACTION_NAME = 'coreSetEditorBufferSaveActionName'
export const CORE_BUFFERED_EDITOR_APPLY_EDITS = 'coreBuffedEditorApplyEdits'
export const CORE_CLEAR_EDITOR_BUFFER = 'coreClearEditorBuffer'
export const CORE_RESOLVE_EDITOR_CONFLICT = 'coreResolveEditorConflict'
export const CORE_DISMISS_EDITOR_MERGE_STATUS = 'coreDismissEditorMergeStatus'
export const CORE_OPEN_CONFLICT_RESOLVER = 'coreOpenConflictResolver'

export const CORE_STATUS_KANBAN_ADD = 'coreStatusKanbanAdd'
export const CORE_STATUS_KANBAN_REMOVE = 'coreStatusKanbanRemove'
export const CORE_STATUS_KANBAN_CHANGE_FILTER = 'coreStatusKanbanInvertFilter'
export const CORE_STATUS_KANBAN_SET_CARD_LOADING = 'coreStatusKanbanSetCardLoading'
export const CORE_STATUS_KANBAN_UPDATE_PAGING_DATA = 'coreStatusKanbanUpdatePagingData'

export const CORE_SET_ACTIVE_LIFECYCLE_FOR_TYPE = 'coreSetActiveLifecycleForType'

export const CORE_GENERIC_PAGER_CREATE = 'coreGenericPagerCreate'
export const CORE_GENERIC_PAGER_DESTROY = 'coreGenericPagerDestroy'
export const CORE_GENERIC_PAGER_PAGE_DATA_SET = 'coreGenericPagerPageDataSet'


// Assignments
export const ASSIGNMENT_ADD_REFERRER = 'assignmentAddRef'
export const ASSIGNMENT_CLEAR_FOR_REF = 'assignmentClearForRef'
export const ASSIGNMENT_DROP_REFERRER = 'assignmentDropRef'
export const ASSIGNMENT_PURGE = 'assignmentPurge'
export const ASSIGNMENT_STORE_LIST_MODELS_FOR_REF = 'assignmentReplaceAllForRef'
export const ASSIGNMENT_STORE_ENTITY_FOR_REF = 'assignmentStoreEntityForRef'
export const ASSIGNMENT_REMOVE_ENTITY_FROM_REF = 'assignmentRemoveEntityFromRef'
export const ASSIGNMENT_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'assignmentRemoveEntityAndListModelFromRef'
export const ASSIGNMENT_KANBAN_STATE_SET = 'assignmentKanbanStateSet'

// Assignment Templates
export const ASSIGNMENT_TEMPLATE_ADD_REFERRER = 'assignmentTemplateAddRef'
export const ASSIGNMENT_TEMPLATE_CLEAR_FOR_REF = 'assignmentTemplateClearForRef'
export const ASSIGNMENT_TEMPLATE_DROP_REFERRER = 'assignmentTemplateDropRef'
export const ASSIGNMENT_TEMPLATE_PURGE = 'assignmentTemplatePurge'
export const ASSIGNMENT_TEMPLATE_STORE_LIST_MODELS_FOR_REF = 'assignmentTemplateReplaceAllForRef'
export const ASSIGNMENT_TEMPLATE_STORE_ENTITY_FOR_REF = 'assignmentTemplateStoreEntityForRef'
export const ASSIGNMENT_TEMPLATE_REMOVE_ENTITY_FROM_REF = 'assignmentTemplateRemoveEntityFromRef'
export const ASSIGNMENT_TEMPLATE_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'assignmentTemplateRemoveEntityAndListModelFromRef'
export const ASSIGNMENT_TEMPLATE_SET_LIFECYCLE_FOR_TYPE = 'assignmentTemplateSetLifecycleForType'

// Assignment Workflows
export const ASSIGNMENT_WORKFLOW_ADD_REFERRER = 'assignmentWorkflowAddRef'
export const ASSIGNMENT_WORKFLOW_CLEAR_FOR_REF = 'assignmentWorkflowClearForRef'
export const ASSIGNMENT_WORKFLOW_DROP_REFERRER = 'assignmentWorkflowDropRef'
export const ASSIGNMENT_WORKFLOW_PURGE = 'assignmentWorkflowPurge'
export const ASSIGNMENT_WORKFLOW_STORE_LIST_MODELS_FOR_REF = 'assignmentWorkflowReplaceAllForRef'
export const ASSIGNMENT_WORKFLOW_STORE_ENTITY_FOR_REF = 'assignmentWorkflowStoreEntityForRef'
export const ASSIGNMENT_WORKFLOW_REMOVE_ENTITY_FROM_REF = 'assignmentWorkflowRemoveEntityFromRef'
export const ASSIGNMENT_WORKFLOW_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'assignmentWorkflowRemoveEntityAndListModelFromRef'

// Comments
export const COMMENT_ADD_REFERRER = 'commentAddRef'
export const COMMENT_CLEAR_FOR_REF = 'commentClearForRef'
export const COMMENT_DROP_REFERRER = 'commentDropRef'
export const COMMENT_PURGE = 'commentPurge'
export const COMMENT_STORE_LIST_MODELS_FOR_REF = 'commentReplaceAllForRef'
export const COMMENT_STORE_ENTITY_FOR_REF = 'commentStoreEntityForRef'
export const COMMENT_REMOVE_ENTITY_FROM_REF = 'commentRemoveEntityFromRef'
export const COMMENT_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'commentRemoveEntityAndListModelFromRef'
export const COMMENT_SET_LINK_DESCRIPTION = "commentSetLinkDescription"

// Contacts
export const CONTACT_ADD_REFERRER = 'contactAddRef'
export const CONTACT_CLEAR_FOR_REF = 'contactClearForRef'
export const CONTACT_DROP_REFERRER = 'contactDropRef'
export const CONTACT_PURGE = 'contactPurge'
export const CONTACT_STORE_LIST_MODELS_FOR_REF = 'contactReplaceAllForRef'
export const CONTACT_STORE_ENTITY_FOR_REF = 'contactStoreEntityForRef'
export const CONTACT_REMOVE_ENTITY_FROM_REF = 'contactRemoveEntityFromRef'
export const CONTACT_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'contactRemoveEntityAndListModelFromRef'
export const CONTACT_MANAGER_COLLECTION_ID_SET = 'contactCollectionIdSet'
export const CONTACT_ADDRESS_LIST_REPLACE = 'contactAddressListReplace'

// Contact Collections
export const CONTACT_COLLECTION_ADD_REFERRER = 'contactCollectionAddRef'
export const CONTACT_COLLECTION_CLEAR_FOR_REF = 'contactCollectionClearForRef'
export const CONTACT_COLLECTION_DROP_REFERRER = 'contactCollectionDropRef'
export const CONTACT_COLLECTION_PURGE = 'contactCollectionPurge'
export const CONTACT_COLLECTION_STORE_LIST_MODELS_FOR_REF = 'contactCollectionReplaceAllForRef'
export const CONTACT_COLLECTION_STORE_ENTITY_FOR_REF = 'contactCollectionStoreEntityForRef'
export const CONTACT_COLLECTION_REMOVE_ENTITY_FROM_REF = 'contactCollectionRemoveEntityFromRef'
export const CONTACT_COLLECTION_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'contactCollectionRemoveEntityAndListModelFromRef'

// Customers
export const CUSTOMER_ADD_REFERRER = 'customerAddRef'
export const CUSTOMER_CLEAR_FOR_REF = 'customerClearForRef'
export const CUSTOMER_DROP_REFERRER = 'customerDropRef'
export const CUSTOMER_PURGE = 'customerPurge'
export const CUSTOMER_STORE_LIST_MODELS_FOR_REF = 'customerReplaceAllForRef'
export const CUSTOMER_STORE_ENTITY_FOR_REF = 'customerStoreEntityForRef'
export const CUSTOMER_REMOVE_ENTITY_FROM_REF = 'customerRemoveEntityFromRef'
export const CUSTOMER_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'customerRemoveEntityAndListModelFromRef'

// Files
export const FILE_ADD_REFERRER = 'fileAddRef'
export const FILE_CLEAR_FOR_REF = 'fileClearForRef'
export const FILE_DROP_REFERRER = 'fileDropRef'
export const FILE_PURGE = 'filePurge'
export const FILE_STORE_LIST_MODELS_FOR_REF = 'fileReplaceAllForRef'
export const FILE_STORE_ENTITY_FOR_REF = 'fileStoreEntityForRef'
export const FILE_REMOVE_ENTITY_FROM_REF = 'fileRemoveEntityFromRef'
export const FILE_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'fileRemoveEntityAndListModelFromRef'

// Items
export const ITEM_ADD_REFERRER = 'itemAddRef'
export const ITEM_CLEAR_FOR_REF = 'itemClearForRef'
export const ITEM_DROP_REFERRER = 'itemDropRef'
export const ITEM_PURGE = 'itemPurge'
export const ITEM_STORE_LIST_MODELS_FOR_REF = 'itemReplaceAllForRef'
export const ITEM_STORE_ENTITY_FOR_REF = 'itemStoreEntityForRef'
export const ITEM_REMOVE_ENTITY_FROM_REF = 'itemRemoveEntityFromRef'
export const ITEM_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'itemRemoveEntityAndListModelFromRef'

// Item Categories
export const ITEM_CATEGORY_ADD_REFERRER = 'itemCategoryAddRef'
export const ITEM_CATEGORY_CLEAR_FOR_REF = 'itemCategoryClearForRef'
export const ITEM_CATEGORY_DROP_REFERRER = 'itemCategoryDropRef'
export const ITEM_CATEGORY_PURGE = 'itemCategoryPurge'
export const ITEM_CATEGORY_STORE_LIST_MODELS_FOR_REF = 'itemCategoryReplaceAllForRef'
export const ITEM_CATEGORY_STORE_ENTITY_FOR_REF = 'itemCategoryStoreEntityForRef'
export const ITEM_CATEGORY_REMOVE_ENTITY_FROM_REF = 'itemCategoryRemoveEntityFromRef'
export const ITEM_CATEGORY_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'itemCategoryRemoveEntityAndListModelFromRef'

// Mailboxes
export const MAILBOX_ADD_REFERRER = 'mailboxAddRef'
export const MAILBOX_CLEAR_FOR_REF = 'mailboxClearForRef'
export const MAILBOX_DROP_REFERRER = 'mailboxDropRef'
export const MAILBOX_PURGE = 'mailboxPurge'
export const MAILBOX_STORE_LIST_MODELS_FOR_REF = 'mailboxReplaceAllForRef'
export const MAILBOX_STORE_ENTITY_FOR_REF = 'mailboxStoreEntityForRef'
export const MAILBOX_REMOVE_ENTITY_FROM_REF = 'mailboxRemoveEntityFromRef'
export const MAILBOX_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'mailboxRemoveEntityAndListModelFromRef'
export const MAILBOX_UNREAD_UPDATE = 'mailboxUnreadUpdate'

// Messages
export const MESSAGE_ADD_REFERRER = 'messageAddRef'
export const MESSAGE_CLEAR_FOR_REF = 'messageClearForRef'
export const MESSAGE_DROP_REFERRER = 'messageDropRef'
export const MESSAGE_MAILBOX_ID_SET = 'messageMailboxIdSet'
export const MESSAGE_PURGE = 'messagePurge'
export const MESSAGE_STORE_LIST_MODELS_FOR_REF = 'messageReplaceAllForRef'
export const MESSAGE_STORE_ENTITY_FOR_REF = 'messageStoreEntityForRef'
export const MESSAGE_REMOVE_ENTITY_FROM_REF = 'messageRemoveEntityFromRef'
export const MESSAGE_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'messageRemoveEntityAndListModelFromRef'

// Quotes
export const QUOTE_ADD_REFERRER = 'quoteAddRef'
export const QUOTE_CLEAR_FOR_REF = 'quoteClearForRef'
export const QUOTE_DROP_REFERRER = 'quoteDropRef'
export const QUOTE_PURGE = 'quotePurge'
export const QUOTE_STORE_LIST_MODELS_FOR_REF = 'quoteReplaceAllForRef'
export const QUOTE_STORE_ENTITY_FOR_REF = 'quoteStoreEntityForRef'
export const QUOTE_REMOVE_ENTITY_FROM_REF = 'quoteRemoveEntityFromRef'
export const QUOTE_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'quoteRemoveEntityAndListModelFromRef'

// Tickets
export const TICKET_ADD_REFERRER = 'ticketAddRef'
export const TICKET_CLEAR_FOR_REF = 'ticketClearForRef'
export const TICKET_DROP_REFERRER = 'ticketDropRef'
export const TICKET_PURGE = 'ticketPurge'
export const TICKET_STORE_LIST_MODELS_FOR_REF = 'ticketReplaceAllForRef'
export const TICKET_STORE_ENTITY_FOR_REF = 'ticketStoreEntityForRef'
export const TICKET_REMOVE_ENTITY_FROM_REF = 'ticketRemoveEntityFromRef'
export const TICKET_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'ticketRemoveEntityAndListModelFromRef'
export const TICKET_SET_LINK_DESCRIPTION = "ticketSetLinkDescription"

// Ticket Types
export const TICKET_TYPE_ADD_REFERRER = 'ticketTypeAddRef'
export const TICKET_TYPE_CLEAR_FOR_REF = 'ticketTypeClearForRef'
export const TICKET_TYPE_DROP_REFERRER = 'ticketTypeDropRef'
export const TICKET_TYPE_PURGE = 'ticketTypePurge'
export const TICKET_TYPE_STORE_LIST_MODELS_FOR_REF = 'ticketTypeReplaceAllForRef'
export const TICKET_TYPE_STORE_ENTITY_FOR_REF = 'ticketTypeStoreEntityForRef'
export const TICKET_TYPE_REMOVE_ENTITY_FROM_REF = 'ticketTypeRemoveEntityFromRef'
export const TICKET_TYPE_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'ticketTypeRemoveEntityAndListModelFromRef'

// Work Orders
export const WORK_ORDER_ADD_REFERRER = 'workOrderAddRef'
export const WORK_ORDER_CLEAR_FOR_REF = 'workOrderClearForRef'
export const WORK_ORDER_DROP_REFERRER = 'workOrderDropRef'
export const WORK_ORDER_PURGE = 'workOrderPurge'
export const WORK_ORDER_STORE_LIST_MODELS_FOR_REF = 'workOrderReplaceAllForRef'
export const WORK_ORDER_STORE_ENTITY_FOR_REF = 'workOrderStoreEntityForRef'
export const WORK_ORDER_REMOVE_ENTITY_FROM_REF = 'workOrderRemoveEntityFromRef'
export const WORK_ORDER_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'workOrderRemoveEntityAndListModelFromRef'
export const WORK_ORDER_KANBAN_STATE_SET = 'workOrderKanbanStateSet'

// Work Order Categories
export const WORK_ORDER_CATEGORY_ADD_REFERRER = 'workOrderCategoryAddRef'
export const WORK_ORDER_CATEGORY_CLEAR_FOR_REF = 'workOrderCategoryClearForRef'
export const WORK_ORDER_CATEGORY_DROP_REFERRER = 'workOrderCategoryDropRef'
export const WORK_ORDER_CATEGORY_PURGE = 'workOrderCategoryPurge'
export const WORK_ORDER_CATEGORY_STORE_LIST_MODELS_FOR_REF = 'workOrderCategoryReplaceAllForRef'
export const WORK_ORDER_CATEGORY_STORE_ENTITY_FOR_REF = 'workOrderCategoryStoreEntityForRef'
export const WORK_ORDER_CATEGORY_REMOVE_ENTITY_FROM_REF = 'workOrderCategoryRemoveEntityFromRef'
export const WORK_ORDER_CATEGORY_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF = 'workOrderCategoryRemoveEntityAndListModelFromRef'
