
import { AbstractTextField } from '../common/forms/AbstractTextField'
import { MAILBOX_SENDING_ADDRESSES } from '@/store/getterNames'

export default AbstractTextField.extend({
  computed: {
    addressList(): string[] {
      return this.$store.getters[MAILBOX_SENDING_ADDRESSES];
    }
  },

  created() {
    if (this.value == '' && this.addressList.length > 0) {
      this.handleInput(this.addressList[0]);
    }
  }
})
