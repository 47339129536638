import { SearchUIFieldDef, SearchUIFieldType, SearchUINumberRangeOptions } from "./SearchUIOption";
import { UpdatableEntitySearchFieldDefs } from "./UpdatableEntitySearchFieldDefs";

export const LinkableEntitySearchFieldDefs = [
  ...UpdatableEntitySearchFieldDefs,
  
  {
    fieldName: "linkCount",
    displayName: "Link Count",
    type: SearchUIFieldType.NumberRange,
    options: new SearchUINumberRangeOptions(0)
  },
] as SearchUIFieldDef[]