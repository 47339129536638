import * as apiErrors from './apiErrors'
import axios, { AxiosResponse } from 'axios'
import { ApiRequestParams } from '@/models/core/api/ApiRequestParams'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import { auth } from '@/security/Auth'
import environment from '../util/environment'
import { InsightApplication } from 'insight-auth'
import { RealtimeService } from './realtimeUpdates'

const baseURL = environment == 'dev'
  ? 'http://localhost:5005/api/v1/' : 'https://werk365.com/api/v1/'

const appcenterUrl = environment == 'dev'
  ? 'http://localhost:5000/api/v1/' : 'https://appcenter.insightgroup.co/api/v1/'

const http = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json'
  }
})

const _assembleQueryString = (paramsAsNestedArray?: ApiRequestParams): string => {
  if (paramsAsNestedArray == null || paramsAsNestedArray == undefined) {
    return ''
  } else if (paramsAsNestedArray.length == 0) {
    return ''
  } else {
    let qry = ''

    paramsAsNestedArray.forEach(item => {
      qry += (qry.length == 0) ? '?' : '&'
      qry += item[0] + '=' + item[1]
    })

    return qry
  }
}

const _handleDataResponse = (response: AxiosResponse): ApiResponse => {
  return { error: false, errorCause: '', data: response.data }
}

const _translateError = (code: number): string => {
  if (code == 400) {
    return apiErrors.INVALID_DATA
  } else if (code == 401) {
    return apiErrors.UNAUTHORIZED
  } else if (code == 403) {
    return apiErrors.FORBIDDEN
  } else if (code == 404) {
    return apiErrors.NOT_FOUND
  } else {
    return apiErrors.GENERIC_ERROR
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _handleErrorResponse = (error: any): ApiResponse|void => {
  if (error.response) {
    const code = error.response.code || error.response.status
    return { error: true, errorCause: _translateError(code), data: error.response.data.message }
  } else if (error.name == 'ClientAuthError') {
    auth.login()
  } else {
    return { error: true, errorCause: apiErrors.GENERIC_ERROR, data: 'There was a network error.' }
  }
}

const _blobToString = (data: Blob, format: 'base64'|'binaryString'): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = (): void => {
      fileReader.abort();
      reject(new Error('Problem parsing file'));
    };

    fileReader.onload = (): void => {
      resolve(fileReader.result as string);
    };

    if (format == 'base64') {
      fileReader.readAsDataURL(data);
    } else {
      fileReader.readAsBinaryString(data);
    }
  });
}

const _handleImageBlobResponse = async (response: AxiosResponse): Promise<ApiResponse> => {
  const imgBase64 = await _blobToString(response.data, 'base64');
  return {error: false, errorCause: '', data: imgBase64};
}

const _handleBinaryStringResponse = async (response: AxiosResponse): Promise<ApiResponse> => {
  const binaryString = await _blobToString(response.data, 'binaryString');
  return {error: false, errorCause: '', data: binaryString};
}

const registeredHeaders: Record<string, string> = {}

const _getCustomHeaders = async (apiName?: InsightApplication, forceJsonRequest = false): Promise<Record<string, string|undefined>> => {
  const headers = { 
    Authorization: await auth.getBearerAuthForApi(apiName),
    'X-Werk-Real-Time-ID': RealtimeService.getConnectionIdentifier() ?? '',
    ...registeredHeaders
  } as Record<string, string|undefined>

  if (forceJsonRequest) {
    headers['Content-Type'] = 'text/json'
  }

  return headers;
}

export class Api {
  get baseUrl(): string {
    //Remove trailing slash to make things more readable when concatenating
    let url = baseURL;

    if (url.lastIndexOf('/') == url.length-1) {
      url = url.substr(0, url.length-1);
    }

    return url;
  }

  registerHeader(name: string, value: string): void {
    registeredHeaders[name] = value;
  }

  async get (
    resource: string, 
    paramsAsNestedArray?: ApiRequestParams, 
    apiName?: InsightApplication,
    dataType: 'json'|'binaryString'|'image'|'blob'|'plainText' = 'json'
  ): Promise<void | ApiResponse>
  {
    if (apiName == 'appCenter') {
      resource = appcenterUrl + resource
    }

    const responseType = dataType == 'json' ? 'json': dataType == 'plainText' ? 'text' : 'blob'

    try {
      const response = await http.get(
        resource + _assembleQueryString(paramsAsNestedArray),
        {
          headers: await _getCustomHeaders(apiName),
          responseType: responseType,
        },
      )

      if (dataType == 'image') {
        return _handleImageBlobResponse(response);
      } else if (dataType == 'binaryString') {
        return _handleBinaryStringResponse(response);
      } else if (dataType == 'blob' || dataType == 'plainText') {
        return {error: false, errorCause: '', data: response.data};
      } else {
        return _handleDataResponse(response)
      }
    } catch (e) {
      return _handleErrorResponse(e)
    }
  }

  async getWerkHeaders(): Promise<Record<string, string|undefined>> {
    return await _getCustomHeaders();
  }

  async update(resourceUrl: string, resourceData: unknown, paramsAsNestedArray?: ApiRequestParams):
    Promise<void | ApiResponse>
  {
    try {
      const response = await http.put(
        resourceUrl + _assembleQueryString(paramsAsNestedArray),
        resourceData,
        { headers: await _getCustomHeaders() }
      )

      return _handleDataResponse(response)
    } catch (e) {
      return _handleErrorResponse(e)
    }
  }

  async create(
    resourceUrl: string,
    resourceData: unknown,
    paramsAsNestedArray?: ApiRequestParams,
    forceJsonRequest = false,
    dataType: 'json'|'binaryString'|'image'|'blob'|'plainText' = 'json'
    ): Promise<void | ApiResponse>
  {
    try {
      const responseType = dataType == 'json' ? 'json': dataType == 'plainText' ? 'text' : 'blob'

      const response = await http.post(
        resourceUrl + _assembleQueryString(paramsAsNestedArray),
        resourceData,
        { 
          headers: await _getCustomHeaders(undefined, forceJsonRequest),
          responseType: responseType,
        }
      )

      if (dataType == 'image') {
        return _handleImageBlobResponse(response);
      } else if (dataType == 'binaryString') {
        return _handleBinaryStringResponse(response);
      } else if (dataType == 'blob' || dataType == 'plainText') {
        return {error: false, errorCause: '', data: response.data};
      } else {
        return _handleDataResponse(response)
      }
    } catch (e) {
      return _handleErrorResponse(e)
    }
  }

  async delete(resourceUrl: string, paramsAsNestedArray: ApiRequestParams): Promise<void | ApiResponse> {
    try {
      const response = await http.delete(
        resourceUrl + _assembleQueryString(paramsAsNestedArray),
        { headers: await _getCustomHeaders() }
      )

      return _handleDataResponse(response)
    } catch (e) {
      return _handleErrorResponse(e)
    }
  }
}

export default new Api();
