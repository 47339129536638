
import { AssignmentWorkflowListModel } from '@/models/assignments/AssignmentWorkflow';
import AssignmentWorkflowManager from './AssignmentWorkflowManager.vue'
import Vue from 'vue';

export default Vue.extend({
  components: {
    AssignmentWorkflowManager
  },

  props: {
    clientCode: {
      type: String,
      default: '',
      required: true,
    },

    show: Boolean,
  },

  data() {
    return {
      selectedWorkflow: null as AssignmentWorkflowListModel|null,
    }
  },

  methods: {
    async close(mode: 'cancel'|'save'): Promise<void> {
      if (mode == 'save') {
        this.$emit('workflow-selected', this.selectedWorkflow);
      }

      this.selectedWorkflow = null;
      this.$emit('update:show', false);
    }
  }
})
