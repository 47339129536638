
import { PropValidator } from 'vue/types/options'
import { StateAttributeDef } from '@/models/core/stateFilters/StateAttributeDef'
import StateFilterEditor from './StateFilterEditor.vue'
import { StateFilterTerm } from '@/models/core/stateFilters/StateFilters'
import Vue from 'vue'

export default Vue.extend({
  components: {
    StateFilterEditor,
  },

  props: {
    attributes: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<StateAttributeDef[]>,

    closeReasons: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<string[]>,

    filter: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<StateFilterTerm[]>,

    readonly: Boolean,

    show: Boolean,

    stageNames: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,

    useOnlyStage: {
      type: String,
      default: null,
    },
  },

  methods: {
    closeEditor(): void {
      this.$emit('update:show', false);
    },
  }
})
