import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';
import {WorkOrder} from '@/models/workOrders/WorkOrder'
import {WorkOrderListModel} from '@/models/workOrders/WorkOrderListModel'

export default class State extends VuexModuleStateBase<WorkOrder, WorkOrderListModel> {
  public kanbanState = {} as Record<string, unknown>;

  protected instantiateListModelForEntity(entity: WorkOrder): WorkOrderListModel {
    const listModel = new WorkOrderListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'WorkOrder'
  }
}