
import { handleEvent } from '@/util/FunctionalComponentEventDispatcher'

export default {
  props: {
    disableTooltip: Boolean,

    icon: {
      type: String,
      default: '',
    },

    iconClass: {
      type: String,
      default: '',
    },

    iconColor: {
      type: String,
      default: '',
    },

    large: Boolean,

    small: Boolean,

    tooltip: {
      type: String,
      default: '',
    },

    tooltipColor: {
      type: String,
      default: 'info',
    },

    xLarge: Boolean,

    xSmall: Boolean,
  },

  methods: {
    handleEvent,
  }
}
