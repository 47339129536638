import { EntityManagerState } from "./EntityManagerState";

export type MutableEntityManagerStateKey = keyof Omit<EntityManagerState, 'client'|'refName'|'entityType'|'useServerSidePaging'>;

export class EntityManagerStateUpdate {
  constructor(
    public client: string,
    public refName: string,
    public entityType: string,
    public propertyName: MutableEntityManagerStateKey,
    public value: never,
  ) {}

  public static for(state: EntityManagerState, propertyName: MutableEntityManagerStateKey, value: never) {
    return new EntityManagerStateUpdate(
      state.client,
      state.refName,
      state.entityType,
      propertyName,
      value
    )
  }
}