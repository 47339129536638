import { parseStateFilterString, StateFilterCommand, StateFilterTerm } from "./StateFilters";

export function describeFilterString(value: string): string {
  const result = parseStateFilterString(value);

  if (result) {
    return describeFilterTerms(result);
  } else {
    return "ERROR"
  }
}

export function describeFilterTerms(terms: StateFilterTerm[]): string {
  let desc = '';
  if (terms.length == 0) {
    desc = '(No filter)';
  } else {
    const sortedTerms = [...terms]
    sortedTerms.sort((a,b) => {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    })
    const termDescriptions = [] as string[];

    for (let i = 0; i < sortedTerms.length; i++) {
      const term = terms[i];
      termDescriptions.push(describeTerm(term));
    }

    desc = termDescriptions.join(' and ')
  }

  return desc;
}

function describeTerm(term: StateFilterTerm): string {
  if (term.command == StateFilterCommand.BlameBlank) {
    return `the ${term.attributeName} field is blank`;
  } else if (term.command == StateFilterCommand.BlameNotBlank) {
    return `the ${term.attributeName} field is not blank`
  } else if (term.command == StateFilterCommand.CloseReason) {
    return `the ${term.attributeName} field equals ${term.filterValue}`
  } else if (term.command == StateFilterCommand.AssignCompleteForStage) {
    return `all assignments are complete for the ${term.filterValue} stage`
  } else if (term.command == StateFilterCommand.AssignIncompleteForStage) {
    return `at least one assignment has started but not all assignments are complete for the ${term.filterValue} stage`
  } else if (term.command == StateFilterCommand.AssignNoneStarted) {
    return `no assignments are started for any stage`
  } else if (term.command == StateFilterCommand.AssignAnyPaused) {
    return `any assignment is paused for any stage`
  } else if (term.command == StateFilterCommand.AssignAllCompleted) {
    return `all assignments are complete for all stages`
  }

  return "ERROR"
}
