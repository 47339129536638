import { ContactCollection } from '@/models/contacts/ContactCollection';
import { ContactCollectionListModel } from '@/models/contacts/ContactCollectionListModel';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<ContactCollection, ContactCollectionListModel> {
  protected instantiateListModelForEntity(entity: ContactCollection): ContactCollectionListModel {
    const listModel = new ContactCollectionListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Contact Collection'
  }
}