import { SearchUIFieldType, SearchUIFieldValue, SearchUIRangeValue } from "./SearchUIOption";
import { AddressListItem } from "@/models/contacts/AddressListItem";
import { DateTimeQueryVarSet } from "./DateTimeQueryVarSet";
import { DecimalRangeQueryVarSet } from "./DecimalRangeQueryVarSet";
import InsightUser from "../InsightUser";
import ItemCategoryListModel from "@/models/items/ItemCategoryListModel";
import { TicketTypeListModel } from "@/models/tickets/TicketType";
import { UserIdListQueryVarSet } from "./UserIdListQueryVarSet";
import { WorkOrderListModel } from "@/models/workOrders/WorkOrderListModel";

export function buildQueryVarsForSearch(
  searchValues: SearchUIFieldValue[],
  includeDeleted = false,
  expanded = false,
  pageSize?: number,
  pageNumber?: number,
  orderBy?: string,
  orderDescending?: boolean,
): Record<string, unknown> {
  const queryVars: Record<string, unknown> = {};

  searchValues.forEach(x => {
    let value: unknown = null;

    if (x.type == SearchUIFieldType.DateTime || x.type == SearchUIFieldType.DateTimeOffset) {
      const v = x.value as SearchUIRangeValue<string>
      const vals = [
        new DateTimeQueryVarSet(v.value1, v.operator == 'lte', v.doNotExcludeBlanks)
      ];

      if (v.operator == 'btwi') {
        vals.push(new DateTimeQueryVarSet(v.value2, true, v.doNotExcludeBlanks));
      }

      value = vals;
    } else if (x.type == SearchUIFieldType.NumberRange || x.type == SearchUIFieldType.PercentRange) {
      const v = x.value as SearchUIRangeValue<number>;

      value = new DecimalRangeQueryVarSet(
        v.operator == 'gte' || v.operator == 'btwi' ? v.value1 : undefined,
        v.operator == 'lte' ? v.value1 : v.operator == 'btwi' ? v.value2 : undefined
      );

    } else if (x.type == SearchUIFieldType.ContactSet) {
      const v = x.value as AddressListItem[];
      
      value = v.map(x => x.address);
    } else if (x.type == SearchUIFieldType.UserSet) {
      const v = x.value as InsightUser[];
      
      value = v.map(x => x.name);
    } else if (x.type == SearchUIFieldType.UserIdSet) {
      const v = x.value as UserIdListQueryVarSet<InsightUser>;
      
      value = new UserIdListQueryVarSet<string>(
        v.values?.map(x => x.id),
        v.doNotExcludeBlanks,
        v.anyValueButBlank
      );
    } else if (x.type == SearchUIFieldType.ItemCategorySet) {
      const v = x.value as ItemCategoryListModel[];
      
      value = v.map(x => x.id);
    } else if (x.type == SearchUIFieldType.WorkOrderCategorySet) {
      const v = x.value as WorkOrderListModel[];
      
      value = v.map(x => x.id);
    } else if (x.type == SearchUIFieldType.TicketTypeSet) {
      const v = x.value as TicketTypeListModel[];
      
      value = v.map(x => x.id);
    } else {
      value = x.value;
    }

    queryVars[x.fieldName] = value;
  });

  queryVars["includeDeleted"] = includeDeleted;
  queryVars["expanded"] = expanded;

  if (pageNumber != undefined && (pageSize ?? 0) > 0) {
    queryVars["pageNumber"] = pageNumber;
    queryVars["pageSize"] = pageSize;
  }

  if (orderBy != undefined) {
    queryVars["orderBy"] = orderBy;
    queryVars["orderDesc"] = !!orderDescending;
  }

  return queryVars;
}