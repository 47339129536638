import Item from '@/models/items/Item'
import ItemListModel from '@/models/items/ItemListModel';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Item, ItemListModel> {
  protected instantiateListModelForEntity(entity: Item): ItemListModel {
    const listModel = new ItemListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Item'
  }
}