
import reformatDate, { ensureDateObject } from '@/util/reformatDate';
import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue, SearchUIRangeValue } from '@/models/core/searchQueries/SearchUIOption';
import { AddressListItem } from '@/models/contacts/AddressListItem';
import EntityManagerSearchDialogRow from './EntityManagerSearchDialogRow.vue';
import InsightUser from '@/models/core/InsightUser';
import ItemCategoryListModel from '@/models/items/ItemCategoryListModel';
import popup from '@/util/popup';
import { PropValidator } from 'vue/types/options';
import { StringQueryVarSet } from '@/models/core/searchQueries/StringQueryVarSet';
import { TicketTypeListModel } from '@/models/tickets/TicketType';
import { UserIdListQueryVarSet } from '@/models/core/searchQueries/UserIdListQueryVarSet';
import Vue from 'vue'
import { WorkOrderCategoryListModel } from '@/models/workOrderCategories/WorkOrderCategoryListModel';

export default Vue.extend({
  components: { 
    EntityManagerSearchDialogRow
  },

  props: {
    buttonText: {
      type: String,
      default: "Search",
    },

    entityTypePlural: {
      type: String,
      default: '',
    },

    fieldDefs: {
      type: Array,
      default: null,
    } as PropValidator<SearchUIFieldDef[]>,

    lockedQueryFields: {
      type: Array,
      default: null,
    } as PropValidator<string[]|null>,

    pageSize: {
      type: Number,
      default: 50,
    },

    show: Boolean,

    supressLongQueryWarning: Boolean,

    useServerSidePaging: Boolean,

    values: {
      type: Array,
      default: null,
    } as PropValidator<SearchUIFieldValue[]>,
  },

  data() {
    return {
      fieldChooserValue: '' as string|null,
      searchRows: [] as SearchUIFieldDef[],
      searchValues: [] as unknown[],
    }
  },

  computed: {
    entityTypePluralLoweracase(): string {
      return this.entityTypePlural?.toLowerCase();
    },

    lockedQueryFieldHash(): Record<string, boolean> {
      if (this.lockedQueryFields) {
        return this.lockedQueryFields.reduce((h, f) => {h[f] = true; return h;}, {} as Record<string, boolean>)
      } else {
        return {}
      }
    },

    unusedFields(): {text: string; value: string}[] {
      return this.fieldDefs
        .filter(x => !this.searchRows.includes(x))
        .map(x => {return {text: x.displayName, value: x.fieldName}})
        .sort((a, b) => a.text > b.text ? 1 : -1);
    }
  },

  watch: {
    values: {
      handler(): void {
        this.resetSearchValues();
      },
      immediate: true,
      deep: true,
    }
  },

  methods: {
    closeDialog(): void {
      this.$emit('update:show', false);
      this.resetSearchValues();
    },

    getRowIndex(row: SearchUIFieldDef): number {
      return this.searchRows.findIndex(x => x.fieldName == row.fieldName);
    },

    async handleFieldSelection(payload: string): Promise<void> {
      const newRow = this.fieldDefs.find(x => x.fieldName == payload);

      if (newRow) {
        this.searchRows.push(newRow);
        this.initializeSearchValueForRow(newRow);

        (this.$refs.fieldChooserElement as HTMLInputElement).blur();
        await this.$nextTick();
        this.fieldChooserValue = null;
      }
    },

    handleRowInput(row: SearchUIFieldDef, payload: unknown): void {
      const idx = this.getRowIndex(row);

      if (idx > -1) {
        Vue.set(this.searchValues, idx, payload);
      }
    },

    handleRemoveRow(row: SearchUIFieldDef): void {
      const idx = this.getRowIndex(row);

      if (idx > -1) {
        this.searchRows.splice(idx, 1);
        this.searchValues.splice(idx, 1);
      }
    },

    initializeSearchValueForRow(row: SearchUIFieldDef): void {
      let value: unknown;

      if (row.type == SearchUIFieldType.DateTime) {
        value = new SearchUIRangeValue('gte', reformatDate(new Date(), "yyyy-mm-dd"))
      } else if (row.type == SearchUIFieldType.DateTimeOffset) {
        var d = new Date()
        d.setHours(17, 0, 0, 0);
        value = new SearchUIRangeValue('gte', reformatDate(d, "yyyy-mm-dd'T'HH:MM:ssp"))
      } else if (row.type == SearchUIFieldType.NumberRange || row.type == SearchUIFieldType.PercentRange) {
        value = new SearchUIRangeValue('gte', 0);
      } else if (row.type == SearchUIFieldType.StringSet) {
        value = [] as string[];
      } else if (row.type == SearchUIFieldType.ContactSet) {
        value = [] as AddressListItem[];
      } else if (row.type == SearchUIFieldType.UserSet) {
        value = [] as InsightUser[];
      } else if (row.type == SearchUIFieldType.UserIdSet) {
        value = new UserIdListQueryVarSet<InsightUser>([], false, false);
      } else if (row.type == SearchUIFieldType.ItemCategorySet) {
        value = [] as ItemCategoryListModel[];
      } else if (row.type == SearchUIFieldType.StringContainsOrEquals) {
        value = new StringQueryVarSet("");
      } else if (row.type == SearchUIFieldType.WorkOrderCategorySet) {
        value = [] as WorkOrderCategoryListModel[];
      } else if (row.type == SearchUIFieldType.TicketTypeSet) {
        value = [] as TicketTypeListModel[];
      } else if (row.type == SearchUIFieldType.LifecycleStatusSet) {
        value = [] as string[];
      } else if (row.type == SearchUIFieldType.LifecycleCloseReasonSet) {
        value = [] as string[];
      } else {
        value = ''
      }

      this.searchValues.push(value);
    },

    queryMightBeLarge(vals: SearchUIFieldValue[]): boolean {
      if (this.supressLongQueryWarning || this.useServerSidePaging) {
        return false;
      } else if (vals.length == 0) {
        return true;
      } else if (vals.length == 1) {
        const val = vals[0];

        if (val.type == SearchUIFieldType.DateTime) {
          const vars = val.value as SearchUIRangeValue<string>;
          const today = new Date();
          const first = ensureDateObject(vars.value1);
          const second = ensureDateObject(vars.value2);

          const twoMonths = 1000 * 3600 * 24 * 60;

          if (vars.operator == 'lte') {
            return true;
          } else if (vars.operator == 'gte') {
            return (today.getTime() - (first?.getTime() ?? 0)) > twoMonths;
          } else {
            return ((second?.getTime() ?? 0) - (first?.getTime() ?? 0)) > twoMonths;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    async requestSearch(): Promise<void> {
      const vals = [] as SearchUIFieldValue[];

      for (let i = 0; i < this.searchRows.length; i++) {
        vals.push(new SearchUIFieldValue(
          this.searchRows[i].fieldName,
          this.searchRows[i].type,
          this.searchValues[i]));
      }

      if (this.queryMightBeLarge(vals)) {
        const confirmed = await popup.confirm(
          this.$store,
          'Confirm Query',
          'This query might return a large number of rows. Are you sure you want to continue?',
          undefined,
          undefined,
          'Large queries can take a long time.')

        if (!confirmed) return;
      }

      this.$emit('update:values', vals);

      this.closeDialog();
    },

    resetSearchValues(): void {
      if (this.values) {
        const vals = [] as unknown[];
        const defs = [] as SearchUIFieldDef[];

        this.values.forEach(v => {
          const d = this.fieldDefs.find(x => x.fieldName == v.fieldName);

          if (d) {
            vals.push(v.value);
            defs.push(d);
          }
        });

        this.searchRows = defs;
        this.searchValues = vals;
      }
    },
  }
})
