import { Buffer } from 'buffer';
import decompress from 'brotli/decompress'

export const PackedJson = {
  unpack(input: string): unknown {
    const decodedBuffer = Buffer.from(input, 'base64');
    const decompressedArr = decompress(decodedBuffer);
    const decompressedString = new TextDecoder().decode(decompressedArr);
    return JSON.parse(decompressedString);
  }
}