
export default {
  props: {
    action: {
      type: Object,
      default: (): never => {return {} as never},
    },

    propsGetter: {
      type: Function,
      default: (): void => {return;}
    }
  }
}
