import { AssignmentWorkflow, AssignmentWorkflowListModel } from '@/models/assignments/AssignmentWorkflow';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<AssignmentWorkflow, AssignmentWorkflowListModel> {
  
  protected instantiateListModelForEntity(entity: AssignmentWorkflow): AssignmentWorkflowListModel {
    const listModel = new AssignmentWorkflowListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'AssignmentWorkflow'
  }
}