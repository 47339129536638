import * as startup from '../util/startup'
import { InsightAuth, InsightAuthClientApp, NavGuard } from "insight-auth"
import { AuthorizableRoute } from 'insight-auth/src/models/AuthorizableRoute'
import { CORE_CLIENT_CURRENT } from '@/store/getterNames'
import { CORE_SWITCH_CLIENT } from '@/store/actionNames'
import notifications from "../util/notifications"
import RootState from "../store/RootState"
import { Route } from "vue-router"
import { Store } from "vuex"
import store from '../store'

let changingClients = false;

function isChangingClients(to: Route, from: Route): boolean {
  if (to.params?.clientCode > '' && from.params?.clientCode > '') {
    return to.params.clientCode.toLowerCase() != from.params.clientCode.toLowerCase();
  } else {
    return false;
  }
}

const app: InsightAuthClientApp = {
  isAppDataLoaded: () => startup.isAppDataLoaded(store as Store<RootState>),

  loadAppData: (route: AuthorizableRoute) => startup.loadAppData(store as Store<RootState>, route),
  
  getCurrentClientCode: () => store.getters[CORE_CLIENT_CURRENT].code as string,

  setCurrentClientCode: (client: string) => store.dispatch(CORE_SWITCH_CLIENT, client),

  getDefaultRouteForClient: (client: string) => `/${client}`,

  getUnauthorizedRouteRedirectTargetForClient: (client: string) => changingClients ? `/${client}` : false,

  warnUnauthorizedNavigation: () => notifications.warn(store, "You do not have permission to view the requested resource")
}

export const navGuard = async (auth: InsightAuth, to: Route, from: Route, next: () => void): Promise<void> => {
  changingClients = isChangingClients(to, from);
  await NavGuard(app, auth, to, from, next)
  changingClients = false;
}
