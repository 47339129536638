import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue, SearchUIRangeValue } from "@/models/core/searchQueries/SearchUIOption";
import { LinkableEntitySearchFieldDefs } from "@/models/core/searchQueries/LinkableEntitySearchFieldDefs";
import reformatDate from "@/util/reformatDate";
import { WerkFileType } from "@/models/files/WerkFileType";

export const FileSearchFieldDefs = [
  ...LinkableEntitySearchFieldDefs,

  {fieldName: "name", displayName: "Name", type: SearchUIFieldType.StringContainsOrEquals},
  {
    fieldName: "type",
    displayName: "Type",
    type: SearchUIFieldType.StringSet,
    options: Object.values(WerkFileType)
  },
] as SearchUIFieldDef[];

const thirtyDaysAgoDate = new Date(new Date().getTime() - (1000*3600*24*30));

const thirtyDaysAgo = reformatDate(thirtyDaysAgoDate, 'yyyy-mm-dd');

export const DefaultFileQuery = [
  new SearchUIFieldValue(
    'createdAt',
    SearchUIFieldType.DateTime,
    new SearchUIRangeValue<string>('gte', thirtyDaysAgo)
  )
] as SearchUIFieldValue[]
