
import { VueEventListener } from '@/util/FunctionalComponentEventDispatcher';

export default {
  methods: {
    toDisplay(decimal: number): number {
      return decimal * 100;
    },

    handleInput(listeners: Record<string, VueEventListener|VueEventListener[]>, payload: number): void {
      const decimalValue = payload / 100;

      const inputListeners = listeners['input'];

      if (!inputListeners) {
        return;
      } else if (typeof inputListeners === 'function') {
        inputListeners(decimalValue);
      } else {
        inputListeners.forEach(l => {
          l(decimalValue);
        });
      }
    }
  }
}
