import IconForType from './MailboxIcons';
import { MailboxType } from './MailboxType';

export class MailboxTreeNode {
  public id = '';
  public name = '';
  public children: MailboxTreeLeaf[] = []

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class MailboxTreeLeaf {
  public id = '';
  public name = '';
  public icon = '';
  public unread = 0;
  public type: MailboxType;

  constructor(id: string, name: string, type: MailboxType, unread: number) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.icon = IconForType(type)
    this.unread = unread
  }
}