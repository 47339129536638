export enum MessageState {
  Draft,
  Sending,
  SentPendingTemplatePopulation,
  Sent,
  Recieved
}

export enum MessageType {
  Internal,
  ExternalAdHoc,
  ExternalTemplated
}

export enum MailTemplate {
  None,
  QuoteSubmit,
}

export enum MessageContactState {
  New,
  Sent,
  Delayed,
  Undeliverable,
  Delivered,
  Read,
  Unknown
}

export enum MessageContactType {
  From,
  To,
  Cc,
  Bcc
}