import Vuex, { Module, StoreOptions } from 'vuex'
import { actions } from './RootActions'
import { EntityUpdateListener } from '@/models/core/realtime/EntityUpdateListener'
import { getters } from './RootGetters'
import modules from './RootModules'
import { mutations } from './RootMutations'
import PrivateEntity from '@/models/core/entities/PrivateEntity'
import PrivateEntityListModel from '@/models/core/entities/PrivateEntityListModel'
import RootState from './RootState'
import Vue from 'vue'
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase'

Vue.use(Vuex)

const state = new RootState()

for (const modName in modules) {
  if (Object.prototype.hasOwnProperty.call(modules, modName)) {
    const module = modules[modName] as Module<unknown, RootState>;
    const moduleState = module.state as VuexModuleStateBase<PrivateEntity, PrivateEntityListModel<PrivateEntity>>;
    
    state.addEntityUpdateListener(
      new EntityUpdateListener(`module_${modName}`, m => moduleState.processEntityUpdate(m))
    );
  }
}

const store: StoreOptions<RootState> = {
  state,
  getters,
  mutations,
  actions,
  modules
}

export default new Vuex.Store<RootState>(store)
