export class EntityPagingData {
  constructor(
    public pagerId: string,
    public pageSize: number,
    public pageNumber: number,
    public entityCount: number,
  ) {}
}

export class GenericPager {
  constructor(
    public name: string,
    public sortBy: string,
    public sortByDesc: boolean,
    public clientPageNumber = 1,
    public serverPageSize = 50,
    public serverPageNumber = 0,
    public serverSideEntityCount = 0,
  ) {}
}