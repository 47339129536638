import Customer from '@/models/customers/Customer';
import CustomerListModel from '@/models/customers/CustomerListModel';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Customer, CustomerListModel> {
  protected instantiateListModelForEntity(entity: Customer): CustomerListModel {
    const listModel = new CustomerListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Customer'
  }
}