import { MailboxTreeLeaf, MailboxTreeNode } from "./MailboxTreeModels";
import MailboxListModel from "./MailboxListModel";
import { SortByTypeThenName } from "./MailboxSortFunction";

export function MailboxTreeBuilder(mailboxes: MailboxListModel[]): MailboxTreeNode[] {
  const groups: MailboxTreeNode[] = []
  const groupHash: Record<string, MailboxTreeNode> = {}

  mailboxes.forEach(mailbox => {
    if (!groupHash[mailbox.address]) {
      groupHash[mailbox.address] = new MailboxTreeNode(mailbox.address, mailbox.address)
      groups.push(groupHash[mailbox.address])
    }

    groupHash[mailbox.address].children.push(
      new MailboxTreeLeaf(
        `${mailbox.id}`,
        mailbox.name,
        mailbox.type,
        mailbox.unreadCount
      )
    );
  });

  groups.forEach(g => g.children.sort(SortByTypeThenName))

  return groups;
}