import * as mt from '../mutationNames'
import { Ticket, TicketListModel } from '@/models/tickets/Ticket';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import { EntityLinkDescriptionUpdateRequest } from '@/models/core/entities/EntityLinkDescriptionUpdateRequest';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import Vue from 'vue';
import VuexEntityReference from '@/models/core/vuex/VuexEntityReference';
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.TICKET_ADD_REFERRER] (state, referrer: VuexEntityReferer<Ticket>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.TICKET_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.TICKET_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.TICKET_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.TICKET_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.TICKET_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.TICKET_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<Ticket>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.TICKET_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<TicketListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName);
  },
  
  //***********************************************************************
  [mt.TICKET_SET_LINK_DESCRIPTION] (state, req: EntityLinkDescriptionUpdateRequest): void {
    const lmRef = state.listModels.find(x => x.entity.id == req.entityId) as VuexEntityReference<TicketListModel>|undefined;

    if (lmRef) {
      const link = lmRef.entity.links.find(x => x.entityId == req.targetId)

      if (link) {
        Vue.set(link, "description", req.description);
      }
    }
  },
}