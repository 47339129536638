import Mailbox from "./Mailbox";
import { Spec } from "../core/spec/Spec";

export class MailboxBelongsToUserSpec extends Spec<Mailbox> {
  private userId: string;

  constructor(userId: string) {
    super();
    this.userId = userId;
  }

  public isSatisfiedBy(entity: Mailbox): boolean {
    return (entity.users?.findIndex(x => x.id == this.userId) ?? -1) >= 0;
  }
}