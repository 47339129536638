
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import { ApiResponse } from '@/models/core/api/ApiResponse';
import popup from '@/util/popup'
import PrivateEntity from '@/models/core/entities/PrivateEntity';
import PrivateEntityListModel from '@/models/core/entities/PrivateEntityListModel';
import { PropValidator } from 'vue/types/options';
import ScrollBox from '../ScrollBox.vue';
import Vue from 'vue'

export default Vue.extend({
  components: {
    ScrollBox
  },

  props: {
    deleteActionName: {
      type: String,
      default: null,
    },

    entityList: {
      type: Array,
      default: (): PrivateEntityListModel<PrivateEntity>[] => []
    } as PropValidator<PrivateEntityListModel<PrivateEntity>[]>,

    entityType: {
      type: String,
      default: 'entity'
    },

    nameField: {
      type: String,
      default: 'name',
    },

    pluralEntityType: {
      type: String,
      default: 'entities',
    },

    restore: Boolean,

    restoreActionName: {
      type: String,
      default: null,
    },

    show: Boolean,
  },

  data() {
    return {
      allRecordsMatch: false,
      allRecordsMatchRequestedAction: false,
      currentIndex: 0,
      deleteConfirmed: false,
      entities: [] as Record<string, string|number|boolean>[],
      failedEntities: [] as string[],
      step: 0,
      stepLoading: false,
    }
  },

  computed: {
    canStepNext(): boolean {
      return this.step == 1 || 
        (this.allRecordsMatch && this.allRecordsMatchRequestedAction &&
          (this.deleteConfirmed || !this.requireDeleteConfirmation));
    },

    failureCount(): number {
      return this.failedEntities.length;
    },

    nextStepText(): string {
      if (this.step == 0) {
        return this.restore ? 'Restore' : 'Delete';
      } else {
        return 'Close';
      }
    },

    progress(): number {
      if (this.step == 1 && this.entities.length > 0) {
        return ((this.currentIndex + 1) / this.entities.length) * 100
      } else {
        return 0
      }
    },

    requireDeleteConfirmation(): boolean {
      return (!this.restore && (this.entities[0] || {} ).deletedBy > '');
    }
  },

  watch: {
    show(): void {
      if (this.show) {
        var type = '';
        var allMatchSoFar = true;
        var allMatchRequestedAction = true;

        this.entityList.forEach(e => {
          const newE = JSON.parse(JSON.stringify(e));
          newE.entityManagerMultiDeleteStatus = "ready";
          this.entities.push(newE);

          const curType = (e.deletedBy ?? '' > "") ? "deleted" : "notDeleted";
          type = type > '' ? type : curType;
          const curEntityOk = (curType == type) && ((this.restore && curType == 'deleted') || !this.restore)

          if (!curEntityOk) newE.entityManagerMultiDeleteStatus = "failed";

          allMatchSoFar = allMatchSoFar && (curType == type);
          allMatchRequestedAction = allMatchRequestedAction && ((this.restore && curType == 'deleted') || !this.restore)
        })

        this.allRecordsMatch = allMatchSoFar;
        this.allRecordsMatchRequestedAction = allMatchRequestedAction;
      }
    }
  },

  methods: {
    async cancel(): Promise<void> {
      const confirmed = (this.step == 0) || await popup.confirm(
        this.$store,
        "Confirm Cancel",
        "This action is not yet completed. Are you sure you want to cancel?",
        "Yes",
        "No",
        `Some ${this.pluralEntityType} may have already been processed. This will not be reversed by cancelling.`);

      if (confirmed) {
        this.close();
      }
    },

    close(): void {
      this.currentIndex = 0;
      this.entities = [];
      this.failedEntities = [];
      this.step = 0;
      this.stepLoading = false;
      this.allRecordsMatch = false;
      this.allRecordsMatchRequestedAction = false;
      this.deleteConfirmed = false;
      this.$emit('update:show', false);
    },

    getIconColorForState(status: 'ready'|'inProgress'|'done'|'failed'): string {
      if (status == 'ready') {
        return '';
      } else if (status == 'inProgress') {
        return 'primary';
      } else if (status == 'done') {
        return 'success';
      } else {
        return 'error';
      }
    },

    getIconForState(status: 'ready'|'inProgress'|'done'|'failed'): string {
      if (status == 'ready') {
        return 'mdi-clock-outline';
      } else if (status == 'inProgress') {
        return 'mdi-sync';
      } else if (status == 'done') {
        return 'mdi-check';
      } else {
        return 'mdi-alert-circle-outline';
      }
    },

    nextStep(): void {
      if (this.step == 0) {
        this.step = 1;
        this.runDeleteOperation();
      } else {
        this.syncEntityList();
        this.close();
      }
    },

    async runDeleteOperation(): Promise<void> {
      this.stepLoading = true;

      const failedEntities: string[] = [];

      for (let i = 0; i < this.entities.length && this.step == 1; i++) {
        const e = this.entities[i];
        this.currentIndex = i;
        
        try {
          e.entityManagerMultiDeleteStatus = 'inProgress';

          if (this.restore) {
            await this.$store.dispatch(this.restoreActionName, e);
            
          } else {
            const payload: ApiDeletePayload<PrivateEntity> = {
              force: false,
              entity: e as unknown as PrivateEntity,
              hard: e.deletedBy > ""
            }

            await this.$store.dispatch(this.deleteActionName, payload);
          }


          e.entityManagerMultiDeleteStatus = 'done';
        } catch (er) {
          e.entityManagerMultiDeleteStatus = 'failed';
          this.$set(e, "entityManagerMultiDeleteFailureReason", (er as ApiResponse).data as string)
          failedEntities.push(e.id as string);
        }
      }

      if (failedEntities.length > 0) {
        this.failedEntities = failedEntities;
      }

      this.stepLoading = false;
    },

    syncEntityList(): void {
      if (this.failedEntities.length > 0) {
        const entitiesStillSelected = this.failedEntities.map(id => this.entityList.find(x => x.id == id))
        this.$emit('update:entity-list', entitiesStillSelected);
      } else {
        this.$emit('update:entity-list', []);

      }
    }
  }
})
