import * as mt from '../mutationNames'
import { AssignmentWorkflow, AssignmentWorkflowListModel } from '@/models/assignments/AssignmentWorkflow';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_ADD_REFERRER] (state, referrer: VuexEntityReferer<AssignmentWorkflow>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<AssignmentWorkflow>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_WORKFLOW_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<AssignmentWorkflowListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName);
  },
}