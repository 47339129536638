export type PromiseResolveFunction<T> = (value: T | PromiseLike<T>) => void
export type PromiseRejectFunction = (reason?: string|null|undefined) => void

export default class PromiseCompleter<T> {
  public resolve: PromiseResolveFunction<T>
  public reject: PromiseRejectFunction

  constructor(resolve: PromiseResolveFunction<T>, reject: PromiseRejectFunction) {
    this.resolve = resolve;
    this.reject = reject;
  }
}