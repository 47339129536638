import { Ticket, TicketListModel } from '@/models/tickets/Ticket';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Ticket, TicketListModel> {
  protected instantiateListModelForEntity(entity: Ticket): TicketListModel {
    const listModel = new TicketListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Ticket'
  }
}