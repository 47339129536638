import { CustomerNameOnly } from "../customers/CustomerNameOnly";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";
import { QuoteTiny } from "../quotes/QuoteTiny";
import { WorkOrder } from "./WorkOrder";
import {WorkOrderCategoryListModel} from "../workOrderCategories/WorkOrderCategoryListModel";
import { WorkOrderStepListModel } from "./WorkOrderStepListModel";

export class WorkOrderListModel extends PrivateEntityListModel<WorkOrder> {
  public client = '';
  public category: WorkOrderCategoryListModel = new WorkOrderCategoryListModel();
  public workOrderNumber = '';
  public customer: CustomerNameOnly = {id: '', name: ''};
  public quote?: QuoteTiny = new QuoteTiny();
  public name = '';
  public status = '';
  public deletedBy = '';
  public createdAt = '';
  public closedAt = '';
  public clientInstructions = '';
  public internalNotes = '';
  public targetDate? = '';
  public priority = '';
  public salesValue = 0;
  public sortIndex = 0;
  public steps: WorkOrderStepListModel[] = [];

  protected populateCore(model: WorkOrder): void {
    this.client = model.client;
    this.category = model.category;
    this.workOrderNumber = model.workOrderNumber;
    this.customer = model.customer;
    this.quote = model.quote;
    this.name = model.name;
    this.status = model.status;
    this.deletedBy = model.deletedBy ?? '';
    this.createdAt = model.createdAt ?? '';
    this.closedAt = model.closedAt ?? '';
    this.clientInstructions = model.clientInstructions;
    this.internalNotes = model.internalNotes;
    this.targetDate = model.targetDate;
    this.priority = model.priority;
    this.salesValue = model.salesValue;
    this.sortIndex = model.sortIndex;

    this.steps = WorkOrderStepListModel.ConvertArrayOfSteps(model.steps);
  }
}