import { compileList, wrapQuotes } from "@/util/naturalLanguage";
import { SearchUIFieldType, SearchUIRangeValue } from "./SearchUIOption";
import { AddressListItem } from "@/models/contacts/AddressListItem";
import InsightUser from "../InsightUser";
import ItemCategoryListModel from "@/models/items/ItemCategoryListModel";
import reformatDate from "@/util/reformatDate";
import { StringQueryVarSet } from "./StringQueryVarSet";
import { TicketTypeListModel } from "@/models/tickets/TicketType";
import { UserIdListQueryVarSet } from "./UserIdListQueryVarSet";
import { WorkOrderCategoryListModel } from "@/models/workOrderCategories/WorkOrderCategoryListModel";

export const describeValueOfType: Record<number, (v: unknown) => string> = {};

//********************************************************************
describeValueOfType[SearchUIFieldType.ContactSet] = v => {
  const contacts = v as AddressListItem[];

  return 'is ' + contacts.reduce((str, c, idx) => {
    if (idx == 0) {
      return str + wrapQuotes(c.address);
    } else if (idx == contacts.length - 1) {
      return str + (idx > 1 ? ',': '') + ' or ' + wrapQuotes(c.address);
    } else {
      return str + ', ' + wrapQuotes(c.address);
    }
  }, '');
}

//********************************************************************
describeValueOfType[SearchUIFieldType.DateTime] = v => {
  const range = v as SearchUIRangeValue<string>;

  if (range.operator == 'lte') {
    return 'is on or before ' + range.value1;
  } else if (range.operator == 'gte') {
    return 'is on or after ' + range.value1;
  } else {
    return 'is between ' + range.value1 + ' and ' + (range.value2 ?? '');
  }
}

//********************************************************************
describeValueOfType[SearchUIFieldType.DateTimeOffset] = v => {
  const range = v as SearchUIRangeValue<string>;

  if (range.operator == 'lte') {
    return 'is on or before ' + reformatDate(range.value1, "mm/dd/yyyy h:MM TT");
  } else if (range.operator == 'gte') {
    return 'is on or after ' + reformatDate(range.value1, "mm/dd/yyyy h:MM TT");
  } else {
    return 'is between ' + reformatDate(range.value1, "mm/dd/yyyy h:MM TT") + ' and ' + reformatDate(range.value2 ?? '', "mm/dd/yyyy h:MM TT");
  }
}

//********************************************************************
describeValueOfType[SearchUIFieldType.ItemCategorySet] = v => {
  const categories = v as ItemCategoryListModel[];
  return 'is ' + compileList(categories.map(c => wrapQuotes(c.name)), 'or');
}

//********************************************************************
describeValueOfType[SearchUIFieldType.LifecycleCloseReasonSet] = v => {
  const types = v as string[];
  return 'is ' + compileList(types.map(x => wrapQuotes(x)), "or")
},

//********************************************************************
describeValueOfType[SearchUIFieldType.LifecycleStatusSet] = v => {
  const types = v as string[];
  return 'is ' + compileList(types.map(x => wrapQuotes(x)), "or")
},

//********************************************************************
describeValueOfType[SearchUIFieldType.NumberRange] = v => {
  const range = v as SearchUIRangeValue<number>;

  if (range.operator == 'lte') {
    return 'is less than or equal to ' + range.value1;
  } else if (range.operator == 'gte') {
    return 'is greater than or equal to ' + range.value1;
  } else {
    return 'is between ' + range.value1 + ' and ' + (range.value2 ?? '');
  }
}

//********************************************************************
describeValueOfType[SearchUIFieldType.PercentRange] = v => {
  const range = v as SearchUIRangeValue<number>;

  if (range.operator == 'lte') {
    return 'is less than or equal to ' + Math.round(range.value1 * 100);
  } else if (range.operator == 'gte') {
    return 'is greater than or equal to ' + Math.round(range.value1 * 100);
  } else {
    return 'is between ' + Math.round(range.value1 * 100) + ' and ' + Math.round((range.value2 ?? 0) * 100);
  }
}

//********************************************************************
describeValueOfType[SearchUIFieldType.StringContainsOrEquals] = v => {
  const options = v as StringQueryVarSet;

  if (options.exactMatch) {
    return 'is ' + wrapQuotes(options.value);
  } else {
    return 'contains ' + wrapQuotes(options.value);
  }
}

//********************************************************************
describeValueOfType[SearchUIFieldType.StringSet] = v => {
  const items = v as string[];
  return 'is ' + compileList(items.map(item => wrapQuotes(item)), 'or');
}

//********************************************************************
describeValueOfType[SearchUIFieldType.TicketTypeSet] = v => {
  const types = v as TicketTypeListModel[];
  return 'is ' + compileList(types.map(x => wrapQuotes(x.name)), "or")
},

//********************************************************************
describeValueOfType[SearchUIFieldType.UserSet] = v => {
  const users = v as InsightUser[];
  return 'is ' + compileList(users.map(u => wrapQuotes(u.name)), 'or');
}

//********************************************************************
describeValueOfType[SearchUIFieldType.UserIdSet] = v => {
  const vars = v as UserIdListQueryVarSet<InsightUser>;

  if (vars.anyValueButBlank) {
    return 'is not blank'
  }

  const users = (vars.values ?? []).map(x => wrapQuotes(x.name));

  if (vars.doNotExcludeBlanks) {
    users.push("blank");
  }

  return 'is ' + compileList(users, 'or');
}

//********************************************************************
describeValueOfType[SearchUIFieldType.WorkOrderCategorySet] = v => {
  const categories = v as WorkOrderCategoryListModel[];
  return 'is ' + compileList(categories.map(c => wrapQuotes(c.name)), 'or');
}