import { getPathsForPropsNamed } from "./BufferTraversalFunctions";

export function preserveTempIds(mine: never, fromServer: never): void {
  const tempIdPaths = getPathsForPropsNamed('tempId', mine);

  tempIdPaths.forEach(path => {
    const myValue = path.readFromBuffer(mine);

    if (myValue) {
      path.writeToBuffer(fromServer, myValue);
    }
  });
}