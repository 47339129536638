import { Comment } from '@/models/comments/Comment';
import { CommentListModel } from '@/models/comments/CommentListModel';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Comment, CommentListModel> {
  protected instantiateListModelForEntity(entity: Comment): CommentListModel {
    const listModel = new CommentListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Comment'
  }
}