import Mailbox from '@/models/mailboxes/Mailbox'
import MailboxListModel from '@/models/mailboxes/MailboxListModel';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Mailbox, MailboxListModel> {
  protected instantiateListModelForEntity(entity: Mailbox): MailboxListModel {
    const listModel = new MailboxListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Mailbox'
  }
}