import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF } from '../refNames';
import { Ticket, TicketListModel } from '@/models/tickets/Ticket';
import { ActionTree } from "vuex";
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import EntityActionResult from '@/models/core/api/EntityActionResult';
import EntityLinkParameters from '@/models/core/entities/EntityLinkParameters';
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.TICKET_DELETE] ({ commit }, data: ApiDeletePayload<Ticket>): Promise<void> {
    const url = data.entity.client + '/tickets/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.TICKET_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.TICKET_FETCH] (
    { commit, getters },
    req: VuexFetchRequest
  ): Promise<TicketListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/tickets`
    return ah.getEntityList(
      url,
      client,
      mt.TICKET_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false);
  },

  //***********************************************************************
  async [ac.TICKET_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<TicketListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/tickets`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.TICKET_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false,
      req.pagerId,
      req.pageSize,
      req.pageNumber,
      req.pageDataMutationName,
      req.orderBy,
      req.orderDescending,
    )
  },

  //***********************************************************************
  async [ac.TICKET_FETCH_FOR_ENTITY] ({ commit }, entity: PrivateEntitySpec): Promise<TicketListModel[] | null> {
    const url = `${entity.client}/tickets/linkedTo/${entity.id}`
    const apiResponse = await api.get(url);

    if (apiResponse) {
      if (!apiResponse.error) {
        const tickets = apiResponse.data as TicketListModel[];

        const mutationData: EntitiesForRef<TicketListModel> = {
          entities: tickets,
          refName: ENTITY_EDITOR_LINK_REF_STEM + entity.id,
        }

        commit(mt.TICKET_STORE_LIST_MODELS_FOR_REF, mutationData);

        return tickets;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.TICKET_FETCH_LINK_DESCRIPTIONS] ({ commit }, entity: TicketListModel): Promise<void> {
    entity.links.forEach(l => {
      if (!l.description) {
        ah.getLinkDescription(entity.client, entity.id, l.type, l.entityId, mt.TICKET_SET_LINK_DESCRIPTION, commit);
      }
    });
  },

  //***********************************************************************
  async [ac.TICKET_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<Ticket | null> {
    const url = request.spec.client + '/tickets/' + request.spec.id

    return ah.getPrivateEntity<Ticket>(url, mt.TICKET_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.TICKET_SAVE] ({ commit }, data: {entity: Ticket; refName: string}): Promise<Ticket | null> {
    const url = data.entity.client + '/tickets'

    let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

    if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
      const targetEntityId = data.refName.substr(ENTITY_EDITOR_LINK_REF_STEM.length);

      if (data.entity.links && data.entity.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.ListModelOnly;
      } else if (data.entity.requestedLinks && data.entity.requestedLinks.findIndex(x => x.id == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.ListModelOnly;
      }
    } else if (data.refName == ENTITY_MANAGER_REF) {
      mode = VuexEntityStorageRequestMode.Add
    }

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.TICKET_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.TICKET_UNDELETE] ({ commit }, entity: Ticket): Promise<Ticket | null> {
    const url = entity.client + '/tickets'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.TICKET_STORE_ENTITY_FOR_REF, commit);
  },

  //***********************************************************************
  async [ac.TICKET_UNLINK_FROM_ENTITY] ({ commit }, data: EntityLinkParameters): Promise<Ticket | null> {
    const url = `${data.linkOwner.client}/tickets/${data.linkOwner.id}/actions/unlink/${data.targetType}/${data.target.id}`

    const apiResponse = await api.create(url, null);

    if (apiResponse) {
      if (!apiResponse.error) {
        const actionResult = apiResponse.data as EntityActionResult<Ticket>
        const ticket = actionResult.entity;

        commit(mt.TICKET_STORE_ENTITY_FOR_REF, {entity: ticket, refName: '', mode: 'updateOnly'})

        return ticket;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.TICKET_LINK_TO_ENTITY] ({ commit }, data: {link: EntityLinkParameters; refName: string}): Promise<Ticket | null> {
    const url = `${data.link.linkOwner.client}/tickets/${data.link.linkOwner.id}/actions/link/${data.link.targetType}/${data.link.target.id}`

    const apiResponse = await api.create(url, null);

    if (apiResponse) {
      if (!apiResponse.error) {
        const actionResult = apiResponse.data as EntityActionResult<Ticket>
        const ticket = actionResult.entity;

        let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

        if (data.refName == ENTITY_MANAGER_REF) {
          mode = VuexEntityStorageRequestMode.Add;

        } else if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
          const targetEntityId = data.refName.substring(ENTITY_EDITOR_LINK_REF_STEM.length);

          if (ticket.links && ticket.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
            mode = VuexEntityStorageRequestMode.Add;
          }
        }

        commit(mt.TICKET_STORE_ENTITY_FOR_REF, {entity: ticket, refName: data.refName, mode: mode})

        return ticket;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  }
}