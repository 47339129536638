import * as gt from '../getterNames'
import Client from '@/models/core/Client'
import Customer from '@/models/customers/Customer'
import CustomerListModel from '@/models/customers/CustomerListModel'
import { ENTITY_MANAGER_REF } from '../refNames'
import { GetterTree } from 'vuex'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.CUSTOMERS_FOR_CURRENT_CLIENT](state, getters): CustomerListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.CUSTOMERS_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): CustomerListModel[] => {
      return state.getListModelsForRef(client, refName);
  },
    
  [gt.CUSTOMERS_OPEN_FOR_CURRENT_CLIENT] (state, getters): Customer[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.CUSTOMERS_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): Customer[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.CUSTOMER_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },
}