
import { handleEvent } from "@/util/FunctionalComponentEventDispatcher";

export default {
  props: {
    openPanels: {
      type: Array,
      default: (): number[] => [],
    },

    panels: {
      type: Array,
      default: null,
    },
  },

  methods: {
    handleEvent,
  }
}
