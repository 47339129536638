
import { AssignmentWorkflow } from '@/models/assignments/AssignmentWorkflow'
import { FieldSetConflictMixin } from '../../common/FieldSetConflictMixin'
import NameField from '../../common/forms/NameField.vue'
import { PropValidator } from 'vue/types/options'
import StepEditor from './StepEditor.vue'

export default FieldSetConflictMixin.extend({
  components: {
    NameField,
    StepEditor,
  },

  props: {
    entity: {
      type: Object,
      default: (): AssignmentWorkflow => new AssignmentWorkflow()
    } as PropValidator<AssignmentWorkflow>,

    readonly: Boolean,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {
  }
})
