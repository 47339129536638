import PromiseCompleter, { PromiseRejectFunction, PromiseResolveFunction } from "./PromiseCompleter";

export default class PopupRequest extends PromiseCompleter<boolean> {
  constructor(
    resolve: PromiseResolveFunction<boolean>,
    reject: PromiseRejectFunction,
    public title: string,
    public msg: string,
    public yesText: string,
    public noText: string,
    public mode: 'confirm'|'alert',
    public warning = '',
    public showCancel = false,
  ) {
    super(resolve, reject);
  }
}