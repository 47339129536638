import { actions } from "./actions";
import { getters } from "./getters";
import { Module } from "vuex";
import { mutations } from "./mutations";
import RootState from '../RootState';
import State from './state';

const state = new State();

export const contactCollections: Module<State, RootState> = {
  state,
  actions,
  mutations,
  getters
}