import Item from './Item';
import PrivateEntityListModel from '../core/entities/PrivateEntityListModel';

export default class ItemListModel extends PrivateEntityListModel<Item> {
    
    public itemCode = '';
    public description = '';
    public listPrice = 0;
    public categoryId = "0";
    public discountable = false;

    protected populateCore(model: Item): void {
        this.itemCode = model.itemCode;
        this.description = model.description;
        this.listPrice = model.listPrice;
        this.categoryId = model.categoryId;
        this.discountable = model.discountable;
    }
}