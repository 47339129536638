
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import { CORE_GET_LIFECYCLE_FOR_TYPE } from "@/store/getterNames";
import IconButton from "../IconButton.vue";
import { Lifecycle } from "@/models/lifecycles/Lifecycle";
import { PropValidator } from "vue/types/options";
import ReadOnlyField from "../forms/ReadOnlyField.vue";
import ScrollBox from "../ScrollBox.vue";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    ReadOnlyField,
    IconButton,
    ScrollBox
  },

  props: {
    locked: Boolean,
    
    options: {
      type: String,
      default: null,
    },

    value: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,
  },

  data() {
    return {
      showItemPicker: false,
    }
  },

  computed: {
    internalValue: {
      get(): string[] {
        return this.value;
      },

      set(newValue: string[]): void {
        this.$emit('input', newValue);
      }
    },

    optionList(): string[] {
      const lifecycle = this.$store.getters[CORE_GET_LIFECYCLE_FOR_TYPE](this.options) as Lifecycle|undefined

      return lifecycle?.stages.flatMap(x => x.statuses.map(y => y.status)) ?? []
    }
  },

  methods: {
    handleRemoveItem(item: string): void {
      this.internalValue = this.internalValue.filter(x => x != item)
    }
  }
})
