
import { ChecklistItem } from '@/models/assignments/Assignment';
import draggable from 'vuedraggable'
import popup from '@/util/popup';
import { PropValidator } from 'vue/types/options';
import Vue from 'vue';

export default Vue.extend({
  components: {
    draggable,
  },

  props: {
    value: {
      type: Array,
      default: null,
    } as PropValidator<ChecklistItem[]>,
  },

  methods: {
    addItem(): void {
      this.$emit('input', [...this.value, new ChecklistItem(this.value.length)]);
    },

    async handleDragEnd(): Promise<void> {
      const items = [] as ChecklistItem[];
      for (let i = 0; i < this.value.length; i++) {
        items.push(new ChecklistItem(i, this.value[i].text));
      }
      this.$emit('input', items);
    },

    handleTextChanged(newValue: string, idx: number): void {
      var oldItem = this.value[idx];
      const items = [...this.value];
      items.splice(idx, 1, new ChecklistItem(oldItem.position, newValue));
      this.$emit('input', items);
    },

    async removeItem(idx: number): Promise<void> {
      const confirmed = await popup.confirm(
        this.$store,
        "Confirm Delete",
        "Are you sure you want to remove this checklist item?");

      if (!confirmed) return;

      const items = [...this.value];
      items.splice(idx, 1);
      this.$emit('input', items);
    },
  }
})
