
import { maxLength, requiredIf, Validator } from '../../../util/validators'

const maxChars = 1000

export default {
  methods: {
    getRules(required: boolean, label: string): Validator[] {
      return [
        requiredIf(() => required, () => label),
        maxLength(maxChars, () => label)
      ]
    },

    getMaxChars(): number {
      return maxChars;
    }
  }
}
