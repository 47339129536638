import { Assignment, AssignmentListModel } from '@/models/assignments/Assignment';
import { AssignmentKanbanState } from '@/models/assignments/AssignmentKanbanState';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Assignment, AssignmentListModel> {
  public kanbanState = {} as Record<string, AssignmentKanbanState>;

  protected instantiateListModelForEntity(entity: Assignment): AssignmentListModel {
    const listModel = new AssignmentListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Assignment'
  }
}