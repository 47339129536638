import * as mt from '../store/mutationNames'
import PopupRequest from '@/models/core/PopupRequest'
import RootState from '@/store/RootState'
import { Store } from 'vuex'

const emit = (
  store: Store<RootState>,
  title: string,
  msg: string,
  yesText: string,
  noText: string,
  mode: 'alert'|'confirm',
  warning = "",
  showCancel = false,
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const request = new PopupRequest(resolve, reject, title, msg, yesText, noText, mode, warning, showCancel)
    store.commit(mt.CORE_ADD_POPUP_REQUEST, request)
  });
}

const confirm = (store: Store<RootState>, title: string, msg: string, yesText = 'yes', noText = 'no', warning = ''): Promise<boolean> => {
  return emit(store, title, msg, yesText, noText, "confirm", warning);
}

const confirmWithCancel = (store: Store<RootState>, title: string, msg: string, yesText = 'yes', noText = 'no', warning = ''): Promise<boolean> => {
  return emit(store, title, msg, yesText, noText, "confirm", warning, true);
}

const alert = (store: Store<RootState>, title: string, msg: string): Promise<boolean> => {
  return emit(store, title, msg, "OK", "NOT_USED", "alert");
}

export default {
  emit,
  confirm,
  confirmWithCancel,
  alert
}
