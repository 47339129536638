
import { CORE_BUFFERED_EDITOR_RESET, CORE_BUFFERED_EDITOR_SAVE, MESSAGE_SAVE, MESSAGE_SEND } from '@/store/actionNames'
import BufferedEditor from '../common/BufferedEditor.vue'
import { BufferedEditorEdit } from '@/models/core/bufferedEditor/BufferedEditorEdit'
import { BufferedEditorResetRequest } from '@/models/core/bufferedEditor/BufferedEditorResetRequest'
import delay from '@/util/delay'
import FileChooser from '../files/FileChooser.vue'
import FileUploader from '../files/FileUploader.vue'
import HtmlEditor from '../common/HtmlEditor.vue'
import IconButton from '../common/IconButton.vue'
import Message from '@/models/messages/Message'
import MessageAttachments from './MessageAttachments.vue'
import MessageContact from '@/models/messages/MessageContact'
import { MessageContactType } from '@/models/messages/MessageEnums'
import MessageEditorContactRow from './MessageEditorContactRow.vue'
import MessageHeaderRow from './MessageHeaderRow.vue'
import MessageViewerContactRow from './MessageViewerContactRow.vue'
import popup from '@/util/popup'
import { PropValidator } from 'vue/types/options'
import reformatDate from '@/util/reformatDate'
import Vue from 'vue'
import WerkFile from '@/models/files/WerkFile'
import WerkFileListModel from '@/models/files/WerkFileListModel'

export default Vue.extend({
  components: {
    BufferedEditor,
    FileChooser,
    FileUploader,
    HtmlEditor,
    IconButton,
    MessageAttachments,
    MessageEditorContactRow,
    MessageHeaderRow,
    MessageViewerContactRow,
  },

  props: {
    editorId: {
      type: String,
      default: null,
      required: true
    },

    height: {
      type: Number,
      default: 0,
    },
    
    message: {
      type: Object,
      default: (): null => null,
      required: true,
    } as PropValidator<Message>,

    refName: {
      type: String,
      default: (): null => null,
      required: false,
    }
  },

  data() {
    return {
      bccRequested: false,
      bodyHeight: 100,
      ccRequested: false,
      footerRequested: false,
      saveActionName: MESSAGE_SEND,
      saveSendStatus: 'suceeded' as 'started'|'failed'|'suceeded',
      sending: true,
      showAttachmentMenu: false,
      showFileChooser: false,
      showUploader: false,
    }
  },

  computed: {
    bccContactType(): MessageContactType {
      return MessageContactType.Bcc;
    },

    canAttach(): boolean {
      return this.$auth.hasPrivilegeForClient(this.message.client, "1004210");
    },

    ccContactType(): MessageContactType {
      return MessageContactType.Cc;
    },

    formattedDate(): string {
      return reformatDate(this.message.messageDate, "mm/dd/yyyy h:MM tt")
    },

    hasSubject(): boolean {
      return this.message.subject > '';
    },

    showBccRow(): boolean {
      return this.bcc(this.message).length > 0 || this.bccRequested;
    },

    showCcRow(): boolean {
      return this.cc(this.message).length > 0 || this.ccRequested;
    },

    showFooter(): boolean {
      return this.message.attachments.length > 0 || this.footerRequested;
    },

    toContactType(): MessageContactType {
      return MessageContactType.To;
    },
  },

  watch: {
    bccRequested(): void {
      this.calcBodyHeight();
    },
    
    ccRequested(): void {
      this.calcBodyHeight();
    },

    height: {
      handler(): void {
        this.calcBodyHeight();
      },
    },
    
    ['message.id'](): void {
      const req = new BufferedEditorResetRequest(
        this.editorId,
        this.message,
        true
      );

      this.$store.dispatch(CORE_BUFFERED_EDITOR_RESET, req);
    }
  },

  mounted() {
    this.calcBodyHeight();

    delay(500).then(() => this.calcBodyHeight())
  },

  methods: {
    bcc(msg: Message): MessageContact[] {
      return msg.contacts?.filter(x => x.type == MessageContactType.Bcc);
    },

    calcBodyHeight(): void {
      this.$nextTick(() => {
        const el = this.$el;
        const header = el.querySelector(".messagePreviewHeader");
        const footer = el.querySelector(".messagePreviewFooter");

        const headerHeight = header ? header.clientHeight : 0;
        const footerHeight = footer ? footer.clientHeight : 0;

        this.bodyHeight = this.height - headerHeight - footerHeight;
      })
    },

    cc(msg: Message): MessageContact[] {
      return msg.contacts?.filter(x => x.type == MessageContactType.Cc);
    },

    createEditEvent(fieldName: string, value: unknown): BufferedEditorEdit[] {
      return [new BufferedEditorEdit(fieldName, value)]
    },

    from(msg: Message): MessageContact[] {
      return msg.contacts?.filter(x => x.type == MessageContactType.From);
    },
    
    handleRecipientChange(
      updatedContacts: MessageContact[],
      editHandler: (edits: BufferedEditorEdit[]) => void,
      buffer: Message,
      type: 'to'|'cc'|'bcc'
    ): void {
      const contactType = type == 'to' ? MessageContactType.To : type == 'cc' ? MessageContactType.Cc: MessageContactType.Bcc;

      const contacts = buffer.contacts.filter(x => x.type != contactType);
      contacts.push(...updatedContacts);
      editHandler([new BufferedEditorEdit('contacts', contacts)]);
    },

    onSelectFile(file: WerkFileListModel, messageBuffer: Message, editHandler: (edits: BufferedEditorEdit[]) => void): void {
      this.footerRequested = true;
      const attachments: WerkFileListModel[] = []
      attachments.push(...messageBuffer.attachments)
      attachments.push(file);

      editHandler([new BufferedEditorEdit('attachments', attachments)])
    },

    onUploadFile(file: WerkFile, messageBuffer: Message, editHandler: (edits: BufferedEditorEdit[]) => void): void {
      this.footerRequested = true;

      const listModel = new WerkFileListModel();
      listModel.populate(file);

      const attachments: WerkFileListModel[] = []
      attachments.push(...messageBuffer.attachments)
      attachments.push(listModel);

      editHandler([new BufferedEditorEdit('attachments', attachments)])
    },

    reset(): void {
      const req = new BufferedEditorResetRequest(
        this.editorId
      )

      this.$store.dispatch(CORE_BUFFERED_EDITOR_RESET, req);
    },

    async saveMail(): Promise<void> {
      this.saveSendStatus = 'started'
      this.saveActionName = MESSAGE_SAVE;
      this.sending = false;

      await delay(100);

      const saved = await this.$store.dispatch(CORE_BUFFERED_EDITOR_SAVE, this.editorId);
      
      this.saveSendStatus = saved ? 'suceeded': 'failed';
    },

    async sendMail(msg: Message): Promise<void> {
      if (msg.contacts.length == 1) {
        popup.alert(this.$store, "Error", "Please add at least one recipient before sending.");
        return;
      }

      this.saveSendStatus = 'started'
      this.saveActionName = MESSAGE_SEND;
      this.sending = true;

      await delay(100);
      
      const saved = await this.$store.dispatch(CORE_BUFFERED_EDITOR_SAVE, this.editorId);

      this.saveSendStatus = saved ? 'suceeded': 'failed';
      
      if (saved) {
        this.$emit('message-sent', true);
      }
    },
    
    to(msg: Message): MessageContact[] {
      return msg.contacts?.filter(x => x.type == MessageContactType.To);
    },
  },

})
