export type ValueFormatter = (v: string|number) => string;

export const moneyFormatter: ValueFormatter = (v) => {
    const numVal = Number.parseFloat(v + '');

    return `$${((numVal*100)/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

export const percentFormatter: ValueFormatter = (v) => {
    const numVal = Number.parseFloat(v + '');

    return `${((numVal*10000)/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%`;
}
