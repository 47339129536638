import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue } from "@/models/core/searchQueries/SearchUIOption";
import { ENTITY_TYPES } from "@/models/assignments/AssignmentTemplate";
import { UpdatableEntitySearchFieldDefs } from "@/models/core/searchQueries/UpdatableEntitySearchFieldDefs";

export const AssignmentWorkflowSearchFieldDefs = [
  ...UpdatableEntitySearchFieldDefs,

  {
    fieldName: "name",
    displayName: "Name",
    type: SearchUIFieldType.StringContainsOrEquals
  },
  {
    fieldName: "entityType",
    displayName: "Entity Type",
    type: SearchUIFieldType.StringSet,
    options: [...ENTITY_TYPES]
  },
] as SearchUIFieldDef[];

export const DefaultAssignmentWorkflowQuery = [] as SearchUIFieldValue[]