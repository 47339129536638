
import api from '@/util/api'
import { DataTableHeader } from 'vuetify'
import EntityActionRecord from '@/models/core/entities/EntityActionRecord'
import pluralize from 'pluralize'
import reformatDate from '@/util/reformatDate'
import Vue from 'vue'


export default Vue.extend({

  filters: {
    formatDisplayDate(v?: string): string {
      return reformatDate(v, "mm/dd/yyyy hh:MM tt")
    },
  },

  props: {
    show: Boolean,

    entityId: {
      type: String,
      default: "0",
    },

    client: {
      type: String,
      default: '',
    },

    entityType: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      records: [] as EntityActionRecord[],
      loadingHistory: 'no' as 'yes'|'no'|'failed',
      showEditRecords: false,
      headers: [
        {
          value: 'action',
          text: 'Action',
          class: 'secondary',
          width: '33%',
        },
        {
          value: 'completedBy',
          text: 'User',
          class: 'secondary',
          width: '34%',
        },
        {
          value: 'completedAt',
          text: 'Date',
          class: 'secondary',
          width: '33%',
          sortable: true,
        }
      ] as DataTableHeader[]
    }
  },

  computed: {
    filteredRecords(): EntityActionRecord[] {
      return this.records.filter(r => r.action != 'Modified' || this.showEditRecords);
    }
  },

  watch: {
    show: {
      handler(): void {
        if (this.show) {
          this.loadHistory();
        } else {
          this.records = [];
        }
      },

      immediate: true,
    }
  },

  methods: {
    closeDialog(): void {
      this.$emit('update:show', false);
    },

    loadHistory(): void {
      this.loadingHistory = 'yes';

      api.get(`${this.client}/${pluralize(this.entityType, 2)}/${this.entityId}/actions`)

      .then(response => {
        if (response) {
          if (response.error) {
            this.loadingHistory = 'failed';
          } else {
            this.records = response.data as EntityActionRecord[];

            this.loadingHistory = 'no';
          }
        } else {
          this.loadingHistory = 'failed';
        }
      })

      .catch(() => this.loadingHistory = 'failed');
    }
  }
})
