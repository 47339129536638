
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

let scrollBoxCounter = 0;

export default Vue.extend({
  props: {
    height: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: [String],
      default: '',
    },
    maxWidth: {
      type: [String],
      default: '',
    },
    outlined: Boolean,
    rounded: Boolean,
    tile: Boolean,
    color: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
    scrollTo: {
      type: String,
      default: '',
    }
  },

  data () {
    return {
      id: `scrollBox${scrollBoxCounter++}`,
    }
  },

  computed: {
    internalBorderColor(): string {
      if (this.borderColor) {
        const exp = /((-lighten)|(-darken))[1-5]$/
        if (exp.test(this.borderColor)) {
          return `var(--v-${this.borderColor})`;
        } else {
          return `var(--v-${this.borderColor}-base)`;
        }
      } else {
        return '#888'
      }
    },

    innerBoxStyle(): string {
      let style = '';
      
      if (this.height) {
        style += `height: calc(${this.height} - 16px);`
      }
      
      if (this.maxHeight) {
        style += `max-height: calc(${this.maxHeight} - 16px);`
      }

      if (this.width) {
        style += `width: calc(${this.width} - 16px);`
      }
      
      if (this.maxWidth) {
        style += `max-width: calc(${this.maxWidth} - 16px);`
      }

      return style;
    }
  },

  watch: {
    scrollTo: {
      handler(): void {
        if (this.scrollTo == '') return;

        const options = {
          container: `#${this.id}`,
          easing: 'ease-in',
          duration: 300,
          force: true,
          cancelable: true,
          x: false,
          y: true
        }

        const ele = `${options.container} ${this.scrollTo}`;

        VueScrollTo.scrollTo(ele, options)
      },
      immediate: true,
    }
  }
})
