
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import IconButton from "../IconButton.vue";
import { PropValidator } from "vue/types/options";
import ReadOnlyField from "../forms/ReadOnlyField.vue";
import TicketTypeField from '../../tickets/types/TicketTypeField.vue'
import { TicketTypeListModel } from "@/models/tickets/TicketType";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    IconButton,
    ReadOnlyField,
    TicketTypeField,
  },

  props: {
    locked: Boolean,
    
    value: {
      type: Array,
      default: null,
    } as PropValidator<TicketTypeListModel[]>,
  },

  data() {
    return {
      catFieldValue: "0",
      showItemPicker: false,
    }
  },

  computed: {
    internalValue: {
      get(): TicketTypeListModel[] {
        return this.value;
      },

      set(newValue: TicketTypeListModel[]): void {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    async handleNewType(type: TicketTypeListModel): Promise<void> {
      this.internalValue = [...this.internalValue, type];

      await this.$nextTick();

      this.catFieldValue = "0";
    },

    handleRemoveType(type: TicketTypeListModel): void {
      this.internalValue = this.internalValue.filter(x => x != type)
    }
  }
})
