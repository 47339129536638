
import EntityNew from '../../common/EntityNew.vue'
import { EntityType } from '@/models/assignments/AssignmentTemplate';
import { PropValidator } from 'vue/types/options';
import { TICKET_TYPE_SAVE } from '@/store/actionNames'
import { TicketType } from '@/models/tickets/TicketType';
import TicketTypeEditorFieldSet from "./TicketTypeEditorFieldSet.vue";
import Vue from 'vue'

export default Vue.extend({

  components: {
    EntityNew,
    TicketTypeEditorFieldSet,
  },

  props: {
    client: {
      type: String,
      default: ''
    },

    entityType: {
      type: String,
      default: 'Ticket',
    } as PropValidator<EntityType>,

    show: Boolean,
  },

  data () {
    return {
      newTicketType: new TicketType()
    }
  },

  computed: {
    saveAction(): string {
      return TICKET_TYPE_SAVE;
    },
  },

  watch: {
    show: {
      handler() {
        if (this.show) {
          this.newTicketType = new TicketType();
          this.newTicketType.client = this.client;
        }
      }
    }
  },
})
