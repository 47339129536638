
import { CORE_SET_ENTITY_EDITOR_COUNTER, FILE_ADD_REFERRER, FILE_DROP_REFERRER, FILE_REMOVE_ENTITY_FROM_REF, FILE_STORE_ENTITY_FOR_REF } from '@/store/mutationNames'
import { FILE_FETCH_FOR_ENTITY, FILE_LINK_TO_ENTITY, FILE_UNLINK_FROM_ENTITY } from '@/store/actionNames'
import { FILE_REFERRER_EXISTS, FILES_LINKED_TO_ENTITY, FILES_OPEN_FOR_REF } from '@/store/getterNames'
import { GetWerkFileColor, GetWerkFileIcon, GetWerkFileTypeDescription, WerkFileType } from '@/models/files/WerkFileType'
import api from '@/util/api'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import { DataTableHeader } from 'vuetify'
import { DeletedSpec } from '@/models/core/spec/DeletedSpec'
import { ENTITY_EDITOR_LINK_REF_STEM } from '@/store/refNames'
import { EntityEditorCounterUpdate } from '@/models/core/entityEditor/EntityEditorChangeValueRequest'
import EntityLinkParameters from '@/models/core/entities/EntityLinkParameters'
import FileChooser from './FileChooser.vue'
import FileUploader from './FileUploader.vue'
import FileViewer from './FileViewer.vue'
import IconButton from '../common/IconButton.vue'
import { LinkedToSpec } from '@/models/core/spec/LinkedToSpec'
import notifications from '@/util/notifications'
import popup from '@/util/popup'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import { PropValidator } from 'vue/types/options'
import reformatDate from '@/util/reformatDate'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest'
import WerkFile from '@/models/files/WerkFile'
import WerkFileListModel from '@/models/files/WerkFileListModel'

export default Vue.extend({
  components: { 
    FileChooser,
    FileUploader,
    FileViewer,
    IconButton,
  },

  props: {
    entity: {
      type: Object,
      default: (): PrivateEntitySpec => {return {} as PrivateEntitySpec},
    } as PropValidator<PrivateEntitySpec>,

    entityType: {
      type: String,
      default: 'entity',
    },
  },

  data() {
    return {
      currentSortDesc: false,
      currentSortField: 'name',
      headers: [
        {
          value: 'name',
          text: 'Name',
        },
        {
          value: 'type',
          text: 'Type',
        },
        {
          value: 'createdAt',
          text: 'Date',
          width: 190,
        },
        {
          value: 'id',
          text: '',
          width: 40,
        },
      ] as DataTableHeader[],
      linkingFile: false,
      loadingEntityList: false,
      loadingEntityPreview: false,
      newEntity: null as WerkFile|null,
      previewerHeight: 600,
      previewerWidth: 1000,
      showEntityPreview: false,
      showFileChooser: false,
      showUploader: false,
      unlinkingId: "",
    }
  },

  computed: {
    canCreateFiles(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1004200") ||
             this.$auth.hasPrivilegeForClient(this.entity.client, "1004210")
    },

    canViewFiles(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1004100") ||
             this.$auth.hasPrivilegeForClient(this.entity.client, "1004110")
    },

    entities(): WerkFileListModel[] {
      return this.$store.getters[FILES_LINKED_TO_ENTITY](this.entity);
    },

    entityPreview(): WerkFile|null {
      return this.$store.getters[FILES_OPEN_FOR_REF](this.entity.client, this.refName)[0] as WerkFile || null;
    },

    referrerExists(): boolean {
      return this.$store.getters[FILE_REFERRER_EXISTS](this.refName);
    },

    refName(): string {
      return ENTITY_EDITOR_LINK_REF_STEM + this.entity.id
    },
  },

  watch: {
    'entities.length'(): void {
      const req = new EntityEditorCounterUpdate(this.entity.id, 'fileCount', this.entities.length);
      this.$store.commit(CORE_SET_ENTITY_EDITOR_COUNTER, req)
    }
  },

  created() {
    if (!this.referrerExists) {
      this.configureEntityReferrer();
      this.loadEntities();
    }
  },

  methods: {
    calcPreviewerDimensions(): void {
      this.previewerHeight = window.innerHeight - 160;
      this.previewerWidth = Math.min(window.innerWidth - 100, 1000);
    },

    configureEntityReferrer(): void {
      if (!this.canViewFiles) return;

      const targetEntityId = this.entity.id;

      const spec = new LinkedToSpec<WerkFile>(targetEntityId).and(new DeletedSpec().not());

      const referrer = new VuexEntityReferer(
        this.entity.client,
        this.refName,
        (entity, mode) =>  {
          if (mode == 'listModel'){
            const file = entity as WerkFile;

            return spec.isSatisfiedBy(file) ? 'yes' : 'no';
          }

          return 'noChange';
        }
      );

      this.$store.commit(FILE_ADD_REFERRER, referrer)
    },

    dropEntityReferrer(): void {
      this.$store.commit(FILE_DROP_REFERRER, {client: this.entity.client, name: this.refName});
    },

    fileTypeColor(type: WerkFileType): string {
      return GetWerkFileColor(type);
    },

    fileTypeIcon(type: WerkFileType): string {
      return GetWerkFileIcon(type);
    },

    fileTypeText(type: WerkFileType): string {
      return GetWerkFileTypeDescription(type);
    },

    formatDateCreated(createdDate: Date|string|null|undefined): string {
      return reformatDate(createdDate, 'mm/dd/yyyy hh:MM tt');
    },

    handleEntityDeleted(): void {
      this.showEntityPreview = false;
    },

    handleNewEntityRequest(): void {
      this.showUploader = true;
    },

    async handleUnlinkRequest(file: PrivateEntitySpec): Promise<void> {
      const confirmed = await popup.confirm(
        this.$store,
        "Confirm Action",
        `Are you sure you want to remove the link between this file and the current ${this.entityType}?`);

      if (confirmed) {
        const parameters: EntityLinkParameters = {
          target: this.entity,
          targetType: this.entityType,
          linkOwner: file,
        }

        this.unlinkingId = file.id;

        try {
          await this.$store.dispatch(FILE_UNLINK_FROM_ENTITY, parameters)
        } catch (error) {
          const e = error as ApiResponse;
          notifications.warn(this.$store, `Failed to unlink the file. Message: ${e.data || e.errorCause || e || "(No Message)"}`);
        }
        
        this.unlinkingId = "";

        this.showEntityPreview = false;
      }
    },

    async loadEntities(): Promise<void> {
      if (!this.canViewFiles) return;

      this.loadingEntityList = true;

      try {
        await this.$store.dispatch(FILE_FETCH_FOR_ENTITY, this.entity);
      } catch (error) {
        const e = error as ApiResponse;
        notifications.warn(this.$store, `There was an error while loading the files. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
      }
      

      this.loadingEntityList = false;
    },

    async onSelectFile(file: WerkFileListModel): Promise<void> {
      this.linkingFile = true;

      const parameters: EntityLinkParameters = {
        target: this.entity,
        targetType: this.entityType,
        linkOwner: file as PrivateEntitySpec,
      }

      try {
        await this.$store.dispatch(FILE_LINK_TO_ENTITY, {link: parameters, refName: this.refName})

        notifications.succeed(this.$store, `${file.name} has been linked to this ${this.entityType}`);
      } catch(error) {
        const e = error as ApiResponse;
        notifications.fail(this.$store, `Unable to link ${file.name} to this ${this.entityType}. Message: ${e.data || e.errorCause || e || "(No Message)"}`);
      }

      this.linkingFile = false;
    },

    onWindowResize(): void {
      this.calcPreviewerDimensions();
    },

    async previewEntity(file: WerkFileListModel): Promise<void> {
      this.loadingEntityPreview = true;

      const url = `${this.entity.client}/files/${file.id}`
      const apiResponse = await api.get(url)

      if (apiResponse) {
        if (!apiResponse.error) {
          this.setEntityPreview(apiResponse.data as WerkFile);
          this.showEntityPreview = true;
        } else {
          const e = apiResponse;
          notifications.warn(this.$store, `Failed to load file. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.loadingEntityPreview = false;
    },

    setEntityPreview(newEntity: WerkFile): void {
      if (this.entityPreview) {
        const spec: VuexEntityRefSpec ={
          refName: this.refName,
          spec: this.entityPreview as PrivateEntitySpec,
        }

        this.$store.commit(FILE_REMOVE_ENTITY_FROM_REF, spec);
      }

      if (newEntity) {
        const request: VuexEntityStorageRequest<WerkFile> = {
          refName: this.refName,
          entity: newEntity
        }

        this.$store.commit(FILE_STORE_ENTITY_FOR_REF, request)
      }
    },

    unlinkById(id: string): void {
      const file = this.entities.find(x => x.id == id);

      if (file) {
        this.handleUnlinkRequest(file);
      }
    },

    unload(): void {
      this.dropEntityReferrer();
    }
  }
})
