import colors from 'vuetify/lib/util/colors'
import { LifecycleStatusInfo } from "./LifecycleStatusInfo";

const ALL_LIFECYCLE_TYPES =    [
  'Unprocessed',
  'Pre-Processing',
  'Main Processing',
  'Post-Processing',
  'Hold',
  'Closure'
] as const;

const LIFECYCLE_STAGE_COLORS = [
  colors.pink.lighten4,
  colors.purple.base,
  colors.lightBlue.darken2,
  colors.brown.base,
  colors.orange.base,
  colors.green.base
] as const;

export type LifecycleStageType = typeof ALL_LIFECYCLE_TYPES[number];

export function canTypesFollow(firstType: LifecycleStageType, secondType: LifecycleStageType): boolean {
  const firstIdx = ALL_LIFECYCLE_TYPES.indexOf(firstType);
  const secondIdx = ALL_LIFECYCLE_TYPES.indexOf(secondType);

  return firstIdx <= secondIdx;
}

export function stageTypeColor(type: LifecycleStageType): string {
  const idx = ALL_LIFECYCLE_TYPES.indexOf(type);
  if (idx > -1) {
    return LIFECYCLE_STAGE_COLORS[idx];
  } else {
    return "#ffffff";
  }
}

export class LifecycleStage {
  constructor(
    public name = '',
    public type: LifecycleStageType = 'Unprocessed',
    public statuses = [] as LifecycleStatusInfo[],
    color?: string,
  ) {
    this.color = color ?? stageTypeColor(type)
  }

  public color: string;

  public static Duplicate(source: LifecycleStage): LifecycleStage {
    return new LifecycleStage(
      source.name,
      source.type,
      source.statuses.map(x => LifecycleStatusInfo.Duplicate(x)),
      source.color
    )
  }
}
