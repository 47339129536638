
import { AbstractField } from "./AbstractField";
import delay from "@/util/delay";
import InsightUser from "@/models/core/InsightUser";
import RootState from "@/store/RootState";
import TextHighlighter from "../TextHighlighter.vue";

export default AbstractField.extend({
  components: {
    TextHighlighter,
  },

  props: {
    blockInputOnBlur: Boolean,
    notDense: Boolean,
  },

  data() {
    return {
      focused: false,
      inputErrors: [] as string[],
      inputText: '',
      maxMenuLength: 5,
      selectedItem: 0,
    }
  },

  computed: {
    //THIS LIST IS LOADED DURING APPLICATION STARTUP
    userList(): InsightUser[] {
      return (this.$store.state as RootState).users;
    },

    filteredList(): InsightUser[] {
      return this.userList
        .filter(x => x.name.toLowerCase().indexOf(this.inputText.toLowerCase()) > -1)
        .slice(0, this.maxMenuLength);
    },

    showMenu(): boolean {
      return this.inputText > '' && this.filteredList.length > 0;
    }
  },

  watch: {
    filteredList(): void {
      this.selectedItem = 0;
    }
  },

  methods: {
    emitUser(item: InsightUser): void {
      this.$emit('user-entered', item);
      this.inputText = '';
      this.inputErrors = [];
    },

    async handleBlur(): Promise<void> {
      await delay(200);
      this.focused = false;

      if (!this.blockInputOnBlur) {
        this.tryProcessInput();
      }
    },

    handleFocus(): void {
      this.focused = true;
    },

    handleKeyPress(evt: KeyboardEvent): void {
      if (evt.key == 'Enter') {
        this.tryProcessInput();
      } else if (evt.key == 'ArrowDown') {
        this.selectedItem = (this.selectedItem + 1) % this.maxMenuLength;
      } else if (evt.key == 'ArrowUp') {
        this.selectedItem = this.selectedItem == 0 ? this.maxMenuLength-1 : this.selectedItem - 1;
      }
    },

    handleMenuClick(index: number): void {
      this.selectedItem = index;
      this.tryProcessInput();
    },

    tryProcessInput(): void {
      if (this.showMenu) {
        this.emitUser(this.filteredList[this.selectedItem]);
      } else if (this.inputText > '') {
        this.inputErrors = ['Please enter a valid user.'];
      }
    }
  },
})
