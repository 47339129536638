import { AssignmentWorkflowListModel } from "../assignments/AssignmentWorkflow";
import PrivateEntity from "../core/entities/PrivateEntity";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";

export class TicketType extends PrivateEntity {
  constructor(
    public name = '',
    public description = '',
    public lifespanInDays = 0,
    public defaultWorkflow = new AssignmentWorkflowListModel(),
  ) {
    super();
  }
}

export class TicketTypeListModel extends PrivateEntityListModel<TicketType> {
  constructor(
    public name = '',
    public description = '',
    public lifespanInDays = 0,
    public defaultWorkflow = '',
    public defaultWorkflowId = '',
    ) {
    super();
  }

  protected populateCore(model: TicketType): void {
    this.name = model.name;
    this.description = model.description;
    this.lifespanInDays = model.lifespanInDays;
    this.defaultWorkflow = model.defaultWorkflow.name;
    this.defaultWorkflowId = model.defaultWorkflow.id;
  }
}