import * as mt from '../mutationNames'
import { Assignment, AssignmentListModel } from '@/models/assignments/Assignment';
import { AssignmentKanbanStateRequest } from '@/models/assignments/AssignmentKanbanState';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import Vue from 'vue';
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.ASSIGNMENT_ADD_REFERRER] (state, referrer: VuexEntityReferer<Assignment>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.ASSIGNMENT_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.ASSIGNMENT_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.ASSIGNMENT_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<Assignment>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.ASSIGNMENT_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<AssignmentListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName);
  },

  //***********************************************************************
  [mt.ASSIGNMENT_KANBAN_STATE_SET] (state, req: AssignmentKanbanStateRequest): void {
    Vue.set(state.kanbanState, req.client, req.state);
  }
}