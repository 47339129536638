import { AbstractField } from './AbstractField'

export const AbstractTextField = AbstractField.extend({
  props: {
    value: {
      type: String,
      default: '',
    }
  },

  methods: {
    handleInput(payload: string): void {
      this.$emit('input', payload)
    }
  }
});