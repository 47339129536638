
import { Behavior, BehaviorType, describeBehavior, extractReasonOrBlank, ParameterType } from '@/models/assignments/Behaviors';
import { EntityType, WorkType } from '@/models/assignments/AssignmentTemplate';
import { makeStateFilterString, parseStateFilterString, StateFilterTerm } from '@/models/core/stateFilters/StateFilters';
import { ActionInfo } from '@/models/assignments/ActionInfo';
import { CloseReasonParams } from "../../../models/core/actionParams/CloseReasonParams";
import popup from '@/util/popup';
import { PropValidator } from 'vue/types/options';
import { StateAttributeDef } from '@/models/core/stateFilters/StateAttributeDef';
import StateFilterField from '@/components/stateFilters/StateFilterField.vue';
import Vue from 'vue';
import wordify from '../../../util/wordifier';

const THE_ASSIGNMENT = "the assignment";

export default Vue.extend({
  components: {
    StateFilterField
  },

  props: {
    actions: {
      type: Array,
      default: null,
    } as PropValidator<ActionInfo[]>,

    closeReasons: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,

    entityType: {
      type: String,
      default: 'Ticket',
    } as PropValidator<EntityType>,

    filterAttributes: {
      type: Array,
      default: null,
    } as PropValidator<StateAttributeDef[]>,

    stageNames: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,

    value: {
      type: Array,
      default: null,
    } as PropValidator<Behavior[]>,

    workType: {
      type: String,
      default: "Out of App",
    } as PropValidator<WorkType>,
  },

  data() {
    return {
      editingIdx: -1,
      isConditional: false,
      showEditDlg: false,
      source: THE_ASSIGNMENT,
      sourceVerb: '',
      targetFilter: [] as StateFilterTerm[],
      targetReason: '',
      targetVerb: '',
    }
  },

  computed: {
    activeActions(): string[] {
      return this.actions.map(x => x.name);
    },

    behaviorDescriptions(): string[] {
      return (this.value || []).map(x => this.describeBehavior(x));
    },

    behaviorStates(): ("valid"|"invalid")[] {
      return (this.value || [])
        .map(x => 
          (this.workType == "In App" || x.behaviorType.startsWith("Execute")) ? "valid" : "invalid");
    },

    entityTypeFriendly(): string {
      return `the ${wordify.fromCamelCase(this.entityType).toLowerCase()}`
    },

    passiveActions(): string[] {
      return this.actions.map(x => x.passiveName);
    },

    selectedAction(): ActionInfo {
      return this.actions.find(x => x.name == this.targetVerb) ?? new ActionInfo("", "", "None");
    },

    sources(): string[] {
      if (this.workType == "Out of App") {
        return [THE_ASSIGNMENT]
      } else {
         return [
          THE_ASSIGNMENT,
          this.entityTypeFriendly
        ];
      }
    },

    sourceVerbs(): string[] {
      if (this.source == THE_ASSIGNMENT) {
        return ["started", "finished"]
      } else {
        return this.passiveActions;
      }
    },

    target(): string {
      if (this.source == THE_ASSIGNMENT) {
        return this.entityTypeFriendly;
      } else {
        return THE_ASSIGNMENT;
      }
    },

    targetVerbs(): string[] {
      if (this.target == THE_ASSIGNMENT) {
        return ["start", "finish"]
      } else {
        return this.activeActions
      }
    }
  },

  methods: {
    closeEditDlg(mode: 'save'|'cancel'): void {
      if (mode == 'save') {
        const behavior = this.getBehaviorFromVars();

        const behaviors = [...this.value];

        if (this.editingIdx == -1) {
          behaviors.push(behavior);
        } else {
          behaviors.splice(this.editingIdx, 1, behavior);
        }

        this.$emit('input', behaviors);
      }

      this.showEditDlg = false;
    },

    createBehavior(): void {
      this.editingIdx = -1;
      this.isConditional = false;
      this.source = THE_ASSIGNMENT;
      this.sourceVerb = "finished";
      this.targetFilter = [];
      this.targetReason = this.closeReasons[0] ?? '';
      this.targetVerb = this.targetVerbs[0];
      this.showEditDlg = true;
    },

    describeBehavior(b: Behavior): string {
      return describeBehavior(b, this.entityType, this.actions);
    },

    editBehavior(idx: number): void {
      const b = this.value[idx];

      this.editingIdx = idx;

      if (b.behaviorType == "Execute Action on Start") {
        this.source = THE_ASSIGNMENT;
        this.sourceVerb = "started";
        this.isConditional = false;
        this.targetFilter = [];
        this.targetReason = extractReasonOrBlank(b);
        this.targetVerb = b.actionName;
      } else if (b.behaviorType == "Execute Action on Finish") {
        this.source = THE_ASSIGNMENT;
        this.sourceVerb = "finished";
        this.isConditional = false;
        this.targetFilter = [];
        this.targetReason = extractReasonOrBlank(b);
        this.targetVerb = b.actionName;
      } else if (b.behaviorType == "Execute Action on Finish Conditionally") {
        this.source = THE_ASSIGNMENT;
        this.sourceVerb = "finished";
        this.isConditional = true;
        this.targetFilter = parseStateFilterString(b.condition) || [];
        this.targetReason = extractReasonOrBlank(b);
        this.targetVerb = b.actionName;
      } else if (b.behaviorType == "Trigger Start from Action") {
        this.source = this.entityTypeFriendly;
        this.sourceVerb = this.actions.find(x => x.name == b.actionName)?.passiveName ?? "";
        this.isConditional = false;
        this.targetFilter = [];
        this.targetReason = "";
        this.targetVerb = "start";
      } else if (b.behaviorType == "Trigger Finish from Action") {
        this.source = this.entityTypeFriendly;
        this.sourceVerb = this.actions.find(x => x.name == b.actionName)?.passiveName ?? "";
        this.isConditional = false;
        this.targetFilter = [];
        this.targetReason = "";
        this.targetVerb = "finish";
      }

      this.showEditDlg = true;
    },

    getBehaviorFromVars(): Behavior {
      let behaviorType: BehaviorType = "Execute Action on Finish";
      let actionName = "";
      const condition = this.isConditional ? makeStateFilterString(this.targetFilter) : "";
      let parameterType: ParameterType = "None";
      let parameterValue = "";

      if (this.source == THE_ASSIGNMENT) {
        behaviorType = this.sourceVerb == "started" ? "Execute Action on Start" : this.isConditional ? "Execute Action on Finish Conditionally" : "Execute Action on Finish"
        actionName = this.targetVerb;
        parameterType = this.selectedAction.parameterType;
        parameterValue = parameterType == "None" ? "" : JSON.stringify(new CloseReasonParams(this.targetReason));
      } else {
        behaviorType = this.targetVerb == 'start' ? "Trigger Start from Action" : "Trigger Finish from Action"
        actionName = this.actions.find(x => x.passiveName == this.sourceVerb)?.name ?? "";
        parameterType = "None";
        parameterValue = "";
      }

      return new Behavior(behaviorType, actionName, condition, parameterType, parameterValue)
    },

    handleSourceSelected(): void {
      this.sourceVerb = this.sourceVerbs[0];
      this.targetVerb = this.targetVerbs[0];
    },

    async removeBehavior(idx: number): Promise<void> {
      if (await popup.confirm(this.$store, "Confirm Delete", "Are you sure you want to remove this automatic behavior?")) {
        const behaviors = [...this.value];
        behaviors.splice(idx, 1);
        this.$emit('input', behaviors);
      }
    }
  },
})
