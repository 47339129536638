
import { applyEdits } from '@/util/applyEdits'
import { BufferedEditorEdit } from '@/models/core/bufferedEditor/BufferedEditorEdit'
import { ENTITY_MANAGER_REF } from '@/store/refNames'
import notifications from '@/util/notifications'
import PrivateEntity from '@/models/core/entities/PrivateEntity'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'
import VuetifyFormInterface from './forms/VuetifyFormInterface'

export default Vue.extend({
  props: {
    blankEntity: {
      type: Object,
      default: (): PrivateEntity => new PrivateEntity()
    } as PropValidator<PrivateEntity>,
    
    client: {
      type: String,
      default: ''
    },

    refName: {
      type: String,
      default: ENTITY_MANAGER_REF,
    },
    
    routeEntityIdFieldName: {
      type: String,
      default: 'id',
    },

    routeName: {
      type: String,
      default: ''
    },

    saveAction: {
      type: String,
      default: ''
    },

    show: Boolean,

    skipNav: Boolean,

    title: {
      type: String,
      default: ''
    }
  },
  
  data() {
    return {
      entity: new PrivateEntity(),
      formValid: false,
      saveInProgress: false,
    }
  },

  watch: {
    blankEntity: {
      handler(): void {
        this.populateEntityObject()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    handleEdits(edits: BufferedEditorEdit[]): void {
      applyEdits(this.entity as unknown as Record<string, unknown>, edits);
    },

    populateEntityObject(): void {
      this.entity = JSON.parse(JSON.stringify(this.blankEntity))
    },

    resetAndClose(): void {
      this.populateEntityObject();

      (this.$refs.formRef as unknown as VuetifyFormInterface).resetValidation()

      this.$emit('update:show', false)
    },

    save(): void {
      this.saveInProgress = true

      this.entity.client = this.client

      this.$store.dispatch(this.saveAction, {entity: this.entity, refName: this.refName})
        .then((data) => {
          this.saveInProgress = false
          this.resetAndClose()

          if(!this.skipNav) {
            this.$router.push({
              name: this.routeName,
              params: {
                clientCode: this.client,
                [this.routeEntityIdFieldName]: data[this.routeEntityIdFieldName] 
              }
            });
          }
        })
        .catch((e) => {
          this.saveInProgress = false

          notifications.warn(this.$store, `An error occured while saving. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        })
    },
  },
})
