
import { PanZoomInstance, PanZoomTransformModel } from '@/models/core/componentInterfaces/PanZoomInstance'
import api from '@/util/api'
import delay from '@/util/delay'
import Vue from 'vue'
export default Vue.extend({
  props: {
    light: Boolean,

    height: {
      type: Number,
      default: null,
    },

    width: {
      type: Number,
      default: null,
    },

    url: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dataUrl: '',
      errorMessage: '',
      loading: false,
      originalTransform: null as PanZoomTransformModel|null,
      panZoomInstance: null as PanZoomInstance|null,
    }
  },

  computed: {
    centerX(): number {
      return this.width / 2;
    },

    centerY(): number {
      return this.height / 2;
    },

    imageContainerStyle(): Record<string, string> {
      const style: Record<string, string> = {};
      
      if (this.width) {
        style.width = this.width + 'px';
      }

      if(this.height) {
        style.height = this.height + 'px';
      }

      return style;
    },

    imageStyle(): Record<string, string> {
      const style: Record<string, string> = {};
      
      if (this.width) {
        style.maxWidth = this.width + 'px';
      }

      if(this.height) {
        style.maxHeight = this.height + 'px';
      }

      return style;
    },

    wrapperStyle(): Record<string, string> {
      const style: Record<string, string> = {};

      if (this.light) {
        style.backgroundColor = "#f0f0f0";
      } else {
        style.backgroundColor = "#000000";
      }

      return style;
    }
  },

  watch: {
    url: {
      handler(newUrl: string, oldUrl?: string): void {
        if ((oldUrl === undefined || newUrl != oldUrl) && newUrl > '') {
          this.loadImage();
        } else {
          this.dataUrl = '';
          this.errorMessage = '';
        }
      },
      immediate: true,
    }
  },

  methods: {
    async loadImage(): Promise<void> {
      this.loading = true;

      const response = await api.get(this.url, undefined, undefined, 'image');

      this.loading = false;

      if (response) {
        if (!response.error) {
          this.dataUrl = response.data as string;
          this.errorMessage = '';
          return;
        }
      }

      this.errorMessage = 'This image cannot be displayed.'
    },

    async onInit(panZoomInstance: PanZoomInstance): Promise<void> {
      this.panZoomInstance = panZoomInstance

      await delay(50);

      const img = this.$refs.imageElement as HTMLImageElement;

      if (img.height < this.height || img.width < this.width) {
        const transform = panZoomInstance.getTransform();

        const x = this.centerX - (img.width * transform.scale / 2);
        const y = this.centerY - (img.height * transform.scale / 2);

        panZoomInstance.moveTo(x, y);
      }

      this.originalTransform = JSON.parse(JSON.stringify(panZoomInstance.getTransform()));
    },

    resetZoom(): void {
      if (this.panZoomInstance && this.originalTransform) {
        this.panZoomInstance.zoomAbs(this.centerX, this.centerY, this.originalTransform.scale);
        this.panZoomInstance.moveTo(this.originalTransform.x, this.originalTransform.y);
      }
    },

    zoomIn(): void {
      if (this.panZoomInstance) {
        this.panZoomInstance.smoothZoom(this.centerX, this.centerY, 2)
      }
    },

    zoomOut(): void {
      if (this.panZoomInstance) {
        this.panZoomInstance.smoothZoom(this.centerX, this.centerY, 0.5)
      }
    }
  }
})
