
import { AssignmentTemplateListModel } from '@/models/assignments/AssignmentTemplate';
import AssignmentTemplateManager from '../templates/AssignmentTemplateManager.vue'
import Vue from 'vue';

export default Vue.extend({
  components: {
    AssignmentTemplateManager
  },

  props: {
    clientCode: {
      type: String,
      default: '',
      required: true,
    },

    entityType: {
      type: String,
      default: '',
      required: true,
    },

    show: Boolean,
  },

  data() {
    return {
      selectedTemplate: null as AssignmentTemplateListModel|null,
    }
  },

  methods: {
    async close(mode: 'cancel'|'save'): Promise<void> {
      if (mode == 'save') {
        this.$emit('template-selected', this.selectedTemplate);
      }

      this.selectedTemplate = null;
      this.$emit('update:show', false);
    }
  }
})
