export class LifecycleStatusInfo {
  constructor(
    public status = '',
    public filterString = '',
    public isStageCompleteStatus = false,
    public isStageIncompleteStatus = false,
    public color = '',
    public icon = '',
  ) {}

  public static Duplicate(source: LifecycleStatusInfo): LifecycleStatusInfo {
    return new LifecycleStatusInfo(
      source.status,
      source.filterString,
      source.isStageCompleteStatus,
      source.isStageIncompleteStatus,
      source.color,
      source.icon,
    )
  }
}