
import { CommandMenuItem, StateAttributeDef } from '@/models/core/stateFilters/StateAttributeDef';
import { StateFilterCommand, StateFilterTerm } from '@/models/core/stateFilters/StateFilters';
import { PropValidator } from 'vue/types/options';
import Vue from 'vue';

export default Vue.extend({
  props: {
    attributes: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<StateAttributeDef[]>,

    closeReasons: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<string[]>,

    readonly: Boolean,

    stageNames: {
      type: Array,
      default: null,
    } as PropValidator<string[]|null>,

    term: {
      type: Object,
      default: null,
      required: true,
    } as PropValidator<StateFilterTerm>,

    useOnlyStage: {
      type: String,
      default: null,
    },
  },

  computed: {
    attributeNames(): string[] {
      return this.attributes.map(x => x.name);
    },

    availableCommands(): CommandMenuItem[] {
      return this.commandList.filter(x => x.type == this.selectedAttribute?.type);
    },

    availableStages(): string[] {
      if (this.useOnlyStage != null && this.useOnlyStage != undefined) {
        return [this.useOnlyStage];
      } else {
        return this.stageNames ?? [];
      }
    },

    commandList(): CommandMenuItem[] {
      return [
        {
          name: "is blank",
          value: StateFilterCommand.BlameBlank,
          type: "Blame",
          requiresValue: false,
        },
        {
          name: "is not blank",
          value: StateFilterCommand.BlameNotBlank,
          type: "Blame",
          requiresValue: false,
        },
        {
          name: "is",
          value: StateFilterCommand.CloseReason,
          type: "CloseReason",
          requiresValue: true,
        },
        {
          name: "are started but not all finished for stage",
          value: StateFilterCommand.AssignIncompleteForStage,
          type: "Assignment",
          requiresValue: true,
        },
        {
          name: "are all finished for stage",
          value: StateFilterCommand.AssignCompleteForStage,
          type: "Assignment",
          requiresValue: true,
        },
        {
          name: "are not started for any stage",
          value: StateFilterCommand.AssignNoneStarted,
          type: "Assignment",
          requiresValue: false,
        },
        {
          name: "are all finished for all stages",
          value: StateFilterCommand.AssignAllCompleted,
          type: "Assignment",
          requiresValue: false,
        },
      ]
    },

    filterValues(): string[] {
      if (this.selectedAttribute?.type == "CloseReason") {
        return this.closeReasons;
      } else if (this.selectedAttribute?.type == "Assignment") {
        return this.availableStages;
      } else {
        return [];
      }
    },

    selectedCommand(): CommandMenuItem|undefined {
      return this.commandList.find(x => x.value == this.term.command)
    },

    selectedAttribute(): StateAttributeDef|undefined {
      return this.attributes.find(x => x.name == this.term.attributeName);
    },

    showOnlyStage(): boolean {
      return this.usesOnlyStage(this.term.command)
    },

    showValueSelector(): boolean {
      return !!this.selectedCommand?.requiresValue && !this.showOnlyStage
    }
  },

  methods: {
    handleAttributeChange(newAttributeName: string): void {
      const def = this.attributes.find(x => x.name == newAttributeName);
      let newCommand = this.term.command;
      let newValue = this.term.filterValue;

      if (def) {
        newCommand = this.commandList.filter(x => x.type == def.type)[0].value;
        newValue = this.usesOnlyStage(newCommand) ? this.useOnlyStage : null
      }

      this.$emit('update:term', new StateFilterTerm(
        newCommand,
        newAttributeName,
        newValue,
      ))
    },

    handleCommandChange(newCommandName: StateFilterCommand): void {
      this.$emit('update:term', new StateFilterTerm(
        newCommandName,
        this.term.attributeName,
        this.usesOnlyStage(newCommandName) ? this.useOnlyStage : null,
      ))
    },

    handleFilterValueChange(newValue: string): void {
      this.$emit('update:term', new StateFilterTerm(
        this.term.command,
        this.term.attributeName,
        newValue,
      ))
    },

    usesOnlyStage(command: StateFilterCommand): boolean {
      const def = this.commandList.find(x => x.value == command)
      return def?.type == 'Assignment' &&
        def?.requiresValue &&
        this.useOnlyStage > ''
    }
  }
})
