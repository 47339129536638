import { BufferTraversalPath } from "./BufferTraversalPath";
import { EditorConflictValues } from "./EditorConflict";

export class BufferedEditorConflictResolverData {
  constructor(
    public values: EditorConflictValues,
    public path: BufferTraversalPath,
    public x: number,
    public y: number,
    public resolveEntireParent = false,
  ) {}
}