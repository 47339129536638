
import { CORE_BUFFERED_EDITOR_RESET, CORE_BUFFERED_EDITOR_SAVE, CORE_CLOSE_ENTITY_EDITOR, CORE_FETCH_COMMENT_COUNT_FOR_ENTITY, CORE_FETCH_FILE_COUNT_FOR_ENTITY, CORE_FETCH_MESSAGE_COUNT_FOR_ENTITY } from '@/store/actionNames'
import { CORE_CLEAR_ENTITY_EDITOR_STATE, CORE_SET_ENTITY_EDITOR_STATE, CORE_SET_PAGE_TITLE, FILE_DROP_REFERRER, MESSAGE_DROP_REFERRER } from '@/store/mutationNames'
import EntityEditorAction, { EntityEditorActionPropsImpl } from '@/models/core/entityEditor/EntityEditorAction'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import BufferedEditor from '../common/BufferedEditor.vue'
import { BufferedEditorResetRequest } from '@/models/core/bufferedEditor/BufferedEditorResetRequest'
import { BufferFieldOverrideCollection } from '../../models/core/bufferedEditor/BufferFieldOverrides'
import CommentsForEntity from '../comments/CommentsForEntity.vue'
import { DASHBOARD } from '@/router/routeNames'
import { DependentReferrer } from '@/models/core/entityEditor/DependentReferrer'
import { EditorBuffer } from '@/models/core/bufferedEditor/EditorBuffer'
import { ENTITY_EDITOR_LINK_REF_STEM } from '@/store/refNames'
import EntityEditorActionButton from './EntityEditor/EntityEditorActionButton.vue'
import EntityEditorActionMenu from './EntityEditor/EntityEditorActionMenu.vue'
import { EntityEditorCloseRequest } from '@/models/core/entityEditor/EntityEditorCloseRequest'
import EntityEditorDeleteConfirm from './EntityEditor/EntityEditorDeleteConfirm.vue'
import { EntityEditorExtraLinkedComponentPanel } from "@/models/core/entityEditor/EntityEditorExtraLinkedComponentPanel";
import EntityEditorLinkedComponents from './EntityEditor/EntityEditorLinkedComponents.vue'
import EntityEditorQuickLinks from './EntityEditor/EntityEditorQuickLinks.vue'
import { EntityEditorState } from '@/models/core/entityEditor/EntityEditorState'
import EntityHistoryViewer from './EntityHistoryViewer.vue'
import FilesForEntity from '../files/FilesForEntity.vue'
import MessagesForEntity from '../messages/MessagesForEntity.vue'
import notifications from '../../util/notifications'
import PrivateEntity from '@/models/core/entities/PrivateEntity'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import { PropValidator } from 'vue/types/options'
import reformatDate from '../../util/reformatDate'
import RootState from '@/store/RootState'
import { StandardEntityFieldOverrides } from './EntityEditor/StandardEntityFieldOverrides'
import { toTitleCase } from '@/util/capitalize'
import Vue from 'vue'

let entityEditorCounter = 0;

export default Vue.extend({
  components: {
    BufferedEditor,
    CommentsForEntity,
    EntityEditorActionButton,
    EntityEditorActionMenu,
    EntityEditorDeleteConfirm,
    EntityEditorLinkedComponents,
    EntityEditorQuickLinks,
    EntityHistoryViewer,
    FilesForEntity,
    MessagesForEntity,
  },

  filters: {
    capitalize(value: string|null|undefined): string {
      return toTitleCase(value || '');
    }
  },

  props: {
    actions: {
      type: Array,
      default: (): null => null,
    } as PropValidator<EntityEditorAction[]>,

    additionalDependentReferrers: {
      type: Array,
      default: (): DependentReferrer[] => [],
    } as PropValidator<DependentReferrer[]>,

    canDeleteEntity: Boolean,

    canEditEntity: Boolean,

    closeMutationName: {
      type: String,
      default: '',
      required: true,
    },

    deleteActionName: {
      type: String,
      default: '',
      required: true,
    },

    dialogMode: Boolean,

    entity: {
      type: Object,
      default: (): null => null,
    } as PropValidator<PrivateEntity|null>,

    entityControllerName: {
      type: String,
      default: null,
      required: false,
    },

    entityIsLocked: Boolean,

    entityType: {
      type: String,
      default: '',
      required:  true,
    },

    fieldOverrides: {
      type: Object,
      default: (): null => null,
    } as PropValidator<BufferFieldOverrideCollection>,

    hideCloseButton: Boolean,

    hideComments: Boolean,

    hideFiles: Boolean,

    hideMessages: Boolean,

    linkedComponentsAfter: {
      type: Array,
      default: (): null => null,
    } as PropValidator<EntityEditorExtraLinkedComponentPanel[]>,
    
    linkedComponentsBefore: {
      type: Array,
      default: (): null => null,
    } as PropValidator<EntityEditorExtraLinkedComponentPanel[]>,
    
    menuActions: {
      type: Array,
      default: (): null => null,
    } as PropValidator<EntityEditorAction[]>,

    refName: {
      type: String,
      default: null,
    },

    saveActionName: {
      type: String,
      default: '',
      required: true,
    },

    title: {
      type: String,
      default: '',
      required:  true,
    },
  },

  data() {
    return {
      forceDelete: false,
      initialized: false,
      lockForLoader: false,
      saveInProgress: false,
      showActionMenu: false,
      showDeleteConfirm: false,
      showHistory: false,
      showMenuButtonLoader: false,
      stickTheToolbarToTop: false,
    }
  },

  computed: {
    actionBarActions(): EntityEditorAction[] | null {
      return this.actions || null;
    },

    allActionMenuItems(): EntityEditorAction[] {
      const items = new Array<EntityEditorAction>();

      if (this.menuActions) {
        items.push(...this.menuActions);
      }

      items.push({
        icon: 'mdi-history',
        color: '',
        title: 'View History',
        rank: 9999,
        loading: false,
        handler: async () => {this.showHistory = true},
      })

      if (this.canDeleteEntity) {
        items.push({
          icon: 'mdi-delete-outline',
          color: 'warning',
          rank: 10000,
          title: `Delete ${ this.capitalize(this.entityType) }`,
          loading: false,
          handler: async () => {this.showDeleteConfirm = true},
        })
      }
      
      return items;
    },

    allFieldOverrides(): BufferFieldOverrideCollection {
      return {...StandardEntityFieldOverrides, ...(this.fieldOverrides || {})}
    },

    allLinkedComponentsHidden(): boolean {
      return this.linkedComponents.length == 0;
    },

    bannerElevation(): number {
      return this.stickTheToolbarToTop ? 2 : 0;
    },

    bannerIsTile(): boolean {
      return this.stickTheToolbarToTop;
    },

    bannerStyle(): Record<string, unknown> {
      return {
        "position": this.stickTheToolbarToTop ? 'fixed' : 'relative',
        "left": this.stickTheToolbarToTop ? this.sideNavIsMinified ? '102px': '278px' : '0px',
      }
    },

    commentCount(): number {
      return this.entityEditorState?.counters?.commentCount ?? 0;
    },

    controllerName(): string {
      return this.entityControllerName || this.entityType;
    },

    createdText(): string {
      return this.entity?.createdBy + ' at ' + reformatDate(this.entity?.createdAt, 'h:MM TT') +
                ' on ' + reformatDate(this.entity?.createdAt, 'mmmm dd, yyyy')
    },

    editorBuffer(): EditorBuffer|undefined {
      return (this.$store.state as RootState).bufferedEditorState[this.editorBufferId];
    },

    editorBufferId(): string {
      return this.entityEditorState?.editorBufferId ?? 'default';
    },

    entityEditorState(): EntityEditorState|undefined {
      return (this.$store.state as RootState).entityEditorState[this.entity?.id ?? 0];
    },

    entityTypeNoSpaces(): string {
      return this.entityType.replaceAll(" ", "");
    },

    fileCount(): number {
      return this.entityEditorState?.counters?.fileCount ?? 0;
    },

    formValid: {
      get(): boolean {
        return this.entityEditorState?.formValid ?? true;
      },

      set(valid: boolean): void {
        if (this.entityEditorState == undefined) return;
        const state = EntityEditorState.clone(this.entityEditorState);
        state.formValid = valid;

        this.$store.commit(CORE_SET_ENTITY_EDITOR_STATE, state);
      }
    },

    hasUnsavedEdits(): boolean {
      return this.editorBuffer?.hasUnsavedEdits ?? false;
    },

    isReadOnly(): boolean {
      return this.saveInProgress || this.lockForLoader || this.entityIsLocked || !this.canEditEntity;
    },

    linkedComponents(): EntityEditorExtraLinkedComponentPanel[] {
      const panels = [];

      panels.push(...this.linkedComponentsBefore ?? [])

      if (!this.hideComments) {
        panels.push(
          new EntityEditorExtraLinkedComponentPanel('mdi-comment-outline','Comments','comments', this.commentCount)
        );
      }

      if (!this.hideMessages) {
        panels.push(
          new EntityEditorExtraLinkedComponentPanel('mdi-email-outline','Messages','messages', this.messageCount)
        );
      }

      if (!this.hideFiles) {
        panels.push(
          new EntityEditorExtraLinkedComponentPanel('mdi-file-outline','Files','files', this.fileCount)
        );
      }

      panels.push(...this.linkedComponentsAfter ?? [])

      return panels;
    },

    linkedComponentsBeforeAndAfter(): EntityEditorExtraLinkedComponentPanel[] {
      return [...this.linkedComponentsBefore ?? [], ...this.linkedComponentsAfter??[]];
    },

    messageCount(): number {
      return this.entityEditorState?.counters?.messageCount ?? 0;
    },

    modifiedText(): string {
      return this.entity?.modifiedBy + ' at ' + reformatDate(this.entity?.modifiedAt, 'h:MM TT') +
                ' on ' + reformatDate(this.entity?.modifiedAt, 'mmmm dd, yyyy')
    },

    openPanels: {
      get(): number[] {
        return this.entityEditorState?.openPanels ?? [];
      },

      set(value: number[]): void {
        if (this.entityEditorState == undefined) return;
        const state = EntityEditorState.clone(this.entityEditorState);
        state.openPanels = value;

        this.$store.commit(CORE_SET_ENTITY_EDITOR_STATE, state);
      }
    },

    sideNavIsMinified(): boolean {
      return (this.$store.state as RootState).sideNav.minified;
    },

    wrapperHtmlId(): string {
      return this.entityEditorState?.wrapperHtmlId ?? '';
    },
  },

  watch: {
    'entity.id'(): void {
      if ((this.$store.state as RootState).entityEditorState[this.entity?.id ?? "0"] == undefined
        && (this.$store.state as RootState).entityEditorState[this.entity?.tempId ?? "0"] == undefined) {
        this.initializeState();
      }
    }
  },

  created() {
    if ((this.$store.state as RootState).entityEditorState[this.entity?.id ?? 0] == undefined) {
      if (!this.dialogMode) {
        this.$store.commit(CORE_SET_PAGE_TITLE, "Loading...");
      }
      this.initializeState();
    } else {
      this.initialized = true;

      if (!this.dialogMode) {
        this.$store.commit(CORE_SET_PAGE_TITLE, this.title);
      }
    }
  },

  methods: {
    capitalize (value: string|null|undefined): string {
      if (!value) return ''
      value = value.toString()
      return toTitleCase(value);
    },

    async close(force?: boolean): Promise<boolean> {
      const req: EntityEditorCloseRequest = {
        entity: this.entity as PrivateEntitySpec,
        mutationName: this.closeMutationName,
        force
      }

      const clientCode = this.entity?.client ?? "";

      const closed = await this.$store.dispatch(CORE_CLOSE_ENTITY_EDITOR, req);

      if (closed) {
        if (this.dialogMode) {
          this.$emit('editor-closed', true);
        } else {
          if ((this.$store.state as RootState).backWontExitApp) {
            this.$router.go(-1);
          } else {
            this.$router.push({name: DASHBOARD, params: {clientCode}})
          }
        }
      }

      return closed;
    },

    getPropsObject(action: EntityEditorAction, isMenuAction = false): EntityEditorActionPropsImpl {
      return new EntityEditorActionPropsImpl(action, this, isMenuAction);
    },

    async handleDelete(confirmed: boolean): Promise<void> {
      this.showDeleteConfirm = false

      if (confirmed) {
        try {
          const id = this.entity?.id ?? ""
          await this.$store.dispatch(this.deleteActionName, { entity: this.entity, force: this.forceDelete })

          this.$store.commit(CORE_CLEAR_ENTITY_EDITOR_STATE, id)

          if (!this.dialogMode) {
            this.$router.go(-1)
          } else {
            this.$emit('editor-closed', true);
          }
        } catch (error) {
          const e = error as ApiResponse;
          notifications.warn(this.$store, `There was an error deleting this ${this.entityType}. Message ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.forceDelete = false
    },

    async initializeState(): Promise<void> {
      if (this.entity) {
        const children: DependentReferrer[] = [
          new DependentReferrer(MESSAGE_DROP_REFERRER, ENTITY_EDITOR_LINK_REF_STEM + this.entity.id ?? "0"),
          new DependentReferrer(FILE_DROP_REFERRER, ENTITY_EDITOR_LINK_REF_STEM + this.entity.id ?? "0"),
          ...this.additionalDependentReferrers
        ]

        const state = new EntityEditorState(
          this.entity.client,
          this.entity.id ?? "0",
          entityEditorCounter++,
          this.refName,
          children
        );

        this.$store.commit(CORE_SET_ENTITY_EDITOR_STATE, state);

        if (!this.dialogMode) {
          this.$store.commit(CORE_SET_PAGE_TITLE, this.title);
        }

        if (!this.hideComments) this.$store.dispatch(CORE_FETCH_COMMENT_COUNT_FOR_ENTITY, this.entity);
        if (!this.hideFiles) this.$store.dispatch(CORE_FETCH_FILE_COUNT_FOR_ENTITY, this.entity);
        if (!this.hideMessages) this.$store.dispatch(CORE_FETCH_MESSAGE_COUNT_FOR_ENTITY, this.entity);

        this.initialized = true;
      }
    },

    onIntersect(entries: IntersectionObserverEntry[]): void {
      this.stickTheToolbarToTop = !entries[0].isIntersecting;
    },

    resetBuffer(force?: boolean, newData?: unknown): void {
      const req = new BufferedEditorResetRequest(
        this.editorBufferId,
        newData,
        force
      );

      this.$store.dispatch(CORE_BUFFERED_EDITOR_RESET, req);
    },

    async save(): Promise<void> {
      this.saveInProgress = true;
      
      const result = await this.$store.dispatch(CORE_BUFFERED_EDITOR_SAVE, this.editorBufferId);

      if (result != false) {
        this.$emit('entity-saved', result);
      }

      this.saveInProgress = false;
    },
  }
})
