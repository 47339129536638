import { render, staticRenderFns } from "./SpreadsheetViewer.vue?vue&type=template&id=5016decd&"
import script from "./SpreadsheetViewer.vue?vue&type=script&lang=ts&"
export * from "./SpreadsheetViewer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports