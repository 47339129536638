
import { CommentFragment } from '@/models/comments/CommentFragment'
import { Mention } from '@/models/comments/Mention'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  props: {
    mentions: {
      type: Array,
      default: null,
    } as PropValidator<Mention[]>,

    text: {
      type: String,
      default: '',
    },
  },

  computed: {
    fragments(): CommentFragment[] {
      return CommentFragment.splitToFragments(this.text, this.mentions);
    }
  }
})
