import EntityEditorInterface from "./EntityEditorInterface";

export interface EntityEditorActionProps {
  hasUnsavedEdits: boolean;
  setLoading: (loading: boolean) => void;
  resetEditorBuffer: (force?: boolean, newData?: unknown) => void;
  save: () => Promise<void>;
}

export class EntityEditorActionPropsImpl implements EntityEditorActionProps {
  private _action: EntityEditorAction;
  private _editor: EntityEditorInterface;
  private _isMenuAction: boolean;
  
  constructor(action: EntityEditorAction, editor: EntityEditorInterface, isMenuAction = false) {
    this._action = action;
    this._editor = editor;
    this._isMenuAction = isMenuAction;
  }

  public get hasUnsavedEdits(): boolean {
    return this._editor.hasUnsavedEdits;
  }

  public setLoading(loading: boolean): void {
    if (this._isMenuAction) {
      this._editor.showMenuButtonLoader = loading;
    } else {
      this._action.loading = loading;
    }
    this._editor.lockForLoader = loading;
  }

  public resetEditorBuffer(force = true, newData?: unknown): void {
    this._editor.resetBuffer(force, newData);
  }

  public save(): Promise<void> {
    return this._editor.save();
  }
}

export type EntityEditorActionHandler = (props: EntityEditorActionProps) => Promise<void>;

export default interface EntityEditorAction {
  icon: string;
  color: string;
  title: string;
  tooltip?: string;
  rank: number;
  loading: boolean;
  handler: EntityEditorActionHandler;
  readyChecker?: () => boolean;
}