import * as mt from '../store/mutationNames'
import RootState from '@/store/RootState'
import { Store } from 'vuex'



const emit = (store: Store<RootState>, text: string, color: string, icon: string, sticky = false): void => {
  store.commit(mt.CORE_ADD_NOTIFICATION, { text, color, icon, sticky })
}

const inform = (store: Store<RootState>, text: string, sticky = false): void => {
  emit(store, text, 'info', 'mdi-information', sticky)
}

const succeed = (store: Store<RootState>, text: string, sticky = false): void => {
  emit(store, text, 'success', 'mdi-check', sticky)
}

const warn = (store: Store<RootState>, text: string, sticky = false): void => {
  emit(store, text, 'warning', 'mdi-alert', sticky)
}

const fail = (store: Store<RootState>, text: string, sticky = false): void => {
  emit(store, text, 'error', 'mdi-alert-octagon', sticky)
}

export default {
  emit,
  inform,
  succeed,
  warn,
  fail
}
