import Mailbox from "./Mailbox";
import MailboxListModel from "./MailboxListModel";
import { MailboxTreeLeaf } from "./MailboxTreeModels";
import { MailboxType } from "./MailboxType";

function compareNames(a: Mailbox|MailboxTreeLeaf|MailboxListModel, b: Mailbox|MailboxTreeLeaf|MailboxListModel): number {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  } else {
    return 0;
  }
}

export function SortByTypeThenName(a: Mailbox|MailboxTreeLeaf|MailboxListModel, b: Mailbox|MailboxTreeLeaf|MailboxListModel): number {
  if (a.type == MailboxType.Inbox && b.type != MailboxType.Inbox) {
    return -1;
  } else if (a.type != MailboxType.Inbox && b.type == MailboxType.Inbox) {
    return 1;
  } else if (a.type == MailboxType.Sent && b.type != MailboxType.Sent) {
    return -1;
  } else if (a.type != MailboxType.Sent && b.type == MailboxType.Sent) {
    return 1
  } else if (a.type == MailboxType.Draft && b.type != MailboxType.Draft) {
    return -1;
  } else if (a.type != MailboxType.Draft && b.type == MailboxType.Draft) {
    return 1;
  } else if (a.type == MailboxType.Trash && b.type != MailboxType.Trash) {
    return -1;
  } else if (a.type != MailboxType.Trash && b.type == MailboxType.Trash) {
    return 1;
  } else {
    return compareNames(a, b);
  }
}

export function SortByAddressThenTypeThenName(a: Mailbox|MailboxListModel, b: Mailbox|MailboxListModel): number {
  if (a.address.toLowerCase() > b.address.toLowerCase()) {
    return 1;
  } else if (a.address.toLowerCase() < b.address.toLowerCase()) {
    return -1;
  } else {
    return SortByTypeThenName(a, b);
  }
}