
import * as gt from '../../../store/getterNames'
import { AssignmentTemplate, AssignmentTemplateListModel, EntityType } from '@/models/assignments/AssignmentTemplate'
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames';
import { SearchUIFieldType, SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption';
import { ASSIGNMENT_TEMPLATE_FETCH_LIFECYCLE_FOR_TYPE } from '@/store/actionNames';
import AssignmentTemplateEditor from "./AssignmentTemplateEditor.vue"
import AssignmentTemplateIcons from './AssignmentTemplateIcons.vue'
import AssignmentTemplateNew from './AssignmentTemplateNew.vue'
import { AssignmentTemplateSearchFieldDefs } from "./AssignmentTemplateSearchFieldDefs";
import { DataTableHeader } from 'vuetify'
import EntityManager from '../../common/EntityManager.vue'
import { LifecycleHash } from '@/models/lifecycles/LifecycleHash';
import { LifecycleRecord } from '@/models/lifecycles/LifecycleRecord';
import { PropValidator } from 'vue/types/options';
import StageChip from '../../lifecycles/StageChip.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    AssignmentTemplateEditor,
    AssignmentTemplateIcons,
    AssignmentTemplateNew,
    EntityManager,
    StageChip,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    forEntitiyType: {
      type: String,
      default: 'Ticket',
    } as PropValidator<EntityType>,

    useAsChooser: Boolean,
    
    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<AssignmentTemplateListModel|null>,
  },

  data () {
    return {
      searchFieldDefs: AssignmentTemplateSearchFieldDefs,
      showNewTemplateDlg: false,
      tableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Info', value: 'hasBehaviors', sortable: false, width: '80px' },
        { text: 'Stage', value: 'stage', sortable: false, width: '122px' },
      ] as DataTableHeader[],
    }
  },

  computed: {
    canCreateTemplates(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013200") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013210") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    canEditTemplates(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013300") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013310") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    canViewTemplates(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013110") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    defaultQuery(): SearchUIFieldValue[] {
      return [
        new SearchUIFieldValue("entityType", SearchUIFieldType.StringSet, [this.forEntitiyType]),
      ]
    },

    lifecycle(): LifecycleRecord {
      return this.$store.getters[gt.ASSIGNMENT_TEMPLATE_GET_LIFECYCLE_FOR_TYPE](this.forEntitiyType) as LifecycleRecord ?? new LifecycleRecord()
    },

    lifecycleHash(): LifecycleHash {
      return {
        stages: this.lifecycle
          .stageRecords
          .reduce(
            (h, x) => {h[x.stage.name] = x.stage.color; return h;},
            {} as Record<string, string>
          ),
        statuses: this.lifecycle
          .stageRecords
          .flatMap(x => x.stage.statuses)
          .reduce(
            (h, x) => {h[x.status] = {icon: x.icon, color: x.color}; return h},
            {} as Record<string, {icon: string; color: string}>
          ),
      }
    },

    editorRoute(): string {
      return "error"
    },
    
    openTemplates(): AssignmentTemplate[] {
      return this.$store.getters[gt.ASSIGNMENT_TEMPLATES_OPEN_FOR_REF](this.clientCode, this.refName);
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    },

    templates(): AssignmentTemplateListModel[] {
      return this.$store.getters[gt.ASSIGNMENT_TEMPLATES_LISTED_FOR_REF](this.clientCode, this.refName);
    },
  },

  async created(): Promise<void> {
    if (this.lifecycle.stageRecords.length == 0) {
      this.$store.dispatch(ASSIGNMENT_TEMPLATE_FETCH_LIFECYCLE_FOR_TYPE, this.forEntitiyType)
    }
  },
})
