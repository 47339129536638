import { DependentReferrer } from "./DependentReferrer";

export class EntityEditorState {
  private _client: string;
  private _dependentBuffers: string[];
  private _dependentReferrers: DependentReferrer[];
  private _entityEditorNumber: number;
  private _entityId: string;
  private _refName: string;
  
  public additionalData: unknown;
  public formValid = true;
  public openPanels: number[] = [];
  public counters: Record<string, number> = {};

  public get dependentBuffers(): string[] {
    return this._dependentBuffers;
  }

  public get dependentReferrers(): DependentReferrer[] {
    return this._dependentReferrers;
  }

  public get entityId(): string {
    return this._entityId;
  }

  public get refName(): string {
    return this._refName;
  }

  public get wrapperHtmlId(): string {
    return `entityEditor${this._entityEditorNumber}`;
  }

  public get editorBufferId(): string {
    return `entityEditor_${this._entityId}`;
  }

  constructor(
    client: string,
    entityId: string,
    editorNumber: number,
    refName: string,
    dependentReferrers: DependentReferrer[],
    dependentBuffers?: string[]
  ) {
    this._entityId = entityId;
    this._client = client;
    this._entityEditorNumber = editorNumber;
    this._dependentReferrers = dependentReferrers;
    this._refName = refName;
    this._dependentBuffers = dependentBuffers ?? []
  }

  public addDependentBuffer(id: string): void {
    if (this._dependentBuffers.findIndex(x => x == id) == -1) {
      this._dependentBuffers.push(id);
    }
  }

  public addDependentReferrer(newReferrer: DependentReferrer): void {
    if (this._dependentReferrers.findIndex(x => x.refName == newReferrer.refName) == -1) {
      this._dependentReferrers.push(newReferrer);
    }
  }

  public static clone(original: EntityEditorState): EntityEditorState {
    const copy = new EntityEditorState(
      original._client,
      original.entityId,
      original._entityEditorNumber,
      original.refName,
      original.dependentReferrers,
      original._dependentBuffers);

    Object.assign(copy, original);

    return copy;
  }
}