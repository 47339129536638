import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue } from "@/models/core/searchQueries/SearchUIOption";
import { UpdatableEntitySearchFieldDefs } from "@/models/core/searchQueries/UpdatableEntitySearchFieldDefs";

export const TicketTypeSearchFieldDefs = [
  ...UpdatableEntitySearchFieldDefs,

  {
    fieldName: "name",
    displayName: "Name",
    type: SearchUIFieldType.StringContainsOrEquals
  },
] as SearchUIFieldDef[];

export const DefaultTicketTypeQuery = [] as SearchUIFieldValue[]