export default class EntityLinkRequest {
  id = '0';
  type = '';
  description?: string;

  constructor(id: string, type: string, description?: string) {
    this.id = id;
    this.type = type;
    this.description = description;
  }
}