import { ActionInfo } from "./ActionInfo";
import { CloseReasonParams } from "../core/actionParams/CloseReasonParams";
import { describeFilterString } from "../core/stateFilters/StateFilterDescriber";
import { EntityType } from "./AssignmentTemplate";
import wordify from '../../util/wordifier';

export const BEHAVIOR_TYPES = [
  "Execute Action on Start",
  "Execute Action on Finish",
  "Execute Action on Finish Conditionally",
  "Trigger Start from Action",
  "Trigger Finish from Action"
] as const;
export type BehaviorType = typeof BEHAVIOR_TYPES[number];

export const PARAMETER_TYPES = ["None","CloseReason"] as const;
export type ParameterType = typeof PARAMETER_TYPES[number];

export class Behavior {
  constructor(
    public behaviorType: BehaviorType = "Execute Action on Finish",
    public actionName = '',
    public condition = '',
    public parameterType: ParameterType = "None",
    public actionParameters = ''
  ) { }
}

export function describeBehavior(b: Behavior, entityType: EntityType, actions: ActionInfo[]): string {
  let source: string, sourceActioned: string, target: string, targetAction: string;
  let condition = '', reason = '';
  
  if (isTrigger(b.behaviorType)) {
    source = wordify.fromCamelCase(entityType).toLowerCase();
    sourceActioned = passivize(b.actionName, actions).toLowerCase();
    target = "assignment";
    targetAction = behaviorTypeVerb(b.behaviorType);

  } else {
    source = "assignment";
    sourceActioned = behaviorTypeVerb(b.behaviorType);
    target = wordify.fromCamelCase(entityType).toLowerCase();
    targetAction = b.actionName.toLowerCase();

    if (b.parameterType == "CloseReason") {
      reason = ` because it has been ${extractReasonOrBlank(b).toLowerCase()}`;
    }

    if (b.behaviorType == "Execute Action on Finish Conditionally") {
      condition = `, but only if ${describeFilterString(b.condition)}`;
    }
  }

  return `When the ${source} is ${sourceActioned}, ${targetAction} the ${target}${reason}${condition}.`;
}

function isTrigger(type: BehaviorType): boolean {
  return type.startsWith("Trigger");
}

function passivize(actionName: string, actions: ActionInfo[]): string {
  return (actions.find(x => x.name == actionName) ?? {}).passiveName ?? (actionName + "ed")
}

function behaviorTypeVerb(behaviorType: BehaviorType): string {
  if (behaviorType == "Trigger Finish from Action") {
    return "finish";
  } else if (behaviorType == "Trigger Start from Action") {
    return "start"
  } else if (behaviorType == "Execute Action on Finish") {
    return "finished";
  } else if (behaviorType == "Execute Action on Finish Conditionally") {
    return "finished";
  } else if (behaviorType == "Execute Action on Start") {
    return "started";
  } else {
    return "ERROR"
  }
}

export function extractReasonOrBlank(b: Behavior): string {
  if (b.parameterType == "CloseReason") {
    return (JSON.parse(b.actionParameters) as CloseReasonParams).reason;
  } else {
    return "";
  }
}

