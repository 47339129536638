import * as mt from '../mutationNames'
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import Item from '@/models/items/Item';
import ItemListModel from '@/models/items/ItemListModel';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.ITEM_ADD_REFERRER] (state, referrer: VuexEntityReferer<Item>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.ITEM_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.ITEM_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.ITEM_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.ITEM_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.ITEM_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.ITEM_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<Item>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.ITEM_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<ItemListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName);
  },
}