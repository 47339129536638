import BaseListModel from './BaseListModel';
import PrivateEntity from './PrivateEntity';

export default abstract class PrivateEntityListModel<T extends PrivateEntity> extends BaseListModel {
    
    public deletedAt?: string;
    public deletedBy? = '';

    public populate(model: T): void {
        this.id = model.id || '0';
        this.client = model.client;

        this.deletedAt = model.deletedAt;
        this.deletedBy = model.deletedBy;

        this.populateCore(model);
    }

    protected abstract populateCore(model: T): void;
}