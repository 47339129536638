
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import IconButton from "../IconButton.vue";
import { PropValidator } from "vue/types/options";
import ReadOnlyField from "../forms/ReadOnlyField.vue";
import WorkOrderCategoryField from '../../workOrderCategories/WorkOrderCategoryField.vue'
import { WorkOrderCategoryListModel } from "@/models/workOrderCategories/WorkOrderCategoryListModel";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    IconButton,
    ReadOnlyField,
    WorkOrderCategoryField,
  },

  props: {
    locked: Boolean,
    
    value: {
      type: Array,
      default: null,
    } as PropValidator<WorkOrderCategoryListModel[]>,
  },

  data() {
    return {
      catFieldValue: "0",
      showItemPicker: false,
    }
  },

  computed: {
    internalValue: {
      get(): WorkOrderCategoryListModel[] {
        return this.value;
      },

      set(newValue: WorkOrderCategoryListModel[]): void {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    async handleNewCategory(category: WorkOrderCategoryListModel): Promise<void> {
      this.internalValue = [...this.internalValue, category];

      await this.$nextTick();

      this.catFieldValue = "0";
    },

    handleRemoveCategory(category: WorkOrderCategoryListModel): void {
      this.internalValue = this.internalValue.filter(x => x != category)
    }
  }
})
