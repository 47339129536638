import { MailboxType } from './MailboxType';

export default function IconForType(type: MailboxType): string {
  if (type == MailboxType.Inbox) {
    return 'mdi-inbox';
  } else if (type == MailboxType.Sent) {
    return 'mdi-send';
  } else if (type == MailboxType.Draft) {
    return 'mdi-email-edit-outline';
  } else if (type == MailboxType.Trash) {
    return 'mdi-delete';
  } else if (type == MailboxType.Custom) {
    return 'mdi-folder-outline';
  } else {
    return '';
  }
}