
import * as mt from '../../store/mutationNames'
import AppNotification from '../../models/core/AppNotification'
import RootState from '@/store/RootState'
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      showSnackbar: false,
    }
  },
  
  computed: {
    nextNotification(): AppNotification {
      return (this.$store.state as RootState).notifications[0] || {}
    },

    notificationCount(): number {
      return (this.$store.state as RootState).notifications.length;
    },

    timeoutDuration(): number {
      return this.nextNotification.sticky == true ? -1 : 5000;
    }
  },

  watch: {
    notificationCount: {
      handler(): void {
        if (this.notificationCount > 0 && this.showSnackbar == false) {
          this.$nextTick(() => this.showSnackbar = true)
        }
      }
    }
  },

  methods: {
    dismissSnackbar(): void {
      this.showSnackbar = false
      this.$store.commit(mt.CORE_REMOVE_NOTIFICATION, 0)
    }
  }
})
