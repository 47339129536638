import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ActionTree } from "vuex";
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import Item from '@/models/items/Item';
import ItemListModel from '@/models/items/ItemListModel';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.ITEM_DELETE] ({ commit }, data: ApiDeletePayload<Item>): Promise<void> {
    const url = data.entity.client + '/items/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.ITEM_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.ITEM_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<ItemListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/items`
    return ah.getEntityList(
      url,
      client,
      mt.ITEM_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },
  
  //***********************************************************************
  async [ac.ITEM_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<ItemListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/items`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.ITEM_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.ITEM_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<Item | null> {
    const url = request.spec.client + '/items/' + request.spec.id

    return ah.getPrivateEntity<Item>(url, mt.ITEM_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.ITEM_SAVE] ({ commit }, data: {entity: Item; refName: string}): Promise<Item | null> {
    const url = data.entity.client + '/items'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.ITEM_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.ITEM_UNDELETE] ({ commit }, entity: Item): Promise<Item | null> {
    const url = entity.client + '/items'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.ITEM_STORE_ENTITY_FOR_REF, commit);
  },
}