
import Client from '@/models/core/Client';
import { CORE_CLIENT_CURRENT } from '@/store/getterNames';
import FileManager from './FileManager.vue'
import Vue from 'vue';
import WerkFileListModel from '@/models/files/WerkFileListModel';

export default Vue.extend({
  components: {
    FileManager
  },

  props: {
    show: Boolean,
  },

  data() {
    return {
      selectedFile: null as WerkFileListModel|null,
    }
  },

  computed: {
    clientCode(): string {
      const client = this.$store.getters[CORE_CLIENT_CURRENT] as Client;
      return client.code;
    },
  },

  methods: {
    async close(mode: 'cancel'|'save'): Promise<void> {
      if (mode == 'save') {
        this.$emit('file-selected', this.selectedFile);
      }

      this.selectedFile = null;
      this.$emit('update:show', false);
    }
  }
})
