import * as gt from '../getterNames'
import { ENTITY_MANAGER_REF, MAILBOX_GLOBAL_REF } from '../refNames'
import Client from '@/models/core/Client'
import { GetterTree } from 'vuex'
import Mailbox from '@/models/mailboxes/Mailbox'
import MailboxListModel from '@/models/mailboxes/MailboxListModel'
import { MailboxTreeBuilder } from '@/models/mailboxes/MailboxTreeBuilder'
import { MailboxTreeNode } from '@/models/mailboxes/MailboxTreeModels'
import { MailboxType } from '@/models/mailboxes/MailboxType'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.MAILBOX_TREE_FOR_CURRENT_USER](state, getters): MailboxTreeNode[] {
    const mailboxes: MailboxListModel[] =  getters[gt.MAILBOXES_FOR_CURRENT_USER];

    return MailboxTreeBuilder(mailboxes);
  },
    
  [gt.MAILBOX_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },

  [gt.MAILBOX_SENDING_ADDRESSES] (state, getters): string[] {
    const mailboxes: MailboxListModel[] =  getters[gt.MAILBOXES_FOR_CURRENT_USER];
    return mailboxes.filter(x => x.type == MailboxType.Draft).map(x => x.address);
  },

  [gt.MAILBOXES_FOR_CURRENT_USER](state, getters): MailboxListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, MAILBOX_GLOBAL_REF);
  },

  [gt.MAILBOXES_OPEN_FOR_CURRENT_USER] (state, getters): Mailbox[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.MAILBOXES_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): MailboxListModel[] => {
      return state.getListModelsForRef(client, refName);
  },

  [gt.MAILBOX_TOTAL_UNREAD_COUNT] (state, getters): number {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    const mailboxes = state.getListModelsForRef(clientCode, MAILBOX_GLOBAL_REF);

    return mailboxes.reduce((v, m) => v + (m.unreadCount ?? 0), 0);
  } 
}