
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import IconButton from "../IconButton.vue";
import { PropValidator } from "vue/types/options";
import ReadOnlyField from "../forms/ReadOnlyField.vue";
import ScrollBox from "../ScrollBox.vue";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    ReadOnlyField,
    IconButton,
    ScrollBox
  },

  props: {
    locked: Boolean,
    
    options: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,

    value: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,
  },

  data() {
    return {
      showItemPicker: false,
    }
  },

  computed: {
    internalValue: {
      get(): string[] {
        return this.value;
      },

      set(newValue: string[]): void {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    handleRemoveItem(item: string): void {
      this.internalValue = this.internalValue.filter(x => x != item)
    }
  }
})
