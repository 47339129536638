
import { handleEvent } from "@/util/FunctionalComponentEventDispatcher";

export default {
  props: {
    entityType: {
      type: String,
      default: '',
    },

    forceDelete: Boolean,

    show: Boolean,
  },

  methods: {
    handleEvent,
  }
}
