import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF } from '../refNames';
import { MessageContactType, MessageState } from '@/models/messages/MessageEnums';
import { ActionTree } from "vuex";
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import EntityActionResult from '@/models/core/api/EntityActionResult';
import EntityLinkParameters from '@/models/core/entities/EntityLinkParameters';
import MailboxListModel from '@/models/mailboxes/MailboxListModel';
import { MailboxType } from '@/models/mailboxes/MailboxType';
import MarkAsReadParams from '@/models/messages/MarkAsReadParams';
import Message from '@/models/messages/Message';
import MessageListModel from '@/models/messages/MessageListModel';
import MoveToFolderParams from '@/models/messages/MoveToFolderParams';
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import RootState from '../RootState';
import State from './state';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.MESSAGE_CHANGE_MAILBOX] ({ commit, state }, data: MoveToFolderParams): Promise<Message | null> {
    const actionName = data.destination == undefined ? 'removeFromMailbox' : (data.source == undefined ? 'addToMailbox' : 'moveToMailbox')
    const url = `${data.msg?.client}/messages/${data.msg?.id}/actions/${actionName}`

    const response = await api.create(url, {source: data.source, destination: data.destination})

    if (!response) return Promise.reject(null);

    if (response.error) {
      return Promise.reject(response)
    }

    const actionResult = response.data as EntityActionResult<Message>

    commit(mt.MESSAGE_STORE_ENTITY_FOR_REF, {entity: actionResult.entity, refName: '', mode: 'updateOnly'})

    if (state.mailboxId == data.source) {
      commit(mt.MESSAGE_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF, {spec: actionResult.entity, refName: ENTITY_MANAGER_REF});
    }

    return actionResult.entity
  },

  //***********************************************************************
  async [ac.MESSAGE_DELETE] ({ commit }, data: ApiDeletePayload<Message>): Promise<void> {
    const url = data.entity.client + '/messages/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.MESSAGE_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.MESSAGE_FETCH_FOR_ENTITY] ({ commit }, entity: PrivateEntitySpec): Promise<MessageListModel[] | null> {
    const url = `${entity.client}/messages/linkedTo/${entity.id}`
    const apiResponse = await api.get(url);

    if (apiResponse) {
      if (!apiResponse.error) {
        const messages = apiResponse.data as MessageListModel[];

        const mutationData: EntitiesForRef<MessageListModel> = {
          entities: messages,
          refName: ENTITY_EDITOR_LINK_REF_STEM + entity.id,
        }

        commit(mt.MESSAGE_STORE_LIST_MODELS_FOR_REF, mutationData);

        return messages;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.MESSAGE_FETCH_FOR_MAILBOX] (
    { commit, state, getters },
    {requestedClient, requestedMailboxId, requestedRefName, pageNumber, pageSize}
  ): Promise<MessageListModel[]> {

    const client = requestedClient || getters[gt.CORE_CLIENT_CURRENT] || {}
    const mailboxId = requestedMailboxId || state.mailboxId;
    const refName = requestedRefName || ENTITY_MANAGER_REF;

    if (mailboxId != state.mailboxId && !requestedRefName) {
      const mailboxAddress = (getters[gt.MAILBOXES_FOR_CURRENT_USER] as MailboxListModel[]).find(x => x.id == mailboxId)?.address
      commit(mt.MESSAGE_MAILBOX_ID_SET, {id: mailboxId, address: mailboxAddress});
    }


    const url = `${client.code}/messages/forMailbox/${mailboxId}`
    return ah.getEntityList(
      url,
      client.code,
      mt.MESSAGE_STORE_LIST_MODELS_FOR_REF,
      refName,
      commit,
      false,
      refName,
      pageSize,
      pageNumber,
      mt.CORE_GENERIC_PAGER_PAGE_DATA_SET
    )
  },

  //***********************************************************************
  async [ac.MESSAGE_LINK_TO_ENTITY] ({ commit }, data: {link: EntityLinkParameters; refName: string}): Promise<Message | null> {
    const url = `${data.link.linkOwner.client}/messages/${data.link.linkOwner.id}/actions/link/${data.link.targetType}/${data.link.target.id}`

    const apiResponse = await api.create(url, null);

    if (apiResponse) {
      if (!apiResponse.error) {
        const actionResult = apiResponse.data as EntityActionResult<Message>
        const message = actionResult.entity;

        let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

        if (data.refName == ENTITY_MANAGER_REF) {
          mode = VuexEntityStorageRequestMode.Add;

        } else if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
          const targetEntityId = data.refName.substr(ENTITY_EDITOR_LINK_REF_STEM.length);

          if (message.links && message.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
            mode = VuexEntityStorageRequestMode.Add;
          }
        }

        commit(mt.MESSAGE_STORE_ENTITY_FOR_REF, {entity: message, refName: data.refName, mode: mode})

        return message;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.MESSAGE_LOAD] ({ commit }, spec: PrivateEntitySpec): Promise<Message | null> {
    const url = spec.client + '/messages/' + spec.id

    return ah.getPrivateEntity<Message>(url, mt.MESSAGE_STORE_ENTITY_FOR_REF, ENTITY_MANAGER_REF, commit)
  },

  //***********************************************************************
  async [ac.MESSAGE_MARK_AS_READ] ({ commit }, data: MarkAsReadParams): Promise<Message | null> {
    const url = `${data.client}/messages/${data.id}/actions/markAsRead`

    const response = await api.create(url, {email: data.email});

    if (!response) return Promise.reject(null);

    if (response.error) {
      return Promise.reject(response)
    }

    const actionResult = response.data as EntityActionResult<Message>

    commit(mt.MESSAGE_STORE_ENTITY_FOR_REF, {entity: actionResult.entity, refName: '', mode: 'updateOnly'})

    return actionResult.entity
  },

  //***********************************************************************
  async [ac.MESSAGE_SAVE] ({ commit, getters, state }, data: {entity: Message; refName: string}): Promise<Message | null> {
    const url = data.entity.client + '/messages'

    let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

    if (data.refName == ENTITY_MANAGER_REF) {
      const mailbox = (getters[gt.MAILBOXES_FOR_CURRENT_USER] as MailboxListModel[])
        .find(x => x.id == state.mailboxId)

      const fromAddress = data.entity.contacts.find(x => x.type == MessageContactType.From);

      if (mailbox?.type == MailboxType.Draft && fromAddress?.address == mailbox.address) {
        mode = VuexEntityStorageRequestMode.Add;
      }
    } else if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
      const targetEntityId = data.refName.substr(ENTITY_EDITOR_LINK_REF_STEM.length);

      if (data.entity.links && data.entity.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.Add;
      } else if (data.entity.requestedLinks && data.entity.requestedLinks.findIndex(x => x.id == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.Add;
      }
    }

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.MESSAGE_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.MESSAGE_SEND] ({ commit, getters, state }, data: {entity: Message; refName: string}): Promise<Message | null> {
    const url = data.entity.client + '/messages'

    data.entity.state = MessageState.Sending;

    let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

    if (data.refName == ENTITY_MANAGER_REF) {
      const mailbox = (getters[gt.MAILBOXES_FOR_CURRENT_USER] as MailboxListModel[])
        .find(x => x.id == state.mailboxId)

      const fromAddress = data.entity.contacts.find(x => x.type == MessageContactType.From);

      if (mailbox?.type == MailboxType.Sent && fromAddress?.address == mailbox.address) {
        mode = VuexEntityStorageRequestMode.Add;
      }
    } else if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
      const targetEntityId = data.refName.substr(ENTITY_EDITOR_LINK_REF_STEM.length);

      if (data.entity.links && data.entity.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.Add;
      } else if (data.entity.requestedLinks && data.entity.requestedLinks.findIndex(x => x.id == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.Add;
      }
    }

    const message = await ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.MESSAGE_STORE_ENTITY_FOR_REF, data.refName, commit, mode)

    return message;
  },

  //***********************************************************************
  async [ac.MESSAGE_UNLINK_FROM_ENTITY] (
    { commit },
    data: EntityLinkParameters
  ): Promise<Message | null> {
    const url = `${data.linkOwner.client}/messages/${data.linkOwner.id}/actions/unlink/${data.targetType}/${data.target.id}`

    const apiResponse = await api.create(url, null);

    if (apiResponse) {
      if (!apiResponse.error) {
        const actionResult = apiResponse.data as EntityActionResult<Message>
        const message = actionResult.entity;

        commit(mt.MESSAGE_STORE_ENTITY_FOR_REF, {entity: message, refName: '', mode: 'updateOnly'})

        return message;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  }
}