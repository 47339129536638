import * as gt from '../getterNames'
import Client from '@/models/core/Client'
import { ContactCollection } from '@/models/contacts/ContactCollection'
import { ContactCollectionListModel } from '@/models/contacts/ContactCollectionListModel'
import { ENTITY_MANAGER_REF } from '../refNames'
import { GetterTree } from 'vuex'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.CONTACT_COLLECTIONS_FOR_CURRENT_CLIENT](state, getters): ContactCollectionListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.CONTACT_COLLECTIONS_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): ContactCollectionListModel[] => {
      return state.getListModelsForRef(client, refName);
  },
    
  [gt.CONTACT_COLLECTIONS_OPEN_FOR_CURRENT_CLIENT] (state, getters): ContactCollection[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.CONTACT_COLLECTIONS_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): ContactCollection[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.CONTACT_COLLECTION_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },
}