import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ActionTree } from "vuex";
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import Mailbox from '@/models/mailboxes/Mailbox';
import MailboxListModel from '@/models/mailboxes/MailboxListModel';
import { MailboxType } from '@/models/mailboxes/MailboxType';
import { MailboxUnreadCountUpdate } from '@/models/core/realtime/MailboxUnreadCountUpdate';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.MAILBOX_DELETE] ({ commit }, data: ApiDeletePayload<Mailbox>): Promise<void> {
    const url = data.entity.client + '/mailboxes/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.MAILBOX_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.MAILBOX_GET_UNREAD] ({commit}, mailbox: MailboxListModel): Promise<number> {
    const url = `${mailbox.client}/mailboxes/${mailbox.id}/unreadCount`
    const response = await api.get(url)

    if (!response) return Promise.reject();

    if (response.error) {
      return Promise.reject(response)
    }

    const count = response.data as number

    commit(mt.MAILBOX_UNREAD_UPDATE, { mailboxId: mailbox.id, count })

    return count
  },

  //***********************************************************************
  async [ac.MAILBOX_FETCH] ({ commit, getters, dispatch, rootState }, req: VuexFetchRequest): Promise<MailboxListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const userId = rootState.userId;
    const url = `${client}/mailboxes/user/${userId}`

    const mailboxes = await ah.getEntityList(
      url,
      client,
      mt.MAILBOX_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false) as MailboxListModel[];

    mailboxes.forEach(box => {
      if (box.type == MailboxType.Inbox) {
        dispatch(ac.MAILBOX_GET_UNREAD, box)
      }
    });

    return mailboxes;
  },

  //***********************************************************************
  async [ac.MAILBOX_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<Mailbox | null> {
    const url = request.spec.client + '/mailboxes/' + request.spec.id

    return ah.getPrivateEntity<Mailbox>(url, mt.MAILBOX_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.MAILBOX_SAVE] ({ commit }, data: {entity: Mailbox; refName: string}): Promise<Mailbox | null> {
    const url = data.entity.client + '/mailboxes'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.MAILBOX_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.MAILBOX_UNDELETE] ({ commit }, entity: Mailbox): Promise<Mailbox | null> {
    const url = entity.client + '/mailboxes'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.MAILBOX_STORE_ENTITY_FOR_REF, commit);
  },

  //***********************************************************************
  [ac.MAILBOX_HANDLE_UNREAD_UPDATE_MSG] ({ commit }, data: MailboxUnreadCountUpdate): void {
    data.UpdatedMailboxes.forEach(m => {
      commit(mt.MAILBOX_UNREAD_UPDATE, {mailboxId: m.Id, count: m.Count});
    });
  },
}