import ItemCategory from './ItemCategory';
import PrivateEntityListModel from '../core/entities/PrivateEntityListModel';

export default class ItemCategoryListModel extends PrivateEntityListModel<ItemCategory> {
    public name = '';
    public parent = "0";

    protected populateCore(model: ItemCategory): void {
        this.name = model.name;
        this.parent = model.parent;
    }
}