
import AssignmentWorkflowChooser from '../../assignments/workflows/AssignmentWorkflowChooser.vue'
import DescriptionField from '../../common/forms/DescriptionField.vue'
import { doSomethingOnEnter } from '@/util/keystrokes'
import { FieldSetConflictMixin } from '../../common/FieldSetConflictMixin'
import NameField from '../../common/forms/NameField.vue'
import NumberField from '@/components/common/forms/NumberField.vue'
import { PropValidator } from 'vue/types/options'
import ReadOnlyField from '../../common/forms/ReadOnlyField.vue'
import { TicketType } from '@/models/tickets/TicketType'

export default FieldSetConflictMixin.extend({
  components: {
    AssignmentWorkflowChooser,
    DescriptionField,
    NameField,
    NumberField,
    ReadOnlyField,
  },

  props: {
    entity: {
      type: Object,
      default: (): TicketType => new TicketType()
    } as PropValidator<TicketType>,

    readonly: Boolean,
  },

  data() {
    return {
      showWorkflowChooser: false,
    }
  },

  computed: {
    workflowName(): string {
      return this.entity?.defaultWorkflow?.name ?? '';
    },
  },

  methods: {
    handleWorkflowKeypress(evt: KeyboardEvent): void {
      doSomethingOnEnter(evt, () => this.showWorkflowChooser = true)
    }
  }
})
