import { SearchUIFieldValue } from "../searchQueries/SearchUIOption";
import VuexFetchRequest from "./VuexFetchRequest";

export class VuexSearchRequest implements VuexFetchRequest {
  constructor(
    public refName: string,
    public searchValues: SearchUIFieldValue[],
    public includeDeleted?: boolean,
    public client?: string,
    public pagerId?: string,
    public pageSize?: number,
    public pageNumber?: number,
    public pageDataMutationName?: string,
    public orderBy?: string,
    public orderDescending?: boolean,
  ) {}
}