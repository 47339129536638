export enum SearchUIFieldType {
  ContactSet,
  DateTime,
  DateTimeOffset,
  ItemCategorySet,
  LifecycleCloseReasonSet,
  LifecycleStatusSet,
  NumberRange,
  StringContainsOrEquals,
  StringSet,
  TicketTypeSet,
  UserSet,
  UserIdSet,
  WorkOrderCategorySet,
  PercentRange,
}


export class SearchUIFieldDef {
  constructor(
    public fieldName: string,
    public displayName: string,
    public type: SearchUIFieldType,
    public notNoun = false,
    public options?: unknown
  ) {}
}

export class SearchUIRangeValue<T> {
  constructor(
    public operator: 'lte'|'gte'|'btwi',
    public value1: T,
    public value2?: T,
    public doNotExcludeBlanks = false
  ) {}
}

export class SearchUIFieldValue {
  constructor(
    public fieldName: string,
    public type: SearchUIFieldType,
    public value: unknown,
  ) {}
}

export class SearchUINumberRangeOptions {
  constructor(
    public decimalDigits: number,
  ){}
}