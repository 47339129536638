
import * as gt from '../../../store/getterNames'
import { AssignmentWorkflow, AssignmentWorkflowListModel } from '@/models/assignments/AssignmentWorkflow'
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames';
import { SearchUIFieldType, SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption';
import AssignmentWorkflowEditor from "./AssignmentWorkflowEditor.vue"
import AssignmentWorkflowNew from './AssignmentWorkflowNew.vue'
import { AssignmentWorkflowSearchFieldDefs } from "./AssignmentWorkflowSearchFieldDefs";
import { DataTableHeader } from 'vuetify'
import EntityManager from '../../common/EntityManager.vue'
import { EntityType } from '@/models/assignments/AssignmentTemplate';
import { PropValidator } from 'vue/types/options';
import Vue from 'vue'

export default Vue.extend({
  components: {
    AssignmentWorkflowEditor,
    AssignmentWorkflowNew,
    EntityManager,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    forEntitiyType: {
      type: String,
      default: 'Ticket',
    } as PropValidator<EntityType>,

    useAsChooser: Boolean,
    
    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<AssignmentWorkflowListModel|null>,
  },

  data () {
    return {
      searchFieldDefs: AssignmentWorkflowSearchFieldDefs,
      showNewWorkflowDlg: false,
      tableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Steps', value: 'stepCount', align: 'end', sortable: false, },
      ] as DataTableHeader[],
    }
  },

  computed: {
    canCreateWorkflows(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013200") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013210") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    canEditWorkflows(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013300") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013310") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    canViewWorkflows(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013110") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    defaultQuery(): SearchUIFieldValue[] {
      return [
        new SearchUIFieldValue("entityType", SearchUIFieldType.StringSet, [this.forEntitiyType]),
      ]
    },

    editorRoute(): string {
      return "error"
    },

    openWorkflows(): AssignmentWorkflow[] {
      return this.$store.getters[gt.ASSIGNMENT_WORKFLOWS_OPEN_FOR_REF](this.clientCode, this.refName);
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    },
    
    workflows(): AssignmentWorkflowListModel[] {
      return this.$store.getters[gt.ASSIGNMENT_WORKFLOWS_LISTED_FOR_REF](this.clientCode, this.refName);
    },
  },
})
