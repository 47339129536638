
import { CORE_CLIENT_CURRENT, WORK_ORDER_CATEGORIES_LISTED_FOR_REF, WORK_ORDER_CATEGORY_REFERRER_EXISTS } from '@/store/getterNames'
import Client from '@/models/core/Client'
import { requiredIf } from '../../util/validators'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest'
import { WORK_ORDER_CATEGORY_ADD_REFERRER } from '@/store/mutationNames'
import { WORK_ORDER_CATEGORY_FETCH } from '@/store/actionNames'
import { WORK_ORDER_CATEGORY_FIELD_REF } from '@/store/refNames'
import { WorkOrderCategoryListModel } from '@/models/workOrderCategories/WorkOrderCategoryListModel'

export default Vue.extend({

  props: {
    conflictingValue: {
      type: String,
      default: "-1",
    },

    excludeValue: {
      type: String,
      default: "-1",
    },
    
    label: {
      type: String,
      default: '',
    },

    required: Boolean,

    value: {
      type: String,
      default: "-1",
    },
  },

  data () {
    return {
      rules: [
        requiredIf(() => this.required, () => this.label)
      ]
    }
  },

  computed: {
    categories(): WorkOrderCategoryListModel[] {
      const getter = this.$store.getters[WORK_ORDER_CATEGORIES_LISTED_FOR_REF];
      const cats = getter(this.currentClient.code, WORK_ORDER_CATEGORY_FIELD_REF)
        .filter((c: WorkOrderCategoryListModel) => c.id != this.excludeValue) as WorkOrderCategoryListModel[]

      cats.sort((a: WorkOrderCategoryListModel, b: WorkOrderCategoryListModel) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name == b.name) {
          return 0;
        } else {
          return -1
        }
      })

      return [
        { id: "0", name: '(No Category Selected)' } as WorkOrderCategoryListModel,
        ...cats
      ]
    },

    currentClient(): Client {
      return this.$store.getters[CORE_CLIENT_CURRENT] as Client;
    },

    referrerExists(): boolean {
      return this.$store.getters[WORK_ORDER_CATEGORY_REFERRER_EXISTS](WORK_ORDER_CATEGORY_FIELD_REF);
    },
  },

  created() {
    if (!this.referrerExists) {
      this.configureEntityReferrer();
      const req: VuexFetchRequest = {
        refName: WORK_ORDER_CATEGORY_FIELD_REF
      }
      this.$store.dispatch(WORK_ORDER_CATEGORY_FETCH, req);
    }
  },

  methods: {
    configureEntityReferrer(): void {
      const referrer = new VuexEntityReferer(
        this.currentClient.code,
        WORK_ORDER_CATEGORY_FIELD_REF,
        (entity, mode) =>  {
          if (mode == 'entity') {
            return 'noChange';

          } else {
            return 'yes'
          }
        }
      );

      this.$store.commit(WORK_ORDER_CATEGORY_ADD_REFERRER, referrer)
    },

    getCategoryNameForId(id: string): string {
      return this.categories.find(x => x.id == id)?.name ?? '(Unknown category)'
    },

    getFieldErrors(): string|never[] {
      if (this.conflictingValue != "-1") {
        return `Conflicting value: ${this.getCategoryNameForId(this.conflictingValue)}`
      } else {
        return [];
      }
    },
    
    handleInput(payload: string): void {
      this.$emit('input', payload)

      const catObj = this.categories.find(x => x.id == payload)
      if (catObj) {
        this.$emit('category-selected', catObj);
      }
    }
  }
})
