import * as gt from '../getterNames'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF } from '../refNames'
import { Ticket, TicketListModel } from '@/models/tickets/Ticket'
import Client from '@/models/core/Client'
import { GetterTree } from 'vuex'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.TICKETS_FOR_CURRENT_CLIENT](state, getters): TicketListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.TICKETS_LIST_FOR_REF]: (state) => 
    (client: string, refName: string): TicketListModel[] => {
      return state.getListModelsForRef(client, refName);
  },

  [gt.TICKETS_LINKED_TO_ENTITY]: (state) => 
    (entity: PrivateEntitySpec): TicketListModel[] => {
      return state.getListModelsForRef(entity.client, ENTITY_EDITOR_LINK_REF_STEM + entity.id);
  },
    
  [gt.TICKETS_OPEN_FOR_CURRENT_CLIENT] (state, getters): Ticket[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.TICKETS_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): Ticket[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.TICKET_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },
}