
import { ActionInfo, getActionListForEntityType } from '@/models/assignments/ActionInfo'
import { ASSIGNMENT_WORKFLOW_DELETE, ASSIGNMENT_WORKFLOW_LOAD, ASSIGNMENT_WORKFLOW_SAVE, CORE_CLOSE_ENTITY_EDITOR, } from '@/store/actionNames'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import { ASSIGNMENT_WORKFLOW_REMOVE_ENTITY_FROM_REF } from '@/store/mutationNames'
import { ASSIGNMENT_WORKFLOWS_OPEN_FOR_CURRENT_CLIENT } from '@/store/getterNames'
import { AssignmentWorkflow } from '@/models/assignments/AssignmentWorkflow'
import { AssignmentWorkflowEditorFieldOverrides } from './AssignmentWorkflowEditorFieldOverrides'
import AssignmentWorkflowEditorFieldSet from './AssignmentWorkflowEditorFieldSet.vue'
import { BufferFieldOverrideCollection } from '@/models/core/bufferedEditor/BufferFieldOverrides'
import { DependentReferrer } from '@/models/core/entityEditor/DependentReferrer'
import { ENTITY_MANAGER_REF } from '@/store/refNames'
import EntityEditor from '../../common/EntityEditor.vue'
import EntityEditorAction from '@/models/core/entityEditor/EntityEditorAction'
import { EntityEditorCloseRequest } from '@/models/core/entityEditor/EntityEditorCloseRequest'
import EntityEditorInterface from '@/models/core/entityEditor/EntityEditorInterface'
import notifications from '@/util/notifications'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import Vue from 'vue'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec'

export default Vue.extend({
  components: {
    AssignmentWorkflowEditorFieldSet,
    EntityEditor,
  },

  inheritAttrs: false,

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      closeMutationName: ASSIGNMENT_WORKFLOW_REMOVE_ENTITY_FROM_REF,
      deleteActionName: ASSIGNMENT_WORKFLOW_DELETE,
      saveActionName: ASSIGNMENT_WORKFLOW_SAVE,
    }
  },

  computed: {
    actions(): EntityEditorAction[] {
      return [];
    },

    additionalDependentReferrers(): DependentReferrer[] {
      return []
    },

    behaviorActionList(): ActionInfo[] {
      return getActionListForEntityType(this.entity?.entityType ?? "Ticket");
    },
    
    canDelete(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013400") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013410") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    canEdit(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013300") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1013310") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    entity(): AssignmentWorkflow|null {
      return (this.$store.getters[ASSIGNMENT_WORKFLOWS_OPEN_FOR_CURRENT_CLIENT] as AssignmentWorkflow[])
        .find(x => x.id == this.id) ?? null;
    },

    menuActions(): EntityEditorAction[] {
      return [];
    },

    overrides(): BufferFieldOverrideCollection {
      return AssignmentWorkflowEditorFieldOverrides;
    },

    refName(): string {
      return ENTITY_MANAGER_REF;
    },

    title(): string {
      return `Assignment Workflow: ${this.entity?.name}`;
    },
  },

  watch: {
    id: {
      handler(): void {
        this.loadEntity();
      },
    }
  },

  async created(): Promise<void> {
    if (this.entity == null && this.id > '') {
      await this.loadEntity();
    }
  },

  methods: {
    async clearEntity(): Promise<boolean> {
      let doContinue = true;

      if (this.entity != null) {

        const closeReq: EntityEditorCloseRequest = {
          entity: this.entity as PrivateEntitySpec,
          mutationName: ASSIGNMENT_WORKFLOW_REMOVE_ENTITY_FROM_REF
        }

        doContinue = await this.$store.dispatch(CORE_CLOSE_ENTITY_EDITOR, closeReq);
      }

      return doContinue;
    },

    close(): Promise<boolean> {
      return this.getEditorInstance().close();
    },

    emitEditorClosed(): void {
      this.$emit('editor-closed', true);
    },
    
    getEditorInstance(): EntityEditorInterface {
      return this.$refs['editor'] as unknown as EntityEditorInterface;
    },

    async loadEntity(): Promise<void> {
      let doLoad = true;

      if (this.entity != null) {
        doLoad = await this.clearEntity();
      }

      if (doLoad && this.id > '') {
        try {
          const request: VuexEntityRefSpec = {
            spec: {
              id: this.id,
              client: this.clientCode
            },
            refName: this.refName
          }

          await this.$store.dispatch(ASSIGNMENT_WORKFLOW_LOAD, request);
        } catch(error) {
          const e = error as ApiResponse;
          notifications.fail(this.$store, `There was an error while loading this assignment workflow! Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }
    }
  },
})
