
import api from '@/util/api'
import ScrollBox from './ScrollBox.vue';
import Vue from 'vue'

export default Vue.extend({
  components: { 
    ScrollBox,
  },

  props: {
    light: Boolean,

    maxHeight: {
      type: String,
      default: null,
    },

    url: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      contents: '',
      errorMessage: '',
      loading: false,
    }
  },

  computed: {
    wrapperStyle(): string {
      if (!this.loading || this.light) {
        return "#fff";
      } else {
        return "#000";
      }
    }
  },

  watch: {
    url: {
      handler(newUrl: string, oldUrl?: string): void {
        if ((oldUrl === undefined || newUrl != oldUrl) && newUrl > '') {
          this.loadImage();
        } else {
          this.contents = '';
          this.errorMessage = '';
        }
      },
      immediate: true,
    }
  },

  methods: {
    async loadImage(): Promise<void> {
      this.loading = true;

      const response = await api.get(this.url, undefined, undefined, 'plainText');

      this.loading = false;

      if (response) {
        if (!response.error) {
          this.contents = response.data as string;
          this.errorMessage = '';
          return;
        }
      }

      this.errorMessage = 'This file cannot be displayed.'
    }
  }
})
