import { LinkableEntity } from "../entities/LinkableEntity";
import { Spec } from "./Spec";

export class LinkedToSpec<T extends LinkableEntity> extends Spec<T> {
  constructor(linkedEntityId: string) {
    super();
    this._linkedEntityId = linkedEntityId;
  }

  private _linkedEntityId: string;

  public isSatisfiedBy(entity: T): boolean {
    if (entity.links) {
      return (entity.links.findIndex(x => x.entityId == this._linkedEntityId) > -1);
    } else {
      return false;
    }
  }
}