import Vue from 'vue'

export interface EntityManagerSearchDialogField {
  focus(): void;
}

export const AbstractEntityManagerSearchDialogField = Vue.extend({
  methods: {
    focus(): void {
      (this.$refs.firstInputField as HTMLInputElement).focus();
    }
  }
})