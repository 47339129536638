import InsightUser from "../core/InsightUser";
import { WorkOrderStep } from "./WorkOrderStep";

export class WorkOrderStepListModel {
  public id = '';
  public type = '';
  public name = '';
  public assignedToUser = new InsightUser();
  public status: 'New'|'Assigned'|'In Progress'|'Paused'|'Done'|'Skipped' = 'New';
  public finishedAt?: string;
  public skippedAt?: string;
  public position = 0;
  public sortIndex = 0;

  public static CreateFromStep(step: WorkOrderStep): WorkOrderStepListModel {
    const lm = new WorkOrderStepListModel();

    lm.id = step.id ?? '';
    lm.type = step.type;
    lm.name = step.name;
    lm.assignedToUser = step.assignedToUser;
    lm.status = step.status;
    lm.finishedAt = step.finishedAt;
    lm.skippedAt = step.skippedAt;
    lm.position = step.position;
    lm.sortIndex = step.sortIndex

    return lm;
  }

  public static ConvertArrayOfSteps(steps: WorkOrderStep[]): WorkOrderStepListModel[] {
    const lms: WorkOrderStepListModel[] = [];

    steps.forEach(step => {
      lms.push(this.CreateFromStep(step));
    });

    return lms;
  }
}