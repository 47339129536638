import * as gt from '../getterNames'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF, MESSAGE_MANAGER_PREVIEW_REF } from '../refNames'
import Client from '@/models/core/Client'
import { GetterTree } from 'vuex'
import Message from '@/models/messages/Message'
import MessageListModel from '@/models/messages/MessageListModel'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.MESSAGE_CURRENT_MAILBOX_ADDRESS](state): string {
    return state.mailboxAddress;
  },

  [gt.MESSAGE_CURRENT_MAILBOX_ID](state): string {
    return state.mailboxId;
  },

  [gt.MESSAGES_FOR_CURRENT_CLIENT](state, getters): MessageListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.MESSAGES_LISTED_FOR_REF]: (state) => (client: string, refName: string): MessageListModel[] => {
    return state.getListModelsForRef(client, refName);
  },

  [gt.MESSAGES_LINKED_TO_ENTITY]: (state) => 
    (entity: PrivateEntitySpec): MessageListModel[] => {
      return state.getListModelsForRef(entity.client, ENTITY_EDITOR_LINK_REF_STEM + entity.id);
  },
    
  [gt.MESSAGES_OPEN_FOR_CURRENT_CLIENT] (state, getters): Message[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.MESSAGES_OPEN_FOR_REF]: (state) => (client: string, refName: string): Message[] => {
    return state.getEntitiesForRef(client, refName);
  },

  [gt.MESSAGE_MANGAGER_PREVIEW] (state, getters): Message|null {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    return state.getEntitiesForRef(clientCode, MESSAGE_MANAGER_PREVIEW_REF)[0] ?? null;
  },

  [gt.MESSAGE_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },
}