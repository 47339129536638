export function capitalizeFirstLetter(text: string): string {
  return text.replace(/^./, function (str: string) { return str.toUpperCase() })
}

export function lowercaseFirstLetter(text: string): string {
  return text.replace(/^./, function (str: string) { return str.toLowerCase() })
}

export function toTitleCase(text: string): string {
  return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
}