import { SearchUIFieldDef, SearchUIFieldValue } from "./SearchUIOption";
import { capitalizeFirstLetter } from "@/util/capitalize";
import { describeValueOfType } from "./QueryDescriptionGeneratorTypeDescribers";
import { wrapQuotes } from "@/util/naturalLanguage";



function getFieldName(value: SearchUIFieldValue, defs: SearchUIFieldDef[]): string {
  const def = defs.find(x => x.fieldName == value.fieldName);

  if (def) {
    return def.notNoun ? wrapQuotes(def.displayName) : `the ${def.displayName.toLowerCase()}`
  } else {
    return "ERROR";
  }
}

function describeQueryTerm(term: SearchUIFieldValue, defs: SearchUIFieldDef[]): string {
  let desc = getFieldName(term, defs);

  desc += ' ' + describeValueOfType[term.type](term.value);

  return desc;
}

export function describeQuery(
  terms: SearchUIFieldValue[],
  defs: SearchUIFieldDef[],
  pluralEntityType: string
): string {
  let description = '';

  if (terms.length > 0) {
    description += capitalizeFirstLetter(pluralEntityType) + ' where';

    description += terms.reduce((desc, term, idx) => {
      return desc + (idx > 0 ? ' and ': ' ') + describeQueryTerm(term, defs);
    }, '');

  } else {
    description = 'All ' + pluralEntityType;
  }

  return description;
}