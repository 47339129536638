import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { AssignmentTemplate, AssignmentTemplateListModel, EntityType } from '@/models/assignments/AssignmentTemplate';
import { fetchSetting, SettingName } from '@/util/settingsApi';
import { ActionTree } from "vuex";
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import Client from '@/models/core/Client';
import { LifecycleRecord } from '@/models/lifecycles/LifecycleRecord';
import notifications from '@/util/notifications';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.ASSIGNMENT_TEMPLATE_DELETE] ({ commit }, data: ApiDeletePayload<AssignmentTemplate>): Promise<void> {
    const url = data.entity.client + '/assignments/templates/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.ASSIGNMENT_TEMPLATE_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_TEMPLATE_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<AssignmentTemplateListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/assignments/templates`
    return ah.getEntityList(
      url,
      client,
      mt.ASSIGNMENT_TEMPLATE_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_TEMPLATE_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<AssignmentTemplateListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/assignments/templates`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.ASSIGNMENT_TEMPLATE_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false,
      req.pagerId,
      req.pageSize,
      req.pageNumber,
      req.pageDataMutationName,
      req.orderBy,
      req.orderDescending,
    )
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_TEMPLATE_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<AssignmentTemplate | null> {
    const url = request.spec.client + '/assignments/templates/' + request.spec.id

    return ah.getPrivateEntity<AssignmentTemplate>(url, mt.ASSIGNMENT_TEMPLATE_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_TEMPLATE_SAVE] ({ commit }, data: {entity: AssignmentTemplate; refName: string}): Promise<AssignmentTemplate | null> {
    const url = data.entity.client + '/assignments/templates'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.ASSIGNMENT_TEMPLATE_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_TEMPLATE_UNDELETE] ({ commit }, entity: AssignmentTemplate): Promise<AssignmentTemplate | null> {
    const url = entity.client + '/assignments/templates'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.ASSIGNMENT_TEMPLATE_STORE_ENTITY_FOR_REF, commit);
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_TEMPLATE_FETCH_LIFECYCLE_FOR_TYPE] ({commit, getters}, entityType: EntityType): Promise<void> {
    const client = getters[gt.CORE_CLIENT_CURRENT] as Client;

    try {
      const id = await fetchSetting(client.code, SettingName.TicketActiveLifecycleId, "0");
      const result = await api.get(`${client.code}/lifecycles/${id}`);
      if (result) {
        if (result.error == false) {
          commit(mt.ASSIGNMENT_TEMPLATE_SET_LIFECYCLE_FOR_TYPE, result.data as LifecycleRecord)
        } else {
          notifications.fail(this, `There was an error loading ${entityType} lifecycle data`);
        }
      } else {
        notifications.fail(this, `There was an error loading ${entityType} lifecycle data`);
      }
    } catch (e) {
      notifications.fail(this, `There was an error loading ${entityType} lifecycle data`);
    }

  },
}