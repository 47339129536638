
import { MessageContactState, MessageContactType } from '@/models/messages/MessageEnums'
import AddressAutosuggestField from '../contacts/AddressAutosuggestField.vue'
import { AddressListItem } from '@/models/contacts/AddressListItem'
import MessageContact from '@/models/messages/MessageContact'
import MessageContactComponent from './MessageContact.vue'
import MessageHeaderRow from './MessageHeaderRow.vue'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  components: {
    AddressAutosuggestField,
    MessageContactComponent,
    MessageHeaderRow,
  },

  props: {
    contacts: {
      type: Array,
      default: (): MessageContact[] => []
    } as PropValidator<MessageContact[]>,

    label: {
      type: String,
      default: "",
    },

    contactType: {
      type: Number,
      default: MessageContactType.To
    } as PropValidator<MessageContactType>
  },

  methods: {
    handleAddressEntered(addr: AddressListItem): void {
      const contact = new MessageContact()
      contact.state = MessageContactState.New
      contact.type = this.contactType
      contact.name = addr.displayName
      contact.address = addr.address

      const newContacts = this.contacts.concat([contact])

      this.$emit('update:contacts', newContacts);
    },

    handleRemoveRequest(contact: MessageContact): void {
      const newContacts = this.contacts.filter(x => x.address != contact.address);

      this.$emit('update:contacts', newContacts)
    },
  }
})
