import { MailTemplate, MessageContactType, MessageState, MessageType } from './MessageEnums';
import EntityLinkRequest from '../core/entities/EntityLinkRequest';
import { LinkableEntity } from '../core/entities/LinkableEntity';
import MailboxListModel from '../mailboxes/MailboxListModel';
import MessageContact from './MessageContact';
import reformatDate from '@/util/reformatDate';
import WerkFileListModel from '../files/WerkFileListModel';

export default class Message extends LinkableEntity {
  public attachments: WerkFileListModel[] = [];
  public bodyHtml: string|null = "";
  public bodyText: string|null = "";
  public contacts: MessageContact[] = [];
  public conversationId = "0";
  public emailId: string|null = null;
  public mailboxes?: MailboxListModel[];
  public messageDate = "";
  public parent: string|null = null;
  public state: MessageState = MessageState.Draft;
  public subject = "";
  public template: MailTemplate = MailTemplate.None;
  public type: MessageType = MessageType.ExternalAdHoc;

  public static GenerateForward(msg: Message, fromAddress: MessageContact): Message {
    const fwd = new Message();

    fwd.subject = `FW: ${this._trimSubjectPrefix(msg.subject)}`;
    fwd.bodyHtml = this._generateFwdRplBodyHtml(msg);
    fwd.bodyText = this._generateFwdRplBodyText(msg);

    fwd.contacts.push(MessageContact.CopyNewContact(fromAddress, MessageContactType.From));

    fwd.conversationId = msg.conversationId;
    fwd.state = MessageState.Draft;
    fwd.type = MessageType.ExternalAdHoc;
    fwd.template = MailTemplate.None;
    fwd.parent = msg.id || null;
    fwd.client = msg.client;

    this._copyLinksToChild(msg, fwd);

    fwd.messageDate = new Date().toISOString();

    return fwd;
  }

  public static GenerateReply(mode: 'single'|'all', msg: Message, replyAddress: MessageContact): Message {
    const reply = new Message();

    reply.subject = `RE: ${this._trimSubjectPrefix(msg.subject)}`;
    reply.bodyHtml = this._generateFwdRplBodyHtml(msg);
    reply.bodyText = this._generateFwdRplBodyText(msg);

    reply.contacts.push(MessageContact.CopyNewContact(replyAddress, MessageContactType.From));

    const fromContact = this._findFrom(msg.contacts);
    if (fromContact) {
      if (fromContact.address != replyAddress.address) {
        reply.contacts.push(MessageContact.CopyNewContact(fromContact, MessageContactType.To));
      } else {
        mode = "all";
      }
    }

    if (mode == "all") {
      reply.contacts.push(...this._filterAndCopyContacts(msg.contacts, replyAddress))
    }

    reply.conversationId = msg.conversationId;
    reply.state = MessageState.Draft;
    reply.type = MessageType.ExternalAdHoc;
    reply.template = MailTemplate.None;
    reply.parent = msg.id || null;
    reply.client = msg.client;

    this._copyLinksToChild(msg, reply);

    reply.messageDate = new Date().toISOString();

    return reply;
  }

  private static _copyLinksToChild(parent: Message, child: Message): void {
    const requestedLinks: EntityLinkRequest[] = [];

      if (parent.links) {
        parent.links.forEach(l => {
          requestedLinks.push(
            new EntityLinkRequest(l.entityId, l.type)
          )
        });
      }

      child.requestedLinks = requestedLinks;
  }

  private static _filterAndCopyContacts(contacts: MessageContact[], replyAddress: MessageContact): MessageContact[] {
    const newContacts: MessageContact[] = [];
    
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      
      if (contact.address != replyAddress.address && 
            contact.type != MessageContactType.Bcc &&
            contact.type != MessageContactType.From)
      {
        newContacts.push(MessageContact.CopyNewContact(contact));
      }
    }

    return newContacts;
  }

  private static _findFrom(contacts: MessageContact[]): MessageContact|undefined {
    return contacts.find(x => x.type == MessageContactType.From);
  }

  private static _generateFwdRplBodyHtml(msg: Message): string| null {
    if (msg.bodyHtml) {
      const formattedDate = reformatDate(msg.messageDate, "mm/dd/yyyy h:MM tt");
      const fromContact = this._findFrom(msg.contacts);
      const fromText = fromContact?.name ? fromContact.name : fromContact?.address;

      const declarationLine = `<p><b>On ${formattedDate}, ${fromText} wrote:</b></p>`;

      return `<p></p>${declarationLine}${msg.bodyHtml}`;
    } else {
      return null;
    }
  }

  private static _generateFwdRplBodyText(msg: Message): string| null {
    if (msg.bodyText) {
      const formattedDate = reformatDate(msg.messageDate, "mm/dd/yyyy h:MM tt");
      const fromContact = this._findFrom(msg.contacts);
      const fromText = fromContact?.name ? fromContact.name : fromContact?.address;

      const declarationLine = `On ${formattedDate}, ${fromText} wrote:\n`;

      return `\n\n${declarationLine}\n${msg.bodyText}`;
    } else {
      return null;
    }
  }

  private static _trimSubjectPrefix(subject: string): string {
    const expr = new RegExp('^(re: *|Re: *|RE: *|fwd: *|Fwd: *|FWD: *|fw: *|Fw: *|FW: *)*(?<subjectBody>.*)');

    const matches = subject.match(expr);

    if (matches?.groups) {
      return matches.groups.subjectBody;
    } else {
      return subject;
    }
  }
}