
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import CategoryField from '../../itemCategories/CategoryField.vue'
import IconButton from "../IconButton.vue";
import ItemCategoryListModel from "@/models/items/ItemCategoryListModel";
import { PropValidator } from "vue/types/options";
import ReadOnlyField from "../forms/ReadOnlyField.vue";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    CategoryField,
    ReadOnlyField,
    IconButton,
  },

  props: {
    locked: Boolean,

    value: {
      type: Array,
      default: null,
    } as PropValidator<ItemCategoryListModel[]>,
  },

  data() {
    return {
      catFieldValue: "0",
      showItemPicker: false,
    }
  },

  computed: {
    internalValue: {
      get(): ItemCategoryListModel[] {
        return this.value;
      },

      set(newValue: ItemCategoryListModel[]): void {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    async handleNewCategory(category: ItemCategoryListModel): Promise<void> {
      this.internalValue = [...this.internalValue, category];

      await this.$nextTick();

      this.catFieldValue = "0";
    },

    handleRemoveCategory(category: ItemCategoryListModel): void {
      this.internalValue = this.internalValue.filter(x => x != category)
    }
  }
})
