export function getValFromObjectOrChild<T>(o: unknown, name: string): T|undefined {
  if (o == undefined || o == null) {
    return undefined;
  }

  const parts = name.split(".");
  if (parts.length > 1) {
    const parent = o as Record<string, unknown>;
    return getValFromObjectOrChild<T>(parent[parts[0]], parts.slice(1).join('.'));
  } else {
    const obj = o as Record<string, T>;
    return obj[name];
  }
}