import { ASSIGNMENT_TYPES, ENTITY_TYPES, WORK_TYPES } from "@/models/assignments/AssignmentTemplate";
import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue } from "@/models/core/searchQueries/SearchUIOption";
import { UpdatableEntitySearchFieldDefs } from "@/models/core/searchQueries/UpdatableEntitySearchFieldDefs";

export const AssignmentTemplateSearchFieldDefs = [
  ...UpdatableEntitySearchFieldDefs,

  {
    fieldName: "name",
    displayName: "Name",
    type: SearchUIFieldType.StringContainsOrEquals
  },
  {
    fieldName: "entityType",
    displayName: "Entity Type",
    type: SearchUIFieldType.StringSet,
    options: [...ENTITY_TYPES]
  },
  {
    fieldName: "assignmentType",
    displayName: "Assignment Type",
    type: SearchUIFieldType.StringSet,
    options: [...ASSIGNMENT_TYPES]
  },
  {
    fieldName: "workType",
    displayName: "Work Type",
    type: SearchUIFieldType.StringSet,
    options: [...WORK_TYPES]
  },
] as SearchUIFieldDef[];

export const DefaultAssignmentTemplateQuery = [] as SearchUIFieldValue[]