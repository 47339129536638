import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ActionTree } from "vuex";
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import { ContactCollection } from '@/models/contacts/ContactCollection';
import { ContactCollectionListModel } from '@/models/contacts/ContactCollectionListModel';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.CONTACT_COLLECTION_DELETE] ({ commit }, data: ApiDeletePayload<ContactCollection>): Promise<void> {
    const url = data.entity.client + '/contacts/collections/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.CONTACT_COLLECTION_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.CONTACT_COLLECTION_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<ContactCollectionListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/contacts/collections`
    return ah.getEntityList(
      url,
      client,
      mt.CONTACT_COLLECTION_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.CONTACT_COLLECTION_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<ContactCollection | null> {
    const url = request.spec.client + '/contacts/collections/' + request.spec.id

    return ah.getPrivateEntity<ContactCollection>(url, mt.CONTACT_COLLECTION_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.CONTACT_COLLECTION_SAVE] ({ commit }, data: {entity: ContactCollection; refName: string}): Promise<ContactCollection | null> {
    const url = data.entity.client + '/contacts/collections'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.CONTACT_COLLECTION_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.CONTACT_COLLECTION_UNDELETE] ({ commit }, entity: ContactCollection): Promise<ContactCollection | null> {
    const url = entity.client + '/contacts/collections'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.CONTACT_COLLECTION_STORE_ENTITY_FOR_REF, commit);
  },
}