import * as gt from '../getterNames'
import Client from '@/models/core/Client'
import { ENTITY_MANAGER_REF } from '../refNames'
import { GetterTree } from 'vuex'
import RootState from '../RootState'
import State from './state'
import {WorkOrder} from '@/models/workOrders/WorkOrder'
import {WorkOrderListModel} from '@/models/workOrders/WorkOrderListModel'

export const getters: GetterTree<State, RootState> = {
  [gt.WORK_ORDERS_FOR_CURRENT_CLIENT](state, getters): WorkOrderListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.WORK_ORDERS_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): WorkOrderListModel[] => {
      return state.getListModelsForRef(client, refName);
  },
    
  [gt.WORK_ORDERS_OPEN_FOR_CURRENT_CLIENT] (state, getters): WorkOrder[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.WORK_ORDERS_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): WorkOrder[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.WORK_ORDER_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },

  [gt.WORK_ORDER_GET_KANBAN_STATE]: (state) => (clientCode: string): unknown => {
    return state.kanbanState[clientCode];
  }
}