import { EntityType } from "./AssignmentTemplate";
import { ParameterType } from "./Behaviors";


export class ActionInfo {
  constructor(
    public name: string,
    public passiveName: string,
    public parameterType: ParameterType
  ) { }
}

export function getActionListForEntityType(type: EntityType): ActionInfo[] {
  if (type == "Ticket") {
    return [
      new ActionInfo("Close", "Closed", "CloseReason")
    ]
  } else {
    throw new Error(`${type} action info hasn't been implemented yet`);
  }
}
