import { AssignmentTemplate, EntityType } from "./AssignmentTemplate";
import PrivateEntity from "../core/entities/PrivateEntity";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";

export class AssignmentWorkflow extends PrivateEntity {
  constructor(
    public name = '',
    public entityType: EntityType = 'Ticket',
    public steps: AssignmentTemplate[] = [],
  ) {
    super();
  }
}

export class AssignmentWorkflowListModel extends PrivateEntityListModel<AssignmentWorkflow> {
  constructor(
    public name = '',
    public entityType: EntityType = 'Ticket',
    public stepCount = 0,
  ) {
    super();
  }

  protected populateCore(model: AssignmentWorkflow): void {
    this.name = model.name;
    this.entityType = model.entityType;
    this.stepCount = model.steps?.length ?? 0;
  }

}