
import { COMMENT_ADD_REFERRER, COMMENT_DROP_REFERRER, COMMENT_REMOVE_ENTITY_FROM_REF, COMMENT_STORE_ENTITY_FOR_REF, CORE_SET_ENTITY_EDITOR_COUNTER } from '@/store/mutationNames'
import { COMMENT_REFERRER_EXISTS, COMMENTS_LINKED_TO_ENTITY, COMMENTS_OPEN_FOR_REF } from '@/store/getterNames'
import api from '@/util/api'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import { Comment } from '@/models/comments/Comment'
import { COMMENT_FETCH_FOR_ENTITY } from '@/store/actionNames'
import CommentDisplayFormatter from './CommentDisplayFormatter.vue'
import { CommentListModel } from '@/models/comments/CommentListModel'
import CommentNew from './CommentNew.vue'
import CommentViewer from './CommentViewer.vue'
import { DataTableHeader } from 'vuetify'
import { DeletedSpec } from '@/models/core/spec/DeletedSpec'
import { ENTITY_EDITOR_LINK_REF_STEM } from '@/store/refNames'
import { EntityEditorCounterUpdate } from '@/models/core/entityEditor/EntityEditorChangeValueRequest'
import { LinkedToSpec } from '@/models/core/spec/LinkedToSpec'
import notifications from '@/util/notifications'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import { PropValidator } from 'vue/types/options'
import reformatDate from '@/util/reformatDate'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest'

export default Vue.extend({
  components: { 
    CommentDisplayFormatter,
    CommentNew,
    CommentViewer,
  },

  props: {
    entity: {
      type: Object,
      default: (): PrivateEntitySpec => {return {} as PrivateEntitySpec},
    } as PropValidator<PrivateEntitySpec>,

    entityType: {
      type: String,
      default: 'entity',
    },
  },

  data() {
    return {
      currentSortDesc: true,
      currentSortField: 'createdAt',
      headers: [
        {
          value: 'createdBy',
          text: 'User',
          width: 190,
        },
        {
          value: 'createdAt',
          text: 'Date',
          width: 190,
        },
        {
          value: 'text',
          text: 'Comment',
        },
      ] as DataTableHeader[],
      loadingEntityList: false,
      loadingEntityPreview: false,
      newEntity: null as Comment|null,
      showNewEntity: false,
    }
  },

  computed: {
    canCreateComments(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1007200") ||
             this.$auth.hasPrivilegeForClient(this.entity.client, "1007210")
    },

    canViewComments(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1007100") ||
             this.$auth.hasPrivilegeForClient(this.entity.client, "1007110")
    },

    entities(): CommentListModel[] {
      return this.$store.getters[COMMENTS_LINKED_TO_ENTITY](this.entity);
    },

    entityPreview(): Comment|null {
      return this.$store.getters[COMMENTS_OPEN_FOR_REF](this.entity.client, this.refName)[0] as Comment || null;
    },

    referrerExists(): boolean {
      return this.$store.getters[COMMENT_REFERRER_EXISTS](this.refName);
    },

    refName(): string {
      return ENTITY_EDITOR_LINK_REF_STEM + this.entity.id
    },

    showEntityPreview(): boolean {
      return !!this.entityPreview;
    },
  },

  watch: {
    'entities.length'(): void {
      const req = new EntityEditorCounterUpdate(this.entity.id, 'commentCount', this.entities.length);
      this.$store.commit(CORE_SET_ENTITY_EDITOR_COUNTER, req)
    }
  },

  created() {
    if (!this.referrerExists) {
      this.configureEntityReferrer();
      this.loadEntities();
    }
  },

  methods: {
    configureEntityReferrer(): void {
      if (!this.canViewComments) return;

      const targetEntityId = this.entity.id;

      const spec = new LinkedToSpec<Comment>(targetEntityId).and(new DeletedSpec().not());

      const referrer = new VuexEntityReferer(
        this.entity.client,
        this.refName,
        (entity, mode) =>  {
          if (mode == 'listModel'){
            const comment = entity as Comment;

            return spec.isSatisfiedBy(comment) ? 'yes' : 'no';
          }

          return 'noChange';
        }
      );

      this.$store.commit(COMMENT_ADD_REFERRER, referrer)
    },

    dropEntityReferrer(): void {
      this.$store.commit(COMMENT_DROP_REFERRER, {client: this.entity.client, name: this.refName});
    },

    formatDateCreated(createdDate: Date|string|null|undefined): string {
      return reformatDate(createdDate, 'mm/dd/yyyy hh:MM tt');
    },

    handleEntityDeleted(): void {
      this.showEntityPreview = false;
    },

    handleNewEntityRequest(): void {
      this.showNewEntity = true;
    },

    async loadEntities(): Promise<void> {
      if (this.canViewComments == false) return;

      this.loadingEntityList = true;

      try {
        await this.$store.dispatch(COMMENT_FETCH_FOR_ENTITY, this.entity);
      } catch (error) {
        const e = error as ApiResponse;
        notifications.warn(this.$store, `There was an error while loading the comments. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
      }

      this.loadingEntityList = false;
    },

    async previewEntity(comment: CommentListModel): Promise<void> {
      this.loadingEntityPreview = true;

      const url = `${this.entity.client}/comments/${comment.id}`
      const apiResponse = await api.get(url)

      if (apiResponse) {
        if (!apiResponse.error) {
          this.setEntityPreview(apiResponse.data as Comment);
        } else {
          const e = apiResponse;
          notifications.warn(this.$store, `Failed to load comment. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.loadingEntityPreview = false;
    },

    setEntityPreview(newEntity: Comment): void {
      if (this.entityPreview) {
        const spec: VuexEntityRefSpec ={
          refName: this.refName,
          spec: this.entityPreview as PrivateEntitySpec,
        }

        this.$store.commit(COMMENT_REMOVE_ENTITY_FROM_REF, spec);
      }

      if (newEntity) {
        const request: VuexEntityStorageRequest<Comment> = {
          refName: this.refName,
          entity: newEntity
        }

        this.$store.commit(COMMENT_STORE_ENTITY_FOR_REF, request)
      }
    },

    unload(): void {
      this.dropEntityReferrer();
    }
  }
})
