
import { CORE_CLIENT_CURRENT, TICKET_TYPE_REFERRER_EXISTS, TICKET_TYPES_LISTED_FOR_REF } from '@/store/getterNames'
import Client from '@/models/core/Client'
import { requiredIf } from '../../../util/validators'
import { TICKET_TYPE_ADD_REFERRER } from '@/store/mutationNames'
import { TICKET_TYPE_FETCH } from '@/store/actionNames'
import { TICKET_TYPE_FIELD_REF } from '@/store/refNames'
import { TicketTypeListModel } from '@/models/tickets/TicketType'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest'

export default Vue.extend({

  props: {
    conflictingValue: {
      type: String,
      default: "-1",
    },

    excludeValue: {
      type: String,
      default: "-1",
    },
    
    label: {
      type: String,
      default: '',
    },

    required: Boolean,

    value: {
      type: String,
      default: "-1",
    },
  },

  data () {
    return {
      rules: [
        requiredIf(() => this.required, () => this.label)
      ]
    }
  },

  computed: {
    types(): TicketTypeListModel[] {
      const getter = this.$store.getters[TICKET_TYPES_LISTED_FOR_REF];
      const types = getter(this.currentClient.code, TICKET_TYPE_FIELD_REF)
        .filter((c: TicketTypeListModel) => c.id != this.excludeValue) as TicketTypeListModel[]

      types.sort((a: TicketTypeListModel, b: TicketTypeListModel) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name == b.name) {
          return 0;
        } else {
          return -1
        }
      })

      return [
        { id: "0", name: '(No Type Selected)' } as TicketTypeListModel,
        ...types
      ]
    },

    currentClient(): Client {
      return this.$store.getters[CORE_CLIENT_CURRENT] as Client;
    },

    referrerExists(): boolean {
      return this.$store.getters[TICKET_TYPE_REFERRER_EXISTS](TICKET_TYPE_FIELD_REF);
    },
  },

  created() {
    if (!this.referrerExists) {
      this.configureEntityReferrer();
      const req: VuexFetchRequest = {
        refName: TICKET_TYPE_FIELD_REF
      }
      this.$store.dispatch(TICKET_TYPE_FETCH, req);
    }
  },

  methods: {
    configureEntityReferrer(): void {
      const referrer = new VuexEntityReferer(
        this.currentClient.code,
        TICKET_TYPE_FIELD_REF,
        (entity, mode) =>  {
          if (mode == 'entity') {
            return 'noChange';

          } else {
            return 'yes'
          }
        }
      );

      this.$store.commit(TICKET_TYPE_ADD_REFERRER, referrer)
    },

    getTypeNameForId(id: string): string {
      return this.types.find(x => x.id == id)?.name ?? '(Unknown type)'
    },

    getFieldErrors(): string|never[] {
      if (this.conflictingValue != "-1") {
        return `Conflicting value: ${this.getTypeNameForId(this.conflictingValue)}`
      } else {
        return [];
      }
    },
    
    handleInput(payload: string): void {
      this.$emit('input', payload)

      const catObj = this.types.find(x => x.id == payload)
      if (catObj) {
        this.$emit('type-selected', catObj);
      }
    }
  }
})
