import { LifecycleStageRecord } from "./LifecycleStageRecord";
import PrivateEntity from "../core/entities/PrivateEntity";

export class LifecycleRecord extends PrivateEntity {
  constructor(
    public entityType = '',
    public name = '',
    public closeReasons = [] as string[],
    public stageRecords = [] as LifecycleStageRecord[],
    public publishedBy = '',
    public publishedAt?: string,
  ) {
    super()
  }

  public static Duplicate(source: LifecycleRecord): LifecycleRecord {
    return new LifecycleRecord(
      source.entityType,
      '',
      [...source.closeReasons],
      source.stageRecords.map(x => LifecycleStageRecord.Duplicate(x)),
    )
  }
}