import Message from '@/models/messages/Message'
import MessageListModel from '@/models/messages/MessageListModel'
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Message, MessageListModel> {
  mailboxId = "0";
  mailboxAddress = '';
  messagePreview: Message|null = null;

  protected instantiateListModelForEntity(entity: Message): MessageListModel {
    const listModel = new MessageListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Message'
  }
}