
import { SearchUIFieldDef, SearchUIFieldType } from '@/models/core/searchQueries/SearchUIOption'
import Vue, { VueConstructor } from 'vue'
import EntityManagerSearchDialogContactSetField from './EntityManagerSearchDialogContactSetField.vue'
import EntityManagerSearchDialogDateTimeField from './EntityManagerSearchDialogDateTimeField.vue'
import EntityManagerSearchDialogDateTimeOffsetField from './EntityManagerSearchDialogDateTimeOffsetField.vue'
import EntityManagerSearchDialogItemCategorySetField from './EntityManagerSearchDialogItemCategorySetField.vue'
import EntityManagerSearchDialogLifecycleCloseReasonSetField from './EntityManagerSearchDialogLifecycleCloseReasonSetField.vue'
import EntityManagerSearchDialogLifecycleStatusSetField from './EntityManagerSearchDialogLifecycleStatusSetField.vue'
import EntityManagerSearchDialogNumberField from './EntityManagerSearchDialogNumberField.vue'
import EntityManagerSearchDialogPercentField from './EntityManagerSearchDialogPercentField.vue'
import EntityManagerSearchDialogStringSetField from './EntityManagerSearchDialogStringSetField.vue'
import EntityManagerSearchDialogTextField from './EntityManagerSearchDialogTextField.vue'
import EntityManagerSearchDialogTicketTypeSetField from './EntityManagerSearchDialogTicketTypeSetField.vue'
import EntityManagerSearchDialogUserIdSetField from './EntityManagerSearchDialogUserIdSetField.vue'
import EntityManagerSearchDialogUserSetField from './EntityManagerSearchDialogUserSetField.vue'
import EntityManagerSearchDialogWorkOrderCategorySetField from './EntityManagerSearchDialogWorkOrderCategorySetField.vue'
import IconButton from '../IconButton.vue'
import { PropValidator } from 'vue/types/options'
import ReadOnlyField from '../forms/ReadOnlyField.vue'

export default Vue.extend({
  components: {
    IconButton,
    ReadOnlyField,
  },

  props: {
    def: {
      type: Object,
      default: null,
    } as PropValidator<SearchUIFieldDef>,

    index: {
      type: Number,
      default: 0,
    },

    locked: Boolean,

    value: {
      type: [Object, String, Array],
      default: null,
    }
  },

  data() {
    return {
    }
  },

  computed: {
    inputComponent(): VueConstructor<Vue> {
      if (this.def.type == SearchUIFieldType.DateTime) {
        return EntityManagerSearchDialogDateTimeField;
      } else if (this.def.type == SearchUIFieldType.DateTimeOffset) {
        return EntityManagerSearchDialogDateTimeOffsetField;
      } else if (this.def.type == SearchUIFieldType.NumberRange) {
        return EntityManagerSearchDialogNumberField;
      } else if (this.def.type == SearchUIFieldType.StringSet) {
        return EntityManagerSearchDialogStringSetField;
      } else if (this.def.type == SearchUIFieldType.ContactSet) {
        return EntityManagerSearchDialogContactSetField;
      } else if (this.def.type == SearchUIFieldType.UserSet) {
        return EntityManagerSearchDialogUserSetField;
      } else if (this.def.type == SearchUIFieldType.UserIdSet) {
        return EntityManagerSearchDialogUserIdSetField;
      } else if (this.def.type == SearchUIFieldType.ItemCategorySet) {
        return EntityManagerSearchDialogItemCategorySetField;
      } else if (this.def.type == SearchUIFieldType.WorkOrderCategorySet) {
        return EntityManagerSearchDialogWorkOrderCategorySetField;
      } else if (this.def.type == SearchUIFieldType.PercentRange) {
        return EntityManagerSearchDialogPercentField;
      } else if (this.def.type == SearchUIFieldType.TicketTypeSet) {
        return EntityManagerSearchDialogTicketTypeSetField;
      } else if (this.def.type == SearchUIFieldType.LifecycleStatusSet) {
        return EntityManagerSearchDialogLifecycleStatusSetField;
      } else if (this.def.type == SearchUIFieldType.LifecycleCloseReasonSet) {
        return EntityManagerSearchDialogLifecycleCloseReasonSetField;
      } else {
        return EntityManagerSearchDialogTextField;
      }
    },

    rowStartText(): string {
      return this.index == 0 ? "..." : "...and "
    },
  },

  mounted(): void {
    if (this.$refs.inputField) {
      (this.$refs.inputField as HTMLInputElement).focus();
    }
  },

  methods: {
    handleInput(payload: unknown): void {
      this.$emit('input', payload);
    },

    requestRemoveRow(): void {
      this.$emit("remove-row", true);
    }
  }
})
