import * as ac from "./actionNames";
import * as gt from "./getterNames"
import * as mt from "./mutationNames"
import { Lifecycle, LifecycleVuexRequest } from "@/models/lifecycles/Lifecycle";
import { ActionTree } from 'vuex'
import api from '../util/api'
import { ApiResponse } from "@/models/core/api/ApiResponse";
import { AppConfig } from "@/models/core/AppConfig";
import { BufferedEditorChangeValueRequest } from "@/models/core/bufferedEditor/BufferedEditorChangeValueRequest";
import { BufferedEditorResetRequest } from "@/models/core/bufferedEditor/BufferedEditorResetRequest";
import Client from "@/models/core/Client";
import { EditorBuffer } from "@/models/core/bufferedEditor/EditorBuffer";
import { EntityEditorCloseRequest } from "@/models/core/entityEditor/EntityEditorCloseRequest";
import { EntityEditorCounterUpdate } from "@/models/core/entityEditor/EntityEditorChangeValueRequest";
import { EntityType } from "@/models/assignments/AssignmentTemplate";
import { InsightApplication } from "insight-auth";
import { loadClientConfiguration } from "@/util/startup";
import notifications from "@/util/notifications";
import popup from "@/util/popup";
import { preserveTempIds } from "@/models/core/bufferedEditor/BufferedEditorPreserveTempIds";
import PrivateEntitySpec from "@/models/core/entities/PrivateEntitySpec";
import RootState from './RootState'
import Vue from "vue";

export const actions: ActionTree<RootState, RootState> = {
  async [ac.CORE_CLIENT_FETCH_ALL]({ commit, getters }): Promise<undefined> {
    // First get the client list from the server
    const clientResponse = await api.get('insightClients', undefined, InsightApplication.AppCenter)

    if (!clientResponse) return;

    if (clientResponse.error) {
        return Promise.reject(clientResponse)
    }

    const clients = clientResponse.data

    // Now get the selected client
    const prevClient = getters[gt.CORE_CLIENT_CURRENT] || {}

    commit(mt.CORE_CLIENT_REPLACE_ALL, clients)

    const newIndex = getters[gt.CORE_CLIENT_INDEX_BY_CODE](prevClient.code)
    commit(mt.CORE_CLIENT_SET_CURRENT, (newIndex > 0) ? newIndex : 0)
  },

  async [ac.CORE_CLOSE_ENTITY_EDITOR] ({ state, commit }, req: EntityEditorCloseRequest): Promise<boolean> {
    const editorState = state.entityEditorState[req.entity.id];

    if (editorState == undefined) return true;

    const hasUnsavedEdits = state.bufferedEditorState[editorState.editorBufferId]?.hasUnsavedEdits ?? false;

    if (hasUnsavedEdits && !req.force) {
      const titleStr = req.entityTitle ? `to ${req.entityTitle} ` : ''

      const confirmed = await popup.confirm(
        this,
        "Confirm Close",
        `Your changes ${titleStr}will be lost if you close. Do you want to continue?`);

      if (!confirmed) return false;
    }

    editorState.dependentReferrers.forEach(r => {
      commit(r.dropMutationName, {client: req.entity.client, name: r.refName});
    });

    commit(req.mutationName, {spec: req.entity, refName: editorState.refName});

    commit(mt.CORE_CLEAR_EDITOR_BUFFER, editorState.editorBufferId);

    editorState.dependentBuffers.forEach(b => {
      commit(mt.CORE_CLEAR_EDITOR_BUFFER, b);
    });

    commit(mt.CORE_CLEAR_ENTITY_EDITOR_STATE, req.entity.id);

    return true;
  },

  async [ac.CORE_BUFFERED_EDITOR_RESET] ({state, commit}, req: BufferedEditorResetRequest): Promise<void> {
    let buffer = state.bufferedEditorState[req.id];

    if (!buffer) {
      buffer = new EditorBuffer(req.id, {}, req.saveActionName ?? '', req.refName ?? '');
      buffer.mergeMode = req.mergeMode ?? 'merge'
      buffer.overrides = req.overrides ?? {};
      commit(mt.CORE_SET_EDITOR_BUFFER, buffer);
    }

    if (buffer.hasUnsavedEdits && !req.force) {
      const confirmed = await popup.confirm(
        this, 'Discard Changes', 'Are you sure you want to discard your changes?');
      
      if (!confirmed) return;
    }

    const refReq = new BufferedEditorChangeValueRequest(buffer.id, true);
    
    commit(mt.CORE_SET_EDITOR_BUFFER_REFRESHING, refReq);

    await Vue.nextTick();

    buffer = EditorBuffer.clone(buffer);
    buffer.reset(req.data);
    buffer.saveActionName = req.saveActionName ?? buffer.saveActionName;
    buffer.refName = req.refName ?? buffer.refName;
    buffer.mergeMode = req.mergeMode ?? buffer.mergeMode
    buffer.overrides = req.overrides ?? buffer.overrides;
    commit(mt.CORE_SET_EDITOR_BUFFER, buffer);

    await Vue.nextTick();

    refReq.value = false;
    commit(mt.CORE_SET_EDITOR_BUFFER_REFRESHING, refReq);

  },

  async [ac.CORE_BUFFERED_EDITOR_SAVE] ({state, dispatch}, id: string): Promise<unknown> {
    const buffer = state.bufferedEditorState[id];

    if (!buffer) return false;

    if (buffer.mergeStatus == 'conflict' || buffer.mergeStatus == 'requiresRefresh') {
      notifications.warn(this, "You must resolve conflicts before saving");
      return false;
    }

    if (buffer.valid) {
      let actionData: unknown;

      if (buffer.refName) {
        actionData = {
          entity: buffer.buffer,
          refName: buffer.refName,
        }
      } else {
        actionData = buffer.buffer;
      }

      try {
        const entity: unknown = await dispatch(buffer.saveActionName, actionData);

        if (entity) {
          preserveTempIds(buffer.buffer as never, entity as never);

          const resetRequest = new BufferedEditorResetRequest(id, entity, true);

          dispatch(ac.CORE_BUFFERED_EDITOR_RESET, resetRequest);

          return entity;
        } else {
          return false;

        }
      } catch (error) {
          const e = error as ApiResponse;
          notifications.fail(this, `Failed to save. Message: ${e.data || e.errorCause || e || "(No Message)"}`);
        return false;
      }
    } else {
      return false;
    }
  },

  async [ac.CORE_FETCH_USERS_FOR_CLIENT] ({getters, commit}, clientCode?: string): Promise<void> {
    
    let clientStr = clientCode;
    if (!clientStr) {
      clientStr = (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    }

    const response = await api.get(`insightclients/${clientStr}/users`, undefined, InsightApplication.AppCenter)

    if (!response) return;

    if (response.error) {
        return Promise.reject(response)
    }

    const users = response.data

    commit(mt.CORE_REPLACE_USER_LIST, users)
  },

  async [ac.CORE_FETCH_ASSIGNMENT_COUNT_FOR_ENTITY] ({commit}, entity: PrivateEntitySpec): Promise<number> {
    const response = await api.get(`${entity.client}/assignments/associatedWith/${entity.id}/count`)

    if (!response) return 0;

    if (response.error) {
      return Promise.reject(response);
    }

    const count = response.data as number;

    commit(mt.CORE_SET_ENTITY_EDITOR_COUNTER, new EntityEditorCounterUpdate(entity.id, 'assignmentCount', count));

    return count;
  },

  async [ac.CORE_FETCH_COMMENT_COUNT_FOR_ENTITY] ({commit}, entity: PrivateEntitySpec): Promise<number> {
    const response = await api.get(`${entity.client}/comments/linkedTo/${entity.id}/count`)

    if (!response) return 0;

    if (response.error) {
      return Promise.reject(response);
    }

    const count = response.data as number;

    commit(mt.CORE_SET_ENTITY_EDITOR_COUNTER, new EntityEditorCounterUpdate(entity.id, 'commentCount', count));

    return count;
  },

  async [ac.CORE_FETCH_FILE_COUNT_FOR_ENTITY] ({commit}, entity: PrivateEntitySpec): Promise<number> {
    const response = await api.get(`${entity.client}/files/linkedTo/${entity.id}/count`)

    if (!response) return 0;

    if (response.error) {
      return Promise.reject(response);
    }

    const count = response.data as number;

    commit(mt.CORE_SET_ENTITY_EDITOR_COUNTER, new EntityEditorCounterUpdate(entity.id, 'fileCount', count));

    return count;
  },

  async [ac.CORE_FETCH_MESSAGE_COUNT_FOR_ENTITY] ({commit}, entity: PrivateEntitySpec): Promise<number> {
    const response = await api.get(`${entity.client}/messages/linkedTo/${entity.id}/count`)

    if (!response) return 0;

    if (response.error) {
      return Promise.reject(response);
    }

    const count = response.data as number;

    commit(mt.CORE_SET_ENTITY_EDITOR_COUNTER, new EntityEditorCounterUpdate(entity.id, 'messageCount', count));

    return count;
  },

  async [ac.CORE_FETCH_TICKET_COUNT_FOR_ENTITY] ({commit}, entity: PrivateEntitySpec): Promise<number> {
    const response = await api.get(`${entity.client}/tickets/linkedTo/${entity.id}/count`)

    if (!response) return 0;

    if (response.error) {
      return Promise.reject(response);
    }

    const count = response.data as number;

    commit(mt.CORE_SET_ENTITY_EDITOR_COUNTER, new EntityEditorCounterUpdate(entity.id, 'ticketCount', count));

    return count;
  },

  async [ac.CORE_FETCH_APP_CONFIG] ({commit}): Promise<void> {
    const response = await api.get(`appConfig`)

    if (!response) return Promise.reject("No resposne from API");

    if (response.error) {
      return Promise.reject(response);
    }

    const cfg = response.data as AppConfig;

    commit(mt.CORE_SET_APP_CONFIG, cfg);
  },

  async [ac.CORE_SWITCH_CLIENT] ({commit}, clientCode: string): Promise<void> {
    try {
      commit(mt.CORE_SET_LOADING_CLIENT, true)
      await loadClientConfiguration(clientCode, this);
    } catch (e) {
      commit(mt.CORE_SET_APP_DATA_LOAD_STATE, 'failed')
    }

    commit(mt.CORE_SET_LOADING_CLIENT, false)
  },

  async [ac.CORE_FETCH_ACTIVE_LIFECYCLE] ({commit, getters}, entityType: EntityType): Promise<void> {
    const client = getters[gt.CORE_CLIENT_CURRENT] as Client;

    try {
      const result = await api.get(`${client.code}/lifecycles/${entityType}/active`);
      if (result) {
        if (result.error == false) {
          commit(mt.CORE_SET_ACTIVE_LIFECYCLE_FOR_TYPE, new LifecycleVuexRequest(
            entityType,
            client.code,
            result.data as Lifecycle
          ))
        } else {
          notifications.fail(this, `There was an error loading ${entityType} lifecycle data`);
        }
      } else {
        notifications.fail(this, `There was an error loading ${entityType} lifecycle data`);
      }
    } catch (e) {
      notifications.fail(this, `There was an error loading ${entityType} lifecycle data`);
    }
  },

  async [ac.CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED] ({dispatch, getters}, entityType: EntityType): Promise<void> {
    const lifecycle = getters[gt.CORE_GET_LIFECYCLE_FOR_TYPE](entityType) as Lifecycle|undefined;

    if (!lifecycle) {
      await dispatch(ac.CORE_FETCH_ACTIVE_LIFECYCLE, entityType);
    }    
  },
}
