
import { requiredIf, Validator } from '../../../util/validators'
import { AbstractField } from './AbstractField';

function setCaretPosition (id: string, caretPos: number): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const el: HTMLInputElement | null = document.getElementById(id) as HTMLInputElement;

  if (el != null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((el as any).createTextRange) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const range = (el as any).createTextRange()
      range.move('character', caretPos)
      range.select()
    } else {
      if (el.selectionStart) {
        el.focus()
        el.setSelectionRange(caretPos, caretPos)
      } else { el.focus() }
    }
  }
}

function getCaretPosition(id: string): number {
  const el: HTMLInputElement | null = document.getElementById(id) as HTMLInputElement;

  if (el != null) {
    return el.selectionStart || 0
  } else {
    return 0
  }
}

function removeExtraDots(str: string, caretPosition: number): string {
  const fIdx = str.indexOf('.')
  const lIdx = str.lastIndexOf('.')

  if (fIdx != lIdx) {
    if (caretPosition > fIdx + 1) {
      return str.substr(0, lIdx) + str.substr(lIdx + 1)
    } else {
      return str.replace(/\.(?=.*?\.)/, '')
    }
  } else {
    return str
  }
}

// This variable coordinates numberFieldIds within an instance.
let ids = 0

export default AbstractField.extend({
  props: {
    decimalPlaces: {
      type: Number,
      default: 2,
    },
    
    prefix: {
      type: String,
      default: '',
    },

    suffix: {
      type: String,
      default: '',
    },

    value: Number,
  },
  
  data() {
    return {
      inputId: 'numberFieldId_' + (ids++),
      internalValue: '',
      rules: [requiredIf(() => this.required, () => this.label)] as Validator[]
    }
  },

  watch: {
    internalValue: {
      handler(): void {
        this.$nextTick(() => {
          // Capture the initial state so we can make some decisions later
          const initialCaretPosition = getCaretPosition(this.inputId)
          const initialValue = this.internalValue

          let cleanValue = initialValue

          // Prevent multiple . characters
          cleanValue = removeExtraDots(cleanValue, initialCaretPosition)

          // Remove non-numeric characters
          cleanValue = cleanValue.replace(/[^0-9.]/, '')

          // Remove leading zeroes unless before a decimal
          cleanValue = cleanValue.replace(/\b0*([1-9][0-9]*|0)\b/, '$1')

          // Prevent entry beyond the cent mark
          const decimalEnforcementRegexp = new RegExp(`(\\.[0-9]{${this.decimalPlaces}})([0-9]+)`)
          cleanValue = cleanValue.replace(decimalEnforcementRegexp, '$1')

          // Save the clean value to the data object.
          this.internalValue = cleanValue

          //Only do the last items if we actually have focus
          const el: HTMLInputElement | null = document.getElementById(this.inputId) as HTMLInputElement;

          if (el) {
            if (el == document.activeElement) {

              // If we removed some characters, fix the caret position.
              if (cleanValue != initialValue) {
                this.$nextTick(() => {
                  const newPosition = initialCaretPosition - (initialValue.length - cleanValue.length)
                  setCaretPosition(this.inputId, newPosition)
                })
              }

              // Emit an input event if the value changed.
              if (this.internalValue != this.value + '') {
                let num = parseFloat(this.internalValue)

                num = isNaN(num) ? 0 : num

                this.$emit('input', num)
              }
            }
          }
        })
      }
    },

    value: {
      handler(): void {
        if (this.value || this.value == 0) {
          const inVal = parseFloat(this.internalValue)

          if (inVal != this.value) {
            this.internalValue = this.value.toFixed(this.decimalPlaces);
          }
        }
      },
      immediate: true,
    }
  },

  methods: {
    handleInput(payload: string): void {
      let num = parseFloat(payload + '')
      num = isNaN(num) ? 0 : num
      this.$emit('input', num)
    },
  }
});
