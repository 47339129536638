import * as gt from '../getterNames'
import { AssignmentTemplate, AssignmentTemplateListModel, EntityType } from '@/models/assignments/AssignmentTemplate'
import Client from '@/models/core/Client'
import { ENTITY_MANAGER_REF } from '../refNames'
import { GetterTree } from 'vuex'
import { LifecycleRecord } from '@/models/lifecycles/LifecycleRecord'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.ASSIGNMENT_TEMPLATES_FOR_CURRENT_CLIENT](state, getters): AssignmentTemplateListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.ASSIGNMENT_TEMPLATES_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): AssignmentTemplateListModel[] => {
      return state.getListModelsForRef(client, refName);
  },
    
  [gt.ASSIGNMENT_TEMPLATES_OPEN_FOR_CURRENT_CLIENT] (state, getters): AssignmentTemplate[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.ASSIGNMENT_TEMPLATES_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): AssignmentTemplate[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.ASSIGNMENT_TEMPLATE_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },



  [gt.ASSIGNMENT_TEMPLATE_GET_LIFECYCLE_FOR_TYPE]: (state, getters) => (entityType: EntityType): LifecycleRecord|undefined => {
    const client = getters[gt.CORE_CLIENT_CURRENT] as Client;

    return state.lifecycleRecords[`${entityType}_${client.code}`];
  },
}