import * as mt from '../mutationNames'
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import Mailbox from '@/models/mailboxes/Mailbox';
import MailboxListModel from '@/models/mailboxes/MailboxListModel';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.MAILBOX_ADD_REFERRER] (state, referrer: VuexEntityReferer<Mailbox>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.MAILBOX_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.MAILBOX_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.MAILBOX_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.MAILBOX_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.MAILBOX_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.MAILBOX_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<Mailbox>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.MAILBOX_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<MailboxListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName);
  },

  //***********************************************************************
  [mt.MAILBOX_UNREAD_UPDATE] (state, {mailboxId, count}) {
    const mailbox = state.listModels.find(x => x.entity.id == mailboxId);

    if (mailbox) {
      Vue.set(mailbox.entity, "unreadCount", count)
    }
  },
}