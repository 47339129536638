
import { handleEvent } from '@/util/FunctionalComponentEventDispatcher'

export default {
  props: {
    actions: {
      type: Array,
      default: (): never[] => []
    },

    loading: Boolean,

    lock: Boolean,

    propsGetter: {
      type: Function,
      default: (): void => {return;}
    },

    value: Boolean,
  },

  methods: {
    handleEvent
  }
}
