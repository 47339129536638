import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';
import {WorkOrderCategory} from '@/models/workOrderCategories/WorkOrderCategory'
import {WorkOrderCategoryListModel} from '@/models/workOrderCategories/WorkOrderCategoryListModel'

export default class State extends VuexModuleStateBase<WorkOrderCategory, WorkOrderCategoryListModel> {
  protected instantiateListModelForEntity(entity: WorkOrderCategory): WorkOrderCategoryListModel {
    const listModel = new WorkOrderCategoryListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'WorkOrderCategory'
  }
}