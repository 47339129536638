import { StateAttributeDef } from "../core/stateFilters/StateAttributeDef";


export function getAttributeDefsForEntityType(type: string): StateAttributeDef[] {
  if (type == "Ticket") {
    return [
      new StateAttributeDef("Closed", "Blame"),
      new StateAttributeDef("Assignments", "Assignment"),
      new StateAttributeDef("Reason Closed", "CloseReason"),
    ];
  } else {
    throw new Error(`${type} attribute definitions are not available.`);
  }
}
