import Vue from 'vue'

export const AbstractField = Vue.extend({
  props: {
    dense: Boolean,
    
    label: {
      type: String,
      default: '',
    },

    readonly: Boolean,

    required: Boolean,
  },

  methods: {
    blur(e: Event): void {
      this.$emit('blur', e);
    },

    focus(e: Event): void {
      this.$emit('focus', e);
    }
  }
});