import { Mention } from "./Mention";

export class CommentFragment {
  public constructor(
    public text: string,
    public mention?: Mention
  ) {}

  public static splitToFragments(text: string, mentions: Mention[]): CommentFragment[] {
    const parts = text.split('@');
    const fragments = [] as CommentFragment[];

    for (let i = 0; i < parts.length; i++) {
      let f = parts[i];
      const mention = CommentFragment.matchMention(f, mentions);

      if (mention) {
        fragments.push(new CommentFragment(mention.user.name, mention));

        f = f.substr(mention.user.name.length);
      } else if (f > '' && i > 0) {
        f = '@' + f;
      }

      if (f > '') {
        fragments.push(new CommentFragment(f))
      }
    }

    return fragments;
  }

  private static matchMention(textFragment: string, mentions: Mention[]): Mention|undefined {
    for (let i = 0; i < mentions.length; i++) {
      const m = mentions[i];
      
      if (textFragment.toLowerCase().startsWith(m.user.name.toLowerCase())) {
        return m;
      }
    }
  }
}

