import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";
import WerkFile from "./WerkFile";
import { WerkFileType } from "./WerkFileType";

export default class WerkFileListModel extends PrivateEntityListModel<WerkFile> {
  public createdAt = "";
  public name = "";
  public type: WerkFileType = WerkFileType.Pdf;
  
  protected populateCore(model: WerkFile): void {
    this.name = model.name;
    this.type = model.type;
    this.createdAt = model.createdAt ?? '';
  }
  
}