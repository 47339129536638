import { AppConfig } from '@/models/core/AppConfig'
import AppNotification from '@/models/core/AppNotification'
import Client from '@/models/core/Client'
import DocumentChooserRequest from '@/models/core/DocumentChooserRequest'
import DocumentCloseRequest from '@/models/core/DocumentCloseRequest'
import { EditorBuffer } from '@/models/core/bufferedEditor/EditorBuffer'
import { EntityEditorState } from '@/models/core/entityEditor/EntityEditorState'
import { EntityManagerState } from '@/models/core/entityManager/EntityManagerState'
import { EntityUpdateListener } from '@/models/core/realtime/EntityUpdateListener'
import { GenericPager } from '@/models/core/entities/EntityPagingData'
import InsightUser from '@/models/core/InsightUser'
import { Lifecycle } from '@/models/lifecycles/Lifecycle'
import PopupRequest from '@/models/core/PopupRequest'
import PrivateEntity from '@/models/core/entities/PrivateEntity'
import { StatusKanbanBoardDef } from '@/models/core/statusKanban/StatusKanbanBoardDef'

export default class RootState {
    appConfig: AppConfig = new AppConfig();

    sideNav = {
      visible: true,
      minified: true
    }

    notifications: AppNotification[] = [];

    popupRequests: PopupRequest[] = [];
    docChooserRequests: DocumentChooserRequest[] = [];
    docCloseRequests: DocumentCloseRequest[] = [];

    appDataLoaded: 'yes'|'no'|'failed' = 'no';
    loadingClientData = false;
    pageTitle = '';
    clients: Client[] = [];
    selectedClientIndex = 0;
    
    loggedIn = false;
    userId = "0";
    users: InsightUser[] = [];
    usersById: Record<string, InsightUser> = {};

    permissionsChangeCount = 0;

    navCount = 0;
    backWontExitApp = false;

    kanbanBoards = [] as StatusKanbanBoardDef[];
    activeLifecycles = {} as Record<string, Lifecycle>;

    entityManagerState: Record<string, EntityManagerState|undefined> = {};
    entityEditorState: Record<string, EntityEditorState|undefined> = {};
    bufferedEditorState: Record<string, EditorBuffer|undefined> = {};

    genericPagers: Record<string, GenericPager|undefined> = {};

    entityUpdateListeners: EntityUpdateListener[] = [];

    addEntityUpdateListener(listener: EntityUpdateListener): void {
      this.removeEntityUpdateListener(listener.name);

      this.entityUpdateListeners.push(listener);
    }

    getBufferedEditorForEntity(entity: PrivateEntity): EditorBuffer|undefined {
      for (const id in this.bufferedEditorState) {
        if (Object.prototype.hasOwnProperty.call(this.bufferedEditorState, id)) {
          const buffer = this.bufferedEditorState[id];
          
          if (buffer) {
            const original = buffer.original as PrivateEntity;

            if (original.id != undefined && (original.id == entity.id || original.id == entity.tempId)) {
              return buffer;
            }
          }
        }
      }

      return undefined;
    }

    removeEntityUpdateListener(name: string): void {
      const idx = this.entityUpdateListeners.findIndex(x => x.name == name);

      if (idx > -1) {
        this.entityUpdateListeners.splice(idx, 1);
      }
    }
}
