import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ActionTree } from "vuex";
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import Customer from '@/models/customers/Customer';
import CustomerListModel from '@/models/customers/CustomerListModel';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.CUSTOMER_DELETE] ({ commit }, data: ApiDeletePayload<Customer>): Promise<void> {
    const url = data.entity.client + '/customers/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.CUSTOMER_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.CUSTOMER_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<CustomerListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/customers`
    return ah.getEntityList(
      url,
      client,
      mt.CUSTOMER_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.CUSTOMER_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<CustomerListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/customers`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.CUSTOMER_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.CUSTOMER_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<Customer | null> {
    const url = request.spec.client + '/customers/' + request.spec.id

    return ah.getPrivateEntity<Customer>(url, mt.CUSTOMER_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.CUSTOMER_SAVE] ({ commit }, data: {entity: Customer; refName: string}): Promise<Customer | null> {
    const url = data.entity.client + '/customers'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.CUSTOMER_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.CUSTOMER_UNDELETE] ({ commit }, entity: Customer): Promise<Customer | null> {
    const url = entity.client + '/customers'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.CUSTOMER_STORE_ENTITY_FOR_REF, commit);
  },
}