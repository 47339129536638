
import { Editor, EditorContent } from '@tiptap/vue-2'
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'
import { AbstractTextField } from '../common/forms/AbstractTextField'
import { CommentFragment } from '@/models/comments/CommentFragment'
import Document from '@tiptap/extension-document'
import InsightUser from '@/models/core/InsightUser'
import { Mention } from '@/models/comments/Mention'
import Paragraph from '@tiptap/extension-paragraph'
import RootState from '@/store/RootState'
import Text from '@tiptap/extension-text'
import TiptapMention from '@tiptap/extension-mention'

const OneLineDoc = Document.extend({content: 'paragraph'})

export default AbstractTextField.extend({
  components: {
    EditorContent,
  },

  data() {
    return {
      editor: null as Editor|null,
      mentionMenuCommand: null as ((props: unknown) => void)|null,
      mentionMenuIndex: 0,
      mentionMenuItems: [] as string[],
      mentionMenuX: 0,
      mentionMenuY: 0,
      plainContent: '',
      showMentionMenu: false,
    }
  },

  computed: {
    possibleMentions(): Mention[] {
      return this.users.map(u => {return {user: u, unread: false } as Mention});
    },

    userList(): string[] {
      return this.users.map(u => u.name);
    },

    users(): InsightUser[] {
      return (this.$store.state as RootState).users;
    },
  },

  watch: {
    value(): void {
      if (this.editor) {
        if (this.plainContent != this.value) {
          this.editor.commands.setContent(this.toHtml(this.value), false, {preserveWhitespace: "full"});
        }
      }
    },

    mentionMenuItems(): void {
      this.mentionMenuIndex = 0
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        OneLineDoc,
        Paragraph,
        Text,
        TiptapMention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            items: (args): string[] => {
              return this.userList.filter(item => item.toLowerCase().startsWith(args.query.toLowerCase())).slice(0, 5)
            },
            render: (): Record<string, unknown> => {

              return {
                onStart: (props: SuggestionProps): void => {
                  this.setMentionMenuProps(props);
                  this.$nextTick(() => {
                    this.mentionMenuIndex = 1;
                    this.$nextTick(() => {
                      this.mentionMenuIndex = 0;
                    })
                  });

                  this.showMentionMenu = true;
                },

                onUpdate: (props: SuggestionProps): void => {
                  this.setMentionMenuProps(props);
                },

                onKeyDown: (props: SuggestionKeyDownProps): boolean => {
                  return this.onKeyDown(props.event)
                },

                onExit: (): void => {
                  this.showMentionMenu = false;

                  this.$nextTick(() => {
                    this.mentionMenuCommand = null;
                    this.mentionMenuItems = [];
                    this.mentionMenuX = 0;
                    this.mentionMenuY = 0;
                  });
                },
              }
            },
          },
        }),
      ],
      content: this.value,
      onUpdate: this.handleContentChange,
    })
  },

  beforeDestroy() {
    this.editor?.destroy()
  },

  

  methods: {
    handleContentChange(): void {
      if (this.editor) {
        const html = this.editor.getHTML();
        this.plainContent = this.toPlainText(html);
        this.$emit('input', this.plainContent);
      }
    },

    handleMentionSelected(name: string): void {
      if (this.mentionMenuCommand) {
        this.mentionMenuCommand({id: name});
      }
    },

    mentionMenuDownHandler(): void {
      this.mentionMenuIndex = (this.mentionMenuIndex + 1) % this.mentionMenuItems.length
    },

    mentionMenuEnterHandler(): void {
      this.mentionMenuSelectItem(this.mentionMenuIndex)
    },

    mentionMenuSelectItem(index: number): void {
      const item = this.mentionMenuItems[index]

      if (item) {
        this.handleMentionSelected(item);
      }
    },

    mentionMenuUpHandler(): void {
      this.mentionMenuIndex = ((this.mentionMenuIndex + this.mentionMenuItems.length) - 1) % this.mentionMenuItems.length
    },

    onKeyDown(event: KeyboardEvent): boolean {
      if (event.key === 'ArrowUp') {
        this.mentionMenuUpHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.mentionMenuDownHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.mentionMenuEnterHandler()
        return true
      }

      return false
    },

    setMentionMenuProps(props: SuggestionProps): void {
      this.mentionMenuCommand = props.command;
      this.mentionMenuItems = props.items;

      if (props.clientRect) {
        const rect = props.clientRect();
        this.mentionMenuX = rect?.x ?? 0;
        this.mentionMenuY = rect?.y ?? 0;
      }
    },

    toPlainText(html: string): string {
      let plaintext = html;

      if (plaintext.toLowerCase().startsWith('<p>')) {
        plaintext = plaintext.substr(3);
      }

      if (plaintext.substr(-4).toLowerCase() == '</p>') {
        plaintext = plaintext.substr(0, plaintext.length - 4);
      }

      const mentionExpression = /<span (?:class="mention"|data-mention="[\w ]*"|[\w-_="{}/|?.,()*&^%$#@!';:[\] ]*)+>(@[\w ]*)<\/span>/g

      plaintext = plaintext.replaceAll(mentionExpression, '$1');

      return plaintext;
    },

    toHtml(plaintext: string): string {
      let html = '<p>';

      const fragments = CommentFragment.splitToFragments(plaintext, this.possibleMentions);

      fragments.forEach(f => {
        if (f.mention) {
          html += `<span class="mention" data-mention="${f.mention.user.name}">@${f.mention.user.name}</span>`;
        } else {
          html += f.text;
        }
      });

      html += '</p>';

      return html;
    },
  }
})
