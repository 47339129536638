import { StateFilterCommand } from "./StateFilters";

export type StateAttributeType = "Blame"|"Assignment"|"CloseReason"

export class StateAttributeDef {
  constructor(
    name: string,
    type: StateAttributeType,
  ) {
    this._name = name;
    this._type = type;
  }

  private _name: string;
  private _type: StateAttributeType;

  public get name(): string {
    return this._name;
  }

  public get type(): StateAttributeType {
    return this._type;
  }
}

export interface CommandMenuItem {
  name: string;
  value: StateFilterCommand;
  type: StateAttributeType;
  requiresValue: boolean;
}