
import { isDarkColor } from "../../util/colorUtils";
import { LifecycleHash } from "../../models/lifecycles/LifecycleHash";
import { PropValidator } from 'vue/types/options';

export default {
  props: {
    lifecycleHash: {
      type: Object,
      default: null,
      required: true,
    } as PropValidator<LifecycleHash>,

    small: Boolean,

    value: {
      type: String,
      default: '',
    },
  },

  methods: {
    getChipColor(stage: string, hash: LifecycleHash): string {
      return hash.stages[stage]
    },

    getTextColor(stage: string, hash: LifecycleHash): string {
      return isDarkColor(hash.stages[stage]) ? "#FFFFFF" : "#000000";
    },
  }
}
