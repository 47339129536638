
import { MessageContactState, MessageContactType } from '@/models/messages/MessageEnums'
import IconButton from '../common/IconButton.vue'
import { MailboxType } from '@/models/mailboxes/MailboxType'
import MarkAsReadParams from '@/models/messages/MarkAsReadParams'
import Message from '@/models/messages/Message'
import { MESSAGE_MARK_AS_READ } from '@/store/actionNames'
import MessageAttachments from './MessageAttachments.vue'
import MessageContact from '@/models/messages/MessageContact'
import MessageHeaderRow from './MessageHeaderRow.vue'
import MessageViewerContactRow from './MessageViewerContactRow.vue'
import { PropValidator } from 'vue/types/options'
import reformatDate from '@/util/reformatDate'
import ScrollBox from '../common/ScrollBox.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    IconButton,
    MessageAttachments,
    MessageHeaderRow,
    MessageViewerContactRow,
    ScrollBox,
  },

  beforeRouteLeave (to, from, next) {
    this.clearPendingMessageRead();
    next();
  },

  props: {
    height: {
      type: Number,
      default: 0,
    },

    mailboxType: {
      type: Number,
      default: MailboxType.Inbox,
    } as PropValidator<MailboxType>,

    message: {
      type: Object,
      default: (): null => null,
      required: true,
    } as PropValidator<Message>,

    mode: {
      type: String,
      default: 'mailbox',
    } as PropValidator<'mailbox'|'entity'|'widget'>,

    pinnable: {
      type: Boolean,
      default: false,
    },
    
    viewedBy: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      bodyHeight: 100,
      readTimeoutHandle: null as NodeJS.Timeout|null,
    }
  },

  computed: {
    bcc(): MessageContact[] {
      return this.message.contacts.filter(x => x.type == MessageContactType.Bcc);
    },

    cc(): MessageContact[] {
      return this.message.contacts.filter(x => x.type == MessageContactType.Cc);
    },
    
    dateLabel(): string {
      if (this.mailboxType == MailboxType.Draft) {
        return "Created"
      } else if (this.mailboxType == MailboxType.Sent) {
        return "Sent"
      } else {
        return "Received"
      }
    },

    formattedDate(): string {
      return reformatDate(this.message.messageDate, "mm/dd/yyyy h:MM tt")
    },

    from(): MessageContact[] {
      return this.message.contacts.filter(x => x.type == MessageContactType.From);
    },
    
    hasSubject(): boolean {
      return this.message.subject > '';
    },

    isDraft(): boolean {
      return this.mailboxType == MailboxType.Draft;
    },

    isSent(): boolean {
      return this.mailboxType == MailboxType.Sent;
    },

    showFooter(): boolean {
      return this.message.attachments.length > 0;
    },

    to(): MessageContact[] {
      return this.message.contacts.filter(x => x.type == MessageContactType.To);
    },
  },

  watch: {
    height: {
      handler(): void {
        this.calcBodyHeight();
      },
    },

    message: {
      handler(): void {
        this.$nextTick(() => {
          this.calcBodyHeight();
        })

        this.dispatchMessageRead();
      },
      immediate: true
    }
  },

  methods: {
    calcBodyHeight(): void {
      this.$nextTick(() => {
        const el = this.$el;
        const header = el.querySelector(".messagePreviewHeader");
        const footer = el.querySelector(".messagePreviewFooter");

        const headerHeight = header ? header.clientHeight : 0;
        const footerHeight = footer ? footer.clientHeight : 0;

        this.bodyHeight = this.height - headerHeight - footerHeight;
      })
    },

    clearPendingMessageRead(): void {
      if (this.readTimeoutHandle != null) {
        clearTimeout(this.readTimeoutHandle);
        this.readTimeoutHandle = null;
      }
    },

    dispatchMessageRead(): void {
      this.clearPendingMessageRead();

      const viewerContact = this.message.contacts.find(x => x.address == this.viewedBy);

      if (viewerContact?.state == MessageContactState.Delivered) {

        this.readTimeoutHandle = setTimeout(() => {
          this.$store.dispatch(MESSAGE_MARK_AS_READ, new MarkAsReadParams(this.message, this.viewedBy))
          this.readTimeoutHandle = null;
        }, 3000)
      }
    },

    requestDelete(): void {
      this.$emit('request-delete', true);
    },

    requestForward(): void {
      this.$emit('request-forward', true);
    },

    requestMove(): void {
      this.$emit('request-move', true);
    },

    requestPin(): void {
      this.$emit('request-pin', true);
    },

    requestReply(mode: 'single'|'all'): void {
      this.$emit('request-reply', mode);
    },

    requestUnlink(): void {
      this.$emit('request-unlink', true);
    },
  },
})
