import * as mt from './mutationNames'
import { EntityEditorChangeValueRequest, EntityEditorCounterUpdate } from '@/models/core/entityEditor/EntityEditorChangeValueRequest'
import { EntityPagingData, GenericPager } from '@/models/core/entities/EntityPagingData'
import { getBackgroundColorForUser, getForegroundColorForUser, getInitialsForUser } from '@/util/userUtils'
import { AppConfig } from '@/models/core/AppConfig'
import AppNotification from '@/models/core/AppNotification'
import { BufferedEditorChangeValueRequest } from '@/models/core/bufferedEditor/BufferedEditorChangeValueRequest'
import { BufferedEditorConflictResolverData } from '@/models/core/bufferedEditor/BufferedEditorConflictResolverData'
import { BufferedEditorEdit } from '@/models/core/bufferedEditor/BufferedEditorEdit'
import { BufferedEditorResolveConflictRequest } from '@/models/core/bufferedEditor/BufferedEditorResolveConflictRequest'
import Client from '@/models/core/Client'
import { DependentReferrer } from '@/models/core/entityEditor/DependentReferrer'
import DocumentChooserRequest from '@/models/core/DocumentChooserRequest'
import DocumentCloseRequest from '@/models/core/DocumentCloseRequest'
import { EditorBuffer } from '@/models/core/bufferedEditor/EditorBuffer'
import { EntityEditorAddDependentBufferRequest } from '@/models/core/entityEditor/EntityEditorAddDependentBufferRequest'
import { EntityEditorState } from '@/models/core/entityEditor/EntityEditorState'
import { EntityManagerState } from '@/models/core/entityManager/EntityManagerState'
import { EntityManagerStateUpdate } from '@/models/core/entityManager/EntityManagerStateUpdate'
import { EntityUpdateMessage } from '@/models/core/realtime/EntityUpdateMessage'
import InsightUser from '@/models/core/InsightUser'
import { LifecycleVuexRequest } from '@/models/lifecycles/Lifecycle'
import { LinkCountUpdateMessage } from '@/models/core/realtime/LinkCountUpdateMessage'
import { MutationTree } from 'vuex'
import { PackedJson } from '@/util/packedJson'
import PopupRequest from '@/models/core/PopupRequest'
import PrivateEntity from '@/models/core/entities/PrivateEntity'
import RootState from './RootState'
import { StatusKanbanBoardDef } from '@/models/core/statusKanban/StatusKanbanBoardDef'
import { StatusKanbanBoardFilterChangeRequest } from '@/models/core/statusKanban/StatusKanbanBoardFilterChangeRequest'
import { StatusKanbanCardLoadingRequest } from '@/models/core/statusKanban/StatusKanbanCardLoadingRequest'
import Vue from 'vue'

export const mutations: MutationTree<RootState> = {
  [mt.CORE_SET_APP_DATA_LOAD_STATE] (state, loadState: 'yes'|'no'|'failed') {
    if (loadState != 'yes' && loadState != 'no' && loadState != 'failed') {
      throw new Error('Invalid load state argument: ' + loadState)
    }

    state.appDataLoaded = loadState
  },

  [mt.CORE_TOGGLE_SIDENAV_MINI] (state) {
    state.sideNav.minified = !state.sideNav.minified
  },

  [mt.CORE_ADD_NOTIFICATION] (state, newNotification: AppNotification) {
    if (newNotification.text) {
      state.notifications.push(newNotification)
    } else {
      throw new Error('Notification text cannot be blank')
    }
  },

  [mt.CORE_REMOVE_NOTIFICATION] (state, index: number) {
    state.notifications.splice(index, 1)
  },

  [mt.CORE_ADD_POPUP_REQUEST] (state, newRequest: PopupRequest) {
    state.popupRequests.push(newRequest);
  },

  [mt.CORE_REMOVE_POPUP_REQUEST] (state, index: number) {
    state.popupRequests.splice(index, 1)
  },

  [mt.CORE_ADD_DOC_LINK_REQUEST] (state, newRequest: DocumentChooserRequest) {
    state.docChooserRequests.push(newRequest);
  },

  [mt.CORE_REMOVE_DOC_LINK_REQUEST] (state, index: number) {
    state.docChooserRequests.splice(index, 1)
  },

  [mt.CORE_ADD_DOC_CLOSE_REQUEST] (state, newRequest: DocumentCloseRequest) {
    state.docCloseRequests.push(newRequest);
  },

  [mt.CORE_REMOVE_DOC_CLOSE_REQUEST] (state, index: number) {
    state.docCloseRequests.splice(index, 1)
  },

  [mt.CORE_SET_PAGE_TITLE] (state, newTitle) {
    state.pageTitle = newTitle
  },

  [mt.CORE_CLIENT_REPLACE_ALL] (state, clients: Client[]) {
    state.clients = clients
  },

  [mt.CORE_CLIENT_SET_CURRENT] (state, newIndex: number) {
    if (state.clients.length > newIndex && newIndex >= 0) {
      state.selectedClientIndex = newIndex
    } else {
      throw new Error('Invalid client index!')
    }
  },

  [mt.CORE_USER_ID_SET] (state, id: string) {
    state.userId = id;
    state.loggedIn = true;
  },

  [mt.CORE_CREATE_ENTITY_MANAGER_STATE] (state, entityManagerState: EntityManagerState) {
    Vue.set(
      state.entityManagerState,
      `${entityManagerState.entityType}_${entityManagerState.refName}_${entityManagerState.client}`,
      entityManagerState
    );
  },

  [mt.CORE_SET_ENTITY_MANAGER_STATE] (state, update: EntityManagerStateUpdate) {
    const key = `${update.entityType}_${update.refName}_${update.client}`
    const entityManagerState = state.entityManagerState[key];

    if (entityManagerState) {
      entityManagerState[update.propertyName] = update.value;
    }
  },

  [mt.CORE_ENTITY_MANAGER_PAGE_DATA_SET] (state, pageData: EntityPagingData) {
    const entityManagerData = state.entityManagerState[pageData.pagerId];

    if (entityManagerData) {
      entityManagerData.updateServerSidePagingData(pageData);
    }
  },

  [mt.CORE_CLEAR_ENTITY_MANAGER_STATE] (state, req: {entityType: string, client: string}) {
    state.entityManagerState[`${req.entityType}_${req.client}`] = undefined;
  },

  [mt.CORE_SET_ENTITY_EDITOR_STATE] (state, entityEditorState: EntityEditorState) {
    Vue.set(state.entityEditorState, entityEditorState.entityId, entityEditorState);
  },

  [mt.CORE_ADD_ENTITY_EDITOR_DEPENDENT_BUFFER] (state, req: EntityEditorAddDependentBufferRequest) {
    const editor = state.entityEditorState[req.entityId];

    if (editor) {
      editor.addDependentBuffer(req.bufferId);
    }
  },

  [mt.CORE_ADD_ENTITY_EDITOR_DEPENDENT_REFERRER] (state, req: EntityEditorChangeValueRequest<DependentReferrer>) {
    const editor = state.entityEditorState[req.entityId];

    if (editor) {
      editor.addDependentReferrer(req.value);
    }
  },

  [mt.CORE_CLEAR_ENTITY_EDITOR_STATE] (state, entityId: string) {
    state.entityEditorState[entityId] = undefined;
  },
  
  [mt.CORE_SET_ENTITY_EDITOR_COUNTER] (state, req: EntityEditorCounterUpdate) {
    const editor = state.entityEditorState[req.entityId];

    if (editor) {
      Vue.set(editor.counters, req.counterName, req.value);
    }
  },

  [mt.CORE_SET_ENTITY_EDITOR_ADDITIONAL_DATA] (state, req: EntityEditorChangeValueRequest<unknown>) {
    const editor = state.entityEditorState[req.entityId];

    if (editor) {
      editor.additionalData = req.value;
    }
  },

  [mt.CORE_PROCESS_ENTITY_UPDATE] (state, msg: EntityUpdateMessage) {
    state.entityUpdateListeners.forEach(l => {
      l.process(msg);
    });

    let editorBuffer: EditorBuffer|undefined = undefined;
    let entity: unknown;

    const entityEditor = state.entityEditorState[msg.EntityId];

    if (entityEditor) {
      editorBuffer = state.bufferedEditorState[entityEditor.editorBufferId];
    } else if (msg.EntityType == "Message") {
      entity = msg.localMessage ? JSON.parse(msg.CompressedData) : PackedJson.unpack(msg.CompressedData);

      editorBuffer = state.getBufferedEditorForEntity(entity as PrivateEntity)
    }

    if (editorBuffer) {
      if (!entity) entity = msg.localMessage ? JSON.parse(msg.CompressedData) : PackedJson.unpack(msg.CompressedData);
      editorBuffer.processExternalUpdate(entity);
    }
  },

  [mt.CORE_PROCESS_LINK_COUNT_UPDATE] (state, msg: LinkCountUpdateMessage) {
    let fieldName: 'commentCount'|'fileCount'|'messageCount'|'assignmentCount' = 'commentCount';

    if (msg.LinkedEntityType == 'File') {
      fieldName = 'fileCount';
    } else if (msg.LinkedEntityType == 'Message') {
      fieldName = 'messageCount';
    } else if (msg.LinkedEntityType == 'Assignment') {
      fieldName = 'assignmentCount';
    }

    msg.AffectedEntities.forEach(e => {
      const entityEditor = state.entityEditorState[e.Id];
      
      if (entityEditor) {
        Vue.set(entityEditor.counters, fieldName, e.Count);
      }
    });
  },

  [mt.CORE_SET_EDITOR_BUFFER] (state, buffer: EditorBuffer) {
    Vue.set(state.bufferedEditorState, buffer.id, buffer);
  },

  [mt.CORE_SET_EDITOR_BUFFER_HAS_UNSAVED] (state, req: BufferedEditorChangeValueRequest<boolean>) {
    const buffer = state.bufferedEditorState[req.id];

    if (buffer) {
      buffer.hasUnsavedEdits = req.value;
    }
  },

  [mt.CORE_SET_EDITOR_BUFFER_REFRESHING] (state, req: BufferedEditorChangeValueRequest<boolean>) {
    const buffer = state.bufferedEditorState[req.id];

    if (buffer) {
      buffer.refreshingBuffer = req.value;
    }
  },

  [mt.CORE_BUFFERED_EDITOR_APPLY_EDITS] (state, req: BufferedEditorChangeValueRequest<BufferedEditorEdit[]>) {
    const buffer = state.bufferedEditorState[req.id];

    if (buffer) {
      buffer.applyEdits(buffer.buffer as Record<string, unknown>, req.value)
    }
  },

  [mt.CORE_SET_EDITOR_BUFFER_VALID] (state, req: BufferedEditorChangeValueRequest<boolean>) {
    const buffer = state.bufferedEditorState[req.id];

    if (buffer) {
      buffer.valid = req.value;
    }
  },

  [mt.CORE_SET_EDITOR_BUFFER_SAVE_ACTION_NAME] (state, req: BufferedEditorChangeValueRequest<string>) {
    const buffer = state.bufferedEditorState[req.id];

    if (buffer) {
      buffer.saveActionName = req.value;
    }
  },

  [mt.CORE_CLEAR_EDITOR_BUFFER] (state, id: string) {
    state.bufferedEditorState[id] = undefined;
  },

  [mt.CORE_RESOLVE_EDITOR_CONFLICT] (state, req: BufferedEditorResolveConflictRequest) {
    const buffer = state.bufferedEditorState[req.id];

    if (buffer) {
      buffer.resolveConflict(req.mode, req.path, req.resolveEntireParent, req.resolveWith);
    }
  },

  [mt.CORE_DISMISS_EDITOR_MERGE_STATUS] (state, id: string) {
    const buffer = state.bufferedEditorState[id];

    if (buffer) {
      buffer.dismissMergedStatus();
    }
  },

  [mt.CORE_OPEN_CONFLICT_RESOLVER] (state, req: BufferedEditorChangeValueRequest<BufferedEditorConflictResolverData>) {
    const buffer = state.bufferedEditorState[req.id];

    if (buffer) {
      buffer.conflictResolverData = req.value;
    }
  },

  [mt.CORE_REPLACE_USER_LIST] (state, users: InsightUser[]) {
    if (users) {
      const usersById: Record<string, InsightUser> = {};

      for (let i = 0; i < users.length; i++) {
        users[i].backgroundColor = getBackgroundColorForUser(users[i]);
        users[i].textColor = getForegroundColorForUser(users[i]);
        users[i].initials = getInitialsForUser(users[i]);

        usersById[users[i].id] = users[i];
      }

      state.users = users;
      state.usersById = usersById;
    } else {
      state.users = [];
      state.usersById = {};
    }
  },

  [mt.CORE_SET_APP_CONFIG] (state, cfg: AppConfig) {
    if (cfg) {
      state.appConfig = cfg;
    } else {
      state.appConfig = new AppConfig();
    }
  },

  [mt.CORE_STATUS_KANBAN_ADD] (state, board: StatusKanbanBoardDef) {
    if (board) {
      const idx = state.kanbanBoards.findIndex(x => x.id == board.id && x.client == board.client);

      if (idx > -1) {
        state.kanbanBoards[idx] = board;
      } else {
        state.kanbanBoards.push(board);
      }
    }
  },

  [mt.CORE_STATUS_KANBAN_REMOVE] (state, board: StatusKanbanBoardDef) {
    if (board) {
      const idx = state.kanbanBoards.findIndex(x => x.id == board.id && x.client == board.client);

      if (idx > -1) {
        state.kanbanBoards.splice(idx, 1);
      }
    }
  },

  [mt.CORE_STATUS_KANBAN_CHANGE_FILTER] (state, req: StatusKanbanBoardFilterChangeRequest) {
    if (req) {
      const board = state.kanbanBoards.find(x => x.id == req.boardId && x.client == req.client);

      if (board) {
        const lane = board.lanes.find(x => x.id == req.laneId);

        if (lane) {
          const status = lane.statuses.find(x => x.id == req.statusId);

          if (status) {
            status.show = req.filterValue;
          }
        }
      }
    }
  },

  [mt.CORE_STATUS_KANBAN_SET_CARD_LOADING] (state, req: StatusKanbanCardLoadingRequest) {
    if (req) {
      const board = state.kanbanBoards.find(x => x.id == req.boardId && x.client == req.client);

      if (board) {
        Vue.set(board.cardLoadingState, req.cardId, req.loading)
      }
    }
  },

  [mt.CORE_STATUS_KANBAN_UPDATE_PAGING_DATA] (state, req: EntityPagingData) {
    if (req) {
      const parts = req.pagerId.split('.');
      const board = state.kanbanBoards.find(x => x.id == parts[1] && x.client == parts[0]);

      if (board) {
        board.overflowCount = Math.max(req.entityCount - req.pageSize, 0);
      }
    }
  },

  [mt.CORE_INCREMENT_PERMISSIONS_CHANGE_COUNT] (state) {
    state.permissionsChangeCount++;
  },

  [mt.CORE_INCREMENT_NAV_COUNT] (state) {
    state.navCount++;

    if (state.navCount > 1) {
      state.backWontExitApp = true;
    }
  },

  [mt.CORE_SET_LOADING_CLIENT] (state, value: boolean) {
    state.loadingClientData = value;
  },

  [mt.CORE_SET_ACTIVE_LIFECYCLE_FOR_TYPE] (state, req: LifecycleVuexRequest) {
    Vue.set(state.activeLifecycles, `${req.entityType}_${req.clientCode}`, req.lifecycle);
  },

  [mt.CORE_GENERIC_PAGER_CREATE] (state, pager: GenericPager) {
    const currentClient = state.clients[state.selectedClientIndex];

    Vue.set(state.genericPagers, `${pager.name}_${currentClient.code}`, pager);
  },

  [mt.CORE_GENERIC_PAGER_DESTROY] (state, name: string) {
    const currentClient = state.clients[state.selectedClientIndex];

    Vue.set(state.genericPagers, `${name}_${currentClient.code}`, undefined);
  },

  [mt.CORE_GENERIC_PAGER_PAGE_DATA_SET] (state, pageData: EntityPagingData) {
    const currentClient = state.clients[state.selectedClientIndex];

    const pager = state.genericPagers[`${pageData.pagerId}_${currentClient.code}`];

    if (pager) {
      pager.serverPageSize = pageData.pageSize;
      pager.serverPageNumber = pageData.pageNumber;
      pager.serverSideEntityCount = pageData.entityCount;
    }
  },
}
