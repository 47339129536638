import { EntityUpdateMessage } from "../realtime/EntityUpdateMessage";
import { PackedJson } from "@/util/packedJson";
import PrivateEntity from "../entities/PrivateEntity";
import PrivateEntityListModel from "../entities/PrivateEntityListModel";
import PrivateEntitySpec from "../entities/PrivateEntitySpec";
import VuexEntityReference from "./VuexEntityReference";
import VuexEntityReferer from "./VuexEntityReferer";
import { VuexEntityStorageRequestMode } from "./VuexEntityStorageRequestMode";

export default abstract class VuexModuleStateBase<E extends PrivateEntity, L extends PrivateEntityListModel<E>> {
  private _referers: VuexEntityReferer<E>[] = [];

  listModels: VuexEntityReference<L>[] = [];
  entities: VuexEntityReference<E>[] = [];

  public addReferrer(newReferer: VuexEntityReferer<E>): void {
    const idx = this._referers.findIndex(x => x.client == newReferer.client && x.name == newReferer.name);

    if (idx > -1) {
      if (this._referers[idx].useCounter) {
        this._referers[idx].counter++;
      } else {
        this._referers[idx] = newReferer;
      }
    } else {
      this._referers.push(newReferer)
    }
  }

  public dropEntityAndListModelFromRef(entity: PrivateEntitySpec, refName: string): void {
    this.dropEntityFromRef(entity, refName);
    this.dropListModelFromRef(entity, refName);
  }

  public dropEntityFromRef(entity: PrivateEntitySpec, refName: string): void {
    const instanceIdx = this.entities.findIndex(x => x.entity.id == entity.id && x.entity.client == entity.client);

    if (instanceIdx > -1) {
      const fullRefName = this.fullRefName(entity.client, refName);
      const instance = this.entities[instanceIdx];

      instance.removeRef(fullRefName);

      if (instance.refCount < 1) {
        this.entities.splice(instanceIdx, 1);
      }
    }
  }

  public dropListModelFromRef(model: PrivateEntitySpec, refName: string): void {
    const instanceIdx = this.listModels.findIndex(x => x.entity.id == model.id && x.entity.client == model.client);

    if (instanceIdx > -1) {
      const fullRefName = this.fullRefName(model.client, refName);
      const instance = this.listModels[instanceIdx];

      instance.removeRef(fullRefName);

      if (instance.refCount < 1) {
        this.listModels.splice(instanceIdx, 1);
      }
    }
  }

  public dropReferer(client: string, name: string): void {
    const idx = this._referers.findIndex(x => x.client == client && x.name == name);
    let dropModels = true;

    if (idx > -1) {
      if (this._referers[idx].useCounter) {
        this._referers[idx].counter--;
      }

      if (this._referers[idx].counter == 0) {
        this._referers.splice(idx, 1);
      } else {
        dropModels = false;
      }
    }

    if (dropModels) {
      this.dropRefNameForEntities(client, name);
      this.dropRefNameForListModels(client, name);
      this.purgeOrphans();
    }
  }

  public dropRefNameForEntities(client: string, refName: string, purgeOrphans = false): void {
    const fullRefName = this.fullRefName(client, refName);

    this.entities.forEach(entityRef => {
      entityRef.removeRef(fullRefName);
    });

    if (purgeOrphans) {
      this.purgeOrphans();
    }
  }

  public dropRefNameForListModels(client: string, refName: string, purgeOrphans = false): void {
    const fullRefName = this.fullRefName(client, refName);

    this.listModels.forEach(modelRef => {
      modelRef.removeRef(fullRefName);
    });

    if (purgeOrphans) {
      this.purgeOrphans();
    }
  }

  public getEntitiesForRef(client: string, refName: string): E[] {
    return this.entities.filter(x => x.refs[this.fullRefName(client, refName)]).map(x => x.entity);
  }

  public getListModelsForRef(client: string, refName: string): L[] {
    return this.listModels.filter(x => x.refs[this.fullRefName(client, refName)]).map(x => x.entity);
  }

  private fullRefName(client: string, name: string): string {
    return `${client}_${name}`;
  }

  public hasReferrer(client: string, name: string): boolean {
    return this._referers.findIndex(x => x.client == client && x.name == name) >= 0;
  }

  public processEntityUpdate(msg: EntityUpdateMessage): void {
    const type = this.getEntityTypeName();

    if (msg.EntityType != type) return;

    const entity = (msg.localMessage ? JSON.parse(msg.CompressedData) : PackedJson.unpack(msg.CompressedData)) as E;


    const entityRef = this.entities.find(x => x.entity.id == msg.EntityId);
    let listModelRef = this.listModels.find(x => x.entity.id == msg.EntityId);

    if (entityRef) {
      entityRef.entity = entity;
      this.updateRefsForEntity(entityRef);
    }

    const listModel = this.instantiateListModelForEntity(entity);

    if (listModelRef) {
      listModelRef.entity = listModel
    } else {
      listModelRef = new VuexEntityReference(listModel);
      this.listModels.push(listModelRef);
    }

    this.updateRefsForListModel(listModelRef, entity);

    this.purgeOrphans();
  }

  public purgeEntity(entity: PrivateEntitySpec): void {
    const entityIdx = this.entities.findIndex(x => x.entity.id == entity.id && x.entity.client == entity.client);

    if (entityIdx > -1) {
      this.entities.splice(entityIdx, 1);
    }

    const modelIdx = this.listModels.findIndex(x => x.entity.id == entity.id && x.entity.client == entity.client);

    if (modelIdx > -1) {
      this.listModels.splice(modelIdx, 1);
    }
  }

  private purgeOrphans(): void {
    for (let i = 0; i < this.entities.length; i++) {
      if (this.entities[i].refCount == 0) {
        this.entities.splice(i, 1);
        i--;
      }
    }

    for (let i = 0; i < this.listModels.length; i++) {
      if (this.listModels[i].refCount == 0) {
        this.listModels.splice(i, 1);
        i--;
      }
    }
  }

  public storeEntities(entities: E[], client: string, refName: string, incompleteList = false): void {
    if (!incompleteList) {
      this.dropRefNameForListModels(client, refName);
    }

    entities.forEach(entity => {
      this.storeEntity(entity, refName);
    });

    this.purgeOrphans();
  }

  public storeEntity(
    entity: E,
    refName: string,
    mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add
  ): void {
    const fullRefName = this.fullRefName(entity.client, refName);
    let existing: VuexEntityReference<E> | undefined;

    if (entity.tempId) {
      existing = this.entities.find(x => x.entity.id == entity.tempId);
    }

    if (!existing) {
      existing = this.entities.find(x => x.entity.id == entity.id);
    }

    if (existing) {
      existing.entity = entity;

      if (mode != 'updateOnly' && mode != 'listModelOnly') {
        existing.addRef(fullRefName);
      }

      this.updateRefsForEntity(existing);

    } else if (mode != 'updateOnly' && mode != 'listModelOnly') {
      const newRefObj = new VuexEntityReference<E>(entity, fullRefName);
      this.entities.push(newRefObj);

      this.updateRefsForEntity(newRefObj);
    }

    const newListModel = this.instantiateListModelForEntity(entity);
    const listModelRef = this.listModels.find(x => x.entity.id == newListModel.id);

    if (listModelRef) {
      listModelRef.entity = newListModel;

      if (mode == 'add' || mode == 'listModelOnly') {
        listModelRef.addRef(fullRefName);
      }

      this.updateRefsForListModel(listModelRef, entity);

    } else if (mode == 'add' || mode == 'listModelOnly') {
      const newListModelRef = new VuexEntityReference<L>(newListModel, fullRefName);
      this.listModels.push(newListModelRef);
      this.updateRefsForListModel(newListModelRef, entity);
    }
  }

  public storeListModels(models: L[], client: string, refName: string, incompleteList = false): void {
    if (!incompleteList) {
      this.dropRefNameForListModels(client, refName);
    }

    models.forEach(model => {
      const existing = this.listModels.find(x => x.entity.id == model.id);
      const fullRefName = this.fullRefName(model.client, refName);

      if (existing) {
        existing.entity = model;
        existing.addRef(fullRefName);
      } else {
        this.listModels.push(new VuexEntityReference<L>(model, fullRefName));
      }
    });

    this.purgeOrphans();
  }

  private updateRefsForEntity(entityRef: VuexEntityReference<E>): void {
    this._referers.forEach(r => {
      if (r.client != entityRef.entity.client) return;

      const fullRefName = this.fullRefName(r.client, r.name);

      const claimResult = r.claim(entityRef.entity, 'entity', this);

      if (claimResult == 'yes') {
        entityRef.addRef(fullRefName)
      } else if(claimResult == 'no') {
        entityRef.removeRef(fullRefName)
      }
    })
  }

  protected updateRefsForListModel(listModelRef: VuexEntityReference<L>, entity: E): void {
    this._referers.forEach(r => {
      if (r.client != listModelRef.entity.client) return;

      const fullRefName = this.fullRefName(r.client, r.name);
      
      const claimResult = r.claim(entity, 'listModel', this);

      if (claimResult == 'yes') {
        listModelRef.addRef(fullRefName)
      } else if(claimResult == 'no') {
        listModelRef.removeRef(fullRefName)
      }
    })
  }

  protected abstract instantiateListModelForEntity(entity: E): L;
  protected abstract getEntityTypeName(): string;
}