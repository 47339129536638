import { AuthConfig, InsightApplication, InsightAuth, PermissionsChangeFlag } from 'insight-auth'
import { CORE_INCREMENT_PERMISSIONS_CHANGE_COUNT, CORE_USER_ID_SET } from '@/store/mutationNames'
import environment from "../util/environment"
import { RealtimeService } from '@/util/realtimeUpdates'
import store from '@/store'

const vuexRecalcTrigger: PermissionsChangeFlag = {
  changed: function (): void {
    store.commit(CORE_INCREMENT_PERMISSIONS_CHANGE_COUNT);
  },
  touch: function (): void {
    if (store.state.permissionsChangeCount > 0) {
      //Do nothing, just access vuex to trigger a change!
    }
  }
}

export const auth = new InsightAuth(new AuthConfig(
    environment,
    InsightApplication.Werk,
    window.location.origin + '/login-redirect'
  ),
  RealtimeService.getSecurityCacheControlEventSource(),
  [(evt): void => store.commit(CORE_USER_ID_SET, evt.userId)],
  vuexRecalcTrigger
)