
import { ActionInfo, getActionListForEntityType } from '@/models/assignments/ActionInfo';
import { ASSIGNMENT_TEMPLATE_FETCH_LIFECYCLE_FOR_TYPE, ASSIGNMENT_TEMPLATE_SAVE } from '@/store/actionNames'
import { AssignmentTemplate, EntityType } from '@/models/assignments/AssignmentTemplate';
import { ASSIGNMENT_TEMPLATE_GET_LIFECYCLE_FOR_TYPE } from '@/store/getterNames';
import AssignmentTemplateEditorFieldSet from "./AssignmentTemplateEditorFieldSet.vue";
import EntityNew from '../../common/EntityNew.vue'
import { LifecycleRecord } from '@/models/lifecycles/LifecycleRecord';
import { PropValidator } from 'vue/types/options';
import Vue from 'vue'

export default Vue.extend({

  components: {
    EntityNew,
    AssignmentTemplateEditorFieldSet,
  },

  props: {
    client: {
      type: String,
      default: ''
    },

    entityType: {
      type: String,
      default: 'Ticket',
    } as PropValidator<EntityType>,

    show: Boolean,
  },

  data () {
    return {
      newTemplate: new AssignmentTemplate()
    }
  },

  computed: {
    behaviorActionList(): ActionInfo[] {
      return getActionListForEntityType(this.entityType);
    },

    lifecycle(): LifecycleRecord {
      return this.$store.getters[ASSIGNMENT_TEMPLATE_GET_LIFECYCLE_FOR_TYPE](this.entityType) as LifecycleRecord ?? new LifecycleRecord()
    },
    
    saveAction(): string {
      return ASSIGNMENT_TEMPLATE_SAVE;
    },
  },

  watch: {
    show: {
      handler() {
        if (this.show) {
          this.newTemplate = new AssignmentTemplate('', this.entityType);
          this.newTemplate.client = this.client;
        }
      }
    }
  },

  created() {
    if (this.lifecycle.stageRecords.length == 0) {
      this.$store.dispatch(ASSIGNMENT_TEMPLATE_FETCH_LIFECYCLE_FOR_TYPE, this.entityType)
    }
  }
})
