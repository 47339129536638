import { TicketType, TicketTypeListModel } from '@/models/tickets/TicketType';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<TicketType, TicketTypeListModel> {
  protected instantiateListModelForEntity(entity: TicketType): TicketTypeListModel {
    const listModel = new TicketTypeListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'TicketType'
  }
}