import { EditorConflictMap } from "./EditorConflict";


export class BufferTraversalPath {
  constructor(
    public key: string | number,
    public type: 'property' | 'index' | 'elementId' = 'property',
    public child?: BufferTraversalPath
  ) { }

  public get formattedKey(): string {
    let key = '';
    if (this.type == 'property') {
      key = `${this.key}`;
    } else if (this.type == 'index') {
      key = `idx:${this.key}`;
    } else if (this.type == 'elementId') {
      key = `id:${this.key}`;
    }

    return key;
  }

  public cloneAndAddLevel(finalLevel: BufferTraversalPath): BufferTraversalPath {
    //Handle the special "root" case--we want to eliminate the root.
    if (this.key == '<root>')
      return finalLevel;

    const target = new BufferTraversalPath(this.key, this.type, this.child);
    let currentLevel = target;

    while (currentLevel.child != undefined) {
      currentLevel.child = new BufferTraversalPath(
        currentLevel.child.key,
        currentLevel.child.type,
        currentLevel.child.child);

      currentLevel = currentLevel.child;
    }

    currentLevel.child = finalLevel;

    return target;
  }

  public cloneAndRemoveLast(): BufferTraversalPath | null {
    if (!this.child)
      return null;

    const target = new BufferTraversalPath(this.key, this.type, this.child);
    let currentLevel = target;
    let lastLevel = target;

    while (currentLevel.child != undefined) {
      currentLevel.child = new BufferTraversalPath(
        currentLevel.child.key,
        currentLevel.child.type,
        currentLevel.child.child);

      lastLevel = currentLevel;
      currentLevel = currentLevel.child;
    }

    lastLevel.child = undefined;

    return target;
  }

  public fullPathString(): string {
    let path = `${this.formattedKey}`;
    let currentLevel = this.child;

    while (currentLevel != undefined) {
      path = `${path}.${currentLevel.formattedKey}`;
      currentLevel = currentLevel.child;
    }

    return path;
  }

  public readFromBuffer(buffer: unknown): unknown {
    let ele: unknown;

    if (this.type == 'property') {
      ele = (buffer as Record<string, unknown>)[this.key];
    } else if (this.type == 'elementId') {
      const arr = buffer as Array<{ id: string; }>;
      ele = arr.find(x => x.id == this.key as string);
    } else {
      const arr = buffer as unknown[];
      ele = arr[this.key as number];
    }

    if (this.child) {
      return this.child.readFromBuffer(ele);
    } else {
      return ele;
    }
  }

  public writeToBuffer(buffer: unknown, value: unknown): void {
    if (this.child) {
      let ele: unknown;

      if (this.type == 'property') {
        ele = (buffer as Record<string, unknown>)[this.key];
      } else if (this.type == 'elementId') {
        const arr = buffer as Array<{ id: string; }>;
        ele = arr.find(x => x.id == this.key as string);
      } else {
        const arr = buffer as unknown[];
        ele = arr[this.key as number];
      }

      this.child.writeToBuffer(ele, value);

    } else {
      if (this.type == 'property') {
        (buffer as Record<string, unknown>)[this.key] = value;

      } else if (this.type == 'index') {
        const arr = buffer as unknown[];
        if (arr.length <= (this.key as number)) {
          arr.push(value);
        } else {
          arr[this.key as number] = value;
        }
      }
    }
  }

  public readFromConflictMap(map: EditorConflictMap): unknown {
    const ele = map[this.formattedKey];

    if (this.child) {
      return this.child.readFromConflictMap((ele ?? {}) as EditorConflictMap);
    } else {
      return ele;
    }
  }

  public clearInConflictMap(map: EditorConflictMap): void {
    if (this.child) {
      this.child.clearInConflictMap(map[this.formattedKey] as EditorConflictMap);
    } else {
      map[this.formattedKey] = undefined;
    }
  }

  public static fromPathString(pathStr: string): BufferTraversalPath | null {
    let basePath: BufferTraversalPath | null = null;

    if (pathStr > "") {
      const pathArr = pathStr.split('.');

      let curPath: BufferTraversalPath;
      basePath = this._instantiatePathSegment(pathArr[0]);
      curPath = basePath;

      while (pathArr.length > 1) {
        pathArr.splice(0, 1);
        curPath.child = this._instantiatePathSegment(pathArr[0]);
        curPath = curPath.child;
      }
    }

    return basePath;
  }

  private static _instantiatePathSegment(segment: string): BufferTraversalPath {
    let key: string | number;
    let type: 'property' | 'index' | 'elementId';

    if (segment.startsWith('idx:')) {
      key = +(segment.substr(4));
      type = 'index';
    } else if (segment.startsWith('id:')) {
      key = segment.substr(3);
      type = 'elementId';
    } else {
      key = segment;
      type = 'property';
    }

    return new BufferTraversalPath(key, type);
  }
}
