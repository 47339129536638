export enum StateFilterCommand {
  BlameBlank = "BIB",
  BlameNotBlank = "BNB",
  CloseReason = "CRE",
  AssignCompleteForStage = "ASC",
  AssignIncompleteForStage = "ASI",
  AssignNoneStarted = "AAN",
  AssignAnyPaused = "AAP",
  AssignAllCompleted = "AAC"
}

export class StateFilterTerm {
  constructor(
    public command: StateFilterCommand = StateFilterCommand.AssignCompleteForStage,
    public attributeName = '',
    public filterValue: string|null = null,
  ) {}
}

const TERM_SEPERATOR = "^";
const TERM_SEPERATOR_ESC = "{t>";
const VALUE_SEPERATOR = "`";
const VALUE_SEPERATOR_ESC = "{v>";

function escapeFilterValue(value: string): string {
  return value
    .replaceAll(TERM_SEPERATOR, TERM_SEPERATOR_ESC)
    .replaceAll(VALUE_SEPERATOR, VALUE_SEPERATOR_ESC);
}

function unescapeFilterValue(value: string): string {
  return value
    .replaceAll(TERM_SEPERATOR_ESC, TERM_SEPERATOR)
    .replaceAll(VALUE_SEPERATOR_ESC, VALUE_SEPERATOR);
}

function parseStateFilterTermString(termStr: string): StateFilterTerm|false {
  const values = termStr.split(VALUE_SEPERATOR);

  if (values.length == 2 || values.length == 3) {
    return new StateFilterTerm(
      values[0] as StateFilterCommand,
      unescapeFilterValue(values[1]),
      values.length == 3 ? unescapeFilterValue(values[2]) : null,
    );
  } else {
    return false;
  }
}

export function parseStateFilterString(val: string): StateFilterTerm[]|false {
  const terms = [] as StateFilterTerm[];

  if (val > '') {
    const termStrings = val.split(TERM_SEPERATOR);

    for (let i = 0; i < termStrings.length; i++) {
      const termStr = termStrings[i];
      const result = parseStateFilterTermString(termStr);

      if (result) {
        terms.push(result);
      } else {
        return false;
      }
    }
  }

  return terms;
}

function makeStateFilterTermString(term: StateFilterTerm): string {
  const vals = [] as string[];
  vals.push(escapeFilterValue(term.command));
  vals.push(escapeFilterValue(term.attributeName));

  if (term.filterValue != null) {
    vals.push(escapeFilterValue(term.filterValue));
  }

  return vals.join(VALUE_SEPERATOR);
}

export function makeStateFilterString(terms: StateFilterTerm[]): string {
  if (!terms) return "";
  
  const termStrs = [] as string[];

  for (let i = 0; i < terms.length; i++) {
    const term = terms[i];
    termStrs.push(makeStateFilterTermString(term));
  }

  return termStrs.join(TERM_SEPERATOR);
}