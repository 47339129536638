
import * as ac from '@/store/actionNames'
import * as gt from '@/store/getterNames'
import * as mt from '@/store/mutationNames'
import * as rt from '@/router/routeNames'
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames'
import { SearchUIFieldDef, SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption'
import { AllSpec } from '@/models/core/spec/AllSpec'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import BaseListModel from '@/models/core/entities/BaseListModel'
import { DataTableHeader } from 'vuetify'
import { DeletedSpec } from '@/models/core/spec/DeletedSpec'
import { describeQuery } from "@/models/core/searchQueries/QueryDescriptionGenerator";
import { EntityEditorCloseRequest } from '@/models/core/entityEditor/EntityEditorCloseRequest'
import EntityManagerMultiDelete from './EntityManager/EntityManagerMultiDelete.vue'
import EntityManagerSearchDialog from './EntityManager/EntityManagerSearchDialog.vue'
import { EntityManagerState } from '@/models/core/entityManager/EntityManagerState'
import { EntityManagerStateUpdate } from '@/models/core/entityManager/EntityManagerStateUpdate'
import { getValFromObjectOrChild } from "../../util/getVal";
import IconButton from './IconButton.vue'
import notifications from '@/util/notifications'
import pluralize from 'pluralize'
import PrivateEntity from '@/models/core/entities/PrivateEntity'
import PrivateEntityListModel from '@/models/core/entities/PrivateEntityListModel'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import { PropValidator } from 'vue/types/options'
import { Spec } from '@/models/core/spec/Spec'
import { toTitleCase } from "@/util/capitalize";
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest'
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest'

const TABLE_HEADER_CLASSES = 'accent'

export default Vue.extend({
  components: {
    EntityManagerMultiDelete,
    EntityManagerSearchDialog,
    IconButton,
  },

  filters: {
    capitalize(value: string|null|undefined): string {
      if (!value) return ''
      value = value.toString()
      return toTitleCase(value);
    }
  },

  props: {
    canCreateEntity: Boolean,

    canViewEntity: Boolean,

    clientCode: {
      type: String,
      default: '',
      required: true,
    },

    defaultQuery: {
      type: Array,
      default: null,
    } as PropValidator<SearchUIFieldValue[]>,

    editorRouteName: {
      type: String,
      default: null,
      required: true,
    },

    embedded: Boolean,

    entities: {
      type: Array,
      default: (): null => null,
    } as PropValidator<BaseListModel[]>,

    entityType: {
      type: String,
      default: '',
    },

    hideNewButton: Boolean,

    idFieldName: {
      type: String,
      default: 'id',
    },

    lockedQueryFields: {
      type: Array,
      default: null,
    } as PropValidator<string[]|null>,

    openEntities: {
      type: Array,
      default: (): null => null,
    } as PropValidator<PrivateEntity[]>,

    redirectOnClose: {
      type: String,
      default: '',
    },

    searchFieldDefs: {
      type: Array,
      default: null,
    } as PropValidator<SearchUIFieldDef[]>,

    sortBy: {
      type: String,
      default: '',
    },

    sortDescending: Boolean,

    supressLongQueryWarning: Boolean,

    tableHeaders: {
      type: Array,
      default: (): null => null,
    } as PropValidator<DataTableHeader[]>,

    tabTitleField: {
      type: String,
      default: 'name',
    },

    title: {
      type: String,
      default: '',
    },

    useAsChooser: Boolean,

    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<BaseListModel|null>,

    useServerSidePaging: Boolean,

    vuexConstantStem: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      clientPageSize: 10,
      loadingEntities: false,
      multiDeleteRestore: false,
      selectedId: null as unknown,
      showEmbeddedEditor: false,
      showMultiDelete: false,
      showSearchDialog: false,
    }
  },

  computed: {
    clientPageCount(): number {
      return Math.ceil((this.entityManagerState?.serverSideEntityCount ?? this.entities.length) / this.clientPageSize);
    },

    clientPageNumber: {
      get(): number {
        return this.entityManagerState?.clientPageNumber ?? 1;
      },

      set(page: number): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'clientPageNumber',
          page as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    closeEntityMutationName(): string {
      return (mt as Record<string, string>)[`${this.vuexConstantStem}_REMOVE_ENTITY_FROM_REF`]
    },

    currentEntityPage(): BaseListModel[] {
      const clientPageIdx = this.clientPageNumber - 1;
      const clientPageFirstRecord = (clientPageIdx * this.clientPageSize);
      const clientPageLastRecord = (clientPageIdx + 1) * this.clientPageSize;

      const offset = this.serverPageNumber * this.serverPageSize;

      const startIdx = Math.max(clientPageFirstRecord - offset, 0);
      const endIdx = Math.max(clientPageLastRecord - offset, 0);

      return this.sortedEntities.slice(startIdx, endIdx);
    },

    deleteActionName(): string {
      return (ac as Record<string, string>)[`${this.vuexConstantStem}_DELETE`]
    },

    entityCountForQuery(): number {
      return this.useServerSidePaging ? this.serverSideEntityCount : this.entities.length;
    },
    
    entityLoadingText(): string {
      return `Loading ${this.pluralEntityType}...`
    },

    entityManagerState(): EntityManagerState|undefined {
      return this.$store.getters[gt.CORE_GET_ENTITY_MANAGER_STATE](this.entityType, this.refName);
    },

    firstRowNumberInList(): number {
      return this.useServerSidePaging ? (this.serverPageNumber * this.serverPageSize) + 1 : 1;
    },

    firstRowNumberOnScreen(): number {
      return this.entityCountForQuery == 0 ? 0 : (this.clientPageSize * (this.clientPageNumber - 1)) + 1;
    },

    loadActionName(): string {
      return (ac as Record<string, string>)[`${this.vuexConstantStem}_FETCH`]
    },
  
    lastRowNumberInList(): number {
      const lastInPage = (this.serverPageNumber + 1) * this.serverPageSize;
      const realLastRowNum = Math.min(lastInPage, this.entityCountForQuery);
      return this.useServerSidePaging ? realLastRowNum : this.entityCountForQuery;
    },

    lastRowNumberOnScreen(): number {
      const lastInPage = (this.clientPageNumber) * this.clientPageSize;
      return Math.min(lastInPage, this.entityCountForQuery);
    },

    managerTitle(): string {
      return this.title || this.pluralEntityType
    },

    multiSelect: {
      get(): boolean {
        return this.entityManagerState?.multiSelect ?? false;
      },

      set(show: boolean): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'multiSelect',
          show as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    pluralEntityType(): string {
      return pluralize(this.entityType)
    },

    queryDescription(): string {
      return "Current query: " + describeQuery(this.searchValues, this.searchFieldDefs, this.pluralEntityType);
    },

    referrerExists(): boolean {
      return this.$store.getters[this.referrerGetterName](this.refName);
    },

    referrerGetterName(): string {
      return (gt as Record<string, string>)[`${this.vuexConstantStem}_REFERRER_EXISTS`]
    },

    referrerAddMutationName(): string {
      return (mt as Record<string, string>)[`${this.vuexConstantStem}_ADD_REFERRER`]
    },

    referrerDropMutationName(): string {
      return (mt as Record<string, string>)[`${this.vuexConstantStem}_DROP_REFERRER`]
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    },

    refSpec: {
      get(): Spec<PrivateEntity> {
        return this.entityManagerState?.refSpec ?? new DeletedSpec().not()
      },

      set(spec: Spec<PrivateEntity>): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'refSpec',
          spec as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);

        this.configureEntityReferrer();
      }
    },

    requestedSearchPageSize: {
      get(): number {
        return this.entityManagerState?.requestedSearchPageSize ?? 50;
      },

      set(size: number): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'requestedSearchPageSize',
          size as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    restoreActionName(): string {
      return (ac as Record<string, string>)[`${this.vuexConstantStem}_UNDELETE`];
    },

    search: {
      get(): string {
        return this.entityManagerState?.search ?? '';
      },

      set(str: string): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'search',
          str as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    searchActionName(): string {
      return (ac as Record<string, string>)[`${this.vuexConstantStem}_SEARCH`]
    },

    searchValues: {
      get(): SearchUIFieldValue[] {
        return this.entityManagerState?.searchValues ?? [];
      },

      set(newVals: SearchUIFieldValue[]): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'searchValues',
          newVals as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    selectedRows: {
      get(): BaseListModel[] {
        return this.entityManagerState?.selectedRows ?? [];
      },

      set(rows: BaseListModel[]): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'selectedRows',
          rows as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    serverPageNumber(): number {
      return this.entityManagerState?.serverPageNumber ?? 0;
    },

    serverPageSize(): number {
      return this.entityManagerState?.serverPageSize ?? 0;
    },

    serverSideEntityCount: {
      get(): number {
        return this.entityManagerState?.serverSideEntityCount ?? 0;
      },

      set(value: number) {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'serverSideEntityCount',
          value as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    showDeleted: {
      get(): boolean {
        return this.entityManagerState?.showDeleted ?? false;
      },

      set(show: boolean): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'showDeleted',
          show as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    showMultiSelectButton: {
      get(): boolean {
        return this.entityManagerState?.showMultiSelectButton ?? true;
      },

      set(show: boolean): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'showMultiSelectButton',
          show as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    sortByInternal: {
      get(): string {
        return this.entityManagerState?.sortByInternal ?? 'name';
      },

      set(column: string): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'sortByInternal',
          column as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    sortDescInternal: {
      get(): boolean {
        return this.entityManagerState?.sortDescInternal ?? false;
      },

      set(value: boolean): void {
        if (this.entityManagerState == undefined) return;
        const state = EntityManagerStateUpdate.for(
          this.entityManagerState,
          'sortDescInternal',
          value as never);

        this.$store.commit(mt.CORE_SET_ENTITY_MANAGER_STATE, state);
      }
    },

    sortedEntities(): BaseListModel[] {
      return this.entities.slice().sort((a, b) => {
        const aVal = getValFromObjectOrChild<string|number>(a, this.sortByInternal) ?? "";
        const bVal = getValFromObjectOrChild<string|number>(b, this.sortByInternal) ?? "";

        if (aVal > bVal) {
          return this.sortDescInternal ? -1 : 1;
        } else if (aVal < bVal) {
          return this.sortDescInternal ? 1 : -1;
        } else {
          return 0;
        }
      })
    },

    tableHeadersInternal(): DataTableHeader[] {
      const headers = this.tableHeaders

      const internalHeaders: DataTableHeader[] = []

      headers.forEach(h => {
        internalHeaders.push({
          ...h,
          class: TABLE_HEADER_CLASSES
        })
      })

      return internalHeaders
    },
  },

  watch: {
    sortBy: {
      handler(): void {
        this.sortByInternal = this.sortBy
      },
    },

    useAsChooserSelection: {
      handler(): void {
        if (this.useAsChooser) {
          if (this.useAsChooserSelection) {
            this.selectedRows = [this.useAsChooserSelection];
          } else {
            this.selectedRows = [];
          }
        }
      },
      immediate: true,
    }
  },

  async created(): Promise<void> {
    if (!this.embedded) {
      this.$store.commit(mt.CORE_SET_PAGE_TITLE, this.title);
    }

    if (this.entityManagerState == undefined) {
      const state = new EntityManagerState(this.entityType, this.clientCode, this.useServerSidePaging, this.refName);
      state.sortByInternal = this.sortBy;
      state.sortDescInternal = this.sortDescending;
      state.searchValues = this.defaultQuery || [];

      this.$store.commit(mt.CORE_CREATE_ENTITY_MANAGER_STATE, state);
    }

    if (!this.referrerExists) {
      this.configureEntityReferrer();
      await this.loadEntities()
    }
  },

  methods: {
    checkFetchServerPage(newClientPageNumber: number): void {
      const firstRequestedRecord = ((newClientPageNumber -1) * this.clientPageSize) + 1;
      const firstLoadedRecord = this.firstRowNumberInList;
      const lastLoadedRecord = this.lastRowNumberInList;

      if (firstRequestedRecord > lastLoadedRecord || firstRequestedRecord < firstLoadedRecord) {
        const requiredServerPage = Math.floor(firstRequestedRecord / this.serverPageSize);
        this.loadEntities(requiredServerPage);
      }
    },

    closeOpenEntity(entity: PrivateEntity): Promise<boolean> {
      const req: EntityEditorCloseRequest = {
        entity: entity as PrivateEntitySpec,
        entityTitle: (entity as unknown as Record<string, string>)[this.tabTitleField],
        mutationName: this.closeEntityMutationName,
      }

      return this.$store.dispatch(ac.CORE_CLOSE_ENTITY_EDITOR, req);
    },

    configureEntityReferrer(): void {
      const spec = this.refSpec;

      const referrer = new VuexEntityReferer(
        this.clientCode,
        this.refName,
        (entity, mode) =>  {
          if (mode == 'entity') {
            return 'noChange';

          } else {
            if (spec.isSatisfiedBy(entity)) {
              if (this.useServerSidePaging && this.entities.find(x => x.id == entity.id) == undefined) {
                this.serverSideEntityCount++;
              }

              return 'yes'
            } else {
              return 'no'
            }
          }
        }
      );

      this.$store.commit(this.referrerAddMutationName, referrer)
    },
    
    getRowClass(item: PrivateEntityListModel<PrivateEntity>): string {
      if ((item as unknown as Record<string, boolean>).deleted == true || (item.deletedBy ?? '') > '') {
        return 'text-decoration-line-through'
      } else {
        return ''
      }
    },

    handleEditorClosed(): void {
      this.showEmbeddedEditor = false;
      this.selectedId = null;
    },

    handleNewEntityClick(): void {
      this.$emit('request-new-entity', true)
    },

    handleRowClick(entity: Record<string, string|number|boolean>): void {
      if (this.useAsChooser) {
        this.$emit('click:entity', entity);
      } else {
        this.showEntity(entity);
      }
    },

    handleRowDoubleClick(evt: never, data: {item: unknown}): void {
      if (this.useAsChooser) {
        this.$emit('dblclick:entity', data.item);
      }
    },

    handleShowDeletedChange(show: boolean): void {
      if (show) {
        this.refSpec = new AllSpec<PrivateEntity>();
      } else {
        this.refSpec = new DeletedSpec<PrivateEntity>().not();
      }
      this.$nextTick(() => {
        if (this.useServerSidePaging) this.clientPageNumber = 1;
        this.loadEntities(0)
      });
    },

    handleSortClick(): void {
      if (this.useServerSidePaging) {
        this.loadEntities(0);
      }
    },

    async loadEntities(requiredPage: false|number|'new' = false): Promise<void> {
      if (!this.canViewEntity) return;

      this.loadingEntities = true

      try {
        if (this.searchValues.length > 0 || this.useServerSidePaging) {
          let pageSize = this.entityManagerState?.serverPageSize;
          let pageNumber = this.entityManagerState?.serverPageNumber;

          if (this.useServerSidePaging && pageSize != this.requestedSearchPageSize) {
            pageSize = this.requestedSearchPageSize;
            pageNumber = 0;
            this.clientPageNumber = 1;
          } else if (requiredPage !== false) {
            if (requiredPage == 'new') {
              pageNumber = 0;
              this.clientPageNumber = 1;
            } else {
              pageNumber = requiredPage;
            }
          }

          const req = new VuexSearchRequest(
            this.refName,
            this.searchValues,
            this.showDeleted,
            this.clientCode,
            this.useServerSidePaging ? `${this.entityType}_${this.refName}_${this.clientCode}` : undefined,
            pageSize,
            pageNumber,
            mt.CORE_ENTITY_MANAGER_PAGE_DATA_SET,
            this.sortByInternal,
            this.sortDescInternal,
          );
          await this.$store.dispatch(this.searchActionName, req);

        } else {
          const req: VuexFetchRequest = {
            refName: this.refName,
            includeDeleted: this.showDeleted,
          }
          await this.$store.dispatch(this.loadActionName, req)
        }
      } catch (error) {
        const e = error as ApiResponse;
        notifications.warn(this.$store, `There was an error while loading the ${this.entityType} list. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
      }

      this.loadingEntities = false
    },

    setSelectMode(mode: 'single'|'multi'): void {
      if (mode == 'multi') {
        this.showMultiSelectButton = false;
        setTimeout(() => {this.multiSelect = true}, 100);
      } else {
        this.multiSelect = false;
        setTimeout(() => {this.showMultiSelectButton = true}, 500);
      }
    },

    setTitle(): void {
      this.$store.commit(mt.CORE_SET_PAGE_TITLE, this.title)
    },

    async showEntity(entity: Record<string, string|number|boolean>): Promise<void> {
      if (entity.deleted === true || entity.deletedBy > '') {
        notifications.inform(this.$store, `This ${this.entityType} has been deleted and cannot be opened. If you need to access this ${this.entityType}, please restore (undelete) it and try again.`)
        return;
      }

      if (this.embedded) {
        this.selectedId = entity[this.idFieldName];
        this.showEmbeddedEditor = true;
      } else {
        this.$router.push({
        name: this.editorRouteName,
        params: {
          clientCode: this.clientCode, 
          [this.idFieldName]: entity[this.idFieldName] + ""
        }
      });
      }
    },

    showMultiDeleteDialog(mode: 'delete'|'restore'): void {
      this.multiDeleteRestore = mode == 'restore';
      this.showMultiDelete = true;
    },

    async unloadEntities(skipRedirect = false): Promise<boolean> {
      for (let i = 0; i < this.openEntities.length; i++) {
        const entity = this.openEntities[i];
        
        const closed = await this.closeOpenEntity(entity);

        if (!closed) return false;
      }

      this.$store.commit(this.referrerDropMutationName, {client: this.clientCode, name: this.refName});
      this.$store.commit(mt.CORE_CLEAR_ENTITY_MANAGER_STATE, {entityType: this.entityType, client: this.clientCode});

      if (!skipRedirect) {
        const name = this.redirectOnClose ? this.redirectOnClose : rt.DASHBOARD
  
        this.$nextTick(() => { this.$router.push({ name, params: { clientCode: this.clientCode } }) })
      }

      return true;
    },
  }
})
