
import { Editor, EditorContent } from '@tiptap/vue-2'
import ScrollBox from '../common/ScrollBox.vue'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Vue from 'vue'

export default Vue.extend({
  components: {
    EditorContent,
    ScrollBox,
  },

  props: {
    content: {
      type: String,
      default: '',
    },

    height: {
      type: Number,
      default: 100,
    }
  },

  data() {
    return {
      editor: null as Editor|null,
      formatItems: [
        {text: "Paragraph", value: "P"},
        {text: "Heading 1", value: "H1"},
        {text: "Heading 2", value: "H2"},
        {text: "Heading 3", value: "H3"},
      ],
      historyIndex: -1,
    }
  },

  watch: {
    content(): void {
      if (this.editor) {
        const currentContent = this.editor.getHTML();

        if (currentContent != this.content) {
          this.editor.commands.setContent(this.content, false, {preserveWhitespace: "full"});
        }
      }
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
      ],
      content: this.content,
      onUpdate: this.handleContentChange,
    })
  },

  beforeDestroy() {
    this.editor?.destroy()
  },

  methods: {
    handleElementTypeChange(newVal: string): void {
      if (this.editor) {
        if (newVal == "P") {
          this.editor.chain().focus().setParagraph().run();

        } else if (newVal == "H1") {
          this.editor.chain().focus().setHeading({ level: 1 }).run();

        } else if (newVal == "H2") {
          this.editor.chain().focus().setHeading({ level: 2 }).run();
          
        } else if (newVal == "H3") {
          this.editor.chain().focus().setHeading({ level: 3 }).run();
          
        }
      }
    },

    getActiveFormatItem(): string {
      if (this.editor) {
        if (this.editor.isActive('paragraph')) {
          return "P";
        } else if (this.editor.isActive('heading', {level: 1})) {
          return "H1";
        } else if (this.editor.isActive('heading', {level: 2})) {
          return "H2";
        } else if (this.editor.isActive('heading', {level: 3})) {
          return "H3";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    handleHistoryClick(idx: number): void {
      this.historyIndex = idx;

      this.$nextTick(() => {
        this.historyIndex = -1;
      })
    },

    handleContentChange(): void {
      if (this.editor) {
        this.$emit('update:content', this.editor.getHTML());
      }
    },

    isActive(name: string, attributes?: unknown): boolean {
      return this.editor?.isActive(name, attributes as Record<string, unknown>) ?? false;
    }
  }
})
