import { EditorConflictMap, EditorConflictValues, ENTITY_DELETED_FLAG } from "@/models/core/bufferedEditor/EditorConflict";
import { BufferedEditorChangeValueRequest } from "@/models/core/bufferedEditor/BufferedEditorChangeValueRequest";
import { BufferedEditorConflictResolverData } from "@/models/core/bufferedEditor/BufferedEditorConflictResolverData";
import { BufferTraversalPath } from "@/models/core/bufferedEditor/BufferTraversalPath";
import { CORE_OPEN_CONFLICT_RESOLVER } from "@/store/mutationNames";
import { EditEventMixin } from "./EditEventMixin";
import { PropValidator } from "vue/types/options";

export const FieldSetConflictMixin = EditEventMixin.extend({
  props: {
    editorId: {
      type: String,
      default: '',
    },

    mergeConflicts: {
      type: [Object, String],
      default: (): EditorConflictMap => new EditorConflictMap()
    } as PropValidator<EditorConflictMap|string>,
  },

  computed: {
    deletedByExternalAction(): boolean {
      return this.mergeConflicts == ENTITY_DELETED_FLAG;
    }
  },

  methods: {
    getConflictMessageForField(
      fieldName: string,
      formatter: (v: unknown) => string = (v: unknown): string => `${v}`
    ): string|never[] {
      const value = this.getConflictValueForField(fieldName);

      if (value != undefined) {
        return `Conflicting value: ${formatter(value as string|number)}`;
      } else {
        return [];
      }
    },

    getConflictValueForField(fieldName: string): unknown {
      if (!this.mergeConflicts) return undefined;
      if (this.mergeConflicts == ENTITY_DELETED_FLAG) return undefined;

      const path = BufferTraversalPath.fromPathString(fieldName);

      return path?.readFromConflictMap(this.mergeConflicts as EditorConflictMap);
    },

    getResolveWithArray(...paths: string[]): string {
      return JSON.stringify(paths);
    },

    resolveConflict(values: EditorConflictValues, path: BufferTraversalPath, event: MouseEvent): void {
      event.preventDefault();

      const data = new BufferedEditorConflictResolverData(
        values,
        path,
        event.x,
        event.y,
      );

      this.showConflictResolver(data);
    },

    showConflictResolver(data: BufferedEditorConflictResolverData): void {
      const req = new BufferedEditorChangeValueRequest<BufferedEditorConflictResolverData>(
        this.editorId,
        data
      );

      this.$store.commit(CORE_OPEN_CONFLICT_RESOLVER, req);
    }
  }
})