import * as gt from '../getterNames'
import { CONTACT_MANAGER_PREVIEW_REF, ENTITY_MANAGER_REF } from '../refNames'
import { AddressListItem } from '@/models/contacts/AddressListItem'
import Client from '@/models/core/Client'
import {Contact} from '@/models/contacts/Contact'
import {ContactListModel} from '@/models/contacts/ContactListModel'
import { GetterTree } from 'vuex'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.CONTACT_CURRENT_ADDRESS_LIST] (state): AddressListItem[] {
    return state.addressList;
  },

  [gt.CONTACT_CURRENT_COLLECTION_ID] (state): string {
    return state.collectionId;
  },

  [gt.CONTACTS_FOR_CURRENT_CLIENT](state, getters): ContactListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.CONTACTS_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): ContactListModel[] => {
      return state.getListModelsForRef(client, refName);
  },

  [gt.CONTACT_MANGAGER_PREVIEW] (state, getters): Contact|null {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    return state.getEntitiesForRef(clientCode, CONTACT_MANAGER_PREVIEW_REF)[0] ?? null;
  },
    
  [gt.CONTACTS_OPEN_FOR_CURRENT_CLIENT] (state, getters): Contact[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.CONTACTS_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): Contact[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.CONTACT_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },
}