
import { AbstractTextField } from './AbstractTextField';
import reformatDate from '../../../util/reformatDate';

export default AbstractTextField.extend({
  inheritAttrs: false,
  
  data() {
    return {
      formattedDate: '',
      internalDate: '',
      showPicker: false,
    }
  },

  watch: {
    value: {
      handler(): void {
        this.internalDate = this.value == '0001-01-01T00:00:00' ? '' : reformatDate(this.value, 'yyyy-mm-dd', false, true);
        this.formattedDate = reformatDate(this.internalDate, 'mm/dd/yyyy', false, true);
      },
      immediate: true,
    }
  },

  methods: {
    handleDateSelection(): void {
      this.showPicker = false;
      this.handleInput(this.internalDate);
    }
  }
})
