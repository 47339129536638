import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { Assignment, AssignmentListModel } from '@/models/assignments/Assignment';
import { ActionTree } from "vuex";
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import { ENTITY_EDITOR_LINK_REF_STEM } from '../refNames';
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.ASSIGNMENT_DELETE] ({ commit }, data: ApiDeletePayload<Assignment>): Promise<void> {
    const url = data.entity.client + '/assignments/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.ASSIGNMENT_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<AssignmentListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/assignments`
    return ah.getEntityList(
      url,
      client,
      mt.ASSIGNMENT_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_FETCH_FOR_ENTITY] ({ commit }, entity: PrivateEntitySpec): Promise<AssignmentListModel[] | null> {
    const url = `${entity.client}/assignments/associatedWith/${entity.id}`
    const apiResponse = await api.get(url);

    if (apiResponse) {
      if (!apiResponse.error) {
        const assignments = apiResponse.data as AssignmentListModel[];

        const mutationData: EntitiesForRef<AssignmentListModel> = {
          entities: assignments,
          refName: ENTITY_EDITOR_LINK_REF_STEM + entity.id,
        }

        commit(mt.ASSIGNMENT_STORE_LIST_MODELS_FOR_REF, mutationData);

        return assignments;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<AssignmentListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/assignments`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.ASSIGNMENT_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false,
      req.pagerId,
      req.pageSize,
      req.pageNumber,
      req.pageDataMutationName,
      req.orderBy,
      req.orderDescending,
    )
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<Assignment | null> {
    const url = request.spec.client + '/assignments/' + request.spec.id

    return ah.getPrivateEntity<Assignment>(url, mt.ASSIGNMENT_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_SAVE] ({ commit }, data: VuexEntityStorageRequest<Assignment>): Promise<Assignment | null> {
    const url = data.entity.client + '/assignments'

    const mode: VuexEntityStorageRequestMode = data.mode ?? VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.ASSIGNMENT_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_UNDELETE] ({ commit }, entity: Assignment): Promise<Assignment | null> {
    const url = entity.client + '/assignments'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.ASSIGNMENT_STORE_ENTITY_FOR_REF, commit);
  },

  //***********************************************************************
  async [ac.ASSIGNMENT_UPDATE_SORT_INDEX] ({ commit }, request: VuexEntityRefSpec): Promise<Assignment | null> {
    const url = request.spec.client + '/assignments';

    return await ah.executeActionOnEntity(request.spec, url, 'updateSortIndex',
      mt.ASSIGNMENT_STORE_ENTITY_FOR_REF, commit, request.spec as AssignmentListModel) as Assignment;
  },
}