import { LifecycleStage } from "./LifecycleStage";

export class LifecycleStageRecord {
  constructor(
    public id = '',
    public sequence = 0,
    public stage = new LifecycleStage(),
  ) {}

  private static idCounter = 0;

  public static Duplicate(source: LifecycleStageRecord): LifecycleStageRecord {
    this.idCounter--;

    return new LifecycleStageRecord(
      `${this.idCounter}`,
      source.sequence,
      LifecycleStage.Duplicate(source.stage)
    );
  }
}