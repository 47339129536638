import * as mt from '../mutationNames'
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import { ENTITY_MANAGER_REF } from '../refNames';
import { EntityPagingData } from '@/models/core/entities/EntityPagingData';
import Message from '@/models/messages/Message';
import MessageListModel from '@/models/messages/MessageListModel';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import Vue from 'vue';
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.MESSAGE_ADD_REFERRER] (state, referrer: VuexEntityReferer<Message>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.MESSAGE_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.MESSAGE_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.MESSAGE_MAILBOX_ID_SET] (state, data: {id: string; address: string; client: string}): void {
    if (state.mailboxId != data.id) {
      state.mailboxId = data.id;
      state.mailboxAddress = data.address
      state.dropRefNameForListModels(data.client, ENTITY_MANAGER_REF, true);
    }
  },

  //***********************************************************************
  [mt.MESSAGE_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.MESSAGE_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.MESSAGE_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.MESSAGE_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<Message>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.MESSAGE_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<MessageListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName);
  },
}