import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ActionTree } from "vuex";
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';
import {WorkOrder} from '@/models/workOrders/WorkOrder';
import {WorkOrderListModel} from '@/models/workOrders/WorkOrderListModel';
import { WorkOrderStepListModel } from '@/models/workOrders/WorkOrderStepListModel';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.WORK_ORDER_DELETE] ({ commit }, data: ApiDeletePayload<WorkOrder>): Promise<void> {
    const url = data.entity.client + '/workOrders/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.WORK_ORDER_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<WorkOrderListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/workOrders`
    return ah.getEntityList(
      url,
      client,
      mt.WORK_ORDER_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<WorkOrderListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/workOrders`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.WORK_ORDER_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false,
      req.pagerId,
      req.pageSize,
      req.pageNumber,
      req.pageDataMutationName,
      req.orderBy,
      req.orderDescending,
    )
  },

  //***********************************************************************
  async [ac.WORK_ORDER_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<WorkOrder | null> {
    const url = request.spec.client + '/workOrders/' + request.spec.id

    return ah.getPrivateEntity<WorkOrder>(url, mt.WORK_ORDER_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_SAVE] ({ commit }, data: {entity: WorkOrder; refName: string}): Promise<WorkOrder | null> {
    const url = data.entity.client + '/workOrders'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.WORK_ORDER_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_UNDELETE] ({ commit }, entity: WorkOrder): Promise<WorkOrder | null> {
    const url = entity.client + '/workOrders'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.WORK_ORDER_STORE_ENTITY_FOR_REF, commit);
  },

  //***********************************************************************
  async [ac.WORK_ORDER_UPDATE_SORT_INDEX_PRIORITY] ({ commit }, request: VuexEntityRefSpec): Promise<WorkOrder | null> {
    const url = request.spec.client + '/workOrders';


    return await ah.executeActionOnEntity(request.spec, url, 'updateSortindexAndPriority',
      mt.WORK_ORDER_STORE_ENTITY_FOR_REF, commit, request.spec as WorkOrderListModel) as WorkOrder;
  },

  //***********************************************************************
  async [ac.WORK_ORDER_UPDATE_STEP_SORT_INDEX] (
    { commit },
    request: {workOrder: WorkOrderListModel; step: WorkOrderStepListModel; refName: string}
  ): Promise<WorkOrder | null> {
    const url = request.workOrder.client + '/workOrders';
    const actionName = `executeStepAction/updateSortIndex/${request.step.id}`

    return await ah.executeActionOnEntity(request.workOrder, url, actionName,
      mt.WORK_ORDER_STORE_ENTITY_FOR_REF, commit, request.step) as unknown as WorkOrder;
  },
}