export function aOrAn(nounPhrase: string) {
  const vowels = ['a','e', 'i', 'o', 'u'];

  if (nounPhrase > '') {
    if (vowels.includes(nounPhrase.toLowerCase().charAt(0))) {
      return 'an';
    } else {
      return 'a'
    }
  } else {
    return 'a'
  }
}

export function compileList(items: string[], conjunction: string, itemSeparator = ","): string {
  if (!items || items.length == 0) {
    return "";
  } else if (items.length == 1) {
    return items[0]
  } else if (items.length == 2) {
    return `${items[0]} ${conjunction} ${items[1]}`
  } else {
    return `${items.slice(0, items.length-1).join(`${itemSeparator} `)}, ${conjunction} ${items[items.length-1]}`
  }
}

export function wrapQuotes(text: string): string {
  return `"${text}"`;
}