
import * as gt from '../../../store/getterNames'
import { DefaultTicketTypeQuery, TicketTypeSearchFieldDefs } from "./TicketTypeSearchFieldDefs";
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames';
import { TicketType, TicketTypeListModel } from '@/models/tickets/TicketType'
import { DataTableHeader } from 'vuetify'
import EntityManager from '../../common/EntityManager.vue'
import { EntityManagerInterface } from "../../../models/core/entityManager/EntityManagerInterface";
import { PropValidator } from 'vue/types/options';
import { SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption';
import TicketTypeEditor from "./TicketTypeEditor.vue"
import TicketTypeNew from './TicketTypeNew.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    EntityManager,
    TicketTypeEditor,
    TicketTypeNew,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    useAsChooser: Boolean,
    
    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<TicketTypeListModel|null>,
  },

  data () {
    return {
      searchFieldDefs: TicketTypeSearchFieldDefs,
      showNewTypeDlg: false,
      tableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description', sortable: false, },
        { text: 'Default Workflow', value: 'defaultWorkflow' },
        { text: 'Lifespan (Days)', value: 'lifespanInDays', align: 'end', },
      ] as DataTableHeader[],
    }
  },

  computed: {
    canCreateTicketTypes(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1015200") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1015210") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    canEditTicketTypes(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1015300") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1015310") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    canViewTicketTypes(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1015100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1015110") ||
                this.$auth.hasPrivilegeAnyClient("8100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "8110");
    },

    defaultQuery(): SearchUIFieldValue[] {
      return DefaultTicketTypeQuery;
    },

    editorRoute(): string {
      return "error"
    },
    
    openTicketTypes(): TicketType[] {
      return this.$store.getters[gt.TICKET_TYPES_OPEN_FOR_REF](this.clientCode, this.refName);
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    },
    
    ticketTypes(): TicketTypeListModel[] {
      return this.$store.getters[gt.TICKET_TYPES_LISTED_FOR_REF](this.clientCode, this.refName);
    },
  },

  methods: {
    async unloadEntities(skipRedirect = false): Promise<boolean> {
      return await (this.$refs["mgr"] as unknown as EntityManagerInterface)?.unloadEntities(skipRedirect);
    }
  }
})
