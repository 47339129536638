import { BufferFieldOverrideCollection } from "./BufferFieldOverrides";
import { EditorBufferMergeMode } from "./EditorBuffer";

export class BufferedEditorResetRequest {
  constructor(
    public id: string,
    public data?: unknown,
    public force?: boolean,
    public saveActionName?: string,
    public refName?: string,
    public mergeMode?: EditorBufferMergeMode,
    public overrides?: BufferFieldOverrideCollection,
  ) {}
}