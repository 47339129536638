import { CORE_CLIENT_CURRENT, CORE_DOC_CLOSE_REQUEST_COUNT, CORE_DOC_LINK_REQUEST_COUNT, CORE_GET_CURRENT_USER, CORE_GET_ENTITY_MANAGER_STATE, CORE_GET_GENERIC_PAGER, CORE_GET_LIFECYCLE_FOR_TYPE, CORE_GET_LIFECYCLE_HASH_FOR_TYPE, CORE_GET_STATUS_KANBAN_BOARD, CORE_NEXT_DOC_CLOSE_REQUEST, CORE_NEXT_DOC_LINK_REQUEST } from './getterNames'
import { createLifecycleHash, LifecycleHash } from '@/models/lifecycles/LifecycleHash'
import Client from '@/models/core/Client'
import { ENTITY_MANAGER_REF } from './refNames'
import { EntityManagerState } from '@/models/core/entityManager/EntityManagerState'
import { EntityType } from '@/models/assignments/AssignmentTemplate'
import { GenericPager } from '@/models/core/entities/EntityPagingData'
import { GetterTree } from 'vuex'
import InsightUser from '@/models/core/InsightUser'
import { Lifecycle } from '@/models/lifecycles/Lifecycle'
import RootState from './RootState'
import { StatusKanbanBoardDef } from '@/models/core/statusKanban/StatusKanbanBoardDef'

export const getters: GetterTree<RootState, RootState> = {
  notificationCount (state) {
    return state.notifications.length
  },

  nextNotification (state) {
    if (state.notifications.length > 0) {
      return state.notifications[0]
    } else {
      return null
    }
  },

  popupRequestCount (state) {
    return state.popupRequests.length;
  },

  nextPopupRequest (state) {
    if (state.popupRequests.length > 0) {
      return state.popupRequests[0];
    } else {
      return null;
    }
  },

  clientCurrent (state) {
    if (state.selectedClientIndex < state.clients.length && state.selectedClientIndex >= 0) {
      return state.clients[state.selectedClientIndex]
    } else {
      return null
    }
  },

  clientByCode: (state) => (code: string): Client|undefined => {
    return state.clients.find(client => client.code == code)
  },

  clientIndexByCode: (state) => (code: string): number => {
    return state.clients.findIndex(client => client.code == code)
  },

  [CORE_GET_STATUS_KANBAN_BOARD]: (state) => (clientCode: string, id: string): StatusKanbanBoardDef|null => {
    return state.kanbanBoards.find(x => x.client == clientCode && x.id == id) ?? null;
  },

  [CORE_GET_CURRENT_USER]: (state) => {
    return state.usersById[state.userId] ?? new InsightUser();
  },

  [CORE_GET_ENTITY_MANAGER_STATE]: (state, getters) => (entityType: string, refName = ENTITY_MANAGER_REF): EntityManagerState|undefined => {
    const client = getters[CORE_CLIENT_CURRENT] as Client;

    return state.entityManagerState[`${entityType}_${refName}_${client.code}`];
  },

  [CORE_GET_LIFECYCLE_FOR_TYPE]: (state, getters) => (entityType: EntityType): Lifecycle|undefined => {
    const client = getters[CORE_CLIENT_CURRENT] as Client;

    return state.activeLifecycles[`${entityType}_${client.code}`];
  },

  [CORE_GET_LIFECYCLE_HASH_FOR_TYPE]: (state, getters) => (entityType: EntityType): LifecycleHash|undefined => {
    const client = getters[CORE_CLIENT_CURRENT] as Client;

    const l = state.activeLifecycles[`${entityType}_${client.code}`];

    if (l) {
      return createLifecycleHash(l);
    } else {
      return undefined;
    }
  },

  [CORE_DOC_LINK_REQUEST_COUNT] (state) {
    return state.docChooserRequests.length;
  },

  [CORE_NEXT_DOC_LINK_REQUEST] (state) {
    if (state.docChooserRequests.length > 0) {
      return state.docChooserRequests[0];
    } else {
      return null;
    }
  },

  [CORE_DOC_CLOSE_REQUEST_COUNT] (state) {
    return state.docCloseRequests.length;
  },

  [CORE_NEXT_DOC_CLOSE_REQUEST] (state) {
    if (state.docCloseRequests.length > 0) {
      return state.docCloseRequests[0];
    } else {
      return null;
    }
  },

  [CORE_GET_GENERIC_PAGER]: (state, getters) => (name: string): GenericPager|undefined => {
    const client = getters[CORE_CLIENT_CURRENT] as Client;

    return state.genericPagers[`${name}_${client.code}`];
  },
}
