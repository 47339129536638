import * as mt from '../mutationNames'
import { Comment } from '@/models/comments/Comment';
import { CommentListModel } from '@/models/comments/CommentListModel';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import { EntityLinkDescriptionUpdateRequest } from '@/models/core/entities/EntityLinkDescriptionUpdateRequest';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import Vue from 'vue';
import VuexEntityReference from '@/models/core/vuex/VuexEntityReference';
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.COMMENT_ADD_REFERRER] (state, referrer: VuexEntityReferer<Comment>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.COMMENT_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.COMMENT_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.COMMENT_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.COMMENT_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.COMMENT_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.COMMENT_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<Comment>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.COMMENT_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<CommentListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName);
  },
  
  //***********************************************************************
  [mt.COMMENT_SET_LINK_DESCRIPTION] (state, req: EntityLinkDescriptionUpdateRequest): void {
    const lmRef = state.listModels.find(x => x.entity.id == req.entityId) as VuexEntityReference<CommentListModel>|undefined;

    if (lmRef) {
      const link = lmRef.entity.links.find(x => x.entityId == req.targetId)

      if (link) {
        Vue.set(link, "description", req.description);
      }
    }
  },
}