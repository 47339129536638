import * as gt from '../getterNames'
import { Assignment, AssignmentListModel } from '@/models/assignments/Assignment'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF } from '../refNames'
import { AssignmentKanbanState } from '@/models/assignments/AssignmentKanbanState'
import Client from '@/models/core/Client'
import { GetterTree } from 'vuex'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import RootState from '../RootState'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  [gt.ASSIGNMENTS_FOR_CURRENT_CLIENT](state, getters): AssignmentListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.ASSIGNMENTS_LISTED_FOR_REF]: (state) => 
    (client: string, refName: string): AssignmentListModel[] => {
      return state.getListModelsForRef(client, refName);
  },

  [gt.ASSIGNMENTS_LINKED_TO_ENTITY]: (state) => 
    (entity: PrivateEntitySpec): AssignmentListModel[] => {
      return state.getListModelsForRef(entity.client, ENTITY_EDITOR_LINK_REF_STEM + entity.id);
  },
    
  [gt.ASSIGNMENTS_OPEN_FOR_CURRENT_CLIENT] (state, getters): Assignment[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.ASSIGNMENTS_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): Assignment[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.ASSIGNMENT_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },

  [gt.ASSIGNMENT_GET_KANBAN_STATE]: (state) => (clientCode: string): AssignmentKanbanState => {
    return state.kanbanState[clientCode];
  }
}