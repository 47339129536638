import { CustomerNameOnly } from '../customers/CustomerNameOnly';
import PrivateEntityListModel from '../core/entities/PrivateEntityListModel';
import Quote from './Quote';

export default class QuoteListModel extends PrivateEntityListModel<Quote> {
    
    public claimedBy = '';
    public closedAt = '';
    public createdAt = '';
    public customer: CustomerNameOnly = {
        id : "0",
        name : ''
    };
    public name = '';
    public quoteNumber = '';
    public salesRep = '';
    public sellingPrice = 0;
    public status = '';
    public submittedAt = '';
    public targetDate = '';

    protected populateCore(model: Quote): void {
        this.claimedBy = model.claimedBy;
        this.closedAt = model.closedAt;
        this.createdAt = model.createdAt ?? '';
        this.customer = model.customer;
        this.name = model.name;
        this.quoteNumber = model.quoteNumber;
        this.salesRep = model.salesRep.name ?? model.salesRep.address ?? '';
        this.sellingPrice = model.sellingPrice;
        this.status = model.status;
        this.submittedAt = model.submittedAt;
        this.targetDate = model.targetDate ?? '';
    }
}