
import reformatDate, { getCurrentTimezoneOffsetString } from '../../../util/reformatDate';
import { AbstractTextField } from './AbstractTextField';

export default AbstractTextField.extend({
  inheritAttrs: false,

  props: {
    defaultTime24hr: {
      type: String,
      default: "17:00"
    }
  },
  
  data() {
    return {
      internalDate: '',
      internalTime: '',
      localTimeZoneOffset: '',
      showPicker: false,
    }
  },

  computed: {
    combinedEditedDateTimeOffsetString(): string {
      return `${this.selectedDate}T${this.selectedTime}${this.localTimeZoneOffset}`
    },

    formattedDate(): string {
      return reformatDate(this.value, 'mm/dd/yyyy hh:MM tt');
    },

    selectedDate(): string {
      return this.internalDate == '' ? '0001-01-01' : this.internalDate;
    },

    selectedTime(): string {
      return this.internalTime == '' ? '00:00:00' : `${this.internalTime}:00`
    }
  },

  created(): void {
    this.localTimeZoneOffset = getCurrentTimezoneOffsetString();
  },

  methods: {
    close(mode: 'save'|'cancel'): void {
      if (mode == 'save') {
        this.handleInput(this.combinedEditedDateTimeOffsetString);
      }

      this.showPicker = false;
    },

    updatePickerVars(): void {
      this.internalDate = this.value == '0001-01-01T00:00:00+00:00' ? '' : reformatDate(this.value, 'yyyy-mm-dd');
      this.internalTime = this.value == '0001-01-01T00:00:00+00:00' ? this.defaultTime24hr : reformatDate(this.value, 'HH:MM');
    }
  }
})
