
import api from '@/util/api'
import Vue from 'vue'

interface ExcelViewerInterface {
  openExcelData(data: string): void;
}

export default Vue.extend({
  props: {
    light: Boolean,

    height: {
      type: Number,
      default: 600,
    },

    url: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      errorMessage: '',
      loading: false,
    }
  },

  computed: {
    wrapperStyle(): Record<string, string> {
      const style: Record<string, string> = {};

      if (!this.loading || this.light) {
        style.backgroundColor = "#f0f0f0";
      } else {
        style.backgroundColor = "#000000";
      }

      return style;
    }
  },

  watch: {
    url: {
      handler(newUrl: string, oldUrl?: string): void {
        if ((oldUrl === undefined || newUrl != oldUrl) && newUrl > '') {
          this.loadSpreadsheet();
        } else {
          this.getExcelViewer().openExcelData('')
          this.errorMessage = '';
        }
      },
      immediate: true,
    }
  },

  methods: {
    getExcelViewer(): ExcelViewerInterface {
      return this.$refs['viewer'] as unknown as ExcelViewerInterface;
    },
    
    async loadSpreadsheet(): Promise<void> {
      this.loading = true;

      const response = await api.get(this.url, undefined, undefined, 'binaryString');

      this.loading = false;

      if (response) {
        if (!response.error) {
          this.errorMessage = '';
          this.getExcelViewer().openExcelData(response.data as string)
          return;
        }
      }

      this.errorMessage = 'This file cannot be displayed.'
    },
  }
})
