import { AddressListItem } from '@/models/contacts/AddressListItem';
import { Contact } from '@/models/contacts/Contact';
import { ContactListModel } from '@/models/contacts/ContactListModel';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Contact, ContactListModel> {
  public collectionId = "0";

  public addressList: AddressListItem[] = [];

  protected instantiateListModelForEntity(entity: Contact): ContactListModel {
    const listModel = new ContactListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Contact'
  }
}