import { BufferFieldOverrideCollection, BufferMergeProcessor } from "./BufferFieldOverrides";
import { EditorConflictMap, EditorConflictValues, EditorMergeStatus, ENTITY_DELETED_FLAG } from "./EditorConflict";
import { forEachKeyIn, nodeIsEntityArray } from "./BufferTraversalFunctions";
import { applyEdits } from "@/util/applyEdits";
import { BufferedEditorConflictResolverData } from "./BufferedEditorConflictResolverData";
import { BufferedEditorEdit } from "./BufferedEditorEdit";
import { BufferTraversalPath } from "./BufferTraversalPath";
import UpdatableEntity from "../entities/UpdatableEntity";

export type EditorBufferMergeMode = 'merge'|'refresh'
export type EditorBufferMergeResult = {status: EditorMergeStatus; conflictCount: number}

export class EditorBuffer {
  public buffer: unknown;
  public conflictResolverData: BufferedEditorConflictResolverData|null = null;
  public hasUnsavedEdits = false;
  public mergeMode: EditorBufferMergeMode = 'merge'
  public overrides: BufferFieldOverrideCollection = {}
  public refName: string;
  public refreshingBuffer = false;
  public saveActionName: string;
  public valid = true;

  //***********************************************************************************

  private _conflictCount = 0;
  private _conflicts: EditorConflictMap;
  private _externalChangeCount = 0;
  private _id: string;
  private _lastStatus: EditorMergeStatus = 'noChange'
  private _original: unknown;

  //***********************************************************************************

  public get conflicts(): EditorConflictMap {
    return JSON.parse(JSON.stringify(this._conflicts));
  }

  public get externalChangeCount(): number {
    return this._externalChangeCount;
  }

  public get id(): string {
    return this._id;
  }

  public get mergeStatus(): EditorMergeStatus {
    return this._lastStatus;
  }

  public get original(): unknown {
    return this._original;
  }

  //***********************************************************************************

  constructor(
    id: string,
    data: unknown,
    saveActionName: string,
    refName: string,
    buffer?: unknown,
    conflicts?: EditorConflictMap
  ) {
    this._id = id;
    this._original = data;

    if (buffer) {
      this.buffer = buffer;
    } else {
      this.populateBuffer(data);
    }

    if (conflicts) {
      this._conflicts = conflicts;
    } else {
      this._conflicts  = new EditorConflictMap();
    }

    this.saveActionName = saveActionName;
    this.refName = refName;
  }

  //***********************************************************************************

  public dismissMergedStatus(): void {
    if (this._lastStatus == 'merged') {
      this._lastStatus = 'noChange';
    }
  }

  public getConflictingValuesForPath(path: BufferTraversalPath): EditorConflictValues|undefined {
    const mine = path.readFromBuffer(this.buffer);
    const theirs = path.readFromConflictMap(this._conflicts);

    if (theirs == undefined) {
      return undefined;
    }

    return new EditorConflictValues(
      mine,
      theirs
    );
  }

  public applyEdits(buffer: Record<string, unknown>, edits: BufferedEditorEdit[]) {
    this.hasUnsavedEdits = true;
    
    applyEdits(buffer, edits);
  }

  public processExternalUpdate(newData: unknown): EditorMergeStatus {
    const mergeResult = this.updateObjectInBuffer(
      this._original as never,
      newData as never,
      this.buffer as never,
      this.overrides);

    this._conflictCount = mergeResult.conflictCount;
    this._lastStatus = mergeResult.status;

    if (mergeResult.status != 'noChange') {
      this._externalChangeCount++;
    }

    this._original = JSON.parse(JSON.stringify(newData));

    return mergeResult.status
  }

  public reset(newData?: unknown): void {
    this._original = newData ?? this._original;
    this.populateBuffer(this._original);
    this._conflicts = new EditorConflictMap();
    this._conflictCount = 0;
    this._lastStatus = 'noChange';
  }

  public resolveConflict(
    useWhich: 'mine'|'theirs',
    path: BufferTraversalPath,
    resolveEntireParent = false,
    resolveWith: string[]|false = false
  ): void {

    let resolveCount = 0;

    if (resolveEntireParent) {
      const parentPath = path.cloneAndRemoveLast();
      
      if (parentPath) {
        resolveCount = this._resolveMultiple(useWhich, parentPath);
      } else {
        console.error('Cannot resolve entire parent on entity root!');
      }
    } else {
      resolveCount = this._resolveSingle(useWhich, path);

      const pathArr = resolveWith as string[]
      if (pathArr.length > 0) {
        pathArr.forEach(p => {
          const otherPath = BufferTraversalPath.fromPathString(p);

          if (otherPath) {
            resolveCount += this._resolveSingle(useWhich, otherPath);
          }
        });
      }
    }


    this._conflictCount -= resolveCount;
    this.conflictResolverData = null;

    if (this._conflictCount == 0) {
      this._conflicts = new EditorConflictMap();
      this._lastStatus = 'noChange'
    }

    this._externalChangeCount++;
  }

  //****************************************************************************************

  private _compileIds(first: UpdatableEntity[], second: UpdatableEntity[]): string[] {
    const ids: string[] = []

    if (first) {
      first.forEach(e => {
        if (e.id != undefined) {
          ids.push(e.id);
        }
      });
    }

    if (second) {
      second.forEach(e => {
        if (e.id != undefined) {
          if (!ids.includes(e.id)) {
            ids.push(e.id);
          }
        }
      })
    }

    return ids;
  }

  private _mergeStatuses(
    oldStatus: EditorMergeStatus,
    newStatus: EditorMergeStatus
  ): EditorMergeStatus {
    if (oldStatus == 'requiresRefresh' || newStatus == 'requiresRefresh') {
      return 'requiresRefresh';
    } else if (oldStatus == 'conflict' || newStatus == 'conflict') {
      return 'conflict'
    } else if (oldStatus == 'merged' || newStatus == 'merged') {
      return 'merged'
    } else {
      return 'noChange'
    }
  }

  private _removeEntityFromArray(path: BufferTraversalPath): void {
    const entity = path.readFromBuffer(this.buffer) as UpdatableEntity;
    const id = entity.id;
    const arrayPath = path.cloneAndRemoveLast();

    if (arrayPath) {
      const array = arrayPath.readFromBuffer(this.buffer) as UpdatableEntity[]

      if (array && id) {
        const idx = array.findIndex(x => x.id == id);

        if (idx > -1) {
          array.splice(idx, 1);
        }
      }
    }

  }

  private _resolveMultiple(useWhich: 'mine'|'theirs', parentPath: BufferTraversalPath): number {
    let resolveCount = 0;
    
    const conflicts = parentPath.readFromConflictMap(this._conflicts) as EditorConflictMap;
    
    for (const key in conflicts) {
      if (Object.prototype.hasOwnProperty.call(conflicts, key)) {
        const finalPath = BufferTraversalPath.fromPathString(key);

        if (finalPath) {
          const childPath = parentPath.cloneAndAddLevel(finalPath)

          const value = childPath.readFromConflictMap(this._conflicts);

          if (typeof(value) == 'object') {
            resolveCount += this._resolveMultiple(useWhich, childPath);
          } else {
            resolveCount += this._resolveSingle(useWhich, childPath);
          }
        }
      }
    }

    return resolveCount;
  }

  private _resolveSingle(useWhich: 'mine'|'theirs', path: BufferTraversalPath): number {
    if (useWhich == 'theirs') {
      const value = path.readFromConflictMap(this._conflicts);

      if (value == ENTITY_DELETED_FLAG) {
        this._removeEntityFromArray(path);
      } else {
        path.writeToBuffer(this.buffer, value);
      }
    }

    path.clearInConflictMap(this._conflicts);

    return 1;
  }

  private populateBuffer(data: unknown): void {
    this.buffer = JSON.parse(JSON.stringify(data));
    this.hasUnsavedEdits = false;
  }

  private updateEntityArrayInBuffer(
    original: UpdatableEntity[],
    current: UpdatableEntity[],
    buffer: UpdatableEntity[],
    forKey: BufferTraversalPath,
    overrides?: BufferFieldOverrideCollection,
  ): EditorBufferMergeResult {
    const ids = this._compileIds(current, buffer);

    let status: EditorMergeStatus = 'noChange';
    let conflictCount = 0;

    ids.forEach(id => {
      const originalEntity = original.find(x => x.id == id) as never;
      const currentEntity = current.find(x => x.id == id) as never;
      const bufferEntity = buffer.find(x => x.id == id) as never;
      const key = forKey.cloneAndAddLevel(new BufferTraversalPath(id, "elementId"));

      if (currentEntity && !originalEntity) {
        //New entity, so just add it in.
        const duplicate = JSON.parse(JSON.stringify(currentEntity));
        buffer.push(duplicate);

      } else if (!currentEntity && originalEntity) {
        //Deleted entity
        if (bufferEntity) {
          EditorConflictMap.addConflict(this._conflicts, key, ENTITY_DELETED_FLAG);
          status = this._mergeStatuses(status, 'conflict');
          conflictCount++;
        }

      } else {
        const result = this.updateObjectInBuffer(originalEntity, currentEntity, bufferEntity, overrides, key)

        status = this._mergeStatuses(status, result.status);
        conflictCount += result.conflictCount;
      }
    });

    return {status, conflictCount}
  }

  private updateObjectInBuffer(
    original: never,
    current: never,
    buffer: never,
    overrides?: BufferFieldOverrideCollection,
    forKey = new BufferTraversalPath('<root>')
  ): EditorBufferMergeResult {
    
    if (!original && !current && !buffer) {
      return {status: 'noChange', conflictCount: 0};
    } if (!original || !current || !buffer) {
      // console.error(`Mismatched objects during update: ${forKey.fullPathString()}`)
      // console.log("Original:")
      // console.log(original)
      // console.log("Current:")
      // console.log(current)
      // console.log("Buffer:")
      // console.log(buffer)
      return {status: 'noChange', conflictCount: 0};
    }

    let status: EditorMergeStatus = 'noChange'
    let conflictCount = 0;

    const ovr = overrides || {};
    
    forEachKeyIn(current, k => {
      const keyPath = forKey.cloneAndAddLevel(new BufferTraversalPath(k))

      if (ovr[k] == 'ignore') {
        //do nothing!

      } else if (ovr[k] == 'system') {
        buffer[k] = current[k];

      } else if (typeof(ovr[k]) == 'function') {
        const mergeValues = (ovr[k] as BufferMergeProcessor);
        const result = mergeValues(original, current, buffer, k, forKey, this._conflicts);

        status = this._mergeStatuses(status, result.status);
        conflictCount += result.conflictCount;

        // console.log(`Custom merge processor: ${keyPath.fullPathString()}, result: ${result.status}`);

      } else if (Array.isArray(current[k])) {
        let result: EditorBufferMergeResult;

        if (nodeIsEntityArray(current[k], original[k], buffer[k])) {
          result = this.updateEntityArrayInBuffer(
            original[k] as UpdatableEntity[],
            current[k] as UpdatableEntity[],
            buffer[k] as UpdatableEntity[],
            keyPath,
            (ovr[k] ?? {}) as BufferFieldOverrideCollection
          );

        } else {
          result = this.updateValueArrayInBuffer(
            original[k] as never[],
            current[k] as never[],
            buffer[k] as never[],
            (ovr[k] ?? {}) as BufferFieldOverrideCollection,
            keyPath
          );
        }

        status = this._mergeStatuses(status, result.status);
        conflictCount += result.conflictCount;

      } else if (typeof(current[k]) == 'object') {
        const subStatus = this.updateObjectInBuffer(
          original[k],
          current[k],
          buffer[k],
          ovr[k] as BufferFieldOverrideCollection,
          keyPath);
        status = this._mergeStatuses(status, subStatus.status);
        conflictCount += subStatus.conflictCount;

      } else if (current[k] != buffer[k]) {
        if (this.mergeMode == 'merge') {
          status = this._mergeStatuses(status, "merged");

          if (buffer[k] == original[k]) { //unedited fields
            buffer[k] = current[k];

            // console.log(`Merged field: ${keyPath.fullPathString()}`);

          } else if (original[k] != current[k] || original[k] === false || original[k] === true) { //edited in local copy
            EditorConflictMap.addConflict(this._conflicts, keyPath, current[k]);
            status = this._mergeStatuses(status, 'conflict');
            conflictCount++;
          }
        } else {
          status = this._mergeStatuses(status, 'requiresRefresh');
        }

      }

    });

    return {status, conflictCount};
  }

  private updateValueArrayInBuffer(
    originalArr: never[],
    currentArr: never[],
    bufferArr: never[],
    ovr: BufferFieldOverrideCollection,
    path: BufferTraversalPath
  ): EditorBufferMergeResult {
    let status: EditorMergeStatus = 'noChange';
    let conflictCount = 0;

    for (let i = 0; i < Math.max(originalArr.length, currentArr.length, bufferArr.length); i++) {
      const result = this.updateObjectInBuffer(
        originalArr[i],
        currentArr[i],
        bufferArr[i],
        ovr,
        path.cloneAndAddLevel(new BufferTraversalPath(i, "index")),
      );

      status = this._mergeStatuses(status, result.status);
      conflictCount += result.conflictCount;
    }

    return {status, conflictCount}
  }

  public static clone(source: EditorBuffer, newBuffer?: unknown): EditorBuffer {
    const clonedBuffer = new EditorBuffer(
      source.id,
      source.original,
      source.saveActionName,
      source.refName,
      newBuffer ?? source.buffer,
      source.conflicts);
    clonedBuffer.refreshingBuffer = source.refreshingBuffer;
    clonedBuffer.hasUnsavedEdits = source.hasUnsavedEdits;
    clonedBuffer.valid = source.valid;
    clonedBuffer.mergeMode = source.mergeMode;
    clonedBuffer.overrides = source.overrides;
    clonedBuffer._externalChangeCount = source._externalChangeCount;
    clonedBuffer.conflictResolverData = source.conflictResolverData;

    return clonedBuffer;
  }
}