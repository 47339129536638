import Quote from '@/models/quotes/Quote'
import QuoteListModel from '@/models/quotes/QuoteListModel'
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<Quote, QuoteListModel> {
  protected instantiateListModelForEntity(entity: Quote): QuoteListModel {
    const listModel = new QuoteListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'Quote'
  }
}