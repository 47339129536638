
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import IconButton from "../IconButton.vue";
import InsightUser from "@/models/core/InsightUser";
import { PropValidator } from "vue/types/options";
import ReadOnlyField from "../forms/ReadOnlyField.vue";
import UserAutosuggestField from "../forms/UserAutosuggestField.vue"
import { UserIdListQueryVarSet } from "@/models/core/searchQueries/UserIdListQueryVarSet";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    UserAutosuggestField,
    ReadOnlyField,
    IconButton,
  },

  props: {
    locked: Boolean,

    value: {
      type: Object,
      default: null,
    } as PropValidator<UserIdListQueryVarSet<InsightUser>>,
  },

  data() {
    return {
      showItemPicker: false,
    }
  },

  computed: {
    internalValue: {
      get(): InsightUser[] {
        return this.value.values ?? [];
      },

      set(newValue: InsightUser[]): void {
        this.$emit('input', new UserIdListQueryVarSet<InsightUser>(
          newValue,
          this.value.doNotExcludeBlanks,
          this.value.anyValueButBlank
        ));
      }
    },
  },

  methods: {
    handleNewUser(user: InsightUser): void {
      this.internalValue = [...this.internalValue, user];
    },

    handleRemoveUser(user: InsightUser): void {
      this.internalValue = this.internalValue.filter(x => x != user)
    }
  }
})
