import Mailbox from './Mailbox';
import { MailboxType } from './MailboxType';
import PrivateEntityListModel from '../core/entities/PrivateEntityListModel';

export default class MailboxListModel extends PrivateEntityListModel<Mailbox> {
    
  public name = '';
  public address = '';
  public type: MailboxType = MailboxType.Inbox;
  public unreadCount = 0;

  protected populateCore(model: Mailbox): void {
    this.name = model.name;
    this.type = model.type;
    this.address = model.address;
  }
}