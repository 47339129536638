
import { COMMENT_REMOVE_ENTITY_FROM_REF, CORE_SET_ENTITY_EDITOR_STATE } from '@/store/mutationNames';
import { ApiResponse } from '@/models/core/api/ApiResponse';
import { Comment } from '@/models/comments/Comment'
import { COMMENT_DELETE } from '@/store/actionNames';
import CommentDisplayFormatter from './CommentDisplayFormatter.vue';
import { ENTITY_MANAGER_REF } from '@/store/refNames';
import { EntityEditorState } from '@/models/core/entityEditor/EntityEditorState';
import IconButton from '../common/IconButton.vue';
import notifications from '@/util/notifications';
import popup from '@/util/popup';
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import { PropValidator } from 'vue/types/options';
import reformatDate from '@/util/reformatDate';
import RootState from '@/store/RootState';
import Vue from 'vue'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';

export default Vue.extend({
  components: {
    IconButton,
    CommentDisplayFormatter,
  },

  props: {
    entity: {
      type: Object,
      default: null,
    } as PropValidator<Comment>,

    refName: {
      type: String,
      default: '',
    },

    show: Boolean,
  },

  data() {
    return {
      deleting: false,
    }
  },

  computed: {
    canDeleteComment(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1007400") ||
                this.$auth.hasPrivilegeForClient(this.entity.client, "1007410")
    },
    
    formattedDate(): string {
      return reformatDate(this.entity.createdAt, 'mm/dd/yyyy hh:MM tt')
    },
  },

  created() {
    if ((this.$store.state as RootState).entityEditorState[this.entity.id ?? 0] == undefined) {
      const state = new EntityEditorState(
        this.entity.client,
        this.entity.id ?? "0",
        0,
        ENTITY_MANAGER_REF,
        []
      );

      this.$store.commit(CORE_SET_ENTITY_EDITOR_STATE, state);
    }
  },

  

  methods: {
    close(): Promise<boolean> {
      const spec: VuexEntityRefSpec = {
        spec: this.entity as PrivateEntitySpec,
        refName: this.refName
      }

      this.$store.commit(COMMENT_REMOVE_ENTITY_FROM_REF, spec)
      return Promise.resolve(true);
    },

    async handleDelete(): Promise<void> {
      this.deleting = true;

      const confirmed = await popup.confirm(
        this.$store,
        "Confirm Delete",
        "Are you sure you want to delete this commment?",
        "delete",
        "cancel");

      if (confirmed) {
        try {
          await this.$store.dispatch(COMMENT_DELETE, { entity: this.entity, force: false })
          this.$emit('entity-deleted', true);
        } catch (error) {
          const e = error as ApiResponse;
          notifications.warn(this.$store, `There was an error while deleting this comment. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.deleting = false;
    },
  }
})
