import Message from './Message';

export default class MarkAsReadParams {
  public email: string;
  public client: string;
  public id: string;

  public constructor(msg: Message, email: string) {
    this.email = email;
    this.client = msg.client;
    this.id = msg.id || "0";
  }
}