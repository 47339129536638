import { colors } from "vuetify/lib";

function parseHexColor(colorStr: string): {r:number; g: number; b: number} {
  if (/^#?([0-9a-fA-F]{3}){1,2}$/.test(colorStr) == false) return {r:0, g:0, b:0};

  let color = (colorStr.charAt(0) === '#') ? colorStr.substring(1) : colorStr;

  if (color.length == 3) {
    color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
  }

  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  
  return {r, g, b};
}

export function isDarkColor(colorStr: string): boolean {
  if (!colorStr) return false;

  const {r, g, b} = parseHexColor(colorStr);
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return L <= 0.33;
}

export function rgbToHsl(hexStr: string): {h: number; s: number; l: number} {
  let {r, g, b} = parseHexColor(hexStr);

  r /= 255, g /= 255, b /= 255;

  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  const l = (max + min) / 2;
  let h = 0;
  let s: number;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return {h, s, l};
}

const colorArr = (function() {
  const arr = [] as {hex: string; name: string; variant: string}[]
  const colorNames = Object.keys(colors);
  const myColors = colors as unknown as Record<string, Record<string, string>>

  for (let i = 0; i < colorNames.length; i++) {
    const name = colorNames[i];
    const variants = Object.keys(myColors[name]);

    for (let j = 0; j < variants.length; j++) {
      const variant = variants[j];

      arr.push({hex: myColors[name][variant], name, variant});
    }
  }

  return arr;
})();

const variantSequence = [
  'lighten5',
  'lighten4',
  'lighten3',
  'lighten2',
  'lighten1',
  'base',
  'darken1',
  'darken2',
  'darken3',
  'darken4'
]

export function getColorName(hexStr: string): string {
  const entry = colorArr.find(x => x.hex.toLowerCase() == hexStr.toLowerCase());

  return entry ? entry.name : "";
}

export function getLighterShade(hexStr: string): string {
  const entry = colorArr.find(x => x.hex.toLowerCase() == hexStr.toLowerCase());

  if (!entry) return hexStr;

  const variantIdx = variantSequence.indexOf(entry.variant);

  if (variantIdx < 0) return hexStr;

  const newVariant = variantSequence[Math.max(0, variantIdx - 2)];

  const lighterEntry = colorArr.find(x => x.name == entry.name && x.variant == newVariant);

  return lighterEntry ? lighterEntry.hex : hexStr;
}
