
import MessageContact from '@/models/messages/MessageContact'
import { MessageContactState } from '@/models/messages/MessageEnums'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  props: {
    contact: {
      type: Object,
      default: (): null => null,
      required: true,
    } as PropValidator<MessageContact>,

    deletable: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      expanded: false,
    }
  },

  computed: {
    statusIcon(): string {
      if (this.contact.state == MessageContactState.New) {
        return this.deletable ? 'mdi-close' : '';
      } else if (this.contact.state == MessageContactState.Sent) {
        return 'mdi-check';
      } else if (this.contact.state == MessageContactState.Delayed) {
        return 'mdi-clock-outline';
      } else if (this.contact.state == MessageContactState.Undeliverable) {
        return 'mdi-alert-circle-outline';
      } else if (this.contact.state == MessageContactState.Delivered) {
        return 'mdi-check-all';
      } else if (this.contact.state == MessageContactState.Read) {
        return 'mdi-check-all';
      } else {
        return 'mdi-help-circle-outline';
      }
    },

    chipIconColor(): string {
      if (this.contact.state == MessageContactState.New) {
        return '';
      } else if (this.contact.state == MessageContactState.Sent) {
        return '';
      } else if (this.contact.state == MessageContactState.Delayed) {
        return 'warning';
      } else if (this.contact.state == MessageContactState.Undeliverable) {
        return 'error';
      } else if (this.contact.state == MessageContactState.Delivered) {
        return '';
      } else if (this.contact.state == MessageContactState.Read) {
        return 'success';
      } else {
        return '';
      }
    },

    statusText(): string {
      if (this.contact.state == MessageContactState.New) {
        return '';
      } else if (this.contact.state == MessageContactState.Sent) {
        return 'Sent';
      } else if (this.contact.state == MessageContactState.Delayed) {
        return 'Delayed';
      } else if (this.contact.state == MessageContactState.Undeliverable) {
        return 'Undeliverable';
      } else if (this.contact.state == MessageContactState.Delivered) {
        return 'Delivered';
      } else if (this.contact.state == MessageContactState.Read) {
        return 'Read';
      } else {
        return 'Status Unknown';
      }
    },

    statusColor(): string {
      if (this.contact.state == MessageContactState.New) {
        return '';
      } else if (this.contact.state == MessageContactState.Sent) {
        return 'info white--text';
      } else if (this.contact.state == MessageContactState.Delayed) {
        return 'warning white--text';
      } else if (this.contact.state == MessageContactState.Undeliverable) {
        return 'error white--text';
      } else if (this.contact.state == MessageContactState.Delivered) {
        return 'accent';
      } else if (this.contact.state == MessageContactState.Read) {
        return 'success white--text';
      } else {
        return 'info white--text';
      }
    },
  },

  methods: {
    handleIconClick(e: Event): void {
      if (this.deletable) {
        e.preventDefault();
        e.stopPropagation();

        this.$emit('request-removal', this.contact);
      }
    }
  }
})
