
import { Comment } from '@/models/comments/Comment'
import { COMMENT_SAVE } from '@/store/actionNames'
import CommentField from './CommentField.vue'
import EntityLinkRequest from '@/models/core/entities/EntityLinkRequest'
import popup from '@/util/popup'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  components: {
    CommentField,
  },

  props: {
    refName: {
      type: String,
      default: null,
    },

    show: Boolean,

    targetEntity: {
      type: Object,
      default: null,
    } as PropValidator<PrivateEntitySpec>,

    targetEntityType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      newCommentText: '',
      saving: false,
    }
  },

  methods: {
    async dismiss(mode: 'save'|'cancel'): Promise<void> {
      let doClose = false;

      const emptyRegex = /^\s*$/

      if (mode == 'save') {
        if (this.newCommentText && emptyRegex.test(this.newCommentText)) {
          await popup.alert(this.$store, 'Error', 'Please enter a comment before saving.')
        } else {
          const comment = new Comment();
          comment.client = this.targetEntity.client;
          comment.text = this.newCommentText;
          comment.requestedLinks = [new EntityLinkRequest(this.targetEntity.id, this.targetEntityType)]

          this.saving = true;
          await this.$store.dispatch(COMMENT_SAVE, {entity: comment, refName: this.refName})
          this.saving = false;

          doClose = true;
        }
      } else {
        if (!emptyRegex.test(this.newCommentText)) {
          doClose = await popup.confirm(this.$store, "Confirm Cancel", "Are you sure you want to lose your changes?");
        } else {
          doClose = true;
        }
      }

      if (doClose) {
        this.$emit('update:show', false);
        this.newCommentText = '';
      }
    },
  }
})
