
import { AbstractField } from "../common/forms/AbstractField";
import { AddressListItem } from "@/models/contacts/AddressListItem";
import { CONTACT_CURRENT_ADDRESS_LIST } from "@/store/getterNames";
import { CONTACT_FETCH_ADDRESS_LIST } from "@/store/actionNames";
import delay from "@/util/delay";
import { isEmail } from "@/util/validators";
import TextHighlighter from "../common/TextHighlighter.vue";

export default AbstractField.extend({
  components: {
    TextHighlighter,
  },

  props: {
    blockInputOnBlur: Boolean,
    notDense: Boolean,
  },

  data() {
    return {
      focused: false,
      inputErrors: [] as string[],
      inputText: '',
      maxMenuLength: 5,
      selectedItem: 0,
    }
  },

  computed: {
    addressList(): AddressListItem[] {
      return this.$store.getters[CONTACT_CURRENT_ADDRESS_LIST];
    },

    filteredList(): AddressListItem[] {
      return this.addressList
        .filter(x => x.displayName.toLowerCase().indexOf(this.inputText.toLowerCase()) > -1 ||
          x.address.toLowerCase().indexOf(this.inputText.toLowerCase()) > -1)
        .slice(0, this.maxMenuLength);
    },

    showMenu(): boolean {
      return this.inputText > '' && this.filteredList.length > 0;
    }
  },

  created(): void {
    this.$store.dispatch(CONTACT_FETCH_ADDRESS_LIST);
  },

  watch: {
    filteredList(): void {
      this.selectedItem = 0;
    }
  },

  methods: {
    emitAddress(item: AddressListItem): void {
      this.$emit('address-entered', item);
      this.inputText = '';
      this.inputErrors = [];
    },

    async handleBlur(): Promise<void> {
      await delay(200);
      this.focused = false;

      if (!this.blockInputOnBlur) {
        this.tryProcessInput();
      }
    },

    handleFocus(): void {
      this.focused = true;
    },

    handleKeyPress(evt: KeyboardEvent): void {
      if (evt.key == 'Enter') {
        this.tryProcessInput();
      } else if (evt.key == 'ArrowDown') {
        this.selectedItem = (this.selectedItem + 1) % this.maxMenuLength;
      } else if (evt.key == 'ArrowUp') {
        this.selectedItem = this.selectedItem == 0 ? this.maxMenuLength-1 : this.selectedItem - 1;
      }
    },

    handleMenuClick(index: number): void {
      this.selectedItem = index;
      this.tryProcessInput();
    },

    tryProcessInput(): void {
      if (this.showMenu) {
        this.emitAddress(this.filteredList[this.selectedItem]);
      } else if (this.inputText > '') {
        const validator = isEmail("New recipient");

        if (validator(this.inputText) === true) {
          const item: AddressListItem = {
            displayName: '',
            info: '',
            address: this.inputText,
          }

          this.emitAddress(item);
        } else {
          this.inputErrors = ['Please enter a valid email address.'];
        }
      }
    }
  },
})
