export default {
  fromCamelCase (word: string): string {
    if (word > '') {
      return word
        .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
        .replace(/^./, function (str: string) { return str.toUpperCase() })
    } else {
      return word
    }
  }
}
