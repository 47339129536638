import Customer from './Customer';
import PrivateEntityListModel from '../core/entities/PrivateEntityListModel';

export default class CustomerListModel extends PrivateEntityListModel<Customer> {
    
    public name = '';
    public billAddressCity = '';
    public billAddressState = '';
    public deleted = false;

    protected populateCore(model: Customer): void {
        this.name = model.name;
        this.billAddressCity = model.billAddress.city;
        this.billAddressState = model.billAddress.state;
    }
}