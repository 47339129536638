import { Lifecycle } from "./Lifecycle";

export interface LifecycleHash {
  stages: Record<string, string>;
  statuses: Record<string, {icon: string; color: string}>;
}

export function createLifecycleHash(l: Lifecycle): LifecycleHash {
  return {
    stages: l.stages
      .reduce(
        (h, x) => {h[x.name] = x.color; return h;},
        {} as Record<string, string>
      ),
    statuses: l.stages
      .flatMap(x => x.statuses)
      .reduce(
        (h, x) => {h[x.status] = {icon: x.icon, color: x.color}; return h},
        {} as Record<string, {icon: string; color: string}>
      ),
  }
}