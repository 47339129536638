
import { capitalizeFirstLetter } from '@/util/capitalize'
import { describeFilterTerms } from '@/models/core/stateFilters/StateFilterDescriber'
import { PropValidator } from 'vue/types/options'
import { StateAttributeDef } from '@/models/core/stateFilters/StateAttributeDef'
import StateFilterEditorDlg from './StateFilterEditorDlg.vue'
import { StateFilterTerm } from '@/models/core/stateFilters/StateFilters'
import Vue from 'vue'

export default Vue.extend({
  components: {
    StateFilterEditorDlg,
  },

  props: {
    attributes: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<StateAttributeDef[]>,

    closeReasons: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<string[]>,

    errorMessages: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,

    filter: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<StateFilterTerm[]>,

    label: {
      type: String,
      default: "Filter",
    },

    readonly: Boolean,

    stageNames: {
      type: Array,
      default: null,
    } as PropValidator<string[]>,

    useOnlyStage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      showEditor: false,
    }
  },

  computed: {
    filterDescription(): string {
      return capitalizeFirstLetter(describeFilterTerms(this.filter));
    }
  }
})
