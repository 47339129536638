
import { CORE_ADD_ENTITY_EDITOR_DEPENDENT_BUFFER, CORE_CLEAR_EDITOR_BUFFER, CORE_SET_ENTITY_EDITOR_COUNTER, MESSAGE_ADD_REFERRER, MESSAGE_DROP_REFERRER, MESSAGE_REMOVE_ENTITY_FROM_REF, MESSAGE_STORE_ENTITY_FOR_REF } from '@/store/mutationNames'
import { MAILBOX_SENDING_ADDRESSES, MESSAGE_REFERRER_EXISTS, MESSAGES_LINKED_TO_ENTITY, MESSAGES_OPEN_FOR_REF } from '@/store/getterNames'
import { MESSAGE_FETCH_FOR_ENTITY, MESSAGE_LINK_TO_ENTITY, MESSAGE_UNLINK_FROM_ENTITY } from '@/store/actionNames'
import { MessageContactType, MessageState, MessageType } from '@/models/messages/MessageEnums'
import api from '@/util/api'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import { DataTableHeader } from 'vuetify'
import { DeletedSpec } from '@/models/core/spec/DeletedSpec'
import { ENTITY_EDITOR_LINK_REF_STEM } from '@/store/refNames'
import { EntityEditorAddDependentBufferRequest } from '@/models/core/entityEditor/EntityEditorAddDependentBufferRequest'
import { EntityEditorCounterUpdate } from '@/models/core/entityEditor/EntityEditorChangeValueRequest'
import EntityLinkParameters from '@/models/core/entities/EntityLinkParameters'
import EntityLinkRequest from '@/models/core/entities/EntityLinkRequest'
import IconButton from '../common/IconButton.vue'
import IdGenerator from '@/util/IdGenerator'
import { LinkedToSpec } from '@/models/core/spec/LinkedToSpec'
import MailboxFromField from '../mailboxes/MailboxFromField.vue'
import { MailboxType } from '@/models/mailboxes/MailboxType'
import Message from '@/models/messages/Message'
import MessageChooser from "./MessageChooser.vue"
import MessageContact from '@/models/messages/MessageContact'
import MessageEditor from './MessageEditor.vue'
import MessageListModel from '@/models/messages/MessageListModel'
import MessageViewer from './MessageViewer.vue'
import notifications from '@/util/notifications'
import popup from '@/util/popup'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import { PropValidator } from 'vue/types/options'
import reformatDate from '@/util/reformatDate'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest'

export default Vue.extend({
  components: {
    IconButton,
    MailboxFromField, 
    MessageChooser,
    MessageEditor,
    MessageViewer,
  },

  props: {
    entity: {
      type: Object,
      default: (): PrivateEntitySpec => {return {} as PrivateEntitySpec},
    } as PropValidator<PrivateEntitySpec>,

    entityType: {
      type: String,
      default: 'entity',
    },
  },

  data() {
    return {
      currentMailboxAddress: '',
      draftState: MessageState.Draft,
      headers: [
        {
          value: 'state',
          text: ' ',
          width: 20,
        },
        {
          value: 'subject',
          text: 'Subject',
        },
        {
          value: 'from',
          text: 'From',
        },
        {
          value: 'to',
          text: 'To',
        },
        {
          value: 'messageDate',
          text: 'Date',
          width: 190,
        },
        {
          value: 'attachmentCount',
          text: ' ',
          width: 20,
          sortable: false,
        },
        {
          value: 'id',
          text: '',
          width: 40,
        },
      ] as DataTableHeader[],
      linkingMessage: false,
      loadingMessageList: false,
      loadingMessagePreview: false,
      newMessage: null as Message|null,
      sendingState: MessageState.Sending,
      showMessageChooser: false,
      showMessagePreview: false,
    }
  },

  computed: {
    fromAddresses(): string[] {
      return this.$store.getters[MAILBOX_SENDING_ADDRESSES]
    },

    mailboxType(): MailboxType {
      return MailboxType.Custom;
    },

    messages(): MessageListModel[] {
      return this.$store.getters[MESSAGES_LINKED_TO_ENTITY](this.entity);
    },

    messagePreview(): Message|null {
      return this.$store.getters[MESSAGES_OPEN_FOR_REF](this.entity.client, this.refName)[0] as Message || null;
    },

    msgEditorId(): string {
      const msg = (this.messagePreview || {}) as Message;
      return `MessageEditor_LinkedTo_${this.entity.id}_${msg.tempId ?? msg.id}`;
    },

    referrerExists(): boolean {
      return this.$store.getters[MESSAGE_REFERRER_EXISTS](this.refName);
    },

    refName(): string {
      return ENTITY_EDITOR_LINK_REF_STEM + this.entity.id
    },
  },

  watch: {
    'entities.length'(): void {
      const req = new EntityEditorCounterUpdate(this.entity.id, 'messageCount', this.messages.length);
      this.$store.commit(CORE_SET_ENTITY_EDITOR_COUNTER, req)
    }
  },

  created() {
    if (this.currentMailboxAddress == '' && this.fromAddresses.length > 0) {
      this.currentMailboxAddress = this.fromAddresses[0];
    }

    if (!this.referrerExists) {
      this.configureEntityReferrer();
      this.loadMessages();
    }
  },

  methods: {
    configureEntityReferrer(): void {
      const targetEntityId = this.entity.id;

      const spec = new LinkedToSpec<Message>(targetEntityId).and(new DeletedSpec().not());

      const referrer = new VuexEntityReferer(
        this.entity.client,
        this.refName,
        (entity, mode) =>  {
          if (mode == 'listModel'){
            const message = entity as Message;

            return spec.isSatisfiedBy(message) ? 'yes' : 'no';
          }

          return 'noChange';
        }
      );

      this.$store.commit(MESSAGE_ADD_REFERRER, referrer)

      const depBufferRequest = new EntityEditorAddDependentBufferRequest(this.entity.id, this.msgEditorId)
      this.$store.commit(CORE_ADD_ENTITY_EDITOR_DEPENDENT_BUFFER, depBufferRequest)
    },

    dropEntityReferrer(): void {
      this.$store.commit(MESSAGE_DROP_REFERRER, {client: this.entity.client, name: this.refName});
    },

    formatMessageDate(messageDate: Date|string|null|undefined): string {
      return reformatDate(messageDate, 'mm/dd/yyyy hh:MM tt');
    },

    handleNewMessageRequest(): void {
      if (this.currentMailboxAddress) {
        const currentContact = new MessageContact();
        currentContact.name = null;
        currentContact.address = this.currentMailboxAddress;
        currentContact.type = MessageContactType.From;

        const newMsg = new Message();
        newMsg.contacts.push(currentContact);
        newMsg.id = IdGenerator.CreateLocalId();
        newMsg.messageDate = new Date().toISOString();
        newMsg.client = this.entity.client;
        newMsg.type = MessageType.ExternalAdHoc;
        newMsg.requestedLinks = [
          new EntityLinkRequest(this.entity.id, this.entityType)
        ]

        this.setMessagePreview(newMsg);
        this.showMessagePreview = true;
      } else {
        notifications.warn(this.$store, "Please select a mailbox before composing messages.");
      }
    },

    handleForwardRequest(): void {
      if (!this.currentMailboxAddress) {
        notifications.warn(this.$store, "Please select a mailbox before composing messages.");
        return;
      }

      const msg = this.messagePreview;
      if (!msg) {
        notifications.warn(this.$store, "Please preview a message before forwarding.");
        return;
      }

      let currentContact = msg.contacts.find(x => x.address == this.currentMailboxAddress);

      if (!currentContact) {
        currentContact = new MessageContact();
        currentContact.address = this.currentMailboxAddress;
        currentContact.type = MessageContactType.From;
      }

      const newMsg = Message.GenerateForward(msg, currentContact)

      newMsg.id = IdGenerator.CreateLocalId();

      this.setMessagePreview(newMsg);
    },

    handleMessageSent(): void {
      this.showMessagePreview = false;
      this.$store.commit(CORE_CLEAR_EDITOR_BUFFER, this.msgEditorId);
      this.setMessagePreview(null);
    },

    handleReplyRequest(mode: 'single'|'all'): void {
      if (!this.currentMailboxAddress) {
        notifications.warn(this.$store, "Please select a mailbox before composing messages.");
        return;
      }

      const msg = this.messagePreview;
      if (!msg) {
        notifications.warn(this.$store, "Please preview a message before replying.");
        return;
      }

      let currentContact = msg.contacts.find(x => x.address == this.currentMailboxAddress);

      if (!currentContact) {
        currentContact = new MessageContact();
        currentContact.address = this.currentMailboxAddress;
        currentContact.type = MessageContactType.From;
      }

      const newMsg = Message.GenerateReply(mode, msg, currentContact)

      newMsg.id = IdGenerator.CreateLocalId();

      this.setMessagePreview(newMsg);
    },

    async handleUnlinkRequest(msg: PrivateEntitySpec): Promise<void> {
      const confirmed = await popup.confirm(
        this.$store,
        "Confirm Action",
        `Are you sure you want to remove the link between this message and the current ${this.entityType}?`);

      if (confirmed) {
        const parameters: EntityLinkParameters = {
          target: this.entity,
          targetType: this.entityType,
          linkOwner: msg,
        }

        await this.$store.dispatch(MESSAGE_UNLINK_FROM_ENTITY, parameters)

        this.showMessagePreview = false;
      }
    },

    async loadMessages(): Promise<void> {
      this.loadingMessageList = true;

      await this.$store.dispatch(MESSAGE_FETCH_FOR_ENTITY, this.entity);

      this.loadingMessageList = false;
    },

    messageIcon(state: MessageState): string {
      if (state == MessageState.Draft) {
        return 'mdi-email-edit-outline';

      } else if (state == MessageState.Sending ||
                 state == MessageState.SentPendingTemplatePopulation ||
                 state == MessageState.Sent)
      {
        return 'mdi-send';

      } else if (state == MessageState.Recieved) {
        return 'mdi-inbox';

      } else {
        return '';
      }
    },

    messageStateText(state: MessageState): string {
      if (state == MessageState.Draft) {
        return 'Draft';

      } else if (state == MessageState.Sending ||
                 state == MessageState.SentPendingTemplatePopulation ||
                 state == MessageState.Sent)
      {
        return 'Sent';

      } else if (state == MessageState.Recieved) {
        return 'Received';

      } else {
        return 'Status Unknown';
      }
    },

    async onSelectMessage(message: MessageListModel): Promise<void> {
      this.linkingMessage = true;

      const parameters: EntityLinkParameters = {
        target: this.entity,
        targetType: this.entityType,
        linkOwner: message as PrivateEntitySpec,
      }

      try {
        await this.$store.dispatch(MESSAGE_LINK_TO_ENTITY, {link: parameters, refName: this.refName})

        notifications.succeed(this.$store, `"${message.subject}" has been linked to this ${this.entityType}`);
      } catch(error) {
        const e = error as ApiResponse;
        notifications.fail(this.$store, `Unable to link "${message.subject}" to this ${this.entityType}. Message: ${e.data || e.errorCause || e || "(No Message)"}`);
      }

      this.linkingMessage = false;
    },

    async previewMessage(message: MessageListModel): Promise<void> {
      this.loadingMessagePreview = true;

      const url = `${this.entity.client}/messages/${message.id}`
      const apiResponse = await api.get(url)

      if (apiResponse) {
        if (!apiResponse.error) {
          this.setMessagePreview(apiResponse.data as Message);
          this.showMessagePreview = true;
        } else {
          const e = apiResponse;
          notifications.warn(this.$store, `There was an error loading this message. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.loadingMessagePreview = false;
    },

    setMessagePreview(newMsg: Message|null): void {
      if (this.messagePreview) {
        const spec: VuexEntityRefSpec ={
          refName: this.refName,
          spec: this.messagePreview as PrivateEntitySpec,
        }

        this.$store.commit(MESSAGE_REMOVE_ENTITY_FROM_REF, spec);
      }

      if (newMsg) {
        const request: VuexEntityStorageRequest<Message> = {
          refName: this.refName,
          entity: newMsg
        }

        this.$store.commit(MESSAGE_STORE_ENTITY_FOR_REF, request)
      }
    },

    unlinkById(id: string): void {
      const msg = this.messages.find(x => x.id == id);

      if (msg) {
        this.handleUnlinkRequest(msg);
      }
    },
  }
})
