import { Contact } from "./Contact";
import { CustomerNameOnly } from "../customers/CustomerNameOnly";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";

export class ContactListModel extends PrivateEntityListModel<Contact> {
  public collectionId = "0";
  public collectionName = "";
  public customer: CustomerNameOnly|null = null;
  public displayName = "";
  public email1 = "";
  public phone1 = "";
  
  protected populateCore(model: Contact): void {
    this.collectionId = model.collection.id;
    this.collectionName = model.collection.name;
    this.customer = model.customer;
    this.displayName = model.displayName ?? "";
    this.email1 = model.email1 ?? "";
    this.phone1 = model.phone1 ?? "";
  }
  
}