import ItemCategory from '@/models/items/ItemCategory';
import ItemCategoryListModel from '@/models/items/ItemCategoryListModel';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<ItemCategory, ItemCategoryListModel> {
  protected instantiateListModelForEntity(entity: ItemCategory): ItemCategoryListModel {
    const listModel = new ItemCategoryListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'ItemCategory'
  }
}