import * as Ably from 'ably/promises'
import { CORE_PROCESS_ENTITY_UPDATE, CORE_PROCESS_LINK_COUNT_UPDATE } from '@/store/mutationNames';
import { SecurityCacheControlEventHandler, SecurityCacheControlEventSource } from 'insight-auth';
import { EntityUpdateMessage } from '@/models/core/realtime/EntityUpdateMessage';
import { LinkCountUpdateMessage } from '@/models/core/realtime/LinkCountUpdateMessage';
import { MAILBOX_HANDLE_UNREAD_UPDATE_MSG } from '@/store/actionNames';
import { MailboxUnreadCountUpdate } from '@/models/core/realtime/MailboxUnreadCountUpdate';
import store from '@/store';
import { Types } from 'ably/ably'

let client: Ably.Realtime;
let channel: Types.RealtimeChannelPromise;

function _processMessage(m: Types.Message): void {
  if (m.name == 'SecurityCacheControl') {
    const data = m.data as string[];
    
    if (cacheControlEventHandler) {
      cacheControlEventHandler({
        invalidIds: data,
      })
    }
  } else if (m.name == 'EntityUpdate') {
    const data = m.data as EntityUpdateMessage;
    
    store.commit(CORE_PROCESS_ENTITY_UPDATE, data);
  } else if (m.name == 'LinkCountUpdate') {
    const data = m.data as LinkCountUpdateMessage;

    store.commit(CORE_PROCESS_LINK_COUNT_UPDATE, data);
  } else if (m.name == 'MailboxUnreadCountUpdate') {
    const data = m.data as MailboxUnreadCountUpdate;

    store.dispatch(MAILBOX_HANDLE_UNREAD_UPDATE_MSG, data);
  }
}

let cacheControlEventHandler: SecurityCacheControlEventHandler;

const securityCacheEventSource: SecurityCacheControlEventSource = {
  listen: function (handler: SecurityCacheControlEventHandler): void {
    cacheControlEventHandler = handler;
  }
}

export const RealtimeService = {
  init(key: string, clientCode?: string): void {
    const options: Ably.Types.ClientOptions = { key, echoMessages: false };
    client = new Ably.Realtime(options);

    channel = client.channels.get(`_scc`);
    channel.subscribe(_processMessage);

    if (clientCode) {
      this.setClient(clientCode);
    }
  },

  setClient(clientCode: string): void {
    channel = client.channels.get(`${clientCode}_cw`);
    channel.subscribe(_processMessage);
  },

  getConnectionIdentifier(): string|undefined {
    return client?.connection?.key;
  },

  getSecurityCacheControlEventSource(): SecurityCacheControlEventSource {
    return securityCacheEventSource;
  }
}