import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ActionTree } from "vuex";
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';
import {WorkOrderCategory} from '@/models/workOrderCategories/WorkOrderCategory';
import {WorkOrderCategoryListModel} from '@/models/workOrderCategories/WorkOrderCategoryListModel';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.WORK_ORDER_CATEGORY_DELETE] ({ commit }, data: ApiDeletePayload<WorkOrderCategory>): Promise<void> {
    const url = data.entity.client + '/workOrders/categories/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.WORK_ORDER_CATEGORY_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_CATEGORY_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<WorkOrderCategoryListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/workOrders/categories`
    return ah.getEntityList(
      url,
      client,
      mt.WORK_ORDER_CATEGORY_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_CATEGORY_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<WorkOrderCategoryListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/workOrders/categories`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.WORK_ORDER_CATEGORY_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_CATEGORY_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<WorkOrderCategory | null> {
    const url = request.spec.client + '/workOrders/categories/' + request.spec.id

    return ah.getPrivateEntity<WorkOrderCategory>(url, mt.WORK_ORDER_CATEGORY_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_CATEGORY_SAVE] ({ commit }, data: {entity: WorkOrderCategory; refName: string}): Promise<WorkOrderCategory | null> {
    const url = data.entity.client + '/workOrders/categories'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.WORK_ORDER_CATEGORY_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.WORK_ORDER_CATEGORY_UNDELETE] ({ commit }, entity: WorkOrderCategory): Promise<WorkOrderCategory | null> {
    const url = entity.client + '/workOrders/categories'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.WORK_ORDER_CATEGORY_STORE_ENTITY_FOR_REF, commit);
  },
}