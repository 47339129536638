import PrivateEntity from "../entities/PrivateEntity";
import PrivateEntityListModel from "../entities/PrivateEntityListModel";
import Vue from 'vue'

export default class VuexEntityReference<T extends PrivateEntity | PrivateEntityListModel<PrivateEntity>> {
  public entity: T;
  public refCount = 0;
  public refs: Record<string, boolean> = {};

  constructor(entity: T, initialRef?: string) {
    this.entity = entity;

    if (initialRef) {
      this.addRef(initialRef);
    }
  }

  public addRef(newRef: string): void {
    if (!this.refs[newRef]) {
      Vue.set(this.refs, newRef, true);
      this.refCount++;
    }
  }

  public removeRef(ref: string): void {
    if (this.refs[ref]) {
      this.refs[ref] = false;
      this.refCount--;
    }
  }
}