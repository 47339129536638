
import { StateFilterCommand, StateFilterTerm } from "@/models/core/stateFilters/StateFilters"
import { PropValidator } from "vue/types/options"
import { StateAttributeDef } from "@/models/core/stateFilters/StateAttributeDef"
import StateFilterTermEditor from "./StateFilterTermEditor.vue"
import Vue from 'vue'

function isLinkedToStage(x: StateFilterTerm): boolean {
  return x.command == StateFilterCommand.AssignCompleteForStage ||
    x.command == StateFilterCommand.AssignIncompleteForStage;
}

export default Vue.extend({
  components: {
    StateFilterTermEditor,
  },

  props: {
    attributes: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<StateAttributeDef[]>,

    closeReasons: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<string[]>,

    filter: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<StateFilterTerm[]>,

    readonly: Boolean,

    stageNames: {
      type: Array,
      default: null,
    } as PropValidator<string[]|null>,

    useOnlyStage: {
      type: String,
      default: null,
    },
  },

  watch: {
    useOnlyStage(newName: string) {
      const newArr = this.filter
        .map(x => new StateFilterTerm(
          x.command,
          x.attributeName,
          isLinkedToStage(x) ? newName : x.filterValue,
        ));

      this.$emit("update:filter", newArr);
    }
  },

  methods: {
    addTerm() {
      this.$emit("update:filter", [
        ...this.filter,
        new StateFilterTerm(),
      ])
    },

    handleTermUpdate(newTermValue: StateFilterTerm, idx: number) {
      const newArr = [...this.filter];
      newArr.splice(idx, 1, newTermValue);
      this.$emit("update:filter", newArr);
    },

    removeTerm(idx: number) {
      const newArr = [...this.filter];
      newArr.splice(idx, 1);
      this.$emit("update:filter", newArr);
    }
  }
})
