import { BufferedEditorEdit } from "@/models/core/bufferedEditor/BufferedEditorEdit"
import Vue from "vue"

export const EditEventMixin = Vue.extend({
  methods: {
    emitEditEventForField(fieldName: string, newValue: unknown): void {
      this.$emit('edited', [new BufferedEditorEdit(fieldName, newValue)]);
    },

    emitEditEventForArrayElementChanged(fieldName: string, elementId: string, newValue: unknown): void {
      this.$emit('edited', [new BufferedEditorEdit(fieldName, newValue, elementId)]);
    },

    emitEditEventForArrayElementAdded(fieldName: string, newValue: unknown): void {
      this.$emit('edited', [new BufferedEditorEdit(fieldName, newValue, "NEW")]);
    },

    emitEditEventForArrayElementRemoved(fieldName: string, elementId: string): void {
      this.$emit('edited', [new BufferedEditorEdit(fieldName, null, elementId)]);
    },

    emitEditEvent(edits: BufferedEditorEdit[]): void {
      this.$emit('edited', edits);
    }
  }
})