import * as mt from '../mutationNames'
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import { MutationTree } from "vuex";
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import State from './state';
import Vue from 'vue';
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';
import {WorkOrder} from '@/models/workOrders/WorkOrder';
import { WorkOrderKanbanStateRequest } from '@/models/workOrders/WorkOrderKanbanStateRequest';
import {WorkOrderListModel} from '@/models/workOrders/WorkOrderListModel';

export const mutations: MutationTree<State> = {
  //***********************************************************************
  [mt.WORK_ORDER_ADD_REFERRER] (state, referrer: VuexEntityReferer<WorkOrder>): void {
    state.addReferrer(referrer);
  },

  //***********************************************************************
  [mt.WORK_ORDER_CLEAR_FOR_REF] (state, data: {client: string; refName: string}): void {
    state.dropRefNameForEntities(data.client, data.refName);
    state.dropRefNameForListModels(data.client, data.refName, true);
  },

  //***********************************************************************
  [mt.WORK_ORDER_DROP_REFERRER] (state, data: {client: string; name: string}): void {
    state.dropReferer(data.client, data.name);
  },
  
  //***********************************************************************
  [mt.WORK_ORDER_PURGE] (state, entitySpec: PrivateEntitySpec): void {
    state.purgeEntity(entitySpec)
  },
  
  //***********************************************************************
  [mt.WORK_ORDER_REMOVE_ENTITY_AND_LIST_MODEL_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityAndListModelFromRef(data.spec, data.refName);
  },
  
  //***********************************************************************
  [mt.WORK_ORDER_REMOVE_ENTITY_FROM_REF] (state, data: VuexEntityRefSpec): void {
    state.dropEntityFromRef(data.spec, data.refName);
  },

  //***********************************************************************
  [mt.WORK_ORDER_STORE_ENTITY_FOR_REF] (state, data: VuexEntityStorageRequest<WorkOrder>): void {
    state.storeEntity(data.entity, data.refName, data.mode);
  },
  
  //***********************************************************************
  [mt.WORK_ORDER_STORE_LIST_MODELS_FOR_REF] (state, data: EntitiesForRef<WorkOrderListModel>): void {
    state.storeListModels(data.entities, data.entities[0]?.client || data.client || '', data.refName, data.incompleteList);
  },

  //***********************************************************************
  [mt.WORK_ORDER_KANBAN_STATE_SET] (state, req: WorkOrderKanbanStateRequest): void {
    Vue.set(state.kanbanState, req.client, req.state);
  }
}