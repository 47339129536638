import { BufferedEditorEdit } from "@/models/core/bufferedEditor/BufferedEditorEdit";

export function applyEdits(buffer: Record<string, unknown>, edits: BufferedEditorEdit[]) {
  edits.forEach(edit => {
    if (Object.prototype.hasOwnProperty.call(buffer, edit.bufferUpdatedPropName)) {
      const subEdits = edit.bufferUpdatedPropValue as BufferedEditorEdit[]

      if(
        Array.isArray(subEdits) &&
        subEdits.length > 0 &&
        subEdits[0].bufferUpdatedPropName != undefined &&
        subEdits[0].bufferUpdatedPropValue !== undefined
      ) {
        let child: Record<string, unknown>
        
        if (edit.bufferUpdatedElementId > '') {
          const arr = buffer[edit.bufferUpdatedPropName] as Array<{id: string}>
          const mabyeChild = arr.find(x => x.id == edit.bufferUpdatedElementId) as Record<string, unknown>|undefined

          if (mabyeChild == undefined) {
            console.warn(`Attempted to updated non-existent child. Array: ${edit.bufferUpdatedPropName}, ID: ${edit.bufferUpdatedElementId}`);
            return;
          } else {
            child = mabyeChild;
          }
        } else {
          child = buffer[edit.bufferUpdatedPropName] as Record<string, unknown>;
        }

        applyEdits(child, subEdits)
      } else {
        if (edit.bufferUpdatedElementId == 'NEW') {
          const arr = buffer[edit.bufferUpdatedPropName] as Array<unknown>
          arr.push(edit.bufferUpdatedPropValue)
        } else if (edit.bufferUpdatedElementId > '') {
          if (edit.bufferUpdatedPropValue == null) {
            const arr = buffer[edit.bufferUpdatedPropName] as Array<{id: string}>
            const idx = arr.findIndex(x => x.id == edit.bufferUpdatedElementId)

            if (idx > -1) {
              arr.splice(idx, 1);
            }
          } else {
            console.warn(`Attempted to delete id ${edit.bufferUpdatedElementId} from array ${edit.bufferUpdatedPropName}, but edit value was not null!`, edit)
          }
        } else {
          buffer[edit.bufferUpdatedPropName] = edit.bufferUpdatedPropValue
        }

      }
    } else {
      console.warn(`Attempted to update non-existent field: ${edit.bufferUpdatedPropName} on buffer!`, edit);
    }
  });
}