import PrivateEntity from "../entities/PrivateEntity";

export abstract class Spec<T extends PrivateEntity> {
  constructor() {
    //Do nothing. Just satisfy the demands of typescript
  }

  public abstract isSatisfiedBy(entity: T): boolean;

  public and(other: Spec<T>): Spec<T> {
    return new class extends Spec<T> {
      private _left: Spec<T>;
      private _right: Spec<T>;
    
      constructor(left: Spec<T>, right: Spec<T>) {
        super();
        this._left = left;
        this._right = right;
      }
    
      public isSatisfiedBy(entity: T): boolean {
        return this._left.isSatisfiedBy(entity) && this._right.isSatisfiedBy(entity);
      }
    }(this, other);
  }
    
  public or(other: Spec<T>): Spec<T> {
    return new class extends Spec<T> {
      private _left: Spec<T>;
      private _right: Spec<T>;

      constructor(left: Spec<T>, right: Spec<T>) {
        super();
        this._left = left;
        this._right = right;
      }

      public isSatisfiedBy(entity: T): boolean {
        return this._left.isSatisfiedBy(entity) || this._right.isSatisfiedBy(entity);
      }
    }(this, other);
  }
  
  public not(): Spec<T> {
    return new class extends Spec<T> {
      private _base: Spec<T>;

      constructor(base: Spec<T>) {
        super();
        this._base = base;
      }

      public isSatisfiedBy(entity: T): boolean {
        return !this._base.isSatisfiedBy(entity);
      }
    }(this);
  }
}