
import { maxLength, requiredIf, Validator } from '../../../util/validators'

const maxChars = 150

function propToBool(prop: boolean|string): boolean {
  if (prop === true || prop === false) {
    return prop;
  } else if (prop == '' || (prop + '').toLowerCase() == 'true') {
    return true;
  } else {
    return false;
  }
}

export default {
  methods: {
    getRules(required: boolean, label: string): Validator[] {
      return [
        requiredIf(() => required, () => label),
        maxLength(maxChars, () => label)
      ]
    },

    getMaxChars(): number {
      return maxChars;
    },

    inputClass(isNumberField: boolean, emphasize: boolean): string {
      let classList = propToBool(isNumberField) ? 'right-aligned-field' : '';

      classList += propToBool(emphasize) ? ' emphasize-field' : '';

      return classList
    },
  }
}
