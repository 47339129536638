
import { CORE_CLEAR_ENTITY_EDITOR_STATE, CORE_SET_ENTITY_EDITOR_STATE, FILE_REMOVE_ENTITY_FROM_REF } from '@/store/mutationNames';
import { FILE_DELETE, FILE_SAVE } from '@/store/actionNames';
import api from '@/util/api';
import { ApiResponse } from '@/models/core/api/ApiResponse';
import { ENTITY_MANAGER_REF } from '@/store/refNames';
import { EntityEditorState } from '@/models/core/entityEditor/EntityEditorState';
import IconButton from '../common/IconButton.vue';
import ImageViewer from '../common/ImageViewer.vue';
import notifications from '@/util/notifications';
import PdfViewer from '../common/PdfViewer.vue'
import popup from '@/util/popup';
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import { PropValidator } from 'vue/types/options';
import reformatDate from '@/util/reformatDate';
import RootState from '@/store/RootState';
import SpreadsheetViewer from '../common/SpreadsheetViewer.vue';
import TextViewer from '../common/TextViewer.vue';
import Vue from 'vue'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import WerkFile from '@/models/files/WerkFile';
import { WerkFileType } from '@/models/files/WerkFileType';

declare global{
 interface Navigator{
      msSaveBlob: (blob: Blob, fileName: string) => boolean;
    }
}

export default Vue.extend({
  components: {
    IconButton,
    PdfViewer,
    ImageViewer,
    SpreadsheetViewer,
    TextViewer,
  },

  props: {
    allowUnlink: {
      type: Boolean,
      default: true,
    },

    entity: {
      type: Object,
      default: null,
    } as PropValidator<WerkFile>,

    height: {
      type: Number,
      default: 600,
    },
    
    refName: {
      type: String,
      default: '',
    },

    showCloseButton: Boolean,

    width: {
      type: Number,
      default: 800,
    },
  },

  data() {
    return {
      deleting: false,
      downloadingFile: false,
      newName: '',
      renaming: false,
      savingName: false,
    }
  },

  computed: {
    canDeleteCustomer(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1004400") ||
             this.$auth.hasPrivilegeForClient(this.entity.client, "1004410")
    },
    
    canEditFile(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1004300") ||
             this.$auth.hasPrivilegeForClient(this.entity.client, "1004310")
    },
    
    cssHeight(): string {
      return this.height + 'px'
    },

    cssWidth(): string {
      return this.width + 'px'
    },

    dataUrl(): string {
      return `${api.baseUrl}/${this.entity.client}/files/${this.entity.id}/data`;
    },

    formattedDate(): string {
      return reformatDate(this.entity.createdAt, 'mm/dd/yyyy hh:MM tt')
    },

    isExcel(): boolean {
      return this.entity.type == WerkFileType.Excel;
    },

    isImage(): boolean {
      return this.entity.type == WerkFileType.Jpeg || this.entity.type == WerkFileType.Png;
    },

    isPdf(): boolean {
      return this.entity.type == WerkFileType.Pdf;
    },

    isText(): boolean {
      return this.entity.type == WerkFileType.Text;
    },
  },

  created() {
    if ((this.$store.state as RootState).entityEditorState[this.entity.id ?? 0] == undefined) {
      const state = new EntityEditorState(
        this.entity.client,
        this.entity.id ?? "0",
        0,
        ENTITY_MANAGER_REF,
        []
      );

      this.$store.commit(CORE_SET_ENTITY_EDITOR_STATE, state);
    }
  },

  

  methods: {
    close(): Promise<boolean> {
      const spec: VuexEntityRefSpec = {
        spec: this.entity as PrivateEntitySpec,
        refName: this.refName
      }

      this.$store.commit(CORE_CLEAR_ENTITY_EDITOR_STATE, this.entity.id ?? "")
      this.$store.commit(FILE_REMOVE_ENTITY_FROM_REF, spec)

      this.$emit('file-viewer-closed', true)
      return Promise.resolve(true)
    },

    async downloadFile(): Promise<void> {
      this.downloadingFile = true;
      const response = await api.get(this.dataUrl, undefined, undefined, 'blob');
      this.downloadingFile = false;
      
      if (response) {
        if (!response.error) {
          this.saveToLocalDevice(this.entity.name, response.data as Blob);
          return;
        } else {
          notifications.fail(this.$store, `There was a problem downloading this file. Message: ${response.data || response.errorCause || response || "(No Message)"}`);
        }
      } else {
        notifications.fail(this.$store, `There was a problem downloading this file.`);
      }
    },

    async handleDelete(): Promise<void> {
      this.deleting = true;

      const confirmed = await popup.confirm(
        this.$store,
        "Confirm Delete",
        "Are you sure you want to delete this file?",
        "delete",
        "cancel",
        "This will delete all links to this file from other documents, such as quotes!");

      if (confirmed) {
        try {
          const id = this.entity.id ?? "";

          await this.$store.dispatch(FILE_DELETE, { entity: this.entity, force: false })
          this.$store.commit(CORE_CLEAR_ENTITY_EDITOR_STATE, id)
          
          this.$emit('entity-deleted', true);
        } catch (error) {
          const e = error as ApiResponse;
          notifications.warn(this.$store, `There was an error while deleting this file. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.deleting = false;
    },
    
    renameStart(): void {
      this.newName = this.entity.name;
      this.renaming = true;
    },

    async renameEnd(save: boolean): Promise<void> {
      if (!save) {
        this.renaming = false;
      } else {
        this.savingName = true;

        const file = JSON.parse(JSON.stringify(this.entity))

        file.name = this.newName;

        const props = {
          entity: file,
          refName: this.refName,
        }

        try{
          await this.$store.dispatch(FILE_SAVE, props)
          this.renaming = false
        } catch (error) {
          const e = error as ApiResponse;
          notifications.warn(this.$store, `Failed to change the file name. Message: ${e.data || e.errorCause || e || "(No message)"}`)
        }

        this.savingName = false
      }
    },
    
    requestUnlink(): void {
      this.$emit('request-unlink', true);
    },

    saveToLocalDevice(filename: string, data: Blob): void {
      if(window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(data, filename);
      } else{
        const elem = window.document.createElement('a');
        const url = window.URL.createObjectURL(data);
        elem.href = url;
        elem.download = filename;        
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        window.URL.revokeObjectURL(url);
      }
    },
  }
})
