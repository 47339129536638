
import * as gt from '../../store/getterNames'
import { DefaultFileQuery, FileSearchFieldDefs } from './FileSearchFieldDefs'
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames'
import { GetWerkFileColor, GetWerkFileIcon, GetWerkFileTypeDescription, WerkFileType } from '@/models/files/WerkFileType'
import { DataTableHeader } from 'vuetify'
import EntityManager from '../common/EntityManager.vue'
import { FILE_STORE_ENTITY_FOR_REF } from '@/store/mutationNames'
import { FILE_VIEWER } from '@/router/routeNames'
import FileUploader from './FileUploader.vue'
import { PropValidator } from 'vue/types/options'
import reformatDate from '@/util/reformatDate'
import Vue from 'vue'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest'
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode'
import WerkFile from '../../models/files/WerkFile'
import WerkFileListModel from '../../models/files/WerkFileListModel'

export default Vue.extend({
  components: {
    EntityManager,
    FileUploader,
  },
  
  props: {
    clientCode: {
      type: String,
      default: ''
    },

    id: {
      type: String,
      default: ""
    },

    useAsChooser: Boolean,
    
    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<WerkFileListModel|null>,
  },
  
  data() {
    return {
      defaultQuery: DefaultFileQuery,
      searchFieldDefs: FileSearchFieldDefs,
      showNewFileDlg: false,
      tableHeaders: [
        {
          value: 'name',
          text: 'Name',
        },
        {
          value: 'type',
          text: 'Type',
        },
        {
          value: 'createdAt',
          text: 'Date',
          width: 190,
        },
      ] as DataTableHeader[],
    }
  },  

  computed: {
    canCreateFiles(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1004200") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1004210")
    },

    canEditFiles(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1004300") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1004310")
    },
    
    canViewFiles(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1004100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1004110")
    },

    editorRoute(): string {
      return FILE_VIEWER
    },

    files(): WerkFileListModel[] {
      return this.$store.getters[gt.FILES_LIST_FOR_REF](this.clientCode, this.refName)
    },

    openFiles(): WerkFile[] {
      return this.$store.getters[gt.FILES_OPEN_FOR_REF](this.clientCode, this.refName)
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    }
  },

  methods: {
    fileTypeColor(type: WerkFileType): string {
      return GetWerkFileColor(type);
    },

    fileTypeIcon(type: WerkFileType): string {
      return GetWerkFileIcon(type);
    },

    fileTypeText(type: WerkFileType): string {
      return GetWerkFileTypeDescription(type);
    },

    formatDateCreated(createdDate: Date|string|null|undefined): string {
      return reformatDate(createdDate, 'mm/dd/yyyy hh:MM tt');
    },

    handleFileUploaded(file: WerkFile): void {
      const req: VuexEntityStorageRequest<WerkFile> = {
        entity: file,
        refName: this.refName,
        mode: VuexEntityStorageRequestMode.ListModelOnly
      }

      this.$store.commit(FILE_STORE_ENTITY_FOR_REF, req);
    }
  },
})
