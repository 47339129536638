import PrivateEntityListModel from '../core/entities/PrivateEntityListModel';
import { WorkOrderCategory } from './WorkOrderCategory';

export class WorkOrderCategoryListModel extends PrivateEntityListModel<WorkOrderCategory> {
    public name = '';
    public description = '';

    protected populateCore(model: WorkOrderCategory): void {
        this.name = model.name;
        this.description = model.description;
    }
}