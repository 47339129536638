import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ActionTree } from "vuex";
import { AddressListItem } from '@/models/contacts/AddressListItem';
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import {Contact} from '@/models/contacts/Contact';
import {ContactListModel} from '@/models/contacts/ContactListModel';
import { ENTITY_MANAGER_REF } from '../refNames';
import { MoveToCollectionParams } from '@/models/contacts/MoveToCollectionParams';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.CONTACT_CHANGE_COLLECTION] ({ commit }, req: MoveToCollectionParams): Promise<Contact | null> {
    const url = req.contact.client + '/contacts'

    return ah.executeActionOnEntity(req.contact, url, 'changeCollection',
      mt.CONTACT_STORE_ENTITY_FOR_REF, commit, {destination: req.destination});
  },
  
  //***********************************************************************
  async [ac.CONTACT_DELETE] ({ commit }, data: ApiDeletePayload<Contact>): Promise<void> {
    const url = data.entity.client + '/contacts/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.CONTACT_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.CONTACT_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<ContactListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/contacts`
    return ah.getEntityList(
      url,
      client,
      mt.CONTACT_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.CONTACT_FETCH_ADDRESS_LIST] ({ commit, getters }): Promise<AddressListItem[]> {
    const client = (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/contacts/emailList`

    const response = await api.get(url);

    if (!response) return Promise.reject();

    if (response.error) {
      return Promise.reject(response)
    }

    const list = response.data as AddressListItem[]

    commit(mt.CONTACT_ADDRESS_LIST_REPLACE, list)

    return list;
  },

  //***********************************************************************
  async [ac.CONTACT_FETCH_FOR_COLLECTION] (
    { commit, state, getters },
    {requestedClient, requestedCollectionId}
  ): Promise<ContactListModel[]> {

    const client = requestedClient || getters[gt.CORE_CLIENT_CURRENT] || {}
    const collectionId = requestedCollectionId || state.collectionId;

    if (collectionId != state.collectionId) {
      commit(mt.CONTACT_MANAGER_COLLECTION_ID_SET, collectionId);
    }

    const url = `${client.code}/contacts/inCollection/${collectionId}`
    return ah.getEntityList(url, client.code, mt.CONTACT_STORE_LIST_MODELS_FOR_REF, ENTITY_MANAGER_REF, commit)
  },

  //***********************************************************************
  async [ac.CONTACT_FETCH_FOR_CUSTOMER] (
    { commit, getters },
    {requestedClient, requestedCustomerId, refName}
  ): Promise<ContactListModel[]> {

    const client = requestedClient || getters[gt.CORE_CLIENT_CURRENT] || {}

    const url = `${client.code}/contacts/forCustomer/${requestedCustomerId}`
    return ah.getEntityList(url, client.code, mt.CONTACT_STORE_LIST_MODELS_FOR_REF, refName, commit)
  },

  //***********************************************************************
  async [ac.CONTACT_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<Contact | null> {
    const url = request.spec.client + '/contacts/' + request.spec.id

    return ah.getPrivateEntity<Contact>(url, mt.CONTACT_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.CONTACT_SAVE] ({ commit }, data: {entity: Contact; refName: string}): Promise<Contact | null> {
    const url = data.entity.client + '/contacts'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.CONTACT_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.CONTACT_UNDELETE] ({ commit }, entity: Contact): Promise<Contact | null> {
    const url = entity.client + '/contacts'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.CONTACT_STORE_ENTITY_FOR_REF, commit);
  },
}