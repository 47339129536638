
import { GetWerkFileColor, GetWerkFileIcon, WerkFileType } from '@/models/files/WerkFileType'
import api from '@/util/api'
import FileViewer from '../files/FileViewer.vue'
import notifications from '@/util/notifications'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'
import WerkFile from '@/models/files/WerkFile'
import WerkFileListModel from '@/models/files/WerkFileListModel'

export default Vue.extend({
  components: {
    FileViewer,
  },

  props: {
    allowRemove: Boolean,

    attachments: {
      type: Array,
      default: (): WerkFileListModel[] => [],
    } as PropValidator<WerkFileListModel[]>,

    client: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      attachmentPreview: null as WerkFile|null,
      loadingAttachment: false,
      showAttachmentPreview: false,
    }
  },

  methods: {
    fileTypeColor(type: WerkFileType): string {
      return GetWerkFileColor(type);
    },

    fileTypeIcon(type: WerkFileType): string {
      return GetWerkFileIcon(type);
    },
    
    async onAttachmentChipClick(file: WerkFileListModel): Promise<void> {
      this.loadingAttachment = true;
      const url = `${this.client}/files/${file.id}`

      try {
        const result = await api.get(url);

        if (result) {
          if (!result.error) {
            this.attachmentPreview = result.data as WerkFile;
            this.showAttachmentPreview = true;
          } else {
            notifications.fail(this.$store, `There was an error while loading this file! Message: ${result.data || result.errorCause || result || "(No Message)"}`)
          }
        }
      } catch (error) {
        notifications.fail(this.$store, 'There was an error while loading this file!')
      }

      this.loadingAttachment = false;
    },

    onAttachmentChipClose(file: WerkFileListModel): void {
      const remainingChips = this.attachments.filter(x => x.id != file.id);

      this.$emit('update:attachments', remainingChips);
    }
  }
})
