import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { TicketType, TicketTypeListModel } from '@/models/tickets/TicketType';
import { ActionTree } from "vuex";
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.TICKET_TYPE_DELETE] ({ commit }, data: ApiDeletePayload<TicketType>): Promise<void> {
    const url = data.entity.client + '/tickets/types/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.TICKET_TYPE_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.TICKET_TYPE_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<TicketTypeListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/tickets/types`
    return ah.getEntityList(
      url,
      client,
      mt.TICKET_TYPE_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false)
  },

  //***********************************************************************
  async [ac.TICKET_TYPE_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<TicketTypeListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/tickets/types`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.TICKET_TYPE_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false,
      req.pagerId,
      req.pageSize,
      req.pageNumber,
      req.pageDataMutationName,
      req.orderBy,
      req.orderDescending,
    )
  },

  //***********************************************************************
  async [ac.TICKET_TYPE_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<TicketType | null> {
    const url = request.spec.client + '/tickets/types/' + request.spec.id

    return ah.getPrivateEntity<TicketType>(url, mt.TICKET_TYPE_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.TICKET_TYPE_SAVE] ({ commit }, data: {entity: TicketType; refName: string}): Promise<TicketType | null> {
    const url = data.entity.client + '/tickets/types'

    const mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add;

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.TICKET_TYPE_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.TICKET_TYPE_UNDELETE] ({ commit }, entity: TicketType): Promise<TicketType | null> {
    const url = entity.client + '/tickets/types'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.TICKET_TYPE_STORE_ENTITY_FOR_REF, commit);
  },
}