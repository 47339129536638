import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF } from '../refNames';
import { ActionTree } from "vuex";
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import { Comment } from '@/models/comments/Comment';
import { CommentListModel } from '@/models/comments/CommentListModel';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import RootState from '../RootState';
import State from './state';
import { UnreadMentionFetchRequest } from '@/models/comments/UnreadMentionFetchRequest';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.COMMENT_DELETE] ({ commit }, data: ApiDeletePayload<Comment>): Promise<void> {
    const url = data.entity.client + '/comments/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.COMMENT_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.COMMENT_FETCH] (
    { commit, getters },
    req: VuexFetchRequest
  ): Promise<CommentListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/comments`
    return ah.getEntityList(
      url,
      client,
      mt.COMMENT_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false);
  },

  //***********************************************************************
  async [ac.COMMENT_FETCH_FOR_ENTITY] ({ commit }, entity: PrivateEntitySpec): Promise<CommentListModel[] | null> {
    const url = `${entity.client}/comments/linkedTo/${entity.id}`
    const apiResponse = await api.get(url);

    if (apiResponse) {
      if (!apiResponse.error) {
        const comments = apiResponse.data as CommentListModel[];

        const mutationData: EntitiesForRef<CommentListModel> = {
          entities: comments,
          refName: ENTITY_EDITOR_LINK_REF_STEM + entity.id,
        }

        commit(mt.COMMENT_STORE_LIST_MODELS_FOR_REF, mutationData);

        return comments;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.COMMENT_FETCH_LINK_DESCRIPTIONS] ({ commit }, entity: CommentListModel): Promise<void> {
    entity.links.forEach(l => {
      if (!l.description) {
        ah.getLinkDescription(entity.client, entity.id, l.type, l.entityId, mt.COMMENT_SET_LINK_DESCRIPTION, commit);
      }
    });
  },

  //***********************************************************************
  async [ac.COMMENT_FETCH_UNREAD_FOR_USER] ({ commit }, req: UnreadMentionFetchRequest): Promise<CommentListModel[] | null> {
    const url = `${req.client}/comments/unreadForUser/${req.userId}`
    const apiResponse = await api.get(url);

    if (apiResponse) {
      if (!apiResponse.error) {
        const comments = apiResponse.data as CommentListModel[];

        const mutationData: EntitiesForRef<CommentListModel> = {
          entities: comments,
          refName: req.refName,
        }

        commit(mt.COMMENT_STORE_LIST_MODELS_FOR_REF, mutationData);

        return comments;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.COMMENT_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<Comment | null> {
    const url = request.spec.client + '/comments/' + request.spec.id

    return ah.getPrivateEntity<Comment>(url, mt.COMMENT_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.COMMENT_MARK_AS_READ] ({ commit }, entity: Comment): Promise<Comment | null> {
    const url = entity.client + '/comments'

    return ah.executeActionOnEntity(
      entity,
      url,
      'markAsRead',
      mt.COMMENT_STORE_ENTITY_FOR_REF,
      commit);
  },

  //***********************************************************************
  async [ac.COMMENT_SAVE] ({ commit }, data: {entity: Comment; refName: string}): Promise<Comment | null> {
    const url = data.entity.client + '/comments'

    let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

    if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
      const targetEntityId = data.refName.substr(ENTITY_EDITOR_LINK_REF_STEM.length);

      if (data.entity.links && data.entity.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.ListModelOnly;
      } else if (data.entity.requestedLinks && data.entity.requestedLinks.findIndex(x => x.id == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.ListModelOnly;
      }
    } else if (data.refName == ENTITY_MANAGER_REF) {
      mode = VuexEntityStorageRequestMode.Add
    }

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.COMMENT_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.COMMENT_UNDELETE] ({ commit }, entity: Comment): Promise<Comment | null> {
    const url = entity.client + '/comments'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.COMMENT_STORE_ENTITY_FOR_REF, commit);
  },
}