import BaseListModel from "../entities/BaseListModel";
import { DeletedSpec } from "../spec/DeletedSpec";
import { ENTITY_MANAGER_REF } from "@/store/refNames";
import { EntityPagingData } from "../entities/EntityPagingData";
import PrivateEntity from "../entities/PrivateEntity";
import { SearchUIFieldValue } from "../searchQueries/SearchUIOption";
import { Spec } from "../spec/Spec";

export class EntityManagerState {
  private _entityType: string;
  private _refName: string;
  private _client: string;
  private _useServerSidePaging: boolean;

  public multiSelect = false;
  public refSpec: Spec<PrivateEntity> = new DeletedSpec<PrivateEntity>().not();
  public search = '';
  public searchValues: SearchUIFieldValue[] = []
  public selectedRows: BaseListModel[] = [];
  public showDeleted = false;
  public showMultiSelectButton = true;
  public sortByInternal = 'name';
  public sortDescInternal = false;
  public clientPageNumber = 1;
  public requestedSearchPageSize = 50;
  public serverPageSize?: number;
  public serverPageNumber?: number;
  public serverSideEntityCount?: number;

  public get entityType(): string {
    return this._entityType;
  }

  public get refName(): string {
    return this._refName;
  }

  public get client(): string {
    return this._client;
  }

  public get useServerSidePaging(): boolean {
    return this._useServerSidePaging;
  }

  constructor(entityType: string, client: string, useServerSidePaging = false, refName = ENTITY_MANAGER_REF) {
    this._entityType = entityType;
    this._refName = refName;
    this._client = client;
    this._useServerSidePaging = useServerSidePaging;

    if (useServerSidePaging) {
      this.serverPageSize = 50;
      this.serverPageNumber = 0;
      this.serverSideEntityCount = 0;
    }
  }

  public updateServerSidePagingData(data: EntityPagingData): void {
    if (this.useServerSidePaging) {
      this.serverPageSize = data.pageSize;
      this.serverPageNumber = data.pageNumber;
      this.serverSideEntityCount = data.entityCount;
    } else {
      this.serverPageSize = undefined;
      this.serverPageNumber = undefined;
      this.serverSideEntityCount = undefined;
    }
  }

  public static clone(original: EntityManagerState): EntityManagerState {
    const copy = new EntityManagerState(
      original.entityType,
      original.client,
      original.useServerSidePaging,
      original.refName
    )
    ;
    Object.assign(copy, original);

    return copy;
  }
}