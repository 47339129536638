
import Vue from 'vue'

export default Vue.extend({
  props: {
    httpErrorCode: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      showAlert: true,
    }
  },
  
  computed: {
    type(): string {
      if (this.httpErrorCode == 404) {
        return 'notFound'
      } else if (this.httpErrorCode == 403) {
        return 'unauthorized'
      } else {
        return 'serverError'
      }
    },

    titleColor(): string {
      return this.type != 'serverError' ? 'warning--text' : 'error--text'
    },

    titleMsg(): string {
      if (this.type == 'notFound') {
        return "We're sorry, that page doesn't exist"
      } else if (this.type == 'unauthorized') {
        return "We're sorry, you don't have permission to access the page you requested."
      } else {
        return "We're sorry, the server encountered an error"
      }
    },

    alertColor(): string {
      return this.type != 'serverError' ? 'info' : 'warning'
    },

    alertMsg(): string {
      if (this.type == 'notFound') {
        return 'HINT: Did you type the URL correctly?'
      } else if (this.type == 'unauthorized') {
        return 'If you believe this is an error, contact your account administrator.'
      } else {
        return 'Most errors are fixed automatically. If this one persists, please contact Insight Business Group'
      }
    }
  }
})
