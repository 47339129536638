
import { AssignmentTemplateListModel } from "@/models/assignments/AssignmentTemplate"
import IconWithTooltip from "../../common/IconWithTooltip.vue"
import { PropValidator } from "vue/types/options"
import Vue from 'vue'

export default Vue.extend({
  components: {
    IconWithTooltip,
  },

  props: {
    compact: Boolean,

    value: {
      type: Object,
      default: null,
    } as PropValidator<AssignmentTemplateListModel>
  }
})
