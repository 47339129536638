
import { ValueFormatter } from "@/util/ValueFormatter";

function propToBool(prop: boolean|string): boolean {
  if (prop === true || prop === false) {
    return prop;
  } else if (prop == '' || (prop + '').toLowerCase() == 'true') {
    return true;
  } else {
    return false;
  }
}

export default {
  methods: {
    formattedValue(formatter: ValueFormatter, value: string|number): string {
      const internalFormatter = formatter || function(v: string|number): string {return v + ''};
      return internalFormatter(value);
    },

    inputClass(isNumberField: boolean, emphasize: boolean): string {
      let classList = propToBool(isNumberField) ? 'right-aligned-field' : '';

      classList += propToBool(emphasize) ? ' emphasize-field' : '';

      return classList
    },
  }
}
