
import api from '@/util/api';
import pdf from 'vue-pdf'
import PdfRequest from '../../models/core/PdfRequest';
import ScrollBox from './ScrollBox.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    pdf,
    ScrollBox,
  },

  props: {
    height: {
      type: String,
      default: "6in",
    },

    loading: Boolean,

    postData: {
      type: Object,
      default: null,
      required: false,
    },

    url: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      floatTools: false,
      pageCount: 0,
      pdfLoaded: false,
      pdfLoadProgress: 0,
      urlWithAuth: null as PdfRequest | string | null | Uint8Array
    }
  },

  computed: {
    showDownload(): boolean {
      return this.urlWithAuth instanceof Uint8Array
    },
  },

  watch: {
    url: {
      async handler(): Promise<void> {
        if (this.url > '') {
          this.pdfLoaded = false;
          this.$emit('update:loading', !this.pdfLoaded);
          this.pageCount = 0;

          if (this.postData != null) {
            const result = await api.create(this.url, this.postData, undefined, false, 'blob');

            if (result) {
              if (!result.error) {
                var r = new Response(result.data as Blob);
                var arr = new Uint8Array(await r.arrayBuffer());
                this.urlWithAuth = arr;
              } else {
                this.$emit("load-error", `Failed to load PDF. Mesage: ${result.data || result.errorCause || "(No message)"}`)
                this.$emit('update:loading', false);
              }
            } else {
              this.$emit("load-error", `Failed to load PDF due to a network error. Check your connection and try again.`)
              this.$emit('update:loading', false);
            }
          } else {
            this.urlWithAuth = {
              url: this.url,
              httpHeaders: {
                "Authorization": await this.$auth.getBearerAuthForApi() ?? ''
              }
            };
          }
        } else {
          this.pdfLoaded = false;
          this.urlWithAuth = null;
        }
      },
      immediate: true,
    }
  },

  methods: {
    downloadPdf(): void {
      const buffer = (this.urlWithAuth as Uint8Array).buffer;
      const blob = new Blob([buffer], {type: "application/pdf"});
      const urlParts = this.url.split("/");
      this.saveToLocalDevice(`${urlParts[urlParts.length-1]}.pdf`, blob);
    },

    handlePdfLoaded(): void {
      this.pdfLoaded = true;
      this.$emit('update:loading', false);
    },

    onIntersect(entries: IntersectionObserverEntry[]): void {
      this.floatTools = !entries[0].isIntersecting;
    },

    saveToLocalDevice(filename: string, data: Blob): void {
      if(window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(data, filename);
      } else{
        const elem = window.document.createElement('a');
        const url = window.URL.createObjectURL(data);
        elem.href = url;
        elem.download = filename;        
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        window.URL.revokeObjectURL(url);
      }
    },
  }
})
