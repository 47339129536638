
import { CORE_BUFFERED_EDITOR_APPLY_EDITS, CORE_DISMISS_EDITOR_MERGE_STATUS, CORE_OPEN_CONFLICT_RESOLVER, CORE_RESOLVE_EDITOR_CONFLICT, CORE_SET_EDITOR_BUFFER_HAS_UNSAVED, CORE_SET_EDITOR_BUFFER_SAVE_ACTION_NAME, CORE_SET_EDITOR_BUFFER_VALID } from "@/store/mutationNames";
import { CORE_BUFFERED_EDITOR_RESET, CORE_BUFFERED_EDITOR_SAVE } from "@/store/actionNames";
import { EditorBuffer, EditorBufferMergeMode } from "@/models/core/bufferedEditor/EditorBuffer";
import { EditorConflictMap, EditorConflictValues, EditorMergeStatus } from "@/models/core/bufferedEditor/EditorConflict";
import { BufferedEditorChangeValueRequest } from "@/models/core/bufferedEditor/BufferedEditorChangeValueRequest";
import { BufferedEditorConflictResolverData } from "@/models/core/bufferedEditor/BufferedEditorConflictResolverData";
import { BufferedEditorEdit } from "@/models/core/bufferedEditor/BufferedEditorEdit";
import { BufferedEditorResetRequest } from "@/models/core/bufferedEditor/BufferedEditorResetRequest";
import { BufferedEditorResolveConflictRequest } from "@/models/core/bufferedEditor/BufferedEditorResolveConflictRequest";
import { BufferFieldOverrideCollection } from "@/models/core/bufferedEditor/BufferFieldOverrides";
import { BufferTraversalPath } from "@/models/core/bufferedEditor/BufferTraversalPath";
import PrivateEntity from '@/models/core/entities/PrivateEntity';
import { PropValidator } from "vue/types/options";
import RootState from "@/store/RootState";
import Vue from 'vue'

type EditorFieldConflictData = {
  name: string;
  displayMine: string|false;
  displayTheirs: string|false;
  resolveEntireParent: boolean;
  resolveWith: string[]|false;
}

export default Vue.extend({
  props: {
    data: {
      type: Object,
      default: (): null => null,
    } as PropValidator<PrivateEntity>,

    fieldOverrides: {
      type: Object,
      default: (): null => null,
    } as PropValidator<BufferFieldOverrideCollection>,

    id: {
      type: String,
      default: '',
      required: true,
    },

    mergeMode: {
      type: String,
      default: 'merge',
    } as PropValidator<EditorBufferMergeMode>,
    
    noPadding: Boolean,
    
    refName: {
      type: String,
      default: null,
    },

    saveActionName: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
    }
  },

  computed: {
    bannerColor(): string {
      if (this.mergeStatus == 'conflict' || this.mergeStatus == 'requiresRefresh') {
        return 'warning'
      } else {
        return 'success'
      }
    },

    bannerDismissable(): boolean {
      return this.mergeStatus == 'merged'
    },

    bannerIcon(): string {
      if (this.mergeStatus == 'conflict' || this.mergeStatus == 'requiresRefresh') {
        return 'mdi-alert'
      } else {
        return 'mdi-check'
      }
    },

    bannerText(): string {
      if (this.mergeStatus == 'merged') {
        return 'Changes from another user were merged into this editor.'
      } else if (this.mergeStatus == 'conflict') {
        return 'Another user has entered changes that conflict with yours. Right-click highlighted fields to resolve.'
      } else if (this.mergeStatus == 'requiresRefresh') {
        return 'Another user has entered changes that cannot be merged with yours. Please refresh to see the changes.'
      } else {
        return ''
      }
    },

    bufferedEditorState(): EditorBuffer|undefined {
      return (this.$store.state as RootState).bufferedEditorState[this.id];
    },

    buffer: {
      get(): unknown {
        return this.bufferedEditorState?.buffer ?? {};
      },
    },

    conflictResolverData(): EditorConflictValues {
      return this.bufferedEditorState?.conflictResolverData?.values ?? new EditorConflictValues('','')
    },

    conflictResolverPath(): BufferTraversalPath {
      return this.bufferedEditorState?.conflictResolverData?.path ?? new BufferTraversalPath('<root>');
    },

    conflictResolverResolveEntireParent(): boolean {
      return this.bufferedEditorState?.conflictResolverData?.resolveEntireParent ?? false;
    },

    conflictResolverX(): number {
      return this.bufferedEditorState?.conflictResolverData?.x ?? 0;
    },

    conflictResolverY(): number {
      return this.bufferedEditorState?.conflictResolverData?.y ?? 0;
    },

    externalChangeCount(): number {
      return this.bufferedEditorState?.externalChangeCount ?? 0;
    },

    hasUnsavedEdits: {
      get(): boolean {
        return this.bufferedEditorState?.hasUnsavedEdits ?? false;
      },

      set(value: boolean): void {
        if (this.bufferedEditorState == undefined) return;

        const req = new BufferedEditorChangeValueRequest(
          this.id,
          value ?? false
        )

        this.$store.commit(CORE_SET_EDITOR_BUFFER_HAS_UNSAVED, req);
      }
    },

    mergeConflicts(): EditorConflictMap {
      return this.bufferedEditorState?.conflicts ?? {};
    },

    mergeStatus(): EditorMergeStatus {
      return this.bufferedEditorState?.mergeStatus ?? 'noChange'
    },

    originalValue(): unknown {
      return this.bufferedEditorState?.original ?? {};
    },

    refreshingBuffer(): boolean {
      return this.bufferedEditorState?.refreshingBuffer ?? false;
    },

    showBanner(): boolean {
      return this.mergeStatus != 'noChange'
    },

    showConflictResolver(): boolean {
      return !!this.bufferedEditorState?.conflictResolverData
    },

    valid: {
      get(): boolean {
        return this.bufferedEditorState?.valid ?? true;
      },

      set(value: boolean): void {
        if (this.bufferedEditorState == undefined) return;

        const req = new BufferedEditorChangeValueRequest(
          this.id,
          value ?? false
        )

        this.$store.commit(CORE_SET_EDITOR_BUFFER_VALID, req);
      }
    },
  },

  watch: {
    saveActionName(): void {
      const req = new BufferedEditorChangeValueRequest(
        this.id,
        this.saveActionName,
      )

      this.$store.commit(CORE_SET_EDITOR_BUFFER_SAVE_ACTION_NAME, req);
    }
  },

  created() {
    if (!this.bufferedEditorState) {
      this.$nextTick(() => {
        this.populateBuffer()
      })
    } else {
      this.validateBuffer();
    }
  },

  methods: {
    //***********************************************************
    dismissBanner(): void {
      this.$store.commit(CORE_DISMISS_EDITOR_MERGE_STATUS, this.id);
    },

    //***********************************************************
    getFieldConflictDataFromContextMenuEvent(event: Event): EditorFieldConflictData|false {
      const target = event.target as HTMLElement;
      let inputEle: HTMLElement|null = null;

      if (target.tagName == 'INPUT') {
        inputEle = target;
      } else {
        let currentEle: HTMLElement|null = target;
        let foundIt = false;

        while (!foundIt && currentEle != null && currentEle != this.$el) {
          if (currentEle.classList.contains('v-input')) {
            foundIt = true;
          } else {
            currentEle = currentEle.parentElement
          }
        }

        if (foundIt && currentEle) {
          inputEle = currentEle.querySelector('input')

          if (!inputEle) {
            inputEle = currentEle.querySelector('textarea');
          }
        }
      }

      if (inputEle) {
        const name = inputEle.dataset['fieldname'] ?? inputEle.dataset['Fieldname'] ?? false;
        const displayMine = inputEle.dataset['fieldConflictDisplayMine'] ?? inputEle.dataset['FieldConflictDisplayMine'] ?? false;
        const displayTheirs = inputEle.dataset['fieldConflictDisplayTheirs'] ?? inputEle.dataset['FieldConflictDisplayTheirs'] ?? false;

        const resolveEntireParentStr = inputEle.dataset['fieldResolveEntireParent'] ?? inputEle.dataset['FieldResolveEntireParent']  ?? 'false';
        const resolveEntireParent = resolveEntireParentStr.toLowerCase() == 'true' ? true : false;

        const resloveWithStr = inputEle.dataset['fieldResolveWith'] ?? inputEle.dataset['FieldResolveWith']  ?? 'false';
        const resolveWith = this.parseResolveWith(resloveWithStr);

        if (name !== false) {
          return {name, displayMine, displayTheirs, resolveEntireParent, resolveWith}
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    //***********************************************************
    handleContextMenu(event: Event): void {
      const fieldData = this.getFieldConflictDataFromContextMenuEvent(event);

      if (fieldData) {
        const path = BufferTraversalPath.fromPathString(fieldData.name);

        if (path) {
          const values = this.bufferedEditorState?.getConflictingValuesForPath(path);

          if (values) {
            values.displayMine = fieldData.displayMine;
            values.displayTheirs = fieldData.displayTheirs;
            values.resolveWith = fieldData.resolveWith;

            event.preventDefault();
            const mouseEvent = event as MouseEvent;

            const data = new BufferedEditorConflictResolverData(
              values,
              path,
              mouseEvent.x,
              mouseEvent.y,
              fieldData.resolveEntireParent
            );

            const req = new BufferedEditorChangeValueRequest(
              this.id,
              data
            );

            this.$store.commit(CORE_OPEN_CONFLICT_RESOLVER, req);
          }
        } else {
          console.error("Error parsing conflict path! Path: " + fieldData.name)
        }
      }
    },

    handleEdits(evt: BufferedEditorEdit[]): void {
      this.$store.commit(
        CORE_BUFFERED_EDITOR_APPLY_EDITS,
        new BufferedEditorChangeValueRequest(this.id, evt)
      );
    },

    //***********************************************************
    handleFormSubmit(event: Event): void {
      event.preventDefault()

      this.save()
    },

    //***********************************************************
    parseResolveWith(resolveWithStr: string): string[]|false {
      try {
        return JSON.parse(resolveWithStr) as string[];
      } catch (e) {
        return false;
      }
    },

    //***********************************************************
    async populateBuffer(): Promise<void> {
      const req = new BufferedEditorResetRequest(
        this.id,
        this.data,
        true,
        this.saveActionName,
        this.refName,
        this.mergeMode,
        this.fieldOverrides,
      )

      await this.$store.dispatch(CORE_BUFFERED_EDITOR_RESET, req);
      
      this.validateBuffer();
    },

    validateBuffer(): void {
      this.$nextTick(() => {
        this.$nextTick(() => {
          (this.$refs['form'] as unknown as {validate(): void}).validate();
        });
      })
    },

    //***********************************************************
    resolveConflict(mode: 'mine'|'theirs'): void {
      const req = new BufferedEditorResolveConflictRequest(
        this.id,
        mode,
        this.conflictResolverPath,
        this.conflictResolverResolveEntireParent,
        this.conflictResolverData.resolveWith,
      );

      this.$store.commit(CORE_RESOLVE_EDITOR_CONFLICT, req);
    },

    //***********************************************************
    async save(): Promise<boolean> {
      return !!(await this.$store.dispatch(CORE_BUFFERED_EDITOR_SAVE, this.id));
    },
  },
})
