// Core
export const CORE_GET_CURRENT_USER = 'getCurrentUser'
export const CORE_GET_ENTITY_MANAGER_STATE = 'coreGetEntityManagerState'

export const CORE_CLIENT_CURRENT = 'clientCurrent'
export const CORE_CLIENT_BY_CODE = 'clientByCode'
export const CORE_CLIENT_INDEX_BY_CODE = 'clientIndexByCode'

export const CORE_NOTIFICATION_COUNT = 'coreNotificationCount'
export const CORE_NEXT_NOTIFICATION = 'coreNextNotification'

export const CORE_POPUP_REQUEST_COUNT = 'popupRequestCount'
export const CORE_NEXT_POPUP_REQUEST = 'nextPopupRequest'

export const CORE_DOC_LINK_REQUEST_COUNT = 'docLinkRequestCount'
export const CORE_NEXT_DOC_LINK_REQUEST = 'nextDocLinkRequest'

export const CORE_DOC_CLOSE_REQUEST_COUNT = 'docCloseRequestCount'
export const CORE_NEXT_DOC_CLOSE_REQUEST = 'nextDocCloseRequest'

export const CORE_GET_STATUS_KANBAN_BOARD = 'getKanbanBoard'

export const CORE_GET_LIFECYCLE_FOR_TYPE = 'getLifecycleForType'
export const CORE_GET_LIFECYCLE_HASH_FOR_TYPE = 'getLifecycleHashForType'

export const CORE_GET_GENERIC_PAGER = 'getGenericPager'

// Assignments
export const ASSIGNMENTS_FOR_CURRENT_CLIENT = 'assignmentsForCurrentClient'
export const ASSIGNMENTS_OPEN_FOR_CURRENT_CLIENT = 'assignmentsOpenForCurrentClient'
export const ASSIGNMENTS_LINKED_TO_ENTITY = 'assignmentsLinkedToEntity'
export const ASSIGNMENTS_LISTED_FOR_REF = 'assignmentsListedForRef'
export const ASSIGNMENTS_OPEN_FOR_REF = 'assignmentsOpenForRef'
export const ASSIGNMENT_REFERRER_EXISTS = 'assignmentReferrerExists'
export const ASSIGNMENT_GET_KANBAN_STATE = 'assignmentGetKanbanState'

// Assignment Templates
export const ASSIGNMENT_TEMPLATES_FOR_CURRENT_CLIENT = 'assignmentTemplatesForCurrentClient'
export const ASSIGNMENT_TEMPLATES_OPEN_FOR_CURRENT_CLIENT = 'assignmentTemplatesOpenForCurrentClient'
export const ASSIGNMENT_TEMPLATES_LISTED_FOR_REF = 'assignmentTemplatesListedForRef'
export const ASSIGNMENT_TEMPLATES_OPEN_FOR_REF = 'assignmentTemplatesOpenForRef'
export const ASSIGNMENT_TEMPLATE_REFERRER_EXISTS = 'assignmentTemplateReferrerExists'
export const ASSIGNMENT_TEMPLATE_GET_LIFECYCLE_FOR_TYPE = 'assginmentTemplateGetLifecycleForType'

// Assignment Workflows
export const ASSIGNMENT_WORKFLOWS_FOR_CURRENT_CLIENT = 'assignmentWorkflowsForCurrentClient'
export const ASSIGNMENT_WORKFLOWS_OPEN_FOR_CURRENT_CLIENT = 'assignmentWorkflowsOpenForCurrentClient'
export const ASSIGNMENT_WORKFLOWS_LISTED_FOR_REF = 'assignmentWorkflowsListedForRef'
export const ASSIGNMENT_WORKFLOWS_OPEN_FOR_REF = 'assignmentWorkflowsOpenForRef'
export const ASSIGNMENT_WORKFLOW_REFERRER_EXISTS = 'assignmentWorkflowReferrerExists'

// Comments
export const COMMENTS_FOR_CURRENT_CLIENT = 'commentsForCurrentClient'
export const COMMENTS_OPEN_FOR_CURRENT_CLIENT = 'commentsOpenForCurrentClient'
export const COMMENTS_LINKED_TO_ENTITY = 'commentsLinkedToEntity'
export const COMMENTS_LIST_FOR_REF = 'commentListForRef'
export const COMMENT_REFERRER_EXISTS = 'commentReferrerExists'
export const COMMENTS_OPEN_FOR_REF = 'commentsOpenForRef'

// Contact Collections
export const CONTACT_COLLECTIONS_FOR_CURRENT_CLIENT = 'contactCollectionsForCurrentClient'
export const CONTACT_COLLECTIONS_OPEN_FOR_CURRENT_CLIENT = 'contactCollectionsOpenForCurrentClient'
export const CONTACT_COLLECTIONS_LISTED_FOR_REF = 'contactCollectionsListedForRef'
export const CONTACT_COLLECTIONS_OPEN_FOR_REF = 'contactCollectionOpenForRef'
export const CONTACT_COLLECTION_REFERRER_EXISTS = 'contactCollectionReferrerExists'

// Contacts
export const CONTACTS_FOR_CURRENT_CLIENT = 'contactsForCurrentClient'
export const CONTACTS_OPEN_FOR_CURRENT_CLIENT = 'contactsOpenForCurrentClient'
export const CONTACTS_LISTED_FOR_REF = 'contactsListedForRef'
export const CONTACTS_OPEN_FOR_REF = 'contactOpenForRef'
export const CONTACT_REFERRER_EXISTS = 'contactReferrerExists'
export const CONTACT_CURRENT_COLLECTION_ID = 'contactCurrentCollectionId'
export const CONTACT_MANGAGER_PREVIEW = 'contactManagerPreview'
export const CONTACT_CURRENT_ADDRESS_LIST = 'contactCurrentAddressList'

// Customers
export const CUSTOMERS_FOR_CURRENT_CLIENT = 'customersForCurrentClient'
export const CUSTOMERS_OPEN_FOR_CURRENT_CLIENT = 'customersOpenForCurrentClient'
export const CUSTOMERS_LISTED_FOR_REF = 'customersListedForRef'
export const CUSTOMERS_OPEN_FOR_REF = 'customerOpenForRef'
export const CUSTOMER_REFERRER_EXISTS = 'customerReferrerExists'

// Files
export const FILES_FOR_CURRENT_CLIENT = 'filesForCurrentClient'
export const FILES_OPEN_FOR_CURRENT_CLIENT = 'filesOpenForCurrentClient'
export const FILES_LINKED_TO_ENTITY = 'filesLinkedToEntity'
export const FILES_LIST_FOR_REF = 'fileListForRef'
export const FILE_REFERRER_EXISTS = 'fileReferrerExists'
export const FILES_OPEN_FOR_REF = 'filesOpenForRef'

// Items
export const ITEMS_FOR_CURRENT_CLIENT = 'itemsForCurrentClient'
export const ITEMS_OPEN_FOR_CURRENT_CLIENT = 'itemsOpenForCurrentClient'
export const ITEMS_LISTED_FOR_REF = 'itemsListedForRef'
export const ITEMS_OPEN_FOR_REF = 'itemsOpenForRef'
export const ITEM_REFERRER_EXISTS = 'itemReferrerExists'

// Item Categories
export const ITEM_CATEGORIES_FOR_CURRENT_CLIENT = 'itemCategoriesForCurrentClient'
export const ITEM_CATEGORIES_OPEN_FOR_CURRENT_CLIENT = 'itemCategoriesOpenForCurrentClient'
export const ITEM_CATEGORIES_LISTED_FOR_REF = 'itemCategoriesListedForRef'
export const ITEM_CATEGORIES_OPEN_FOR_REF = 'itemCategoriesOpenForRef'
export const ITEM_CATEGORY_REFERRER_EXISTS = 'itemCategoryReferrerExists'

// Mailboxes
export const MAILBOXES_FOR_CURRENT_USER = 'mailboxesForCurrentUser'
export const MAILBOX_TREE_FOR_CURRENT_USER = 'mailboxTreeForCurrentUser'
export const MAILBOXES_OPEN_FOR_CURRENT_USER = 'mailboxesOpenForCurrentUser'
export const MAILBOX_SENDING_ADDRESSES = 'mailboxFromAddresses'
export const MAILBOX_REFERRER_EXISTS = 'mailboxReferrerExists'
export const MAILBOXES_LISTED_FOR_REF = 'mailboxesListedForRef'
export const MAILBOX_TOTAL_UNREAD_COUNT = 'mailboxTotalUnreadCount'

// Messages
export const MESSAGES_FOR_CURRENT_CLIENT = 'messagesForCurrentClient'
export const MESSAGES_OPEN_FOR_CURRENT_CLIENT = 'messagesOpenForCurrentClient'
export const MESSAGES_LINKED_TO_ENTITY = 'messagesLinkedToEntity'
export const MESSAGE_MANGAGER_PREVIEW = 'messageManagerPreview'
export const MESSAGE_REFERRER_EXISTS = 'messageReferrerExists'
export const MESSAGES_LISTED_FOR_REF = 'messagesListedForRef'
export const MESSAGES_OPEN_FOR_REF = 'messagesOpenForRef'
export const MESSAGE_CURRENT_MAILBOX_ADDRESS = 'messageCurrentMailboxAddress'
export const MESSAGE_CURRENT_MAILBOX_ID = 'messageCurrentMailboxId'

// Quotes
export const QUOTES_FOR_CURRENT_CLIENT = 'quotesForCurrentClient'
export const QUOTES_OPEN_FOR_CURRENT_CLIENT = 'quotesOpenForCurrentClient'
export const QUOTES_LISTED_FOR_REF = 'quotesListedForRef'
export const QUOTES_OPEN_FOR_REF = 'quotesOpenForRef'
export const QUOTE_REFERRER_EXISTS = 'quoteReferrerExists'

// Tickets
export const TICKETS_FOR_CURRENT_CLIENT = 'ticketsForCurrentClient'
export const TICKETS_OPEN_FOR_CURRENT_CLIENT = 'ticketsOpenForCurrentClient'
export const TICKETS_LINKED_TO_ENTITY = 'ticketsLinkedToEntity'
export const TICKETS_LIST_FOR_REF = 'ticketListForRef'
export const TICKET_REFERRER_EXISTS = 'ticketReferrerExists'
export const TICKETS_OPEN_FOR_REF = 'ticketsOpenForRef'

// Ticket Types
export const TICKET_TYPES_FOR_CURRENT_CLIENT = 'ticketTypesForCurrentClient'
export const TICKET_TYPES_OPEN_FOR_CURRENT_CLIENT = 'ticketTypesOpenForCurrentClient'
export const TICKET_TYPES_LISTED_FOR_REF = 'ticketTypesListedForRef'
export const TICKET_TYPES_OPEN_FOR_REF = 'ticketTypesOpenForRef'
export const TICKET_TYPE_REFERRER_EXISTS = 'ticketTypeReferrerExists'

// Work Orders
export const WORK_ORDERS_FOR_CURRENT_CLIENT = 'workOrdersForCurrentClient'
export const WORK_ORDERS_OPEN_FOR_CURRENT_CLIENT = 'workOrdersOpenForCurrentClient'
export const WORK_ORDERS_LISTED_FOR_REF = 'workOrdersListedForRef'
export const WORK_ORDERS_OPEN_FOR_REF = 'workOrdersOpenForRef'
export const WORK_ORDER_REFERRER_EXISTS = 'workOrderReferrerExists'
export const WORK_ORDER_GET_KANBAN_STATE = 'workOrderGetKanbanState'

// Work Order Categories
export const WORK_ORDER_CATEGORIES_FOR_CURRENT_CLIENT = 'workOrderCategoriesForCurrentClient'
export const WORK_ORDER_CATEGORIES_OPEN_FOR_CURRENT_CLIENT = 'workOrderCategoriesOpenForCurrentClient'
export const WORK_ORDER_CATEGORIES_LISTED_FOR_REF = 'workOrderCategoriesListedForRef'
export const WORK_ORDER_CATEGORIES_OPEN_FOR_REF = 'workOrderCategoriesOpenForRef'
export const WORK_ORDER_CATEGORY_REFERRER_EXISTS = 'workOrderCategoryReferrerExists'
