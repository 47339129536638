
import { AssignmentTemplate, AssignmentTemplateListModel } from '@/models/assignments/AssignmentTemplate';
import { CORE_GET_LIFECYCLE_FOR_TYPE, CORE_GET_LIFECYCLE_HASH_FOR_TYPE } from '@/store/getterNames';
import { aOrAn } from "../../../util/naturalLanguage";
import AssignmentTemplateEditor from '../templates/AssignmentTemplateEditor.vue'
import AssignmentTemplateIcons from '../templates/AssignmentTemplateIcons.vue'
import { capitalizeFirstLetter } from '@/util/capitalize';
import { CORE_FETCH_ACTIVE_LIFECYCLE } from '@/store/actionNames';
import draggable from 'vuedraggable'
import { Lifecycle } from '@/models/lifecycles/Lifecycle';
import { LifecycleHash } from '@/models/lifecycles/LifecycleHash';
import popup from '@/util/popup';
import { PropValidator } from 'vue/types/options';
import StageChip from '../../lifecycles/StageChip.vue'
import StepEditorAddStepDlg from './StepEditorAddStepDlg.vue'
import Vue from 'vue';

export default Vue.extend({
  components: {
    AssignmentTemplateEditor,
    AssignmentTemplateIcons,
    draggable,
    StageChip,
    StepEditorAddStepDlg,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
      required: true,
    },

    entityType: {
      type: String,
      default: '',
      required: true,
    },

    value: {
      type: Array,
      default: null,
    } as PropValidator<AssignmentTemplateListModel[]>,
  },

  data() {
    return {
      editingTemplateId: '',
      showStepChooser: false,
      showTemplateEditor: false,
    }
  },

  computed: {
    lifecycle(): Lifecycle {
      return this.$store.getters[CORE_GET_LIFECYCLE_FOR_TYPE](this.entityType) ?? new Lifecycle();
    },

    lifecycleHash(): LifecycleHash {
      return this.$store.getters[CORE_GET_LIFECYCLE_HASH_FOR_TYPE](this.entityType) ?? {stages: {}, statuses: {}}
    },

    stepErrors(): string[] {
      const errors = [""];
      let prevStep: AssignmentTemplateListModel|null = null;

      for (let i = 0; i < this.value.length; i++) {
        const step = this.value[i];
        
        if (prevStep) {
          const curStageIdx = this.lifecycle.stages.findIndex(x => x.name == step.stage)
          const prevStageIdx = this.lifecycle.stages.findIndex(x => x.name == prevStep?.stage);

          if (curStageIdx < prevStageIdx) {
            const stepPhrase = capitalizeFirstLetter(`${aOrAn(step.stage)} "${step.stage}"`)
            const prevStepPhrase = `${aOrAn(prevStep.stage)} "${prevStep.stage}"`;
            errors.push(`${stepPhrase} step cannot follow ${prevStepPhrase} step`);
          } else {
            errors.push('');
          }
        }

        prevStep = step;
      }

      return errors;
    },
  },

  watch: {
    entityType: {
      handler() {
        if (this.entityType > '' && this.$store.getters[CORE_GET_LIFECYCLE_HASH_FOR_TYPE](this.entityType) == undefined) {
          this.$store.dispatch(CORE_FETCH_ACTIVE_LIFECYCLE, this.entityType);
        }
      },
      immediate: true,
    },
  },

  methods: {
    addItem(template: AssignmentTemplateListModel): void {
      this.$emit('input', [...this.value, template]);
    },

    editItem(template: AssignmentTemplateListModel): void {
      this.editingTemplateId = template.id;
      this.showTemplateEditor = true;
    },

    handleTemplateUpdated(template: AssignmentTemplate): void {
      const listModel = new AssignmentTemplateListModel()
      listModel.populate(template);

      const idx = this.value.findIndex(x => x.id == listModel.id);
      if (idx > -1) {
        const steps = [...this.value];
        steps.splice(idx, 1, listModel);
        this.$emit('input', steps);
      }
    },

    async removeItem(idx: number): Promise<void> {
      const confirmed = await popup.confirm(
        this.$store,
        "Confirm Delete",
        "Are you sure you want to remove this step?");

      if (!confirmed) return;

      const items = [...this.value];
      items.splice(idx, 1);
      this.$emit('input', items);
    },
  }
})
