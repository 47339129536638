// CORE
export const CORE_FETCH_APP_CONFIG = "coreFetchAppConfig"

export const CORE_CLIENT_FETCH_ALL = 'clientFetchAll'
export const CORE_SWITCH_CLIENT = "coreSwitchClient"
export const CORE_FETCH_USERS_FOR_CLIENT = "coreUsersFetchForClient"

export const CORE_CLOSE_ENTITY_EDITOR = "coreCloseEntityEditor"

export const CORE_BUFFERED_EDITOR_RESET = "coreBufferedEditorReset"
export const CORE_BUFFERED_EDITOR_SAVE = "coreBufferedEditorSave"

export const CORE_FETCH_ASSIGNMENT_COUNT_FOR_ENTITY = "coreFetchAssignmentCountForEntity"
export const CORE_FETCH_COMMENT_COUNT_FOR_ENTITY = "coreFetchCommentCountForEntity"
export const CORE_FETCH_FILE_COUNT_FOR_ENTITY = "coreFetchFileCountForEntity"
export const CORE_FETCH_MESSAGE_COUNT_FOR_ENTITY = "coreFetchMessageCountForEntity"
export const CORE_FETCH_TICKET_COUNT_FOR_ENTITY = "coreFetchTicketCountForEntity"

export const CORE_FETCH_ACTIVE_LIFECYCLE = "coreFetchActiveLifecycle"
export const CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED = "coreFetchActiveLifecycleIfNeeded"

// ASSIGNMENT
export const ASSIGNMENT_FETCH = 'assignmentFetch'
export const ASSIGNMENT_SEARCH = 'assignmentSearch'
export const ASSIGNMENT_LOAD = 'assignmentLoad'
export const ASSIGNMENT_SAVE = 'assignmentSave'
export const ASSIGNMENT_DELETE = 'assignmentDelete'
export const ASSIGNMENT_UNDELETE = 'assignmentUndelete'
export const ASSIGNMENT_FETCH_FOR_ENTITY = 'assignmentFetchForEntity'
export const ASSIGNMENT_UPDATE_SORT_INDEX = 'assignmentUpdateSortIndex'

// ASSIGNMENT TEMPLATE
export const ASSIGNMENT_TEMPLATE_FETCH = 'assignmentTemplateFetch'
export const ASSIGNMENT_TEMPLATE_SEARCH = 'assignmentTemplateSearch'
export const ASSIGNMENT_TEMPLATE_LOAD = 'assignmentTemplateLoad'
export const ASSIGNMENT_TEMPLATE_SAVE = 'assignmentTemplateSave'
export const ASSIGNMENT_TEMPLATE_DELETE = 'assignmentTemplateDelete'
export const ASSIGNMENT_TEMPLATE_UNDELETE = 'assignmentTemplateUndelete'
export const ASSIGNMENT_TEMPLATE_FETCH_LIFECYCLE_FOR_TYPE = "assignmentTemplateFetchLifecycleForType"

// ASSIGNMENT WORKFLOW
export const ASSIGNMENT_WORKFLOW_FETCH = 'assignmentWorkflowFetch'
export const ASSIGNMENT_WORKFLOW_SEARCH = 'assignmentWorkflowSearch'
export const ASSIGNMENT_WORKFLOW_LOAD = 'assignmentWorkflowLoad'
export const ASSIGNMENT_WORKFLOW_SAVE = 'assignmentWorkflowSave'
export const ASSIGNMENT_WORKFLOW_DELETE = 'assignmentWorkflowDelete'
export const ASSIGNMENT_WORKFLOW_UNDELETE = 'assignmentWorkflowUndelete'

// COMMENTS
export const COMMENT_FETCH = 'commentFetch'
export const COMMENT_LOAD = 'commentLoad'
export const COMMENT_SAVE = 'commentSave'
export const COMMENT_DELETE = 'commentDelete'
export const COMMENT_FETCH_FOR_ENTITY = 'commentFetchForEntity'
export const COMMENT_UNDELETE = 'commentUndelete'
export const COMMENT_FETCH_UNREAD_FOR_USER = 'commentFetchUnreadForUser'
export const COMMENT_FETCH_LINK_DESCRIPTIONS = 'commentFetchLinkDescriptions'
export const COMMENT_MARK_AS_READ = 'commentMarkAsRead'

// CONTACT COLLECTIONS
export const CONTACT_COLLECTION_FETCH = 'contactCollectionFetch'
export const CONTACT_COLLECTION_LOAD = 'contactCollectionLoad'
export const CONTACT_COLLECTION_SAVE = 'contactCollectionSave'
export const CONTACT_COLLECTION_DELETE = 'contactCollectionDelete'
export const CONTACT_COLLECTION_UNDELETE = 'contactCollectionUndelete'

// CONTACTS
export const CONTACT_FETCH = 'contactFetch'
export const CONTACT_LOAD = 'contactLoad'
export const CONTACT_SAVE = 'contactSave'
export const CONTACT_DELETE = 'contactDelete'
export const CONTACT_UNDELETE = 'contactUndelete'
export const CONTACT_FETCH_FOR_COLLECTION = 'contactFetchForCollection'
export const CONTACT_FETCH_FOR_CUSTOMER = 'contactFetchForCustomer'
export const CONTACT_CHANGE_COLLECTION = 'contactChangeCollection'
export const CONTACT_FETCH_ADDRESS_LIST = 'contactFetchAddressList'

// CUSTOMERS
export const CUSTOMER_FETCH = 'customerFetch'
export const CUSTOMER_SEARCH = 'customerSearch'
export const CUSTOMER_LOAD = 'customerLoad'
export const CUSTOMER_SAVE = 'customerSave'
export const CUSTOMER_DELETE = 'customerDelete'
export const CUSTOMER_UNDELETE = 'customerUndelete'

// FILES
export const FILE_FETCH = 'fileFetch'
export const FILE_SEARCH = 'fileSearch'
export const FILE_LOAD = 'fileLoad'
export const FILE_SAVE = 'fileSave'
export const FILE_DELETE = 'fileDelete'
export const FILE_FETCH_FOR_ENTITY = 'fileFetchForEntity'
export const FILE_UNLINK_FROM_ENTITY = 'fileUnlinkFromEntity'
export const FILE_LINK_TO_ENTITY = 'fileLinkToEntity'
export const FILE_UNDELETE = 'fileUndelete'

// ITEMS
export const ITEM_FETCH = 'itemFetch'
export const ITEM_SEARCH = 'itemSearch'
export const ITEM_LOAD = 'itemLoad'
export const ITEM_SAVE = 'itemSave'
export const ITEM_DELETE = 'itemDelete'
export const ITEM_UNDELETE = 'itemUndelete'

// ITEM CATEGORIES
export const ITEM_CATEGORY_FETCH = 'itemCategoryFetch'
export const ITEM_CATEGORY_SEARCH = 'itemCategorySearch'
export const ITEM_CATEGORY_LOAD = 'itemCategoryLoad'
export const ITEM_CATEGORY_SAVE = 'itemCategorySave'
export const ITEM_CATEGORY_DELETE = 'itemCategoryDelete'
export const ITEM_CATEGORY_UNDELETE = 'itemCategoryUndelete'

// MAILBOXES
export const MAILBOX_FETCH = 'mailboxFetchForUser'
export const MAILBOX_LOAD = 'mailboxLoad'
export const MAILBOX_SAVE = 'mailboxSave'
export const MAILBOX_DELETE = 'mailboxDelete'
export const MAILBOX_GET_UNREAD = 'mailboxUnreadGet'
export const MAILBOX_UNDELETE = 'mailboxUndelete'

// MESSAGES
export const MESSAGE_FETCH_FOR_MAILBOX = 'messageFetchForMailbox'
export const MESSAGE_LOAD = 'messageLoad'
export const MESSAGE_SAVE = 'messageSave'
export const MESSAGE_DELETE = 'messageDelete'
export const MESSAGE_MARK_AS_READ = 'messageMarkAsRead'
export const MESSAGE_SEND = 'messageSend'
export const MESSAGE_CHANGE_MAILBOX = 'messageChangeMailbox'
export const MESSAGE_FETCH_FOR_ENTITY = 'messageFetchForEntity'
export const MESSAGE_LINK_TO_ENTITY = 'messageLinkToEntity'
export const MESSAGE_UNLINK_FROM_ENTITY = 'messageUnlinkFromEntity'
export const MAILBOX_HANDLE_UNREAD_UPDATE_MSG = 'mailboxHandleUnreadUpdateMsg'

// QUOTES
export const QUOTE_FETCH = 'quoteFetch'
export const QUOTE_SEARCH = 'quoteSearch'
export const QUOTE_LOAD = 'quoteLoad'
export const QUOTE_SAVE = 'quoteSave'
export const QUOTE_DELETE = 'quoteDelete'
export const QUOTE_UNDELETE = 'quoteUndelete'

// TICKETS
export const TICKET_FETCH = 'ticketFetch'
export const TICKET_SEARCH = 'ticketSearch'
export const TICKET_LOAD = 'ticketLoad'
export const TICKET_SAVE = 'ticketSave'
export const TICKET_DELETE = 'ticketDelete'
export const TICKET_FETCH_FOR_ENTITY = 'ticketFetchForEntity'
export const TICKET_UNLINK_FROM_ENTITY = 'ticketUnlinkFromEntity'
export const TICKET_LINK_TO_ENTITY = 'ticketLinkToEntity'
export const TICKET_UNDELETE = 'ticketUndelete'
export const TICKET_FETCH_LINK_DESCRIPTIONS = 'ticketFetchLinkDescriptions'

// TICKET TYPES
export const TICKET_TYPE_FETCH = 'ticketTypeFetch'
export const TICKET_TYPE_SEARCH = 'ticketTypeSearch'
export const TICKET_TYPE_LOAD = 'ticketTypeLoad'
export const TICKET_TYPE_SAVE = 'ticketTypeSave'
export const TICKET_TYPE_DELETE = 'ticketTypeDelete'
export const TICKET_TYPE_UNDELETE = 'ticketTypeUndelete'

// WORK ORDERS
export const WORK_ORDER_FETCH = 'workOrderFetch'
export const WORK_ORDER_SEARCH = 'workOrderSearch'
export const WORK_ORDER_LOAD = 'workOrderLoad'
export const WORK_ORDER_SAVE = 'workOrderSave'
export const WORK_ORDER_DELETE = 'workOrderDelete'
export const WORK_ORDER_UNDELETE = 'workOrderUndelete'
export const WORK_ORDER_UPDATE_SORT_INDEX_PRIORITY = 'workOrderUpdateSortIndexAndPriority'
export const WORK_ORDER_UPDATE_STEP_SORT_INDEX = 'workOrderUpdateStepSortIndex'

// WORK ORDER CATEGORIES
export const WORK_ORDER_CATEGORY_FETCH = 'workOrderCategoryFetch'
export const WORK_ORDER_CATEGORY_SEARCH = 'workOrderCategorySearch'
export const WORK_ORDER_CATEGORY_LOAD = 'workOrderCategoryLoad'
export const WORK_ORDER_CATEGORY_SAVE = 'workOrderCategorySave'
export const WORK_ORDER_CATEGORY_DELETE = 'workOrderCategoryDelete'
export const WORK_ORDER_CATEGORY_UNDELETE = 'workOrderCategoryUndelete'
