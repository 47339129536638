import { BufferTraversalPath } from "./BufferTraversalPath";

export class BufferedEditorResolveConflictRequest {
  constructor(
    public id: string,
    public mode: 'mine'|'theirs',
    public path: BufferTraversalPath,
    public resolveEntireParent: boolean,
    public resolveWith: string[]|false = false,
  ) {}
}