import { Behavior } from "./Behaviors";
import { ChecklistItem } from "./Assignment";
import InsightUser from "@/models/core/InsightUser";
import { LifecycleStage } from "@/models/lifecycles/LifecycleStage";
import PrivateEntity from "@/models/core/entities/PrivateEntity";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";

export const ASSIGNMENT_TYPES = ["One Click", "Start/Pause/Finish"] as const;
export type AssignmentType = typeof ASSIGNMENT_TYPES[number];

export const ENTITY_TYPES = ["Quote", "Ticket", "WorkOrder"] as const;
export type EntityType = typeof ENTITY_TYPES[number];

export const WORK_TYPES = ["In App", "Out of App"] as const;
export type WorkType = typeof WORK_TYPES[number];

export class AssignmentTemplate extends PrivateEntity {
  constructor(
    public name = '',
    public entityType: EntityType = "Quote",
    public stage = new LifecycleStage(),
    public stageRecordId = '',
    public assignmentType: AssignmentType = "Start/Pause/Finish",
    public workType: WorkType = "Out of App",
    public behaviors: Behavior[] = [],
    public defaultAssignee = new InsightUser(),
    public checklist: ChecklistItem[] = [],
  ) {
    super()
  }
}

export class AssignmentTemplateListModel extends PrivateEntityListModel<AssignmentTemplate> {
  constructor(
    public name = '',
    public entityType: EntityType = "Quote",
    public stage = '',
    public assignmentType: AssignmentType = "Start/Pause/Finish",
    public workType: WorkType = "Out of App",
    public hasChecklist = false,
    public hasBehaviors = false,
    public hasDefaultAssignee = false,
  ) {
    super()
  }

  protected populateCore(model: AssignmentTemplate): void {
    this.name = model.name;
    this.entityType = model.entityType;
    this.stage = model.stage.name;
    this.assignmentType = model.assignmentType
    this.workType = model.workType;
    this.hasChecklist = model.checklist.length > 0;
    this.hasBehaviors = model.behaviors.length > 0;
    this.hasDefaultAssignee = !!model.defaultAssignee && model.defaultAssignee.id > '';
  }
}