import api from "./api";

export enum SettingName {
  ClientHolidayList = "clientHolidayList",
  QuoteNumberFormatScheme = "quoteNumberFormatScheme",
  TicketActiveLifecycleId = "ticketLifecycle",
  TicketNumberFormatScheme = "ticketNumberFormatScheme",
  WorkOrderDiscounts = "workOrderDiscounts",
  WorkOrderNumberFormatScheme = "workOrderNumberFormatScheme",
  WorkOrderRequireLinkedQuote = "workOrderRequireLinkedQuote",
  WorkOrderWorkflows = "workOrderWorkflows",
}

function _buildSettingUrl(client: string, name: SettingName): string {
  return `${client}/settings/${name}`;
}

export async function fetchSetting<T>(client: string, name: SettingName, defaultValue: T): Promise<T> {
  const url = _buildSettingUrl(client, name);
  const response = await api.get(url)

  if (!response) return Promise.reject(null);

  if (response.error) {
    if (response.errorCause == "notFound") {
      return defaultValue
    } else {
      return Promise.reject(response)
    }
  }

  return response.data as T
}

export async function saveSetting<T>(client: string, name: SettingName, data: T): Promise<void> {

  const url = _buildSettingUrl(client, name);

  let realData;
  if ((data as unknown) === false) {
    realData = "false";
  } else {
    realData = data;
  }

  const response = await api.create(url, realData, undefined, true)
  

  if (!response) return Promise.reject(null);

  if (response.error) {
    return Promise.reject(response)
  }
}