import { assignments } from "./assignments";
import { assignmentTemplates } from "./assignmentTemplates";
import { assignmentWorkflows } from "./assignmentWorkflows";
import { comments } from './comments'
import { contactCollections } from './contactCollections'
import { contacts } from './contacts'
import { customers } from './customers'
import { files } from './files'
import { itemCategories } from './itemCategories'
import { items } from './items'
import { mailboxes } from './mailboxes'
import { messages } from './messages'
import { ModuleTree } from 'vuex'
import { quotes } from './quotes'
import RootState from './RootState'
import { tickets } from './tickets'
import { ticketTypes } from './ticketTypes'
import { workOrderCategories } from './workOrderCategories'
import { workOrders } from './workOrders'

const RootModules: ModuleTree<RootState> = {
    assignments,
    assignmentTemplates,
    assignmentWorkflows,
    comments,
    contacts,
    contactCollections,
    customers,
    files,
    items,
    itemCategories,
    mailboxes,
    messages,
    quotes,
    tickets,
    ticketTypes,
    workOrders,
    workOrderCategories,
}

export default RootModules
