
import { SearchUINumberRangeOptions, SearchUIRangeValue } from "@/models/core/searchQueries/SearchUIOption";
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import PercentField from '../forms/PercentField.vue'
import { PropValidator } from "vue/types/options";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    PercentField
  },

  props: {
    locked: Boolean,

    options: {
      type: Object,
      default: null,
    } as PropValidator<SearchUINumberRangeOptions>,

    value: {
      type: Object,
      default: null,
    } as PropValidator<SearchUIRangeValue<number>>,
  },

  data() {
    return {
      internalValue: 0,
      internalValue2: 0,
      operator: 'gte' as 'gte'|'lte'|'btwi',
      operators: [
        {text: '\u2265 (is greater than or equal to)', value: 'gte'},
        {text: '\u2264 (is less than or equal to)', value: 'lte'},
        {text: 'is between (inclusive)', value: 'btwi'},
      ]
    }
  },

  watch: {
    value: {
      handler(): void {
        if (this.value) {
          if (this.internalValue != this.value.value1)
            this.internalValue = this.value.value1;

          if (this.internalValue2 != this.value.value2)
            this.internalValue2 = this.value.value2 ?? 0;
          
          if (this.operator != this.value.operator)
            this.operator = this.value.operator;
        }
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    handleInput(): void {
      this.$emit(
        'input',
        new SearchUIRangeValue(
          this.operator,
          this.internalValue,
          this.operator == 'btwi' ? this.internalValue2 : undefined));
    }
  }
})
