
import { ASSIGNMENT_TYPES, AssignmentTemplate, AssignmentType, WORK_TYPES } from '@/models/assignments/AssignmentTemplate'
import { ActionInfo } from '@/models/assignments/ActionInfo'
import BehaviorEditor from './BehaviorEditor.vue'
import ChecklistEditor from './ChecklistEditor.vue'
import DescriptionField from '../../common/forms/DescriptionField.vue'
import { FieldSetConflictMixin } from '../../common/FieldSetConflictMixin'
import { getAttributeDefsForEntityType } from '@/models/lifecycles/AttributesByEntityType'
import InsightUser from '@/models/core/InsightUser'
import { LifecycleHash } from '@/models/lifecycles/LifecycleHash'
import { LifecycleRecord } from '@/models/lifecycles/LifecycleRecord'
import { LifecycleStage } from '@/models/lifecycles/LifecycleStage'
import { LifecycleStageRecord } from '@/models/lifecycles/LifecycleStageRecord'
import NameField from '../../common/forms/NameField.vue'
import { PropValidator } from 'vue/types/options'
import StageChip from '../../lifecycles/StageChip.vue'
import { StateAttributeDef } from '@/models/core/stateFilters/StateAttributeDef'
import UserChooser from '../../common/UserChooser.vue'

export default FieldSetConflictMixin.extend({

  components: {
    BehaviorEditor,
    ChecklistEditor,
    DescriptionField,
    NameField,
    StageChip,
    UserChooser,
  },

  props: {
    actionList: {
      type: Array,
      default: null,
    } as PropValidator<ActionInfo[]>,

    entity: {
      type: Object,
      default: (): AssignmentTemplate => new AssignmentTemplate()
    } as PropValidator<AssignmentTemplate>,

    lifecycle: {
      type: Object,
      default: null,
    } as PropValidator<LifecycleRecord>,

    readonly: Boolean,
  },

  data() {
    return {
      showUserChooser: false,
    }
  },

  computed: {
    assignedToUser(): string {
      return this.entity.defaultAssignee?.name ?? '(Unassigned)'
    },

    assignmentTypes(): AssignmentType[] {
      if (!this.isPausableStage) {
        return ["One Click"]
      } else {
        return [...ASSIGNMENT_TYPES];
      }
    },

    filterAttributes(): StateAttributeDef[] {
      if (this.entity && this.entity.entityType) {
        return getAttributeDefsForEntityType(this.entity.entityType);
      } else {
        return [];
      }
    },

    isPausableStage(): boolean {
      return this.selectedStage
        .statuses
        .reduce<boolean>((v, x) => v || (x.isStageIncompleteStatus && !x.isStageCompleteStatus), false)
    },

    lifecycleHash(): LifecycleHash {
      return {
        stages: this.lifecycle
          .stageRecords
          .reduce(
            (h, x) => {h[x.stage.name] = x.stage.color; return h;},
            {} as Record<string, string>
          ),
        statuses: this.lifecycle
          .stageRecords
          .flatMap(x => x.stage.statuses)
          .reduce(
            (h, x) => {h[x.status] = {icon: x.icon, color: x.color}; return h},
            {} as Record<string, {icon: string; color: string}>
          ),
      }
    },

    selectedStage(): LifecycleStage {
      return this.lifecycle.stageRecords.find(x => x.id == this.entity.stageRecordId)?.stage ?? new LifecycleStage();
    },

    stageNames(): string[] {
      return this.lifecycle.stageRecords.map(x => x.stage.name);
    },

    stages(): LifecycleStageRecord[] {
      return this.lifecycle.stageRecords.filter(x => x.stage.type != "Unprocessed" && x.stage.type != "Hold");
    },

    workTypes(): typeof WORK_TYPES {
      return WORK_TYPES;
    }
  },

  methods: {
    chooseUser(): void {
      this.showUserChooser = true;
    },

    clearUser(): void {
      this.emitEditEventForField('defaultAssignee', undefined)
    },

    handleUserSelected(user: InsightUser): void {
      this.emitEditEventForField('defaultAssignee', user);
    },
  }
})
