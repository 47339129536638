
import delay from '@/util/delay';
import { EntityEditorExtraLinkedComponentPanel } from '@/models/core/entityEditor/EntityEditorExtraLinkedComponentPanel';
import VueScrollTo from 'vue-scrollto'

export default {
  props: {
    entityType: {
      type: String,
      default: 'entity'
    },

    openPanels: {
      type: Array,
      default: (): number[] => []
    },
    
    panels: {
      type: Array,
      default: null,
    },
    
    wrapperHtmlId: {
      type: String,
      default: '',
    },
  },

  methods: {
    async scrollTo(
      section: string,
      props: {entityType: string; openPanels: number[]; panels: EntityEditorExtraLinkedComponentPanel[]; wrapperHtmlId: string}
    ): Promise<void> {

      const options = {
        container: 'body',
        easing: 'ease-in',
        duration: 300,
        force: true,
        cancelable: true,
        x: false,
        y: true,
        offset: -100,
      }

      const pane = props.panels.findIndex(x => x.name == section);

      let rescroll = false;

      if (pane > -1 && props.openPanels.indexOf(pane) == -1) {
        props.openPanels.push(pane);
        rescroll = true;
      }

      const ele = `#${props.wrapperHtmlId} .${section}_scroll_target`;

      VueScrollTo.scrollTo(ele, options)

      if (rescroll) {
        await delay(options.duration + 10);
        this.scrollTo(section, props);
      }
    },
  }
}
