
import { AjaxResponse } from 'vue-file-agent/types/src/lib/ajax-request'
import api from '@/util/api'
import EntityLinkParameters from '@/models/core/entities/EntityLinkParameters'
import { FILE_LINK_TO_ENTITY } from '@/store/actionNames'
import { FileRecord } from 'vue-file-agent'
import { GetWerkFileType } from '@/models/files/WerkFileType'
import notifications from '@/util/notifications'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'
import VueFileAgentInterface from '@/models/core/componentInterfaces/VueFileAgentInterface'
import WerkFile from '@/models/files/WerkFile'

export default Vue.extend({
  props: {
    client: {
      type: String,
      default: '',
    },

    linkTarget: {
      type: Object,
      default: null,
    } as PropValidator<PrivateEntitySpec>,

    linkTargetType: {
      type: String,
      default: null,
    },

    vuexRefName: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      fileList: [] as FileRecord[],
      fileRecordsForUpload: [] as FileRecord[],
      uploadHeaders: {} as Record<string, string|undefined>
    }
  },

  computed: {
    uploadUrl(): string {
      return `${api.baseUrl}/${this.client}/files`;
    }
  },

  async mounted() {
    this.uploadHeaders = await api.getWerkHeaders();
  },

  methods: {
    customizeFormData(fileData: FileRecord): FormData {
      const formData = new FormData();
      formData.append('fileData', fileData.file);
      formData.append('name', fileData.file.name);
      formData.append('type', GetWerkFileType(fileData.file.type));
      
      return formData;
    },

    filesSelected(fileRecordsNewlySelected: FileRecord[]): void {
      const validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);

      if (validFileRecords.length < fileRecordsNewlySelected.length) {
        const invalidFileRecords = fileRecordsNewlySelected.filter((fileRecord) => fileRecord.error);

        setTimeout(() => {
          invalidFileRecords.forEach(ir => {
            this.removeFailedFileRecord(ir);
          });

          const suffix = invalidFileRecords.length > 1 ? 's' : '';

          notifications.warn(
            this.$store,
            `${invalidFileRecords.length} file${suffix} had an invalid type. Please convert to an accepted file type and try again.`
          );
        }, 3000)
      }

      if (validFileRecords.length > 0) {
        this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
        this.uploadFiles();
      }
    },

    getFileAgent(): VueFileAgentInterface {
      return this.$refs.vueFileAgent as unknown as VueFileAgentInterface
    },

    onUpload(responses: AjaxResponse[]): void {
      responses.forEach(r => {
        if (r.status >= 200 && r.status < 300) {
          const file = r.data as WerkFile;

          if(this.linkTarget != null) {
            const props: EntityLinkParameters = {
              target: this.linkTarget,
              targetType: this.linkTargetType,
              linkOwner: file as PrivateEntitySpec,
            }

            this.$store.dispatch(FILE_LINK_TO_ENTITY, {link: props, refName: this.vuexRefName});
          }

          this.$emit('file-uploaded', file);

          setTimeout(() => this.removeUploadedFile(file), 1000)
        }
      });
    },

    removeFailedFileRecord(file: FileRecord): void {
      const idx = this.fileList.findIndex(x => x.id == file.id);

      if (idx > -1) {
        this.fileList.splice(idx, 1);
      }
    },

    removeUploadedFile(file: WerkFile): void {
      const idx = this.fileList.findIndex(x => x.file.name == file.name);

      if (idx > -1) {
        this.fileList.splice(idx, 1);
      }
    },

    async uploadFiles(): Promise<void> {
      try {
        await this.getFileAgent().upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload, this.customizeFormData);
      } catch(e: unknown) {
        const err = e as {fileRecord: FileRecord}[];

        if (err[0]) {
          setTimeout(() => {
            this.removeFailedFileRecord(err[0].fileRecord);
            notifications.fail(this.$store, 'Your file was not uploaded.');
          }, 3000);
        }
      }

      this.fileRecordsForUpload = [];
    },
  }
})
