import InsightUser from "@/models/core/InsightUser";

const hues = [
  'red','pink','purple','deep-purple','indigo','blue','light-blue',
  'cyan','teal','green','light-green','lime','yellow','amber',
  'orange','deep-orange','brown','blue-grey'
];

const shades = [
  '', 'lighten-2', 'lighten-4',
  'darken-2', 'darken-4'
];

export function getBackgroundColorForUser(user: InsightUser): string {
  const numericId = parseInt(user.id);
  const hue = hues[numericId % hues.length];
  const shade = shades[numericId % shades.length];

  return `${hue} ${shade}`;
}

export function getForegroundColorForUser(user: InsightUser): string {
  const color = getBackgroundColorForUser(user);

  if (color.indexOf('darken-') > -1) {
    return 'white';
  } else if (color.indexOf('lighten-') > -1) {
    return '';
  } else {
    const parts = color.split(' ');
    const paleColors = ['lime', 'yellow', 'amber'];

    if (paleColors.indexOf(parts[0]) > -1) {
      return '';
    } else {
      return 'white'
    }
  }
}

export function getInitialsForUser(user: InsightUser): string {
  let initials = '';

  const parts = user.name.split(' ');
  initials += parts[0].substr(0,1).toUpperCase();

  if (parts.length > 1) {
    initials += parts[parts.length-1].substr(0,1).toUpperCase();
  }

  return initials
}
