import Vue from 'vue'
import Vuetify from 'vuetify/lib'

const theme = {
  themes: {
    light: {
      primary: '37a7e5', // Light aqua
      secondary: 'f8f8f8', // Light gray
      pageBackground: 'e4f4fc', // Very light blue
      accent: 'b8e0f6', // Light blue-grey
      accentAlt: 'fffb00', // Bright yellow
      info: '693010' // Dark red-brown
    }
  },
  options: {
    customProperties: true
  }
}

Vue.use(Vuetify)

const opts = {
  theme,
}

export default new Vuetify(opts)
