import { EntityType } from "../assignments/AssignmentTemplate";
import { LifecycleStage } from "./LifecycleStage";

export class Lifecycle {
  constructor(
    public closeReasons = [] as string[],
    public stages = [] as LifecycleStage[],
  ) {}
}

export class LifecycleVuexRequest {
  constructor(
    public entityType: EntityType,
    public clientCode: string,
    public lifecycle: Lifecycle,
  ) {}
}