import * as gt from '../getterNames'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF } from '../refNames'
import Client from '@/models/core/Client'
import { GetterTree } from 'vuex'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import RootState from '../RootState'
import State from './state'
import WerkFile from '@/models/files/WerkFile'
import WerkFileListModel from '@/models/files/WerkFileListModel'

export const getters: GetterTree<State, RootState> = {
  [gt.FILES_FOR_CURRENT_CLIENT](state, getters): WerkFileListModel[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getListModelsForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.FILES_LIST_FOR_REF]: (state) => 
    (client: string, refName: string): WerkFileListModel[] => {
      return state.getListModelsForRef(client, refName);
  },

  [gt.FILES_LINKED_TO_ENTITY]: (state) => 
    (entity: PrivateEntitySpec): WerkFileListModel[] => {
      return state.getListModelsForRef(entity.client, ENTITY_EDITOR_LINK_REF_STEM + entity.id);
  },
    
  [gt.FILES_OPEN_FOR_CURRENT_CLIENT] (state, getters): WerkFile[] {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;

    return state.getEntitiesForRef(clientCode, ENTITY_MANAGER_REF);
  },

  [gt.FILES_OPEN_FOR_REF]: (state) => 
    (client: string, refName: string): WerkFile[] => {
      return state.getEntitiesForRef(client, refName);
  },
  
  [gt.FILE_REFERRER_EXISTS]: (state, getters) => (name: string): boolean => {
    const clientCode = (getters[gt.CORE_CLIENT_CURRENT] as unknown as Client).code;
    
    return state.hasReferrer(clientCode, name);
  },
}