
import { AbstractEntityManagerSearchDialogField } from "./AbstractEntityManagerSearchDialogField";
import { PropValidator } from "vue/types/options";
import ReadOnlyField from "../forms/ReadOnlyField.vue";
import { StringQueryVarSet } from "../../../models/core/searchQueries/StringQueryVarSet";

export default AbstractEntityManagerSearchDialogField.extend({
  components: {
    ReadOnlyField
  },

  props: {
    locked: Boolean,
    
    value: {
      type: Object,
      default: () => new StringQueryVarSet(""),
    } as PropValidator<StringQueryVarSet>,
  },

  methods: {
    handleOperatorInput(payload: 'is'|'contains'): void {
      const exactMatch = payload == 'is';

      this.$emit('input', new StringQueryVarSet(this.value.value, exactMatch));
    },
    
    handleTextInput(payload: string): void {
      this.$emit('input', new StringQueryVarSet(payload, this.value.exactMatch));
    }
  }
})
