import { render, staticRenderFns } from "./MessageAttachments.vue?vue&type=template&id=543c7093&"
import script from "./MessageAttachments.vue?vue&type=script&lang=ts&"
export * from "./MessageAttachments.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports