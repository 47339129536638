
import { handleEvent } from '@/util/FunctionalComponentEventDispatcher'

export default {
  props: {
    buttonColor: {
      type: String,
      default: '',
    },

    pulse: Boolean,

    disabled: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    noTooltip: Boolean,

    outlined: Boolean,

    tooltip: {
      type: String,
      default: '',
    },

    small: {
      type: Boolean,
      default: true,
    },

    tooltipColor: {
      type: String,
      default: 'info',
    },

    xSmall: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    handleEvent,
  }
}
