
import InsightUser from '@/models/core/InsightUser';
import UserAutosuggestField from './forms/UserAutosuggestField.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    UserAutosuggestField,
  },

  props: {
    cancelText: {
      type: String,
      default: 'Cancel',
    },

    show: Boolean,

    title: {
      type: String,
      default: 'Select User',
    }
  },

  methods: {
    handleSelection(user: InsightUser|null): void {
      if (user) {
        this.$emit('user-selected', user);
      }

      this.$emit('update:show', false);
    },
  }
})
