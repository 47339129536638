import { BufferFieldOverrideCollection } from "@/models/core/bufferedEditor/BufferFieldOverrides";

export const StandardEntityFieldOverrides: BufferFieldOverrideCollection = {
  createdAt: 'system',
  createdBy: 'system',
  modifiedAt: 'system',
  modifiedBy: 'system',
  readyActions: 'system',
  deletedAt: 'system',
  deletedBy: 'system',
  tempId: 'ignore',
}