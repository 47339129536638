import * as ac from '../store/actionNames'
import * as mt from '../store/mutationNames'
import { CORE_CLIENT_CURRENT, CORE_CLIENT_INDEX_BY_CODE } from '@/store/getterNames'
import { AuthorizableRoute } from 'insight-auth/src/models/AuthorizableRoute'
import Client from '@/models/core/Client'
import Mailbox from '@/models/mailboxes/Mailbox'
import { MAILBOX_GLOBAL_REF } from '@/store/refNames'
import { MailboxBelongsToUserSpec } from '@/models/mailboxes/MailboxBelongsToUserSpec'
import { RealtimeService } from './realtimeUpdates'
import RootState from '@/store/RootState'
import { Store } from 'vuex'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'

export async function loadClientConfiguration(clientCode: string, store: Store<RootState>): Promise<void> {
  if (clientCode > '') {
    const clientIdx = store.getters[CORE_CLIENT_INDEX_BY_CODE](clientCode);
    store.commit(mt.CORE_CLIENT_SET_CURRENT, clientIdx);
  }

  const client = store.getters[CORE_CLIENT_CURRENT] as Client;

  //Load users
  await store.dispatch(ac.CORE_FETCH_USERS_FOR_CLIENT);

  RealtimeService.setClient(client.code);

  //Load mailboxes
  await loadMailboxes(store, client.code);
}

async function loadMailboxes(store: Store<RootState>, client: string): Promise<void> {
  const mailboxSpec = new MailboxBelongsToUserSpec(store.state.userId);
  
  const referrer = new VuexEntityReferer(
    client,
    MAILBOX_GLOBAL_REF,
    (entity, mode) =>  {
      if (mode == 'entity') {
        return 'no';
      } else {
        return mailboxSpec.isSatisfiedBy(entity as Mailbox) ? 'yes' : 'no';
      }
    }
  );

  store.commit(mt.MAILBOX_ADD_REFERRER, referrer);

  await store.dispatch(ac.MAILBOX_FETCH, {refName: MAILBOX_GLOBAL_REF, client})
}

export const isAppDataLoaded = (store: Store<RootState>): boolean => {
  return store.state.appDataLoaded == 'yes'
}

export const loadAppData = async (store: Store<RootState>, route: AuthorizableRoute): Promise<boolean> => {
  try {
    // Load clients
    const p1 = store.dispatch(ac.CORE_CLIENT_FETCH_ALL)

    //Load app config
    const p2 = store.dispatch(ac.CORE_FETCH_APP_CONFIG);

    //Wait for the first requests to finish
    await Promise.all([p1, p2]);

    //Initialize real-time services
    const key = store.state.appConfig.realTimeKey;
    RealtimeService.init(key);

    //Initialize the client-specific data
    const clientCode = route.params['clientCode'] ?? '';
    await loadClientConfiguration(clientCode, store);

    store.commit(mt.CORE_SET_APP_DATA_LOAD_STATE, 'yes')
    return true;
  } catch (e) {
    store.commit(mt.CORE_SET_APP_DATA_LOAD_STATE, 'failed')
    return false;
  }
}
