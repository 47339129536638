export type VueEventListener = (evt: unknown) => void

export function handleEvent(listeners: Record<string, VueEventListener|VueEventListener[]>, eventName: string, event: never): void {

  const filteredListeners = listeners[eventName];

  if (!filteredListeners) {
    return;
  } else if (typeof filteredListeners === 'function') {
    filteredListeners(event);
  } else {
    filteredListeners.forEach(l => {
      l(event);
    });
  }
}