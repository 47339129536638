import { ContactCollection } from "./ContactCollection";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";

export class ContactCollectionListModel extends PrivateEntityListModel<ContactCollection> {
  public name = "";
  public createdBy = ""

  protected populateCore(model: ContactCollection): void {
    this.name = model.name;
    this.createdBy = model.createdBy ?? "unknown";
  }

}