import { AssignmentTemplate, AssignmentTemplateListModel } from '@/models/assignments/AssignmentTemplate';
import { LifecycleRecord } from '@/models/lifecycles/LifecycleRecord';
import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';

export default class State extends VuexModuleStateBase<AssignmentTemplate, AssignmentTemplateListModel> {
  
  public lifecycleRecords = {} as Record<string, LifecycleRecord>;

  protected instantiateListModelForEntity(entity: AssignmentTemplate): AssignmentTemplateListModel {
    const listModel = new AssignmentTemplateListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'AssignmentTemplate'
  }
}