
import { CORE_NEXT_POPUP_REQUEST, CORE_POPUP_REQUEST_COUNT } from '@/store/getterNames'
import { CORE_REMOVE_POPUP_REQUEST } from '@/store/mutationNames';
import delay from '@/util/delay';
import PopupRequest from '@/models/core/PopupRequest';
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      show: false,
    }
  },

  computed: {
    popupRequestCount(): number {
      return this.$store.getters[CORE_POPUP_REQUEST_COUNT];
    },

    nextPopupRequest(): PopupRequest | null {
      return this.$store.getters[CORE_NEXT_POPUP_REQUEST];
    },

    title(): string {
      return this.nextPopupRequest?.title || 'Confirm Action';
    },

    message(): string {
      return this.nextPopupRequest?.msg || 'Choose a button below';
    },

    yesText(): string {
      return this.nextPopupRequest?.yesText || 'Yes';
    },

    noText(): string {
      return this.nextPopupRequest?.noText || 'No';
    },

    showCancelButton(): boolean {
      return this.showNoButton && (this.nextPopupRequest?.showCancel ?? false)
    },

    showNoButton(): boolean {
      return this.nextPopupRequest?.mode != 'alert';
    },

    warning(): string {
      return this.nextPopupRequest?.warning || '';
    }
  },

  watch: {
    popupRequestCount(): void {
      if (this.popupRequestCount > 0 && this.show == false) {
        this.$nextTick(() => this.show = true)
      }
    }
  },

  methods: {
    async dismiss(mode: 'yes'|'no'|'cancel'): Promise<void> {
      if (this.nextPopupRequest) {
        if (this.nextPopupRequest.mode == 'alert') {
          this.nextPopupRequest.resolve(true);
        } else {
          if (mode == 'cancel') {
            this.nextPopupRequest.reject();
          } else {
            this.nextPopupRequest.resolve(mode == 'yes');
          }
        }

        this.show = false;
        await delay(200);
        this.$store.commit(CORE_REMOVE_POPUP_REQUEST, 0);
      }
    }
  }
})
