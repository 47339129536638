export enum WerkFileType {
  Excel = "xlsx",
  Jpeg = "jpg",
  Pdf = "pdf",
  Png = "png",
  Text = "txt",
  Word = "docx",
}

export function GetWerkFileColor(type: WerkFileType): string {
  if (type == WerkFileType.Pdf) {
    return 'red';

  } else if (type == WerkFileType.Excel) {
    return 'green';

  } else if (type == WerkFileType.Text) {
    return 'blue-grey';

  } else if (type == WerkFileType.Jpeg) {
    return 'purple';

  } else if (type == WerkFileType.Png) {
    return 'amber';

  } else if (type == WerkFileType.Word) {
    return 'blue';

  } else {
    return 'brown';
  }
}

export function GetWerkFileIcon(type: WerkFileType): string {
  if (type == WerkFileType.Pdf) {
    return 'mdi-file-pdf-box';

  } else if (type == WerkFileType.Excel) {
    return 'mdi-file-table-outline';

  } else if (type == WerkFileType.Text) {
    return 'mdi-file-document-outline';

  } else if (type == WerkFileType.Jpeg || type == WerkFileType.Png) {
    return 'mdi-file-image-outline';

  } else if (type == WerkFileType.Word) {
    return 'mdi-file-word-outline';

  } else {
    return 'mdi-file-outline';
  }
}

export function GetWerkFileType(mimeType: string): string {
  if (mimeType == 'application/pdf') {
    return WerkFileType.Pdf
  } else if (mimeType == 'text/plain') {
    return WerkFileType.Text
  } else if (mimeType == 'image/jpeg') {
    return WerkFileType.Jpeg
  } else if (mimeType == 'image/png') {
    return WerkFileType.Png
  } else if (mimeType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return WerkFileType.Word
  } else if (mimeType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return WerkFileType.Excel
  } else {
    return '';
  }
}

export function GetWerkFileTypeDescription(type: WerkFileType): string {
  if (type == WerkFileType.Pdf) {
    return 'PDF';

  } else if (type == WerkFileType.Excel) {
    return 'Spreadsheet';

  } else if (type == WerkFileType.Text) {
    return 'Text File';

  } else if (type == WerkFileType.Jpeg || type == WerkFileType.Png) {
    return 'Image';

  } else if (type == WerkFileType.Word) {
    return 'Document';

  } else {
    return 'Type Unknown';
  }
}