
import { CORE_CLIENT_CURRENT, CORE_GET_GENERIC_PAGER, MAILBOXES_FOR_CURRENT_USER, MESSAGE_REFERRER_EXISTS, MESSAGES_LISTED_FOR_REF } from '@/store/getterNames';
import { CORE_GENERIC_PAGER_CREATE, MESSAGE_ADD_REFERRER } from '@/store/mutationNames';
import { ApiResponse } from '@/models/core/api/ApiResponse';
import Client from '@/models/core/Client';
import { GenericPager } from '@/models/core/entities/EntityPagingData';
import MailboxListModel from '@/models/mailboxes/MailboxListModel';
import { MESSAGE_CHOOSER_REF } from '@/store/refNames';
import { MESSAGE_FETCH_FOR_MAILBOX } from '@/store/actionNames';
import MessageListModel from '@/models/messages/MessageListModel';
import { MessageState } from '@/models/messages/MessageEnums';
import notifications from '@/util/notifications';
import reformatDate from '@/util/reformatDate';
import { SortByAddressThenTypeThenName } from '@/models/mailboxes/MailboxSortFunction';
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer';

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loadingMessageList: false,
      mailboxId: "0",
      search: '',
    }
  },

  computed: {
    client(): string {
      const client = this.$store.getters[CORE_CLIENT_CURRENT] as Client;
      return client.code;
    },

    clientPageCount(): number {
      return Math.ceil((this.pager?.serverSideEntityCount ?? this.messages.length) / this.clientPageSize);
    },
    
    clientPageNumber: {
      get(): number {
        return this.pager?.clientPageNumber ?? 1
      },

       set(value: number) {
          if (this.pager) {
            const newPager = Object.assign({}, this.pager);
            newPager.clientPageNumber = value;
            this.$store.commit(CORE_GENERIC_PAGER_CREATE, newPager);
          }
       }
    },

    clientPageSize(): number{
      return 12;
    },
    
    currentEntityPage(): MessageListModel[] {
      const clientPageIdx = this.clientPageNumber - 1;
      const clientPageFirstRecord = (clientPageIdx * this.clientPageSize);
      const clientPageLastRecord = (clientPageIdx + 1) * this.clientPageSize;

      const offset = (this.pager?.serverPageNumber ?? 0) * (this.pager?.serverPageSize ?? 48);

      const startIdx = Math.max(clientPageFirstRecord - offset, 0);
      const endIdx = Math.max(clientPageLastRecord - offset, 0);

      return this.messages.slice(startIdx, endIdx);
    },

    entityCountForQuery(): number {
      return this.pager?.serverSideEntityCount ?? 0;
    },
    
    firstRowNumberInList(): number {
      return ((this.pager?.serverPageNumber ?? 0) * (this.pager?.serverPageSize ?? 48)) + 1;
    },

    firstRowNumberOnScreen(): number {
      return this.entityCountForQuery == 0 ? 0 : (this.clientPageSize * (this.clientPageNumber - 1)) + 1;
    },

    formattedMailboxList(): {text: string; value: string}[] {
      const mailboxes = this.$store.getters[MAILBOXES_FOR_CURRENT_USER] as MailboxListModel[];
      
      mailboxes.sort(SortByAddressThenTypeThenName);

      return mailboxes.map(x => {return {text: `${x.name} (${x.address})`, value: x.id}});
    },

    lastRowNumberInList(): number {
      const lastInPage = ((this.pager?.serverPageNumber ?? 0) + 1) * (this.pager?.serverPageSize ?? 48);
      const realLastRowNum = Math.min(lastInPage, this.entityCountForQuery);
      return realLastRowNum;
    },

    lastRowNumberOnScreen(): number {
      const lastInPage = (this.clientPageNumber) * this.clientPageSize;
      return Math.min(lastInPage, this.entityCountForQuery);
    },

    messages(): MessageListModel[] {
      return this.$store.getters[MESSAGES_LISTED_FOR_REF](this.client, this.refName);
    },

    pager(): GenericPager|null {
      return this.$store.getters[CORE_GET_GENERIC_PAGER](this.refName) ?? null
    },

    receivedState(): MessageState {
      return MessageState.Recieved
    },

    refName(): string {
      return MESSAGE_CHOOSER_REF
    },

    referrerExists(): boolean {
      return this.$store.getters[MESSAGE_REFERRER_EXISTS](this.refName);
    },
  },

  watch: {
    show(): void {
      if (this.show) {
        if (!this.referrerExists) {
          this.configurePager();
          this.configureEntityReferrer();
          
          if (this.mailboxId == "0") {
            this.mailboxId = this.formattedMailboxList[0]?.value ?? "0";
          }

          this.loadEntities();
        }
      }
    }
  },

  methods: {
    checkFetchServerPage(newClientPageNumber: number): void {
      const firstRequestedRecord = ((newClientPageNumber -1) * this.clientPageSize) + 1;
      const firstLoadedRecord = this.firstRowNumberInList;
      const lastLoadedRecord = this.lastRowNumberInList;

      if (firstRequestedRecord > lastLoadedRecord || firstRequestedRecord < firstLoadedRecord) {
        const requiredServerPage = Math.floor(firstRequestedRecord / (this.pager?.serverPageSize ?? 48));
        this.loadEntities(requiredServerPage);
      }
    },

    configureEntityReferrer(): void {
      const referrer = new VuexEntityReferer(
        this.client,
        this.refName,
        (entity, mode) =>  {
          if (mode == 'listModel'){
            return 'yes';
          }

          return 'no';
        }
      );

      this.$store.commit(MESSAGE_ADD_REFERRER, referrer)
    },

    configurePager(): void {
      if (this.pager == null) {
        const pager = new GenericPager(this.refName, 'createdAt', false, 1, 48);
        this.$store.commit(CORE_GENERIC_PAGER_CREATE, pager);
      }
    },

    formatDateCreated(createdDate: Date|string|null|undefined): string {
      return reformatDate(createdDate, 'mm/dd/yyyy hh:MM tt');
    },

    async loadEntities(pageNumber = 0): Promise<void> {
      this.loadingMessageList = true;

      try {
        const req = {
          requestedMailboxId: this.mailboxId, 
          requestedRefName: this.refName,
          pageSize: 48,
          pageNumber,
        }

        await this.$store.dispatch(MESSAGE_FETCH_FOR_MAILBOX, req)
      } catch (error) {
        const e = error as ApiResponse;
        notifications.warn(this.$store, `There was an error while loading the message list. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
      }

      this.loadingMessageList = false;
    },

    onDialogInput(show: boolean): void {
      this.$emit('update:show', show);
    },

    onMessageClick(message: MessageListModel): void {
      this.$emit("message-selected", message);
      this.$emit('update:show', false);
    },
  }
})
