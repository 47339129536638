
import Vue from 'vue'

class HighlighterFragment {
  constructor(
    public text: string,
    public highlight: boolean,
  ) {}
}

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: '',
    },

    highlightText: {
      type: String,
      default: '',
    }
  },

  computed: {
    fragments(): HighlighterFragment[] {
      const delimiter = '{{{|||SPLIT|||}}}';
      const pattern = new RegExp(`(${this.highlightText})`, 'gi');
      const delimited = this.text.replaceAll(pattern, `${delimiter}$1${delimiter}`);
      const parts = delimited.split(delimiter);

      const fragments = [] as HighlighterFragment[];

      parts.forEach(part => {
        fragments.push(new HighlighterFragment(part, part.toLowerCase() == this.highlightText.toLowerCase()));
      });

      return fragments;
    }
  }
})
