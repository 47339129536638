import VuexModuleStateBase from '@/models/core/vuex/VuexModuleStateBase';
import WerkFile from '@/models/files/WerkFile';
import WerkFileListModel from '@/models/files/WerkFileListModel';

export default class State extends VuexModuleStateBase<WerkFile, WerkFileListModel> {
  protected instantiateListModelForEntity(entity: WerkFile): WerkFileListModel {
    const listModel = new WerkFileListModel();
    listModel.populate(entity);
    return listModel;
  }

  protected getEntityTypeName(): string {
    return 'File'
  }
}