import { BufferTraversalPath } from "./BufferTraversalPath";
import UpdatableEntity from "../entities/UpdatableEntity";

export function nodeIsEntityArray(...params: unknown[][]): boolean {

  for (let i = 0; i < params.length; i++) {
    const arr = params[i];
    
    if (arr.length > 0) {
      if (arr[0]) {
        const arrEle = arr[0] as UpdatableEntity;
        if (arrEle.id != undefined) {
          return true;
        }
      }
    }
  }

  return false;
}

export function forEachKeyIn(obj: never, callback: (k: string) => void) {
  const keys = Object.keys(obj);

  keys.forEach(callback);
}

export function getPathsForPropsNamedInArray(
  propName: string,
  arr: never[],
  basePath: BufferTraversalPath
): BufferTraversalPath[] {
  const paths = [] as BufferTraversalPath[];

  for (let i = 0; i < arr.length; i++) {
    const e = arr[i];
    
    if (Array.isArray(e)) {
      paths.push(
        ...getPathsForPropsNamedInArray(
          propName,
          e as never[],
          basePath.cloneAndAddLevel(new BufferTraversalPath(i, "index"))
        )
      );
    } else if ((e as unknown) instanceof Object) {
      paths.push(
        ...getPathsForPropsNamed(
          propName,
          e,
          basePath.cloneAndAddLevel(new BufferTraversalPath(i, "index"))
        )
      );
    }
  }

  return paths;
}

export function getPathsForPropsNamed(
  propName: string,
  obj: never,
  basePath = new BufferTraversalPath('<root>')
): BufferTraversalPath[] {
  const paths = [] as BufferTraversalPath[];

  forEachKeyIn(obj, k => {
    if (k == propName) {
      paths.push(basePath.cloneAndAddLevel(new BufferTraversalPath(k)));
    } else if (Array.isArray(obj[k])) {
      paths.push(
        ...getPathsForPropsNamedInArray(
          propName,
          obj[k] as never[],
          basePath.cloneAndAddLevel(new BufferTraversalPath(k))
        )
      );
    } else if ((obj[k] as unknown) instanceof Object) {
      paths.push(
        ...getPathsForPropsNamed(
          propName,
          obj[k],
          basePath.cloneAndAddLevel(new BufferTraversalPath(k))
        )
      );
    }
  });

  return paths;
}