import { Comment } from "./Comment"
import EntityLink from "../core/entities/EntityLink";
import { Mention } from "./Mention";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";

export class CommentListModel extends PrivateEntityListModel<Comment> {
  public createdAt = "";
  public createdBy = "";
  public links: EntityLink[] = [];
  public mentions: Mention[] = [];
  public text = "";
  
  protected populateCore(model: Comment): void {
    this.text = model.text;
    this.createdAt = model.createdAt ?? '';
    this.createdBy = model.createdBy ?? '';
    this.mentions = model.mentions;
    this.links = model.links ?? [];
  }
  
}