import * as ac from '../actionNames'
import * as ah from '../_actionHelpers'
import * as gt from '../getterNames'
import * as mt from '../mutationNames'
import { ENTITY_EDITOR_LINK_REF_STEM, ENTITY_MANAGER_REF } from '../refNames';
import { ActionTree } from "vuex";
import api from '@/util/api';
import ApiDeletePayload from '@/models/core/api/ApiDeletePayload';
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef';
import EntityActionResult from '@/models/core/api/EntityActionResult';
import EntityLinkParameters from '@/models/core/entities/EntityLinkParameters';
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec';
import RootState from '../RootState';
import State from './state';
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest';
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest';
import WerkFile from '@/models/files/WerkFile';
import WerkFileListModel from '@/models/files/WerkFileListModel';

export const actions: ActionTree<State, RootState> = {

  //***********************************************************************
  async [ac.FILE_DELETE] ({ commit }, data: ApiDeletePayload<WerkFile>): Promise<void> {
    const url = data.entity.client + '/files/' + data.entity.id

    return ah.deletePrivateEntity(data.entity, url, data.force, mt.FILE_PURGE, commit, data.hard)
  },

  //***********************************************************************
  async [ac.FILE_FETCH] ({ commit, getters }, req: VuexFetchRequest): Promise<WerkFileListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/files`
    return ah.getEntityList(
      url,
      client,
      mt.FILE_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false);
  },

  //***********************************************************************
  async [ac.FILE_SEARCH] ({ commit, getters }, req: VuexSearchRequest): Promise<WerkFileListModel[]> {
    const client = req.client || (getters[gt.CORE_CLIENT_CURRENT] || {}).code
    const url = `${client}/files`
    return ah.doEntitySearch(
      url,
      client,
      req.searchValues,
      mt.FILE_STORE_LIST_MODELS_FOR_REF,
      req.refName,
      commit,
      req.includeDeleted ?? false,
      req.pagerId,
      req.pageSize,
      req.pageNumber,
      req.pageDataMutationName,
      req.orderBy,
      req.orderDescending,
    )
  },

  //***********************************************************************
  async [ac.FILE_FETCH_FOR_ENTITY] ({ commit }, entity: PrivateEntitySpec): Promise<WerkFileListModel[] | null> {
    const url = `${entity.client}/files/linkedTo/${entity.id}`
    const apiResponse = await api.get(url);

    if (apiResponse) {
      if (!apiResponse.error) {
        const files = apiResponse.data as WerkFileListModel[];

        const mutationData: EntitiesForRef<WerkFileListModel> = {
          entities: files,
          refName: ENTITY_EDITOR_LINK_REF_STEM + entity.id,
        }

        commit(mt.FILE_STORE_LIST_MODELS_FOR_REF, mutationData);

        return files;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.FILE_LOAD] ({ commit }, request: VuexEntityRefSpec): Promise<WerkFile | null> {
    const url = request.spec.client + '/files/' + request.spec.id

    return ah.getPrivateEntity<WerkFile>(url, mt.FILE_STORE_ENTITY_FOR_REF, request.refName, commit)
  },

  //***********************************************************************
  async [ac.FILE_SAVE] ({ commit }, data: {entity: WerkFile; refName: string}): Promise<WerkFile | null> {
    const url = data.entity.client + '/files'

    let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

    if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
      const targetEntityId = data.refName.substr(ENTITY_EDITOR_LINK_REF_STEM.length);

      if (data.entity.links && data.entity.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.Add;
      } else if (data.entity.requestedLinks && data.entity.requestedLinks.findIndex(x => x.id == targetEntityId) >= 0) {
        mode = VuexEntityStorageRequestMode.Add;
      }
    } else if (data.refName == ENTITY_MANAGER_REF) {
      mode = VuexEntityStorageRequestMode.Add
    }

    return ah.createOrUpdatePrivateEntity(data.entity, url,
      mt.FILE_STORE_ENTITY_FOR_REF, data.refName, commit, mode)
  },

  //***********************************************************************
  async [ac.FILE_UNDELETE] ({ commit }, entity: WerkFile): Promise<WerkFile | null> {
    const url = entity.client + '/files'


    return ah.executeActionOnEntity(entity, url, 'undelete',
      mt.FILE_STORE_ENTITY_FOR_REF, commit);
  },
  
  //***********************************************************************
  async [ac.FILE_UNLINK_FROM_ENTITY] ({ commit }, data: EntityLinkParameters): Promise<WerkFile | null> {
    const url = `${data.linkOwner.client}/files/${data.linkOwner.id}/actions/unlink/${data.targetType}/${data.target.id}`

    const apiResponse = await api.create(url, null);

    if (apiResponse) {
      if (!apiResponse.error) {
        const actionResult = apiResponse.data as EntityActionResult<WerkFile>
        const file = actionResult.entity;

        commit(mt.FILE_STORE_ENTITY_FOR_REF, {entity: file, refName: '', mode: 'updateOnly'})

        return file;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  },

  //***********************************************************************
  async [ac.FILE_LINK_TO_ENTITY] ({ commit }, data: {link: EntityLinkParameters; refName: string}): Promise<WerkFile | null> {
    const url = `${data.link.linkOwner.client}/files/${data.link.linkOwner.id}/actions/link/${data.link.targetType}/${data.link.target.id}`

    const apiResponse = await api.create(url, null);

    if (apiResponse) {
      if (!apiResponse.error) {
        const actionResult = apiResponse.data as EntityActionResult<WerkFile>
        const file = actionResult.entity;

        let mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.NoListModel;

        if (data.refName == ENTITY_MANAGER_REF) {
          mode = VuexEntityStorageRequestMode.Add;

        } else if (data.refName.indexOf(ENTITY_EDITOR_LINK_REF_STEM) == 0) {
          const targetEntityId = data.refName.substr(ENTITY_EDITOR_LINK_REF_STEM.length);

          if (file.links && file.links.findIndex(x => x.entityId == targetEntityId) >= 0) {
            mode = VuexEntityStorageRequestMode.Add;
          }
        }

        commit(mt.FILE_STORE_ENTITY_FOR_REF, {entity: file, refName: data.refName, mode: mode})

        return file;
      } else {
        return Promise.reject(apiResponse);
      }
    } else {
      return Promise.reject("No response from API");
    }
  }
}