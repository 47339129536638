import { render, staticRenderFns } from "./ImageViewer.vue?vue&type=template&id=0fd1952e&"
import script from "./ImageViewer.vue?vue&type=script&lang=ts&"
export * from "./ImageViewer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports